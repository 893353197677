import { useCallback } from "react";

export const useTextmenuCommands = (editor) => {
  const onBold = useCallback(
    () => editor.chain().focus().toggleBold().run(),
    [editor]
  );
  const onItalic = useCallback(
    () => editor.chain().focus().toggleItalic().run(),
    [editor]
  );
  const onStrike = useCallback(
    () => editor.chain().focus().toggleStrike().run(),
    [editor]
  );
  const onUnderline = useCallback(
    () => editor.chain().focus().toggleUnderline().run(),
    [editor]
  );
  const onCode = useCallback(
    () => editor.chain().focus().toggleCode().run(),
    [editor]
  );
  const onCodeBlock = useCallback(
    () => editor.chain().focus().toggleCodeBlock().run(),
    [editor]
  );

  const onSubscript = useCallback(
    () => editor.chain().focus().toggleSubscript().run(),
    [editor]
  );
  const onSuperscript = useCallback(
    () => editor.chain().focus().toggleSuperscript().run(),
    [editor]
  );
  const onAlignLeft = useCallback(
    () => editor.chain().focus().setTextAlign("left").run(),
    [editor]
  );
  const onAlignCenter = useCallback(
    () => editor.chain().focus().setTextAlign("center").run(),
    [editor]
  );
  const onAlignRight = useCallback(
    () => editor.chain().focus().setTextAlign("right").run(),
    [editor]
  );
  const onAlignJustify = useCallback(
    () => editor.chain().focus().setTextAlign("justify").run(),
    [editor]
  );

  const onChangeColor = useCallback(
    (color) => editor.chain().setColor(color).run(),
    [editor]
  );
  const onClearColor = useCallback(
    () => editor.chain().focus().unsetColor().run(),
    [editor]
  );

  const onChangeHighlight = useCallback(
    (color) => editor.chain().setHighlight({ color }).run(),
    [editor]
  );
  const onClearHighlight = useCallback(
    () => editor.chain().focus().unsetHighlight().run(),
    [editor]
  );
  //ask researchpal
  // const onAskResearchPal = useCallback(
  //   () => {
  //     if (apiData) {
  //       // Use the fetched data here
  //       editor.chain().focus().insertText(apiData).run(); // Example usage
  //     }
  //   },
  //   [editor, apiData] // Include apiData in dependencies
  // );
  const onEmojify = useCallback(
    () => editor.chain().focus().aiEmojify().run(),
    [editor]
  );
  const onCompleteSentence = useCallback(
    () => editor.chain().focus().aiComplete().run(),
    [editor]
  );
  const onFixSpelling = useCallback(
    () => editor.chain().focus().aiFixSpellingAndGrammar().run(),
    [editor]
  );
  const onMakeLonger = useCallback(
    () => editor.chain().focus().aiExtend().run(),
    [editor]
  );
  const onMakeShorter = useCallback(
    () => editor.chain().focus().aiShorten().run(),
    [editor]
  );
  // const onTldr = useCallback(() => editor.chain().focus().aiTldr().run(), [editor]);
  const onTone = useCallback(
    (tone) => editor.chain().focus().aiAdjustTone(tone).run(),
    [editor]
  );
  const onTranslate = useCallback(
    (language) => editor.chain().focus().aiTranslate(language).run(),
    [editor]
  );
  const onLink = useCallback(
    (url, inNewTab) =>
      editor
        .chain()
        .focus()
        .setLink({ href: url, target: inNewTab ? "_blank" : "" })
        .run(),
    [editor]
  );

  const onSetFont = useCallback(
    (font) => {
      if (!font || font.length === 0) {
        return editor.chain().focus().unsetFontFamily().run();
      }
      return editor.chain().focus().setFontFamily(font).run();
    },
    [editor]
  );

  const onSetFontSize = useCallback(
    (fontSize) => {
      if (!fontSize || fontSize.length === 0) {
        return editor.chain().focus().unsetFontSize().run();
      }
      return editor.chain().focus().setFontSize(fontSize).run();
    },
    [editor]
  );

  const onLineHeight = useCallback(
    (lineHeight) => {
      if (!lineHeight || lineHeight.length === 0) {
        return editor.chain().focus().unsetLineHeight().run(); //
      }
      return editor.chain().focus().setLineHeight(lineHeight).run(); //why its giving erroron setLineHeight is not a function
      //a:
    },
    [editor]
  );
  return {
    onBold,
    onItalic,
    onStrike,
    onUnderline,
    onCode,
    onCodeBlock,
    onSubscript,
    onSuperscript,
    onAlignLeft,
    onAlignCenter,
    onAlignRight,
    onAlignJustify,
    onChangeColor,
    onClearColor,
    onChangeHighlight,
    onClearHighlight,
    onSetFont,
    onSetFontSize,
    // onAskResearchPal,
    onEmojify,
    onCompleteSentence,
    onFixSpelling,
    onMakeLonger,
    onMakeShorter,
    // onTldr,
    onTone,
    onTranslate,
    onLink,
    onLineHeight,
  };
};
