export const Publications = [
    "Review",
    "JournalArticle",
    "CaseReport",
    "ClinicalTrial",
    "Editorial",
    "LettersAndComments",
    "MetaAnalysis",
    "News",
    "Study",
    "Book",
    "BookSection",
  ];
  