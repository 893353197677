import { Circle, Close, Restore } from "@mui/icons-material";
import { Box, Button, Chip, Drawer, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getHistory } from "../../../../../apiservice";
import Toast from "../../../../../components/Toast";
import moment from "moment";
import TailSpinLoader from "../../../../../components/TailSpinLoader";

const History = ({
  anchor,
  open,
  toggleDrawer,
  setOpenHistoryDialog,
  historyClick,
}) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) {
      getHistoryData();
    }
  }, [open]);

  const getHistoryData = async () => {
    try {
      setLoading(true);
      const response = await getHistory({
        projectId: localStorage.getItem("projectId"),
      });
      setHistoryData(response);
      setLoading(false);
      console.log("response:", response);
    } catch (e) {
      console.log(e);
      Toast("error", "No Data Found!");
    }
    // const data = await response.json();
    // return data;
  };

  const convertUnixToDate = (unixTimestamp) => {
    var date = new Date(unixTimestamp * 1000);

    return date;
  };

  return (
    <Drawer
      variant="temporary"
      anchor={anchor}
      open={open}
      elevation={4}
      onClose={toggleDrawer(anchor, false)}
      sx={{
        zIndex: 10000,
        "& .MuiDrawer-paper": {
          width: "300px",
        },
      }}
    >
      {" "}
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ padding: "10px" }}
      >
        <Stack direction={"row"} width={"100%"} spacing={0}>
          <Close
            onClick={toggleDrawer(anchor, false)}
            color="#EFEFEF"
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Typography>Saved History</Typography>
      </Stack>
      <Box p={1}>
        {historyData?.documentHistory?.length > 0 ? (
          historyData?.documentHistory?.map((item, index) => (
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={4}
              p={1}
              my={1}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "5px",

                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#e6e6e6",
                },
              }}
              onClick={() => {
                historyClick(item);
              }}
            >
              <Restore />
              <Stack>
                <Box
                  gap={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {new Date(item.date * 1000).toLocaleDateString()}
                  </Typography>
                  <Typography>
                    {new Date(item.date * 1000).toLocaleTimeString()}
                  </Typography>
                </Box>
                {index === 0 && (
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Circle
                      fontSize="8px"
                      sx={{
                        color: "#51A79B",
                        fontSize: "8px",
                      }}
                    />
                    <Typography fontSize={"12px"} fontFamily={"Raleway"}>
                      Current Version
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Stack>
          ))
        ) : loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "30vh",
            }}
          >
            <TailSpinLoader />
          </Box>
        ) : (
          <Stack
            direction={"row"}
            width={"100%"}
            spacing={2}
            sx={{
              padding: "10px",
            }}
          >
            <Restore />
            <Typography>No history available</Typography>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default History;
