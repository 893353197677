import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Tooltip, styled, tooltipClasses } from "@mui/material";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#ffffff",
    fontFamily: "Raleway",
    fontWeight: 500,
    color: "#353535",
    boxShadow: "0px 2px 4px 0px #0000002E",
    textAlign: "center",
    padding: "10px 20px",
    fontSize: "14px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
  },
});

const CopyButton = ({ text, file = "" }) => {
  const [copy, setCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCopy = () => {
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, 1000);

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy(true);
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  return (
    <Box
      sx={{
        background: "#3535351A",
        height: "30px",
        width: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
      }}
    >
      <CustomWidthTooltip open={isOpen} arrow title="Copied !">
        <ContentCopyIcon
          sx={{
            mx: 0.8,
            fontSize: "20px",
            color: "#35353599",
            cursor: "pointer",
            "&:hover": {
              color: "#353535",
            },
          }}
          onClick={handleCopy}
        />
      </CustomWidthTooltip>
    </Box>
  );
};

export default CopyButton;
