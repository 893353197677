import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TailSpinLoader from "../../components/TailSpinLoader";
import { TailSpin } from "react-loader-spinner";

function EditReferenceDialog({
  open,
  onClose,
  reference,
  onSubmit,
  editLoading,
}) {
  const [editedReference, setEditedReference] = useState(reference);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReference(value);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // Call the onSubmit function with the edited reference details
    onSubmit(editedReference);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{
        padding: "0px !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Edit Reference</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          name="title"
          label="Reference Title"
          type="text"
          fullWidth
          value={editedReference} //q::why i am unable to change the value of the textfield
          //a::because the value is set to reference instead of editedReference
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          sx={{
            textTransform: "none",
            background: "#ffffff !important",
            color: "#353535",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{
            textTransform: "none",
            // background: "#353535 !important",
            background: "#1E4F6B !important",
          }}
          onClick={handleSubmit}
        >
          {editLoading ? (
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={1}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"10px"}>Saving ...</Typography>
            </Stack>
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditReferenceDialog;
