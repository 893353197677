import { Editor } from '@tiptap/react';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { isTableSelected } from '../../utils';
import Table from '../../Table.js';
export const isColumnGripSelected = ({
  editor=Editor,
  view=EditorView,
  state=EditorState,
  from,
}) => {
  const domAtPos = view.domAtPos(from).node;
  const nodeDOM = view.nodeDOM(from);
  const node = nodeDOM || domAtPos;

  if (!editor.isActive(Table.name) || !node || isTableSelected(state.selection)) {
    return false;
  }

  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement;
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected');

  return !!gripColumn;
};

export default isColumnGripSelected;
