import { Box, Button, Chip, Stack, Typography, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import CardImg from "../../assets/images/no payments.svg";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import moment from "moment/moment";
import { getPaymentHistory } from "../../apiservice";
import Toast from "../../components/Toast";
import TailSpinLoader from "../../components/TailSpinLoader";

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 600,
  color: "#14224B",
});

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "65vh",
  padding: "2% 8%",
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
}));
const IconBox = styled(Box)({
  width: "44px",
  height: "44px",
  background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const columns = [
  {
    name: "#",
    selector: (row, idx) => idx + 1,
    width: "100px",
  },
  {
    name: "Amount",
    selector: (row) => "$" + row.amount,
  },
  {
    name: "Status",
    cell: (row) => (
      <Chip
        label={row.status === "succeeded" ? "Successful" : "Failed"}
        variant="filled"
        sx={{
          background: row.status === "succeeded" ? "#17CEAD" : "#FF0000",
          color: "#FFF",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: 500,
        }}
      />
    ),
  },
  // {
  //   name: "Description",
  //   selector: (row) => "Monthly Subscription Fee",
  //   width: "20%",
  // },
  // {
  //   name: "Subscription Type",
  //   selector: (row) => row?.type,
  // },
  {
    name: "Date",
    selector: (row) => moment.unix(row?.paymentDate).format("DD-MM-YYYY"),
  },
];
const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      background: "rgba(255, 255, 255, 1)",
      borderBottom: "1px solid rgba(182, 209, 221, 1)",
    },
  },

  header: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#353535",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "20px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#1E4F6B",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      fontWeight: 500,
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      display: "flex",
      justifyContent: "center",
      lineBreak: "anyWhere",
      width: "20px",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

function HistorySection({ user }) {
  // const getPaymentHistory = (id) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer sk_test_51MvEfmACTSkJyVrNk2ltw1jarBte4B1UrUmkS9YYDhEYOEO4HEqH3XY43xXwJaOwfCLzLqfUKh3jk1FGB1z31v90001jzx0ynY"
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `https://api.stripe.com/v1/payment_intents?customer=${id}`,
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => {
  //       let json = JSON.parse(result);
  //       // setData(json.data);
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const data = await getPaymentHistory({ email: user?.email });
        console.log("data:", data);
        if (data.response?.status.statusCode == 200) {
          setPaymentHistory(data.response.data.paymentHistory || []);
        } else {
          Toast("err", "NO Record Found");
        }
      } catch (error) {
        console.error("Failed to fetch payment history:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchPaymentHistory();
  }, []);
  return (
    <RootStyle height={"100vh"}>
      {user.subscription.planName !== "free-plan" && (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fff",
              padding: "12px 16px",
              borderRadius: "3px",
              margin: "32px 0px 24px 0px",
              flexWrap: "wrap",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1.2}>
              <IconBox>
                <CardMembershipIcon
                  sx={{
                    color: "#ffffff",
                  }}
                />
              </IconBox>
              <Stack>
                <Title
                  sx={{
                    textTransform: "capitalize",
                    textAlign: { xs: "center", sm: "unset" },
                  }}
                >
                  {user?.subscription?.planName || "Free"} Plan
                </Title>
                <Title
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    textAlign: { xs: "center", sm: "unset" },
                  }}
                >
                  Billing Monthly .. Next payment on{" "}
                  {moment.unix(user?.nextPaymentDate).format("DD-MM-YYYY")}
                </Title>
                {/* <Title sx={{ fontSize: "14px", fontWeight: 500 }}>
                Billing Monthly .. Next payment on June 14, 2023 for $10
              </Title> */}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              width={{ xs: "100%", md: "auto" }}
              justifyContent={{ xs: "center", md: "unset" }}
              sx={{ mt: { xs: 3, md: 0 } }}
            >
              <Title sx={{ fontSize: "14px" }}>Status: </Title>
              <Stack
                sx={{
                  borderRadius: "36px",
                  // background: "#17CEAD",
                  background:
                    "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  color: "#FFF",
                  padding: {
                    xs: "4px 14px 4px 14px",
                    sm: "10px 24px 10px 16px",
                  },
                }}
                direction="row"
                alignItems="center"
              >
                <VerifiedIcon fontSize="small" sx={{ mr: 1 }} />
                Active
              </Stack>
            </Stack>
          </Box>
          <Stack sx={{ width: "100%" }}>
            <DataTable
              columns={columns}
              data={paymentHistory || []}
              customStyles={customStyles}
              pagination
              noDataComponent={
                loading ? <TailSpinLoader /> : "There are no records to display"
              }
            />
          </Stack>
        </Box>
      )}
      {user.subscription.planName === "free-plan" && (
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ width: "317px" }} src={CardImg} alt="" />
          <Title sx={{ fontSize: "20px" }}>No payments made yet !</Title>
        </Stack>
      )}
    </RootStyle>
  );
}

export default HistorySection;
