import BlogImg3 from './Images/blog3.png'



const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  };


export const blog3 ={
    
        id: createSlug("How To Write A Literature Review For Dissertation In 12 Simple Steps"),
        image: BlogImg3,
        title: "How To Write A Literature Review For Dissertation In 12 Simple Steps",
        metaText:"Master the art of how to write a literature review for a dissertation with our 12 simple steps. Get started today!",
        text: "As you sift through academic articles and papers in your literature search, you find conflicting information about your topic. What do you do? If you're like most dissertation writers, you want to hit pause and figure out what's going on. This is a logical reaction, but it also has a consequence: it slows down your research process. You may also put off writing the literature review until you can sort through the issues and resolve the discrepancies. This isn’t the best approach.\n\nThe literature review is the part of your dissertation where you sort through the existing research on your topic to understand what is known, what is not, and how your research will contribute to the academic conversation. Writing this section will help you make sense of the literature and its relevance to your work. This guide will help you understand how to write a literature review for a dissertation.\n\nOne way to speed up the literature review writing process and improve outcomes is by using AI research tools like ResearchPal. This intelligent assistant can help you gather, sort, and analyze research on your topic so you can organize your literature review and even generate a draft to help you get started.",
        postOn:"October 22, 2024",
        category:"Literature Review for Dissertation",
        read:"14 min read",
        alt:"A girl in grey sweater writing notes for her literature review on her diary",
        sections: [
        {
          heading: "What Is A Dissertation?",
          content: "A dissertation, <a href=\"https://www.topuniversities.com/blog/what-dissertation\" target=\"_blank\">also known as a thesis</a>, is a research project that students complete as part of an undergraduate or postgraduate degree. Typically, a dissertation allows students to present their findings in response to a question or proposition they choose. The project aims to test the independent research skills students have acquired at university, using the assessment to help determine their final grades.\n\nAlthough your tutors usually provide some guidance, the dissertation project is largely independent. For most students, this will be the longest, most challenging, and most crucial assignment they complete at university, requiring months of preparation and hard work (the library might become a second home).\n\nHowever, it can also be gratifying, particularly if you're passionate about your topic. Choosing a subject you're genuinely interested in is a good idea. The type of dissertation you complete will vary depending on your course of study. One of the main differences is between empirical and non-empirical dissertations. Empirical dissertations involve collecting data, for example, in a psychology degree.\n\nThis may mean putting into <a href=\"https://www.naceweb.org/career-development/organizational-structure/principles-for-ethical-professional-practice/\" target=\"_blank\">practice professional and ethical guidelines</a> when collecting data from members of the public. Empirical dissertations in natural and life science subjects may involve or center entirely on laboratory work. Non-empirical dissertations are based on existing data and arguments in the work of others. This will likely mean spending much time with your head in a book! In this type of dissertation, you need to make sure you don't just describe what others are saying but critically analyze the work and explore its practical applications.",

          relatedReading:{
            title: "Related Reading",
                description: "• Literature Search\n\n• AI For Literature Review"

          }

        },
        {
          heading: "How To Write A Literature Review For Dissertation In 12 Simple Steps",
          content: "",
          points: [
            {
              title: "1. Use ResearchPal: Your Best Friend for Writing Literature Reviews",
              description: "<a href=\"https://researchpal.co/\" target=\"_blank\">ResearchPal is a powerful AI</a> research tool designed to streamline academic workflow for researchers, students, and professionals. The platform has features that accelerate writing and research processes, including AI-driven literature reviews, intelligent writing assistance, automated paper insights extraction, advanced academic search, reference and library management, and interactive chat with research papers. <a href=\"https://researchpal.co/\" target=\"_blank\">Get started for free today</a> with our award-winning AI research tool!"
                                    
            },
            {
              title: "2. Identifying Sources: Finding the Right Materials for Your Review ",
              description: "A good <a href=\"https://www.scribbr.com/methodology/literature-review/\" target=\"_blank\">literature review</a> starts with identifying the suitable sources to include. If your instructor hasn’t provided you with a reference list, you can refer to the techniques we recommended earlier. As you begin to compile your sources, ensure a balanced mix of books, academic journals, and any other published work from reputable scholars that may be useful to your research.\n\nTo help you select your sources appropriately, consider the parameters and objectives of your study. What are you hoping to find out? What theoretical issues or perspectives do you aim to tackle in your literature review? How about your methodology? Will you focus mainly on qualitative or quantitative studies or both? These general questions should help guide you in selecting your sources. And again, remember that the abstract of a source is a handy tool.\n\nA quick scan of the abstract and its ‘keywords’ will often indicate whether the source will be helpful in your research. As you’re identifying your sources, ensure you keep a list. It’s straightforward to lose focus, given the broad scope of the Internet. Reference tools such as Mendeley allow you to store your sources online and via a desktop app and are a great way to keep your bibliography organized. The citation tools attached to these programs will also allow you to easily export citations in a format you choose when required later. They will save you countless hours trying to figure out how to use Harvard or APA referencing correctly. ",

            },
            {
                title: "3. Read Your Sources: Digging Into the Research",
                description: "Now that you have organized your sources efficiently, it’s time to read through them. As unnatural as it may feel, reading in a few stages is most effective, as detailed below: First, review all the texts to get a sense of their general content and arguments. This will also help you judge which sources you mainly want to focus on in your review. During the second stage of your reading, you can then take a more critical, in-depth look at your sources.\n\nMake a lot of notes and be critical. Ask questions. What is your academic opinion on the text? Do you have any comments on the methodological approach, the theoretical argument, or the general hypothesis? Note these down. It will ensure that your literature review is not merely a summary of your readings and will encourage a clear line of argument to make your work logical and coherent.",
  
              },
              {
                title: "4. Consider Gaps in the Research: Why It’s Crucial for Your Literature Review",
                description: "When writing a dissertation literature review, an essential thing to consider is identifying the research gap. Identifying the gap is particularly important if your review forms part of a research proposal, as it will highlight the pertinence of your research. In other instances, identifying the gap indicates good critical analysis and can score extra points. To determine the “gap,” we must know what this “gap” is. A research gap is a research question, perspective, or problem that has not been answered in the existing literature on any field of study.\n\n<a href=\"https://www.editage.com/insights/dont-know-where-to-start-6-tips-on-identifying-research-gaps\" target=\"_blank\">Identifying the research gap</a> is vital for highlighting the originality of your research; it proves you’re not simply recounting or regurgitating existing research. It also shows that you are very much aware of the status of the literature in your chosen field of study, which, in turn, demonstrates the amount of research and effort you have put into your review. Many students, especially at the postgraduate level, find it extremely difficult to identify research gaps in their subject area. For postgraduate research papers, identifying research gaps and formulating research questions that can address these gaps form the very essence of a research paper. Identifying research gaps does not have to be challenging, and several ways exist to overcome this difficulty. ",
  
              },
           
              {
                title: "5. Start by Reading: Digging Deeper into Key Articles",
                description: "A simple approach will be to read important parts of critical articles in your research area. First, note that you’ll have to sift through many articles to identify the most suitable research. A quick search using keywords on Google Scholar will often give you a quick overview of the available literature. Other valuable sources include databases such as JSTOR or Wiley Online Library. You can then snowball additional articles by clicking on ‘related articles’ or checking out which other papers have cited your source.",
  
              },
              {
                title: "6. Abstracts and Recommendations: What They Will Tell You",
                description: "Whichever avenue you choose, reading the abstract is often a good starting point to get a sense of what the articles entail. You should also do a quick examination of the introductory and concluding paragraphs of the paper, as these sections always provide some information on the aims and outcomes of the research, as well as ‘recommendations for future studies.’ These recommendations typically give some insight into the research gaps in the literature. Another route would be to simply read as much as possible on your research subject while considering which research areas still need addressing in the literature. This is usually an indication of research gaps. ",
  
              },
              {
                title: "7. Use Sample Literature Reviews: They Can Help You Get Started",
                description: "Look at sample dissertation literature reviews in your subject area and read them thoroughly to familiarize yourself with key debates and themes. This can be a good starting point for framing and structuring your review. If you are still getting familiar with academic writing, going through samples will help you understand what is expected in this regard. Pay attention to the academic language and formal style used. Also, remember that the bibliography or reference section of your selected texts will help you to snowball further references if you need any.",
  
              },
              {
                title: "8. Keep It Simple: Narrow Your Focus",
                description: "Keep your topic as narrowed down as possible. Remember that there are hundreds – or sometimes thousands – of sources or perspectives concerning any subject area or topic. Researchers investigate research problems in many divergent ways, and the literature available on any subject is extensive. You won’t be expected to address every argument or perspective concerning your topic in your literature review. This might undermine your ability to write a coherent and focused piece. You’ll make your work more manageable if you limit the scope of your work. In your review, ensure that you clearly state what the focus of your work will be.",
  
              },
              {
                title: "9. Make Sure Your Sources Are Current: Why It Matters",
                description: "If you are reviewing scientific work, your sources must be as current as possible, given the advancements in the field over the years. In the medical field, research is constantly evolving, and a source that’s only three years old may be outdated. This rule may not apply in the social sciences, as many theoretical works are classics, and you will be expected to be familiar with these perspectives. You might have to review the work of Marx, Hobbes, or any other classic scholar. You still need to balance theory with current approaches, as you will need to demonstrate how perspectives in the literature have changed over the years, or you may even want to illustrate how scholars have used classic theories to inform their work.",
  
              },
              {
                title: "10. Organize Your Work: Structure Matters",
                description: "Organizing your work in a dissertation literature review goes beyond having an introduction, body, and conclusion. You’ll be reviewing several texts, so you’ll also have to think clearly about organizing themes, topics, and your argument in general.",
  
              },
              {
                title: "11. Write the Body Paragraphs: Follow Academic Conventions",
                description: "Once you have settled on the approach to writing your body, you must write each of its paragraphs in a way that is in keeping with academic conventions. ",
  
              },
              {
                title: "12. Write the Conclusion: Don’t Forget This Important Part",
                description: "The conclusion of a dissertation literature review should always include a summary of the implications of the literature, which you should then link to your argument or general research question.",
  
              },
           
          ]

        },
          {
            heading: "Purpose of Writing A Dissertation Literature Review",
            content: "",
            points: [
              {
                title: "Why Write a Literature Review? To Show Your Knowledge",
                description: "<a href=\"https://library.famu.edu/c.php?g=577356&p=3982811\" target=\"_blank\">Performing a literature review</a> during your research will show that you know your field of study. You’ll demonstrate familiarity with the vocabulary, theories, methods, and history of research on your topic. You also will learn about the influential researchers and research groups active in your area of interest. Finally, with some modification, the literature review is a “legitimate and publishable scholarly document” (LeCompte and colleagues, 2003, p. 124). "
              },
              {
                title: "Why Write a Literature Review? To Help You Scientifically",
                description: "In addition to the above, there are scientific reasons for conducting a literature review. Gall, Borg, and Gall (1996) argue that the literature review plays a role in: ",
                subPoints:[
                    {
                    subHeading: "",
                    subDescription: "●	delimiting the research problem,\n●	seeking new lines of inquiry,\n●	avoiding fruitless approaches,\n●	gaining methodological insights,\n●	identifying recommendations for further research and\n●	seeking support for grounded theory. ",
                  },
                  
                ]
              },
              {
                title: "",
                description: "Hart (1998) contributes additional reasons for reviewing the literature, including: ",
                subPoints:[
                    {
                    subHeading: "",
                    subDescription: "●	distinguishing what has been done from what needs to be done, \n●	discovering important variables relevant to the topic, \n●	synthesizing and gaining a new perspective, \n●	identifying relationships between ideas and practices, \n●	establishing the context of the topic or problem,\n●	rationalizing the significance of the problem,\n●	enhancing and acquiring the subject vocabulary,\n●	understanding the structure of the subject,\n●	relating ideas and theory to applications,\n●	identifying the primary methodologies and research techniques that have been used and\n●	placing the research in a historical context to show familiarity with state-of-the-art developments. (p. 27) ",
                  },
                  
                ]
              },
              {
                title: "Why Write a Literature Review? To Provide a Framework for Your Dissertation",
                description: "Another <a href=\"https://uscupstate.libguides.com/c.php?g=627058&p=4389968\" target=\"_blank\">purpose for the literature review</a> that needs to be mentioned above is that it provides a framework for relating new findings to previous findings in the discussion section of a dissertation. It is only possible to demonstrate how the latest research advances the earlier study by establishing the state of the last research."
              },
              {
                title: "ResearchPal: Your New Best Friend for Literature Searches",
                description: `ResearchPal is a <a href="https://researchpal.co/" target="_blank">powerful AI</a> research tool designed to streamline the academic workflow for researchers, students, and professionals. Unlike task-specific alternatives, ResearchPal offers a full suite of features to accelerate writing and research processes. Key functionalities include AI-driven literature reviews, Intelligent writing assistance, Automated paper insights extraction, Advanced academic search, Efficient reference and library management, and Interactive chat with research papers. <a href="https://researchpal.co/" target="_blank">Get started for free today</a> with our award-winning AI research tool!`


              },
              
            ]
          },
          {
            heading: "10 Best Tools To Write Dissertation Literature Review",
            content: "",
            points: [
              {
                title: "1. ResearchPal: Your All-in-One Academic Research Assistant",
                description: "<a href=\"https://researchpal.co/\" target=\"_blank\">ResearchPal is an advanced AI-driven</a> research platform that can help you and your academic work, including literature reviews. It comes with a range of features to improve the efficiency of your writing and research processes. For example, ResearchPal’s AI literature reviews can accelerate the writing process by summarizing existing research on your topic.\n\nFrom there, you can use the tool’s intelligent writing aid to help you compose your paper. <a href=\"https://researchpal.co/\" target=\"_blank\">ResearchPal also automates insights extraction</a> from academic papers, enables you to manage your references and research library, and offers interactive chat features to help you engage with research papers."

              },
              {
                title: "2. Jasper: The Smart Assistant for Writing Projects of All Kinds",
                description: "Jasper is a popular AI writing tool that can help you write faster and more efficiently. It doesn’t specialize in academic writing but has multiple templates and unique features to help you tackle your dissertation literature review. For example, Jasper has an AI editor that can improve existing content, rewrite sections, and help you brainstorm ideas for your literature review. The tool even allows you to upload documents to help you work on your project. ",
                subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	Unique features and templates. \n●	A long-form assistant is a huge plus and easy to use despite being complex. ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	It can get expensive. \n●	Output is only top-notch sometimes.",
                  },
                  
                ]
              
              },
              {
                title: "3. Copy.AI: Easy AI Content Generation",
                 description: "Copy.ai is an artificial intelligence <a href=\"https://datasciencedojo.com/blog/essential-research-tools/\" target=\"_blank\">writing tool designed to help</a> marketers, business owners, and copywriters create various forms of content, including website copy, sales landing pages, email, social media, and blog posts. A boon to content marketers, Copy.ai can automatically conduct SEO research and produce content briefs for writers, streamlining the production process and guiding writers. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	The content matches the brand's tone and voice\n●	Low learning curve and easy-to-use ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	It can sometimes get detected as AI content \n●	Lacks full-length article writing feature",
                  },
                  
                ]
              },

              {
                title: "4. Rytr: A Budget-Friendly AI Writing Tool",
                 description: "Rytr is an AI-powered writing tool capable of producing copywriting content on various topics. It’s one of the best AI writing tools for commercial copywriting jobs, where copywriters can use it to automate the creation of post and caption ideas, paragraph content, SEO meta titles, emails, calls to action, replies, and other less complex copywriting assets. The platform supports over 40 use cases, including generating blog ideas and creating job descriptions. In addition, paid users can create their use cases by training Rytr to meet their specific needs. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	Affordable Compact interface\n●	<a href=\"https://biglanguage.com/blog/multilingual-customer-support-what-is-it-and-why-do-i-need-it/\" target=\"_blank\">Support for multiple languages</a>. ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	Output could be better. \n●	It can be confusing at first",
                  },
                  
                ]
              },

              {
                title: "5. Anyword: A Data-Driven Approach to AI Writing",
                 description: "Anyword is another data-driven AI tool designed for enterprise marketing teams. Its superpower is using data to help teams understand their audience and create more resonated content. Like Copy.ai, Anyword offers a variety of templates to generate marketing content. However, it has several advanced features, such as predictive scores, to identify the most effective copy. This tool can be handy for marketers who must launch complex ad campaigns and optimize multi-channel content performance. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	AI and data analytics integrations for smarter copywriting decisions \n●	Helps to improve content engagement and conversion rates across your campaigns\n●	Generates various types of marketing copy ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	Might have a bit of a learning curve to leverage predictive scoring fully \n●	It might not be ideal for small teams and individual creators because of its complexity\n●	It doesn’t offer its own SEO data to optimize your content for search",
                  },
                  
                ]
              },

              {
                title: "6. Casper AI: An Academic Research Assistant ",
                 description: "Casper AI is an artificial intelligence tool that can simplify your literature review process. Using the latest OpenAI models, Casper can summarize existing research, create content, and help you share insights with others. You can access Casper via its website or use the Chrome extension to streamline your workflow. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	Easy to use: Casper AI is a Chrome extension that is easy to install and use.\n●	Accurate: Casper AI shows accurate results for well-written content.\n●	Versatile: Casper AI can be used for various tasks, including article summarization, content creation support, and sharing insights with multiple stakeholders. \n●	Counter argument: Casper AI points out the gaps in the content so that you can find other sources. ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	Still under development: Casper AI is still under development, so it may only sometimes work perfectly. \n●	Casper AI is a subscription-based service, so it can be expensive for users who need to use it frequently.",
                  },
                  
                ]
              },

              {
                title: "7. PDF GPT.IO: Engage with Research Papers Using AI ",
                 description: "PDFGPT.IO is a tool powered by OpenAI that allows you to engage with PDF documents with AI and get questions relevant to the document answered. It is a good option if you want a free chat tool for PDFs. It cannot summarize PDFs, but it does have a few features that other alternatives still need, such as the ability to upload multiple PDFs at once and get answers to your questions in a table format. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	Effortless document automation \n●	Easy-to-use interface Improves productivity \n●	Provides summaries instantly after uploading \n●	Answers any questions relevant to the doc and keeps engaging with the user ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	The basic plan has significant limitations; users must upgrade to paid plans to use it properly.\n●	The paid plan also has a few limitations\n●	The price isn't reasonable when compared to other PDF AI tools\n●	You cannot convert files like PDF to HTML (with UPDF, you can) ",
                  },
                  
                ]
              },

              {
                title: "8. ConnectedPapers: Visualize Research Paper Connections ",
                 description: "Connected Papers is a tool that aids researchers in identifying gaps in literature reviews. By visualizing the research landscape and exploring paper interconnections, researchers can deepen their understanding of research relationships and identify critical papers and trends, making their literature reviews more comprehensive and insightful. ",
                 subPoints:[
                    {
                    subHeading: "Pros",
                    subDescription: "●	Easy-to-use visual interface for exploring research papers. \n●	Helps quickly identify foundational and recent studies in a field. \n●	Supports various scientific disciplines via the Semantic Scholar database. \n●	Allows customization of graphs to focus on specific research interests. ",
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "●	Limited to 5 graphs per month on the free plan. \n●	No mobile app, limiting access to desktop users only.\n●	It can be overwhelming to navigate large graphs with many nodes.",
                  },
                  
                ]
              },
              {
                title: "9. SciScape: A Powerful Research Tool for Finding Academic Papers",
                description: "SciSpace is a valuable AI tool for researchers. It offers a repository of over 270 million papers. This tool simplifies finding research articles, extracting critical information for comparison, and interacting with individual papers using an AI research assistant called Copilot.",
                subPoints: [
                  {
                    subHeading: "Pros",
                    subDescription: "● Ease of Writing and Collaboration: Simplifies version control and collaboration.\n● Automated Formatting: Instantly publishes in multiple formats and automates formatting.\n● Structured Metadata: Provides publishers with structured metadata.\n● Versatile Application: Suitable for creating white papers, teaching material, technical documentation, and journal or research papers."
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "● Learning Curve: New users may need time to familiarize themselves with the platform's features.\n● Subscription Cost: While there is a free trial, continued use requires a subscription.\n● Internet Dependency: An online platform requires a stable internet connection for access."
                  }
                ]
              },
              {
                title: "10. AskYourPDF: Get Quick Answers from Research Papers",
                description: "AskYourPDF streamlines the literature review process by providing an AI-powered writing assistant. By automating the research process, generating structured summaries, and creating comprehensive reviews, researchers can boost their academic work with AskYourPDF.",
                subPoints: [
                  {
                    subHeading: "Pros",
                    subDescription: "● Real-time collaboration features\n● Customizable viewing options\n● Extensive file format support"
                  },
                  {
                    subHeading: "Cons",
                    subDescription: "● Limited editing capabilities\n● Steep learning curve\n● Minimal customer support"
                  }
                ]
              }
            ],
            
          relatedReading:{
            title: "Related Reading",
                description: "• Literature Review Tools\n\n• AI for Literature Review"

          }

          },
          {
            heading: "Use Our AI Research Tool for Free Today!",
            content: "<a href=\"https://researchpal.co/\" target=\"_blank\">ResearchPal is a powerful AI</a> tool that helps you write a literature review for your dissertation and streamline the entire academic research process. This software's intuitive interface and automated functions take the stress out of scholarly research so you can <a href=\"https://researchpal.co/\" target=\"_blank\">confidently write your dissertation</a>.",
            relatedReading:{
                title: "Related Reading",
                    description: "• Zotero Alternatives"
    
              }
  
          },
         
        ]
      }