import { createContext } from "react";

const EditorContext = createContext({
  isAiLoading: false,
  aiError: null,
  setIsAiLoading: () => {},
  setAiError: () => {},
});

export default EditorContext;
