import React from "react";
import { useCookies } from "react-cookie";
import { Button, Typography, Box } from "@mui/material";

function AcceptCookiesForm() {
  const [cookies, setCookie] = useCookies(["user"]);

  const handleClose = () => {
    setCookie("user", true, { path: "/" });
  };

  return (
    // <Box
    //   sx={{
    //     padding: "2%",
    //     background: "rgba(0, 0, 0, 0.5)",
    //   }}
    // >
    //   {/* <Typography variant="h6" sx={{ color: "white" }}>
    //     Accept cookies?
    //   </Typography> */}
    //   <Box
    //     sx={{ display: "grid", gridTemplateColumns: "75% 20%", columnGap: 3 }}
    //   >
    //     <Typography sx={{ color: "white" }}>
    //       We use cookies to personalize content and ads, to provide social media
    //       features and to analyze our traffic. We also share information about
    //       your use of our site with our social media, advertising and analytics
    //       partners who may combine it with other information that you’ve
    //       provided to them or that they’ve collected from your use of their
    //       services.
    //     </Typography>

    //     <Button
    //       size="small"
    //       variant="contained"
    //       onClick={handleClose}
    //       sx={{
    //         background: "#353535 !important",
    //         borderRadius: "40px",
    //         height: "40px",
    //       }}
    //     >
    //       Accept All Cookies
    //     </Button>
    //   </Box>
    // </Box>
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        py: { xs: 4, sm: 3 },
        px: { xs: 4, sm: 3 },
        // width: "30%",
        gap: 4,
        justifyContent: { xs: "center", md: "space-between" },
        alignItems: "center",
        // background: "rgba(0, 0, 0, 0.5)",
        background: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 32px 68px rgba(0,0,0,.3)",
      }}
    >
      <Typography
        sx={{ color: "#353535", width: { xs: "100%", md: "70%", lg: "80%" } }}
      >
        We use cookies to personalize content and ads, to provide social media
        features and to analyze our traffic. We also share information about
        your use of our site with our social media, advertising and analytics
        partners who may combine it with other information that you’ve provided
        to them or that they’ve collected from your use of their services.
      </Typography>

      <Button
        size="small"
        variant="contained"
        onClick={handleClose}
        sx={{
          // background: "#353535 !important",
          background:
            "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%) !important",
          "&:hover": {
            background:
              "linear-gradient(90deg, rgba(48, 70, 174, 1) 0%, rgba(20, 181, 153, 1) 100%) !important",
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
          },
          borderRadius: { xs: "40px", md: "25px" },
          py: 1,
          px: 4,
          // fontSize: "12px",
        }}
      >
        Accept All Cookies
      </Button>
    </Box>
  );
}

export default AcceptCookiesForm;
