import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedText: {},
  focus: "",
  editorValue: "",
  timer: "",
  send: false,
  receive: "i am receiver",
  isPopoverOpen: false,
  currentTime: "Not saved Yet",
  popoverPosition: {
    top: 0,
    left: 0,
  },
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    textSelection: (state, action) => {
      state.selectedText = action.payload;
    },
    focused: (state, action) => {
      state.focus = action.payload;
    },
    changeValue: (state, action) => {
      state.editorValue = action.payload;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;
    },
    setSend: (state, action) => {
      state.send = action.payload;
    },
    setReceive: (state, action) => {
      state.receive = action.payload;
    },
    setIsPopoverOpen: (state, action) => {
      state.isPopoverOpen = action.payload;
    },
    clearIsPopoverOpen: (state) => {
      state.isPopoverOpen = false;
    },
    setPopoverPosition: (state, action) => {
      state.popoverPosition = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
  },
});

export const {
  textSelection,
  focused,
  changeValue,
  setTimer,
  setSend,
  setReceive,
  setIsPopoverOpen,
  clearIsPopoverOpen,
  setPopoverPosition,
  setCurrentTime,
} = editorSlice.actions;

export default editorSlice.reducer;
