import Repository from "./Repository";

export default {
  default(payload) {
    return Repository.post("", payload);
  },
  email(payload) {
    return Repository.post("email", payload);
  },
  user(payload) {
    return Repository.post("users", payload);
  },
  subscribe(payload) {
    return Repository.post("subscription", payload);
  },
  research(payload) {
    return Repository.post("research-projects", payload);
  },
  script(payload) {
    return Repository.post("script", payload);
  },
  excel(payload) {
    return Repository.post("excel", payload);
  },
  otherFormats(payload) {
    return Repository.post("/formats", payload);
  },
};
