import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { MenuItem, Select, Stack, Typography, Tooltip } from "@mui/material";
import TailSpinLoader from "../../components/TailSpinLoader";
import styled from "styled-components";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { useDispatch } from "react-redux";
import { getUser } from "../Admin/userSlice";
import { Domains } from "../authentication/sign up/DomainsList";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "14px",
  color: "#353535",
});

const DomainDialogue = ({ open, onClose, setDomainDialogue }) => {
  const [state, setState] = useState({
    loading: false,
    error: false,
    domain: "Computer Science",
    userType: "Student",
  });
  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleDomain = async () => {
    try {
      setState({ ...state, loading: true });
      let { data } = await Repo.user({
        request: {
          method: "updateUser",
          data: {
            _id: localStorage.getItem("email"),
            userType: state.userType,
            domain: state.domain,
            domainAccess: true,
          },
        },
      });
      onClose();
      if (data.response.status.statusCode === 200) {
        Toast("success", "Domain Updated");
        dispatch(getUser());
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      Toast("error", "Error");
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2}>
          <Stack>
            <Text sx={{ fontWeight: 600 }}>User Type</Text>
            <Select
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              name="userType"
              value={state.userType}
              onChange={handleInput}
            >
              <MenuItem value="Student">Student</MenuItem>
              <MenuItem value="Researcher">Researcher</MenuItem>
              <MenuItem value="Professional">Professional</MenuItem>
            </Select>
          </Stack>
          <Stack>
            <Text sx={{ fontWeight: 600 }}>Domain</Text>
            <Select
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              name="domain"
              value={state.domain}
              onChange={handleInput}
            >
              {Domains.map((domain) => {
                return (
                  <MenuItem value={domain}>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Typography>{domain}</Typography>
                      {(domain == "Computer Science" ||
                        domain == "Medical & Health Sciences" ||
                        domain == "Humanities and Social Sciences" ||
                        domain == "Business Studies") && (
                        <Tooltip
                          title={
                            <Typography>
                              Currently our automated Literature Review and
                              Search is optimized for this domain.
                            </Typography>
                          }
                          placement="top"
                        >
                          <InfoOutlinedIcon sx={{ color: "#353535", mr: 1 }} />
                        </Tooltip>
                      )}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack>
            {state.loading ? (
              <Box sx={{ height: "50px" }}>
                <TailSpinLoader />
              </Box>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  justifyContent={"space-evenly"}
                  mt={2}
                  spacing={2}
                >
                  <Button
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#353535",
                      background: "#ffffff !important",
                      border: "1px solid #353535",
                    }}
                    onClick={() => setDomainDialogue(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="btn"
                    variant="contained"
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      fontSize: "16px",
                      background: "#353535 !important",
                    }}
                    onClick={handleDomain}
                  >
                    Done
                  </Button>
                </Stack>
              </Box>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DomainDialogue;
