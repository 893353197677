import * as Popover from '@radix-ui/react-popover';
import { Toolbar } from '../../../ui/Toolbar';
import Icon from '../../../ui/Icon';
import { LinkEditorPanel } from '../../../panels/LinkEditorPanel/LinkEditorPanel';

export const EditLinkPopover = ({ onSetLink }) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Toolbar.Button tooltip="Set Link">
          <Icon name="Link" />
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Content>
        <LinkEditorPanel onSetLink={onSetLink} />
      </Popover.Content>
    </Popover.Root>
  );
};
