import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  // fontSize: "13px",
  color: "#353535",
  padding: "10px",
});

const SearchGuidelineDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          //   flexWrap: "wrap-reverse",
        }}
      >
        <Text sx={{ fontWeight: 600 }}>
          Guidelines for Using the "Search" Feature
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: "absolute",
            right: 8,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Text>
          <strong>1. Be Specific:</strong> Enter a clear and focused research
          topic or question to get the most relevant search results.
        </Text>
        <Text>
          <strong>2. Use Key Terms:</strong> Include specific terms or phrases
          related to your topic to narrow down the search.
        </Text>
        <Text>
          <strong>3. Avoid Multiple Topics:</strong> Focus on one subject or
          question per search to get the best-matched papers.
        </Text>
        <Text>
          <strong>4. Keep it Concise:</strong> Short and direct queries work
          best to find precise academic papers.
        </Text>
        <Text>
          <strong>5. Refine as Needed:</strong> If results are broad or unclear,
          adjust your search terms for better accuracy.
        </Text>
        <Text>
          Following these tips will help you quickly find and save the most
          relevant research papers.
        </Text>
      </DialogContent>
    </Dialog>
  );
};

export default SearchGuidelineDialog;
