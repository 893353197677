import React from "react";
import Latex from "react-latex";

export const handleDownloadLatex = (html_element, refFormats) => {
  const format = localStorage.getItem("selectedFormat") || "apa";
  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }
    let filtered = arr.filter((i) => i.toString().toLowerCase());

    return filtered;
  };
  // Function to convert HTML to plain text (suitable for LaTeX inclusion)
  const convertToLatexText = (html) => {
    const text = document.createElement("div");
    text.innerHTML = html;
    return text.textContent.trim(); // Remove leading/trailing whitespace
  };

  // Function to generate LaTeX content with formatted references
  const showLatex = () => {
    const filteredFormats = formats(); // Refactor for clarity (assuming formats() is defined elsewhere)
    const refes = filteredFormats ? filteredFormats : [];
    const referencesSection = refes
      ?.map((ref, index) => {
        const matchDOI = ref.match(/(http:\/\/doi\.org\/[^\s]+)/);
        if (matchDOI) {
          const doi = matchDOI[0]; // Extracting DOI from the reference
          const refWithLink = ref.replace(
            doi,
            `<a href="${doi}" target="_blank">${doi}</a>`
          ); // Replacing DOI with clickable link
          return `${index + 1}- ${refWithLink}`;
        } else {
          return `${index + 1}- ${ref}`;
        }
      })
      .join("\n");

    const referencesHtml =
      refes.length > 0
        ? `<p><strong>References:</strong></p><p>${referencesSection}</p>`
        : "";

    const latexText = convertToLatexText(html_element); // Convert HTML to plain text

    const combinedContent =
      "\\documentclass{article}\n" +
      "\\begin{document}\n" +
      latexText +
      "\\section*{References}\n" +
      referencesHtml +
      "\\end{document}";

    return combinedContent;
  };

  // Download the LaTeX content
  const downloadLatex = () => {
    const blob = new Blob([showLatex()], { type: "application/latex" });
    const url =
      "data:application/latex;charset=utf-8," + encodeURIComponent(showLatex());
    const filename = `${localStorage.getItem("projectTitle")}.tex`;

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    }
  };
  return downloadLatex();
};
