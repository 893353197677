import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import Abstract from "../Abstract";

const AbstractDialogue = ({
  AbstractData,
  handleCloseSecondDialogue,
  openSecondDialogue,
}) => {
  return (
    <div>
      <Dialog
        onClose={handleCloseSecondDialogue}
        aria-labelledby="customized-abstract-title"
        open={openSecondDialogue}
        maxWidth="lg"
        sx={{
          zIndex: 999999,
          padding: "0px !important",
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          fontSize={12}
          fontWeight={600}
          fontFamily={"Raleway"}
          id="customized-abstract-title"
          sx={{
            scrollBehavior: "smooth",
            scrollMarginTop: "100px",
          }}
        >
          ABSTRACT
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseSecondDialogue}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            padding: "0px",
            width: "fit-content",
          }}
        >
          <Abstract data={AbstractData} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AbstractDialogue;
