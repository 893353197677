import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Logo1 from "../../assets/images/logos/Logo1.png";
import Logo2 from "../../assets/images/logos/Logo2.png";
import Logo3 from "../../assets/images/logos/Logo3.png";
import Logo4 from "../../assets/images/logos/Logo4.png";
import Logo5 from "../../assets/images/logos/Logo5.png";
import Logo6 from "../../assets/images/logos/Logo6.png";
import Logo7 from "../../assets/images/logos/Logo7.png";
import Logo8 from "../../assets/images/logos/Logo8.png";
import Logo9 from "../../assets/images/logos/Logo9.png";
import Logo10 from "../../assets/images/logos/Logo10.png";
import Logo11 from "../../assets/images/logos/Logo11.png";
import Logo12 from "../../assets/images/logos/Logo12.png";
import Logo13 from "../../assets/images/logos/Logo13.png";
import Logo14 from "../../assets/images/logos/Logo14.png";
import Logo15 from "../../assets/images/logos/Logo15.png";
import Logo16 from "../../assets/images/logos/Logo16.png";
import Logo17 from "../../assets/images/logos/Logo17.png";
import Logo18 from "../../assets/images/logos/Logo18.png";
import Logo19 from "../../assets/images/logos/Logo19.png";
import Logo20 from "../../assets/images/logos/Logo20.png";
import Logo21 from "../../assets/images/logos/Logo21.png";
import Logo22 from "../../assets/images/logos/Logo22.png";
import Logo23 from "../../assets/images/logos/Logo23.png";
import Logo24 from "../../assets/images/logos/Logo24.png";
import Logo25 from "../../assets/images/logos/Logo25.png";
import Logo26 from "../../assets/images/logos/Logo26.png";
import Logo27 from "../../assets/images/logos/Logo27.png";
import Logo28 from "../../assets/images/logos/Logo28.png";
import Logo29 from "../../assets/images/logos/Logo29.png";
import Logo30 from "../../assets/images/logos/Logo30.png";
import Logo31 from "../../assets/images/logos/Logo31.png";
import Logo32 from "../../assets/images/logos/Logo32.png";
import Logo33 from "../../assets/images/logos/Logo33.png";
import Logo34 from "../../assets/images/logos/Logo34.png";
import Logo35 from "../../assets/images/logos/Logo35.png";
import Logo36 from "../../assets/images/logos/Logo36.png";
import BadgeIcon from "../../assets/images/NewImages/badgeIcon.svg";
import { useMediaQuery, Stack } from "@mui/material";
import "./slider.css";

const ImgBox = styled(Box)({
  margin: "0 24px",
  display: "flex",
  justifyContent: "center",
  // content",
});

const ListSlider = ({ speed }) => {
  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  return (
    <Box gap={6}>
      <Stack
        sx={{
          mb: 6,
          paddingInline: "10px",
          marginTop: isSmallScreen ? "10px" : "0px",
        }}
        direction={isSmallScreen ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          <Box>
            <img src={BadgeIcon} alt="" />
          </Box>
          <Typography
            variant="h1"
            textAlign={isSmallScreen && "center"}
            sx={{
              fontSize: "20px",
              fontFamily: "Raleway",
              fontWeight: 700,
              color: "#15A68F",
              mx: "8px",
            }}
          >
            Trusted By 100,000+ Researchers {!isSmallScreen && "|"}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "20px" },
            fontFamily: "Raleway",
            fontWeight: 500,
            color: "#14224B",
            mt: { xs: 2, sm: 0 },
          }}
          textAlign={isSmallScreen && "center"}
        >
          Top Universities, Industry Professionals and Academic Writers around
          the World
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box className="slider">
          <Box className="slide-track">
            <ImgBox className="slide img">
              <img src={Logo1} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo2} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo3} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo4} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo5} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo6} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo7} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo8} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo9} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo10} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo11} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo12} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo13} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo14} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo15} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo16} alt="" />
            </ImgBox>

            <ImgBox className="slide img">
              <img src={Logo17} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo18} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo19} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo20} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo21} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo22} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo23} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo24} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo25} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo26} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo27} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo28} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo29} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo30} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo31} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo32} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo33} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo34} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo35} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo36} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo1} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo2} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo3} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo4} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo5} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo6} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo7} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo8} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo9} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo10} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo11} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo12} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo13} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo14} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo15} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo16} alt="" />
            </ImgBox>

            <ImgBox className="slide img">
              <img src={Logo17} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo18} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo19} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo20} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo21} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo22} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo23} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo24} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo25} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo26} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo27} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo28} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo29} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo30} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo31} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo32} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo33} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo34} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo35} alt="" />
            </ImgBox>
            <ImgBox className="slide img">
              <img src={Logo36} alt="" />
            </ImgBox>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ListSlider;
