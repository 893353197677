import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();

  const cardStyle = {
    maxWidth: 400,
    margin: "auto",
    marginTop: 100,
    padding: 16,
    textAlign: "center",
    border: "1px solid red",
  };

  return (
    <Card style={cardStyle}>
      <CardContent>
        <Typography variant="h5" gutterBottom color={"red"}>
          Access Denied
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" paragraph>
          Hey,
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" paragraph>
          Many thanks for your interest in ResearchPal 😊. We’re overwhelmed by
          the response of the research community and are trying our best to give
          everyone access as soon as possible. We have added you to our waiting
          list and will reach out when your access is resumed.
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" paragraph>
          Thanks again,
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Team ResearchPal
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ marginTop: 5, background: "#353535 !important" }}
        >
          Go Back
        </Button>
      </CardContent>
    </Card>
  );
};

export default AccessDenied;
