import {
  Cancel,
  Layers,
  PriceCheck,
  RepeatOneTwoTone,
  ViewInAr,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnSub,
  BtnSubscribed,
  PlanCard,
  PriceTitle,
  SubTitle,
  Text,
} from "./styles";
import Repo from "../../../repo/Repo";
import Toast from "../../../components/Toast";
import { getUser } from "../../Admin/userSlice";
import TailSpinLoader from "../../../components/TailSpinLoader";

function ProCard({ onSelect, billingPeriod, handleBillingPeriodChange }) {
  console.log("billingPeriod:", billingPeriod);
  const { user } = useSelector((state) => state.user);
  const cancelSubscription = user?.cancelSubscription;
  const [modal, setModal] = useState(false);
  const toggleCancel = () => setModal((prev) => !prev);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getPrice = () => {
    if (billingPeriod === "yearly") {
      // return "$359.88";
      return "$360"; // Change this to your yearly price
    }
    return "$39.99"; // Change this to your monthly price
  };
  const handleCancel = async () => {
    try {
      setLoading(true);

      let { data } = await Repo.subscribe({
        request: {
          method: "cancelSubscription",
          data: {
            email: localStorage.getItem("email"),
          },
        },
      });
      if (data.response.status.statusCode == 200) {
        Toast("success", "Subscription Cancelled");
        dispatch(getUser());
        setLoading(false);
        toggleCancel();
      } else {
        Toast("error", "Error");
        setLoading(false);
        toggleCancel();
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      toggleCancel();
    }
  };
  return (
    <PlanCard
      spacing={1.6}
      sx={{
        pb: "10px",
        justifyContent: !user ? "space-around" : "space-between",
      }}
    >
      <Stack spacing={1}>
        <Stack spacing={1}>
          <SubTitle>
            <Layers sx={{ marginRight: "4px" }} />
            Pro
          </SubTitle>
          <Stack alignItems="center" direction="row" justifyContent="center">
            <PriceTitle
              sx={{
                fontFamily: "sans-serif !important",
                fontSize: "2.25rem",
              }}
            >
              {getPrice()}
            </PriceTitle>
            {/* {billingPeriod === "yearly" && (
              <PriceTitle sx={{ color: "#A2B0B7", fontSize: "15px" }}>
                /monthly
              </PriceTitle>
            )} */}
            <PriceTitle
              sx={{
                ml: 2,
                textDecoration: "line-through",
                color: "#A2B0B7",
                fontFamily: "sans-serif !important",
                fontSize: "2.2rem",
              }}
            >
              {billingPeriod === "yearly" ? "$480" : ""}
            </PriceTitle>
          </Stack>
          <PriceTitle sx={{ color: "#A2B0B7", fontSize: "15px" }}>
            Billed {billingPeriod === "yearly" ? "Yearly" : "Monthly"}
          </PriceTitle>
          <Box
            justifyContent={"center"}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100% ",
            }}
          >
            <PriceTitle sx={{ fontWeight: "700", fontSize: "13px" }}>
              Super charge your research
            </PriceTitle>
            {billingPeriod === "yearly" && (
              <Box
                sx={{
                  backgroundColor: "#1DB45A",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "5px",
                  width: "fit-content",
                  height: "fit-content",
                  marginInline: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Text
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  textAlign={"center"}
                >
                  save 25%
                </Text>
              </Box>
            )}
          </Box>
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            value={billingPeriod}
            exclusive
            onChange={handleBillingPeriodChange}
            aria-label="billing period"
            sx={{
              height: "30px",
            }}
          >
            <ToggleButton
              value="monthly"
              aria-label="monthly"
              disabled={
                user &&
                user?.subscription?.price === 359.88 &&
                !cancelSubscription //false
              }
            >
              Monthly
            </ToggleButton>
            <ToggleButton value="yearly" aria-label="yearly">
              Yearly
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {user && user?.subscription?.planName === "pro" ? (
          <Stack direction={"row"} justifyContent={"space-between"}>
            {user?.subscription?.price === 39.99 &&
            billingPeriod === "yearly" ? (
              <BtnSubscribed
                onClick={() => onSelect("pro", billingPeriod)}
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#f3f3f3 !important",
                  cursor: "pointer",
                  minWidth: "200px",
                }}
              >
                <PriceCheck
                  sx={{
                    color: "#353535 !important",
                    marginRight: "10px",
                  }}
                />
                <Text sx={{ fontSize: "18px" }}>Upgrade</Text>
              </BtnSubscribed>
            ) : user?.subscription?.price === 359.88 &&
              billingPeriod === "yearly" ? (
              <>
                <BtnSubscribed
                  sx={{
                    marginBottom: "10px",
                    marginRight: "10px",
                    backgroundColor: "red !important",
                  }}
                  onClick={toggleCancel}
                >
                  <Text sx={{ fontSize: "18px", color: "#FFFFFF" }}>
                    Cancel
                  </Text>
                </BtnSubscribed>
                <BtnSubscribed
                  onClick={() => onSelect("pro", billingPeriod)}
                  sx={{
                    marginBottom: "10px",
                    backgroundColor: "#f3f3f3 !important",
                    cursor: "pointer",
                  }}
                >
                  <RepeatOneTwoTone
                    sx={{
                      color: "#353535 !important",
                    }}
                  />
                  <Text sx={{ fontSize: "18px" }}>Renew</Text>
                </BtnSubscribed>
              </>
            ) : (
              <>
                <BtnSubscribed
                  disabled
                  sx={{ marginBottom: "10px", marginRight: "10px" }}
                >
                  <Text sx={{ fontSize: "18px", color: "#FFFFFF" }}>
                    Subscribed
                  </Text>
                </BtnSubscribed>
                <BtnSubscribed
                  onClick={() => onSelect("pro", billingPeriod)}
                  sx={{
                    marginBottom: "10px",
                    backgroundColor: "#f3f3f3 !important",
                    cursor: "pointer",
                  }}
                >
                  <RepeatOneTwoTone
                    sx={{
                      color: "#353535 !important",
                    }}
                  />
                  <Text sx={{ fontSize: "18px" }}>Renew</Text>
                </BtnSubscribed>
              </>
            )}
          </Stack>
        ) : (
          <BtnSub
            variant="contained"
            onClick={() => onSelect("pro", billingPeriod)}
            sx={{ marginBottom: "10px" }}
          >
            <Text sx={{ fontSize: "18px" }}>Choose Plan</Text>
          </BtnSub>
        )}
      </Box>
      <Dialog open={modal} onClose={toggleCancel}>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Cancel sx={{ color: "#353535CC", fontSize: "60px" }} />
            <Typography variant="h6" align="center">
              Are you sure you want to cancel your subscription? You will have
              access to your subscription till the end of your billing cycle.
            </Typography>

            {loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "#353535CC !important",
                }}
                onClick={handleCancel}
              >
                Cancel Subscription
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </PlanCard>
  );
}

export default ProCard;
