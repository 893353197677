export const languages = [
  { name: "arabic", label: "Arabic", value: "Arabic" },
  { name: "bangali", label: "Bangali", value: "Bangali" },
  { name: "bulgarian", label: "Bulgarian", value: "Bulgarian" },
  { name: "chinese", label: "Chinese", value: "Chinese" },
  { name: "czech", label: "Czech", value: "Czech" },
  { name: "danish", label: "Danish", value: "Danish" },
  { name: "dutch", label: "Dutch", value: "Dutch" },
  { name: "english", label: "English", value: "English" },
  { name: "filipino", label: "Filipino", value: "Filipino" },
  { name: "french", label: "French", value: "French" },
  { name: "german", label: "German", value: "German" },
  { name: "greek", label: "Greek", value: "Greek" },
  { name: "hindi", label: "Hindi", value: "Hindi" },
  { name: "hungarian", label: "Hungarian", value: "Hungarian" },
  { name: "indonesian", label: "Indonesian", value: "Indonesian" },
  { name: "italian", label: "Italian", value: "Italian" },
  { name: "japanese", label: "Japanese", value: "Japanese" },
  { name: "korean", label: "Korean", value: "Korean" },
  { name: "norwegian", label: "Norwegian", value: "Norwegian" },
  { name: "polish", label: "Polish", value: "Polish" },
  { name: "portuguese", label: "Portuguese", value: "Portuguese" },
  { name: "romanian", label: "Romanian", value: "Romanian" },
  { name: "russian", label: "Russian", value: "Russian" },
  { name: "spanish", label: "Spanish", value: "Spanish" },
  { name: "swedish", label: "Swedish", value: "Swedish" },
  { name: "thai", label: "Thai", value: "Thai" },
  { name: "turkish", label: "Turkish", value: "Turkish" },
  { name: "urdu", label: "Urdu", value: "Urdu" },
  { name: "vietnamese", label: "Vietnamese", value: "Vietnamese" },
];

export const textTunner = [
  { name: "rephrasing", label: "Rephrasing", value: "rephrasing" },
  //continue writing
  {
    name: "continueWriting",
    label: "Continue Writing",
    value: "continue_writing",
  },
  { name: "writeInDept", label: "Write In Depth", value: "text_in_depth" },

  { name: "summarize", label: "Summarize", value: "summarize" },
  //opposing argument
  {
    name: "opposingArgument",
    label: "Opposing Argument",
    value: "opposing_argument",
  },

  //make longer
  { name: "makeLonger", label: "Make Longer", value: "make_longer" },
  { name: "makeShorter", label: "Make Shorter", value: "make_shorter" },
  {
    name: "generateOutline",
    label: "Generate Outline",
    value: "headings",
  },
];
export const tones = [
  { name: "academic", label: "Academic", value: "academic" },
  { name: "casual", label: "Casual", value: "casual" },
  { name: "persuasive", label: "Persuasive", value: "persuasive" },
  { name: "boldly", label: "boldly", value: "boldly" },
  { name: "friendly", label: "friendly", value: "friendly" },
  { name: "professionally", label: "professionally", value: "professionally" },
];

export const userNames = [
  "Lea Thompson",
  "Cyndi Lauper",
  "Tom Cruise",
  "Madonna",
  "Jerry Hall",
  "Joan Collins",
  "Winona Ryder",
  "Christina Applegate",
  "Alyssa Milano",
  "Molly Ringwald",
  "Ally Sheedy",
  "Debbie Harry",
  "Olivia Newton-John",
  "Elton John",
  "Michael J. Fox",
  "Axl Rose",
  "Emilio Estevez",
  "Ralph Macchio",
  "Rob Lowe",
  "Jennifer Grey",
  "Mickey Rourke",
  "John Cusack",
  "Matthew Broderick",
  "Justine Bateman",
  "Lisa Bonet",
];

export const userColors = [
  "#fb7185",
  "#fdba74",
  "#d9f99d",
  "#a7f3d0",
  "#a5f3fc",
  "#a5b4fc",
  "#f0abfc",
];

export const themeColors = [
  // Black to White Shades
  "#000000",
  "#434343",
  "#666666",
  "#999999",
  "#B7B7B7",
  "#CCCCCC",
  "#D9D9D9",
  "#EFEFEF",
  "#F3F3F3",
  "#FFFFFF",

  // Red Shades
  "#980000",
  "#FF0000",
  "#FF9900",
  "#FFFF00",
  "#FFD966",

  // Green Shades
  "#00FF00",
  "#93C47D",
  "#6AA84F",

  // Blue Shades
  "#00FFFF",
  "#4A86E8",
  "#0000FF",
  "#45818E",
  "#3C78D8",
  "#3D85C6",
  "#6FA8DC",
  "#6D9EEB",
  "#1155CC",
  "#0B5394",
  "#1C4587",
  "#073763",

  // Yellow Shades
  "#FF00FF",
  "#E06666",
  "#F6B26B",
  "#F1C232",

  // Purple Shades
  "#9900FF",
  "#B4A7D6",
  "#A64D79",

  // Orange Shades
  "#FF00FF",
  "#FFD966",
  "#FF9900",
  "#FFD966",
  "#FF9900",
  "#FFD966",

  // Brown Shades
  "#A61C00",
  "#E69138",
  "#F1C232",
  "#783F04",
  "#7F6000",
  "#BF9000",
  "#B45F06",
  "#990000",
  "#CC0000",
  "#E69138",

  // Gray Shades
  "#D0E0E3",
  "#C9DAF8",
  "#CFE2F3",
  "#D9D2E9",
  "#EAD1DC",
  "#D5A6BD",
  "#EA9999",
  "#F9CB9C",
  "#FFE599",
  "#B6D7A8",
  "#A2C4C9",
  "#9FC5E8",
];
