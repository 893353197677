import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MobileStepper,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { updateUser } from "../../../apiservice";
import Repo from "../../../repo/Repo";
import Create_Project_Gif from "../../../assets/videos/Create_Project_Gif.gif";
import Text_Editor from "../../../assets/videos/Text_Editor.gif";
import AI_Commands_and_In_Text_Citations from "../../../assets/videos/AI_Commands_and_In_Text_Citations.gif";
import Literature_Review from "../../../assets/videos/Literature_Review.gif";
import References from "../../../assets/videos/References.gif";
import Search_Papers from "../../../assets/videos/Search_Papers.gif";
import Upload_Files_Refrences_and_Chat from "../../../assets/videos/Upload_Files_Refrences_and_Chat.gif";
import Paper_Insight from "../../../assets/videos/Paper_Insight.gif";
import BoardingResearchPal from "../../../assets/images/BoardingResearchPal.png";

const steps = [
  {
    label: "Create Project",
    imgPath: Create_Project_Gif,
    description:
      "In order to create a project, you need to click on the plus icon on top left corner of the screen. You can create multiple projects and organise your work.",
  },
];

function IntroductoryDialogue({ openDialogue, onClose }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      onClose={onClose}
      open={openDialogue}
      fullWidth
      maxWidth="lg"
      sx={{
        padding: "0px !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        fontSize={12}
        fontWeight={600}
        fontFamily={"Raleway"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start", // Align to the left
          textAlign: "center",
          scrollBehavior: "smooth",
          scrollMarginTop: "100px",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            width: "auto",
            fontSize: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BoardingResearchPal}
            alt="logo"
            height="30px"
            width="30px"
            style={{ marginRight: "10px" }}
          />
          Welcome To ResearchPal
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "65%",
            fontSize: "16px",
          }}
        >
          Take a tour of the platform
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "100%",
          }}
        >
          <Stack direction={"row"}>
            <Stack
              square
              elevation={0}
              width={"100%"}
              spacing={2}
              px={2}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",

                bgcolor: "background.default",
              }}
            >
              <Typography fontSize={18} fontWeight={600} fontFamily={"Raleway"}>
                {steps[activeStep].label}
              </Typography>
              <Typography
                fontSize={16}
                fontFamily={"Raleway"}
                sx={{
                  textAlign: "justify",
                }}
              >
                {steps[activeStep].description}
              </Typography>
            </Stack>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                height={"100%"}
                width={"100%"}
                style={{
                  borderRadius: "16px",
                  margin: "4px",
                  border: "1px solid #EFEFEF",
                  shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                src={steps[activeStep].imgPath}
                alt="kuchbhi"
              />
            </Box>
          </Stack>
          <Stack
            width={"100%"}
            justifyContent={"flex-end"}
            direction={"row"}
            spacing={2}
            mt={2}
          >
            <Button
              size="small"
              onClick={onClose}
              variant="contained"
              sx={{
                background: "#353535 !important",
              }}
            >
              Finish
            </Button>
          </Stack>
          {/* <MobileStepper
            variant="dots"
            // steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              activeStep === maxSteps - 1 ? (
                <Button
                  size="small"
                  onClick={handleFinish}
                  variant="contained"
                  sx={{
                    background: "#353535 !important",
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={handleNext}
                  sx={{
                    color: "#353535",
                  }}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              )
            }
          /> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default IntroductoryDialogue;
