import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { Close } from "@mui/icons-material";
import { getFeedback, getLitFeedback } from "../../apiservice";

const modalColumns = [
  {
    name: "Index",
    selector: (row, idx) => idx + 1,
    width: "100px",
  },

  {
    name: "Date",
    selector: (row, idx) => row.date,
    width: "200px",
  },
  {
    name: "Rating",
    selector: (row, idx) => row.rating,
    width: "100px",
  },
  {
    name: "Feedback",
    selector: (row, idx) => row.message,
    width: 900,
    wrap: true,
  },
];
const LiteratureColumns = [
  {
    name: "Index",
    selector: (row, idx) => idx + 1,
    width: "100px",
  },

  {
    name: "Date",
    selector: (row, idx) => row.date,
    width: "100px",
  },
  {
    name: "Rating",
    selector: (row, idx) => row.rating,
    width: "100px",
  },
  {
    name: "Query Type",
    selector: (row, idx) => row.type,
    width: "100px",
  },
  {
    name: "Query",
    selector: (row, idx) => row.query,
    width: "100px",
    wrap: true,
  },
  {
    name: "Literature Review",
    selector: (row, idx) => row.literatureReview,
    width: 900,
    wrap: true,
  },
];
const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      background: "transparent",
    },
  },
  header: {
    style: {
      // borderTopRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#01A35F",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "40px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#01A35F",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

function FeedBacks({ onClose, info, ratingInfo }) {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    if (info) {
      getFeedbacks();
    } else if (ratingInfo) {
      getLitRating();
    }
  }, []);

  const getFeedbacks = async () => {
    try {
      setLoading(true);
      let userData = await getFeedback({ email: info });
      const sortedFeedbacks = userData.feedbacksArr;
      const newFeedback = sortedFeedbacks.reverse();

      setRowData(newFeedback);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getLitRating = async () => {
    try {
      setLoading(true);
      let userData = await getLitFeedback({ email: ratingInfo });
      const sortedFeedbacks = userData.feedbacksArr;

      setRowData(sortedFeedbacks);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <Dialog onClose={onClose} open={true} maxWidth="lg" fullWidth>
      <DialogTitle
        fontSize={12}
        fontWeight={600}
        fontFamily={"Raleway"}
        id="customized-dialog-title"
      >
        FEEDBACK
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        {loading && (
          <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
            <CircularProgress size={50} color="primary" />
          </Box>
        )}
        {!loading && (
          <DataTable
            columns={ratingInfo ? LiteratureColumns : modalColumns}
            data={rowData}
            customStyles={customStyles}
            noDataComponent={
              <Stack
                sx={{
                  //center the spinner
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={24} color="primary" />
              </Stack>
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default FeedBacks;
