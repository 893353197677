import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  project: "",
  loading: false,
  error: "",
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    selectProject: (state, action) => {
      state.project = action.payload;
    },
    clearProject: (state) => {
      state.project = "";
    },
  },
});

export const { selectProject, clearProject } = projectSlice.actions;

export default projectSlice.reducer;
