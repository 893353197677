import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseDomain } from "../../repo/Repository";
import TailSpinLoader from "../../components/TailSpinLoader";
import { CheckCircle } from "@mui/icons-material";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const email = new URLSearchParams(location.search).get("email");
  const [checkColor, setCheckColor] = useState("grey");

  // const verifyEmail = () => {
  //   if (!email) {
  //     toast("Reopen the link. Something went wrong");
  //     return;
  //   }
  //   setLoading(true);
  //   axios
  //     // .post("https://veracious.herokuapp.com/users", {
  //     .post(`${baseDomain}/users`, {
  //       request: {
  //         method: "updateUser",
  //         data: {
  //           _id: email,
  //           emailVerified: true,
  //         },
  //       },
  //     })
  //     .then((res) => {
  //       toast("Your email is verified.");
  //       setLoading(false);
  //       navigate("/", {
  //         state: {
  //           open: true,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);

  //       toast("Something went wrong");
  //     });
  // };

  useEffect(() => {
    const verifyEmail = () => {
      if (!email) {
        toast("Reopen the link. Something went wrong");
        return;
      }
      setLoading(true);
      axios
        .post(`${baseDomain}/users`, {
          request: {
            method: "updateUser",
            data: {
              _id: email,
              emailVerified: true,
            },
          },
        })
        .then((res) => {
          toast("Your email is verified.");
          setLoading(false);
          navigate("/", {
            state: {
              open: true,
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          toast("Something went wrong");
        });
    };

    verifyEmail(); // Call verifyEmail immediately when the component mounts
  }, [email, navigate]);

  useEffect(() => {
    // Change the check icon color to green after 2 seconds
    const timer = setTimeout(() => {
      setCheckColor("green");
    }, 1200);
    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  // return (
  //   <div className="h-screen w-full flex items-center justify-center">
  //     <div className="border shadow rounded w-fit p-5">
  //       <h1 className="text-lg font-medium">
  //         To verify your email, press the verify button below
  //       </h1>

  //       <button
  //         className="rounded  px-5 py-2 text-white font-medium mx-auto block"
  //         style={{ background: "#353535" }}
  //         onClick={verifyEmail}
  //       >
  //         {loading ? "Loading..." : "Verify"}
  //       </button>
  //     </div>
  //   </div>
  // );

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="border shadow rounded w-fit p-5 flex flex-col items-center">
        <h1 className="text-lg font-medium mb-4">Verifying your email...</h1>
        {loading ? <TailSpinLoader /> : null}
        <CheckCircle color="success" sx={{ color: checkColor, mt: 1 }} />
      </div>
    </div>
  );
};

export default EmailVerification;
