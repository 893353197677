import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  forwardRef,
} from "react";

import { Box, CircularProgress, Drawer, Switch } from "@mui/material";
import { Button, Stack, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import Files from "../../assets/images/file.png";
import { useDispatch, useSelector } from "react-redux";

// import { textSelection } from "../research/editorSlice";
// import { clearRefs, getRefs } from "./literature review/refsSlice";
import Repo from "../../repo/Repo";
import DeleteDialog from "../../components/DeleteDialog";
import Toast from "../../components/Toast";
import AbstractDialogue from "../library/AbstractDialogue";
import {
  ArticleOutlined,
  Close,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import { getRefs } from "../references/refsSlice";
import { textSelection } from "../editorSlice";
import NoAbstracts from "../../assets/images/NoAbstracts.png";
const Tip =
  "Abstracts from “literature review” or “Get Abstracts” from web feature will be saved here and can be referenced later.";

const SimpleAccordion = forwardRef((props, ref) => {
  console.log("ref:", ref);
  const {
    abstract,
    onSuccess,
    toggleDrawer,
    editor,
    customLink,
    highlightRef,
  } = props;
  const { open, data, anchor } = props;
  console.log("data:", data);
  const [checked, setChecked] = useState(false);
  const linkcheck = editor?.getAttributes("link");

  // let abstracts = "";
  // let references = "";
  // let file = "";
  // let abs = localStorage.getItem("abstracts");
  // if (abs && abs !== "undefined") {
  //   abstracts = JSON.parse(abs);
  //   file = Object.values(abstracts);
  // }
  //   localStorage.getItem("abstracts") !== undefined &&
  //   JSON.parse(localStorage.getItem("abstracts"));
  // const file = abstracts && Object.values(abstracts);

  // let refs = localStorage.getItem("references");
  // if (refs && refs !== "undefined") {
  //   references = JSON.parse(refs);
  // }
  let mappedAbstracts = "";
  let file = "";

  let abs = localStorage.getItem("mappedAbstracts");
  if (abs && abs !== "undefined") {
    mappedAbstracts = JSON.parse(abs);
    file = Object.values(mappedAbstracts);
  }
  const dispatch = useDispatch();

  const { refFormats } = useSelector((state) => state.refs);
  const { selectedText } = useSelector((state) => state.editor);
  const [selected, setSelected] = useState([]);
  const [remModal, setRemModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSecondDialogue, setOpenSecondDialogue] = React.useState(false);
  const [selectedAbstract, setSelectedAbstract] = useState(null);
  function getEntries(obj) {
    return Object.entries(obj).map(([key, value]) => ({
      key,
      value,
    }));
  }

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };
  const handleCloseSecondDialogue = () => {
    setOpenSecondDialogue(false);
  };
  const handleAbstracts = (item) => {
    setOpenSecondDialogue(true);
    setSelectedAbstract(item);

    abstract(item);
  };
  const clearText = () => {
    dispatch(textSelection({}));
  };

  const handleDeleteAll = async (refRemove) => {
    try {
      setLoading(true);
      toggle();
      let keys = [];
      let refKeys = [];
      let isRef = false;
      if (refRemove.length > 0) {
        isRef = true;
      }

      selected.forEach((item) => {
        let key = Object.keys(data?.abstracts).find(
          (key) => data?.abstracts[key].title === item
        );
        keys.push(key);
      });
      refRemove.forEach((item) => {
        let key = Object.keys(data?.abstracts).find(
          (key) => data?.abstracts[key].title === item
        );
        refKeys.push(key);
      });
      let res = await Repo.research({
        request: {
          method: "deleteProjectDocument",
          headers: {},
          data: {
            paperId: keys,
            isAll: selected.length === file.length ? true : false,
            email: localStorage.getItem("email"),
            title: data.title,
            uncheck: true,
            deleteReferences: isRef,
            referenceToDelete: refKeys,
          },
        },
      });
      // dispatch(clearRefs());
      setSelected([]);
      setLoading(false);

      if (res.data.response.data.deleteFile) {
        Toast("success", "Files deleted successfully");
        onSuccess();
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      Toast("error", "Error");
    }
  };

  const toggle = () => {
    if (selected.length === 0) {
      Toast("info", "Kindly select abstracts files");
      return;
    }
    setRemModal((prev) => !prev);
  };

  const getDescription = () => {
    if (selected.length == file.length) {
      return "This will remove all files from abstracts.";
    } else {
      return "This will remove selected files from abstracts.";
    }
  };

  return (
    <Drawer
      variant="temporary"
      anchor={anchor}
      open={open}
      elevation={4}
      onClose={toggleDrawer(anchor, false)}
      sx={{
        zIndex: 10000,
      }}
    >
      {/* <Stack
        justifyContent="start"
        alignItems="flex-start"
        sx={{ padding: "0px", margin: "0px" }}
      >
        <Close onClick={toggleDrawer(anchor, false)} />
      </Stack> */}
      {loading && (
        <Stack height="20px" mt={2} alignItems="center" justifyContent="center">
          <CircularProgress size={20} color="success" />
        </Stack>
      )}
      {!loading && file && file.length > 0 ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ padding: "10px" }}
        >
          <Stack direction={"row"} width={"100%"} spacing={0}>
            <Close
              onClick={toggleDrawer(anchor, false)}
              color="#EFEFEF"
              sx={{
                cursor: "pointer",
              }}
            />
          </Stack>
          <Typography>Saved Abstracts</Typography>

          <Box>
            <Box>
              {getEntries(mappedAbstracts)
                .sort((a, b) => b.value.uploadDate - a.value.uploadDate)
                .map((item, id) => {
                  const matched = item?.value?.url === linkcheck?.href;
                  // const reference = Object.keys(references).find(
                  //   (key) => references[key] === item.key
                  // );
                  // console.log(
                  //   matched,
                  //   "item.value.url",
                  //   "for",
                  //   truncateString(item.value.title, 20),
                  //   "is",
                  //   selectedText?.title,
                  //   item.value.url,
                  //   "is",
                  //   "and linkcheck?.href:",
                  //   linkcheck?.href
                  // );

                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ marginTop: 1, width: "100%" }}
                    >
                      <Stack direction="row">
                        <Stack
                          direction="row"
                          alignItems="center"
                          onClick={() => handleAbstracts(item.value)}
                          sx={{
                            padding: "3px",
                            // backgroundColor: matched ? "#90EE90" : "#f6f6f6",
                            background: matched
                              ? "linear-gradient(90deg, #5671F1 0%, rgba(23, 206, 173, 1) 0%, rgba(23, 206, 173, 1) 100%)"
                              : "#f6f6f6",
                            "&:hover": {
                              background: matched
                                ? "linear-gradient(90deg, #15C3A3 0%, #15C3A3 100%)"
                                : "#f6f6f6",
                              boxShadow: "none",
                            },
                            color: matched ? "#ffff" : "inherit",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          <ArticleOutlined
                            sx={{
                              color: matched ? "#ffff" : "#303030",
                            }}
                          />
                          <Tooltip
                            key={id}
                            title={`${item.key}`}
                            placement="right"
                            PopperProps={{
                              style: { zIndex: 15000000 }, // Set the z-index here
                            }}
                          >
                            <Button
                              // ref={item.value.url === linkcheck?.href ? ref : null}
                              ref={matched ? highlightRef : null}
                              size="small"
                              onClick={clearText}
                              className={`${
                                item?.value?.title == selectedText?.title &&
                                "input-box tooltip"
                              }`}
                              sx={{
                                fontSize: "12px",
                                textTransform: "capitalize",
                                color: matched ? "#ffff" : "#353535CC",
                                width: "300px",
                                display: "flex",
                                justifyContent: "flex-start",
                                "&:hover": {
                                  backgroundColor:
                                    item?.value?.url === linkcheck?.href
                                      ? "linear-gradient(90deg, #15C3A3 0%, #15C3A3 100%)"
                                      : "#E4E4E4",
                                },
                              }}
                            >
                              {item?.value?.title &&
                                truncateString(item?.value?.title, 40)}
                            </Button>
                          </Tooltip>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
            </Box>
          </Box>
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: 1,
            width: "250px",
            height: "100%",
            marginInline: "10px",
          }}
          spacing={2}
        >
          <img src={NoAbstracts} alt="No Abstracts" />
          <Typography fontFamily={"Raleway"} fontSize={"14px"}>
            No Abstracts Found !
          </Typography>
        </Stack>
      )}
      {remModal && (
        <DeleteDialog
          text={getDescription()}
          onSuccess={handleDeleteAll}
          onClose={toggle}
          refs={selected}
        />
      )}
      <AbstractDialogue
        AbstractData={selectedAbstract}
        handleCloseSecondDialogue={handleCloseSecondDialogue}
        openSecondDialogue={openSecondDialogue}
      />
    </Drawer>
  );
});

export default SimpleAccordion;
