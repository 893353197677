import { Editor } from '@tiptap/react';

import { TableOfContentsNode } from '../../extensions/TableOfContentsNode/TableOfContentsNode';
import HorizontalRule from '../../extensions/HorizontalRule/HorizontalRule';
import ImageBlock from '../../extensions/ImageBlock/ImageBlock';
import ImageUpload from '../../extensions/ImageUpload/ImageUpload';
import CodeBlock from '@tiptap/extension-code-block';
import Link from '../../extensions/Link/Link';
import Figcaption from '../../extensions/FigCaption/FigCaption';

 function isTableGripSelected(node) {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement;
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow = container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
}

export function isCustomNodeSelected(editor, node) {
  const customNodes = [
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    ImageBlock.name,
    Link.name,
    // AiWriter.name,
    // AiImage.name,
    Figcaption.name,
    TableOfContentsNode.name,
  ];

  return customNodes.some(type => editor.isActive(type)) || isTableGripSelected(node);
}

export default isCustomNodeSelected;
