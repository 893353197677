import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
const TableOfNodeContent = (props) => {
  const { editor } = props;

  return (
    React.createElement(NodeViewWrapper, null,
      React.createElement("div", { className: "p-2 -m-2 rounded-lg", contentEditable: false },
        React.createElement(TableOfContents, { editor: editor })))
  );
};

export const TableOfContentsNode = Node.create({
  name: 'tableOfContentsNode',
  group: 'block',
  atom: true,
  selectable: true,
  draggable: true,
  inline: false,

  parseHTML() {
    return [
      {
        tag: 'div[data-type="table-of-content"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': 'table-of-content' }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TableOfNodeContent);
  },

  addCommands() {
    return {
      insertTableOfContents:
      () =>
      ({ commands }) => {
          const node =this;
          return commands.insertContent({
            type: this.name,
          });
        },
    };
  },
});
