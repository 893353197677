import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  // fontSize: "13px",
  color: "#353535",
  padding: "10px",
});

const LitDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          //   flexWrap: "wrap-reverse",
        }}
      >
        <Text sx={{ fontWeight: 600 }}>
          Unable to Generate Literature Review
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: "absolute",
            right: 8,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Text>
          We're sorry, but we couldn’t find enough relevant research papers
          based on your query to generate a literature review. Here are some
          steps to help you find and upload papers:
        </Text>
        <ol>
          <li>
            <Text>
              <strong>1. Try Rephrasing Your Query:</strong> Adjust your
              keywords or focus area for improved results.
            </Text>
          </li>
          <li>
            <Text>
              <strong>2. Use "Search Papers":</strong> Utilize the "Search
              Papers" feature to find relevant papers and their sources.
            </Text>
          </li>
          <li>
            <Text>
              <strong>3. Download Relevant Papers:</strong>
            </Text>
            <ul style={{ marginLeft: "1.5rem" }}>
              <li>
                <Text>
                  • If the papers are open access, download them directly from
                  the source link or through our platform.
                </Text>
              </li>
              <li>
                <Text>
                  • <strong>Using "Semantic Search":</strong> Apply the
                  "Filters" and enable the open access toggle to locate freely
                  available papers. After selecting any search result, you’ll
                  find the link to access the complete paper.
                </Text>
              </li>
              <li>
                <Text>
                  • <strong>Using "ResearchPal" Search:</strong> For results in
                  this search mode, visit the source links to check if the
                  relevant papers are open access for download.
                </Text>
              </li>
            </ul>
          </li>
          <li>
            <Text>
              <strong>4. Upload to "Library":</strong> Upload the downloaded
              papers into your "Library" section on ResearchPal.
            </Text>
          </li>
          <li>
            <Text>
              <strong>5. Retry Literature Review:</strong> Go back to the
              "Literature Review" feature, select "Library" as your source,
              choose the desired papers, and try generating your review again.
            </Text>
          </li>
        </ol>
        <Text sx={{ mt: 2 }}>
          If you still encounter issues, feel free to contact the ResearchPal
          Team for further assistance.
        </Text>
      </DialogContent>
    </Dialog>
  );
};

export default LitDialog;
