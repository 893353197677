import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";

export const XmlDownload = async (filesData) => {
  try {
    const xmlString = filesData.map((item) => {
      return {
        title: item.title || " ",
        author: item.author || " ",
        journal: item.journal || " ",
        year: item.year || " ",
      };
    });

    let { data } = await Repo.otherFormats({
      request: {
        method: "createInsightsToXML",
        data: xmlString,
      },
    });
    if (data?.response?.status?.statusCode === 200) {
      window.open(data?.response?.data?.success, "_blank");
      // Toast("success", "XML file downloaded");
    } else {
      Toast("error", "Error");
    }
  } catch (err) {
    console.log(err);
  }
};
