import { Surface } from "../../ui/Surface";
import { Toolbar } from "../../ui/Toolbar";
import Icon from "../../ui/Icon";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import SimpleAccordion from "../../../../abstract/AbstractFiles";
import { useState, useRef } from "react";
import { Box, Stack } from "@mui/material";

const LinkPreviewPanel = ({
  onClear,
  onEdit,
  url,

  title,
  tip,
  projectTitle,
  anchor,
  data,
  file,
  editor,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const highlightRef = useRef(null);

  const handleAbstract = () => {
    setAccordionOpen(!accordionOpen);
    setTimeout(() => {
      if (highlightRef.current) {
        highlightRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    setAccordionOpen(open);
  };

  return (
    <>
      <Surface className="flex items-center gap-2 p-2 dark:bg-white dark:text-black rounded-lg">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm underline break-all"
        >
          {url}
        </a>
        <Toolbar.Divider />
        <Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }} gap={2}>
            <Tooltip title="Edit link">
              <Toolbar.Button onClick={onEdit}>
                <Icon name="Pen" />
              </Toolbar.Button>
            </Tooltip>
            <Tooltip title="Remove link">
              <Toolbar.Button onClick={onClear}>
                <Icon name="Trash2" />
              </Toolbar.Button>
            </Tooltip>
          </Box>
          {/* <Tooltip title="Remove link"> */}
          <Toolbar.Button
            variant="contained"
            className="bg-[#f6f6f6]  text-black hover:bg-[#e5e5e5]"
            onClick={handleAbstract}
          >
            View Abstract
          </Toolbar.Button>
          {/* </Tooltip> */}
        </Stack>
      </Surface>
      {accordionOpen && (
        <SimpleAccordion
          title={title}
          tip={tip}
          projectTitle={projectTitle}
          anchor={anchor}
          open={accordionOpen}
          // onClose={() => setAccordionOpen(false)}
          toggleDrawer={(anchor, open) => toggleDrawer(anchor, open)}
          data={data}
          file={file}
          editor={editor}
          highlightRef={highlightRef}
        />
      )}
    </>
  );
};

export { LinkPreviewPanel };
