import React, { useState } from "react";
import Contact from "../../subscription/Contact";
import { Typography, Stack, Button, Box } from "@mui/material";

const ContactUsSection = () => {
  const [open, setOpen] = useState(false);

  const contactOpen = () => {
    setOpen(true);
  };

  const contactClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          gap="16px"
          alignItems="center"
          sx={{
            py: 1,
            px: 2,
            mr: 2,
            mb: { xs: 2, md: 0 },
            borderRadius: "8px",
            // background: "#F6F6F6",
            // width: 1 / 2,
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              background:
                "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%)",
              textTransform: "capitalize",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(48, 70, 174, 1) 0%, rgba(20, 181, 153, 1) 100%)",
              },
            }}
            onClick={contactOpen}
          >
            Contact Us For Demo
          </Button>
        </Stack>

        <Contact open={open} onClose={contactClose} />
      </Box>
    </>
  );
};

export default ContactUsSection;
