import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Files from "../../../assets/images/file.png";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import Toast from "../../components/Toast";
import { Folder, InsertDriveFile } from "@mui/icons-material";
import SubFolderAccordian from "./SubFolderAccordian";
import SearchHistory from "../../assets/images/search-history.svg";

const apiKey = localStorage.getItem("zoteroKey");

export default function MendeleyAccordion({
  data,
  toggle,
  checked,
  selectAll,
  s3Files,
  subFolderData,
  getProject,
  zoteroClose,
}) {
  const [state, setState] = React.useState({
    searchData: [],
    name: "",
    openAccordion: null,
    subCollectionData: [],
    subFolderDialog: false,
  });
  const isSmall = useMediaQuery("(max-width:480px)");
  const handleAccordionChange = (panel) => {
    setState((prev) => ({
      ...prev,
      openAccordion: panel === state.openAccordion ? null : panel,
    }));
  };

  const searchFiles = (key, file) => {
    let id = localStorage.getItem("userId");
    let zotKey = localStorage.getItem("zoteroKey");
    setState((prev) => ({ ...prev, searchData: [], name: file }));
    if (key) {
      try {
        axios
          .get(
            `https://api.zotero.org/users/${id}/collections/${key}/items/top`,
            {
              headers: {
                "Zotero-API-Key": zotKey,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              let filtered = response.data.filter((item) => {
                const email = localStorage.getItem("email");
                const title = item.data.title;
                const pattern = new RegExp(`${email}/[0-9]${title}`);
                return s3Files.some((file) => pattern.test(file));
              });

              filtered.forEach((object) => {
                object.alreadyAdded = true;
              });
              filtered.map((obj) => {
                response.data.map((item) => {
                  if (obj.key === item.key) {
                    item = { ...obj, ...item };
                  }
                  return item;
                });
                return obj;
              });
              setState((prev) => ({
                ...prev,
                searchData: response.data,
                loading: false,
              }));
              // setState((prev) => ({
              //   ...prev,
              //   searchData: response.data,
              // }));
            } else {
              console.error("Error:", response.status, response.statusText);
            }
          })
          .catch((error) => {
            console.error("API Request Error:", error);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      Toast("error", "NO DATA FOUND");
    }
  };
  const selected = (e, data) => {
    selectAll(e, data);
  };
  const searchSubFolderFiles = (key, file) => {
    let id = localStorage.getItem("userId");
    let zotKey = localStorage.getItem("zoteroKey");
    setState((prev) => ({
      ...prev,
      subCollectionData: [],
      subFolderDialog: true,
    }));

    if (key) {
      try {
        axios
          .get(
            `https://api.zotero.org/users/${id}/collections/${key}/collections`,
            {
              headers: {
                "Zotero-API-Key": zotKey,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setState((prev) => ({
                ...prev,
                subCollectionData: response.data,
                loading: false,
              }));
            } else {
              console.error("Error:", "No data found");
              Toast("error", "NO DATA FOUND");
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleClose = () => {
    setState((prev) => ({ ...prev, subFolderDialog: false }));
  };
  return (
    <div>
      {data &&
        data.map((item, index) => (
          <Accordion
            sx={{ m: 2 }}
            expanded={state.openAccordion === index}
            onChange={() => handleAccordionChange(index)}
            key={index}
          >
            <AccordionSummary
              onClick={() => searchFiles(item.key, item.name)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {index + 1} - <Folder sx={{ color: "#D32f2f", mr: 2, ml: 1 }} />
                {item?.name}{" "}
                {item?.subFolderData?.map((subFolder, i) => {
                  return (
                    <Button
                      variant="contained"
                      key={i}
                      sx={{
                        textTransform: "capitalize",
                        background: "#D32F2F !important",
                        fontFamily: "Raleway",
                        fontSize: "10px",
                        fontWeight: 500,
                        height: "24px !important",
                        color: "#fff !important",
                        padding: "6px 12px !important",
                        marginLeft: "20px",
                      }}
                      onClick={() =>
                        searchSubFolderFiles(subFolder.parentCollection)
                      }
                    >
                      open Subfolder
                    </Button>
                  );
                })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {state.searchData && state.searchData.length !== 0 && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          onChange={(e) => selected(e, state.searchData)}
                        />
                      </ListItemIcon>

                      <ListItemText primary={`Select All`} />
                    </ListItemButton>
                  </ListItem>
                )}
                {state.searchData && state.searchData.length !== 0 ? (
                  state.searchData.map((value, id) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;

                    return (
                      state.name === item.name && (
                        <ListItem key={value} disablePadding>
                          {`${id + 1}.`}
                          <ListItemButton
                            disabled={value.alreadyAdded}
                            onClick={toggle(value)}
                          >
                            {/* <ListItemIcon > */}
                            <Checkbox
                              size="small"
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#353535",
                                },
                              }}
                            />
                            {/* </ListItemIcon> */}
                            {/* <ListItemAvatar>
                              <InsertDriveFile />
                            </ListItemAvatar> */}
                            <Box sx={{ mr: 1 }}>
                              <InsertDriveFile />
                            </Box>
                            <ListItemText
                              disabled={value.alreadyAdded}
                              id={labelId}
                              //split the title name ... if it is too long
                              primary={
                                value.data.title?.length > (isSmall ? 18 : 30)
                                  ? `${value.data.title.substring(
                                      0,
                                      isSmall ? 18 : 30
                                    )}...`
                                  : value.data.title
                              }
                              primaryTypographyProps={{
                                style: { fontSize: isSmall ? "14px" : "16px" }, // Set font size to 12px
                              }}
                              sx={{ overflow: "hidden" }}
                            />
                            {value.alreadyAdded && (
                              <Chip
                                label="Uploaded"
                                color="success"
                                sx={{
                                  height: "20px",
                                  borderRadius: "5px !important",
                                }}
                              />
                            )}
                          </ListItemButton>
                        </ListItem>
                      )
                    );
                  })
                ) : (
                  <CircularProgress size={20} style={{ marginLeft: "50%" }} />
                )}
              </>
            </AccordionDetails>
          </Accordion>
        ))}
      {/* {data.length <= 0 && data && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "40vh",
          }}
        >
          <img
            src={SearchHistory}
            alt=""
            styles={{
              width: "100px",
              height: "100px",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#4E4E4E",
              my: 1,
            }}
          >
            No Search Results
          </Typography>
        </Box>
      )} */}

      {state.subFolderDialog && (
        <SubFolderAccordian
          data={state.subCollectionData}
          onClose={handleClose}
          open={state.subFolderDialog}
          getProject={getProject}
          zoteroDialogClose={zoteroClose}
        />
      )}
    </div>
  );
}
