import { useEffect, useState } from "react";
import { Surface } from "../../components/ui/Surface";
import { Tooltip } from "../../components/ui/Tooltip/Tooltip";
import {
  Stack,
  Box,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Button,
  MenuItem,
  Select,
  Typography,
  styled,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Menu,
} from "@mui/material";
import Icon from "../../components/ui/Icon";
import SendIcon from "@mui/icons-material/Send";
import { Toolbar } from "../ui/Toolbar";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import { useSelector } from "react-redux";
import ModelSelection from "./ModelSelection";
import ChatAbstract from "./ChatAbstract";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ChatBot from "../../../../assets/images/NewImages/RP ChatBot.png";
import {
  saveChat,
  sendQuery,
  getChatHistory,
  getAllChats,
  deleteChat,
  updateChat,
  updateChatTitle,
  sendErrorMail,
} from "../../../../apiservice";
import { useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Toast from "../../../../components/Toast";

const Text = styled(Typography)({
  fontFamily: "Raleway",
});

export default function ChatHome() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [query, setQuery] = useState("");
  const { user } = useSelector((state) => state.user);
  // const [selectedModel, setSelectedModel] = useState("");
  const [selectedModel, setSelectedModel] = useState(
    user.subscription.planName === "pro" ? "Gemini 1.5 pro" : ""
  );

  const [chatHistory, setChatHistory] = useState([]);
  console.log("Chat History", chatHistory);
  const [selectedChat, setSelectedChat] = useState(null);
  console.log("selectedChat", selectedChat);
  const { chatId } = useParams();
  console.log("Chat ID", chatId);

  const [answer, setAnswer] = useState("");
  console.log("Answer", answer);
  const [submittedQuery, setSubmittedQuery] = useState("");
  console.log("Submitted", submittedQuery);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  console.log("Chat To Delete", chatToDelete);

  const [loading, setLoading] = useState(false);
  const isSmall = useMediaQuery("(max-width:600px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editChatId, setEditChatId] = useState(null);
  const [newChatTitle, setNewChatTitle] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [modelError, setModelError] = useState(false);

  const handleMenuClick = (event, chat) => {
    setAnchorEl(event.currentTarget);
    setEditChatId(chat.id);
    setNewChatTitle(chat.chatTitle); // Set the current title for editing
    setChatToDelete(chat);
    // setOpenDialog(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleCloseMenu(); // Close the menu when opening the dialog
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditChat = async () => {
    if (newChatTitle.trim() && newChatTitle !== editChatId) {
      // Call the API to update the chat title
      await updateChatTitle(editChatId, newChatTitle);
      Toast("success", "Chat Title Saved");
      setChatHistory((prev) =>
        prev.map((chat) =>
          chat.id === editChatId ? { ...chat, chatTitle: newChatTitle } : chat
        )
      );
    }
    setIsEditing(false);
    handleCloseMenu();
    setOpenDialog(false);
  };

  const getChatCount = () => {
    return parseInt(localStorage.getItem("chatCount") || "0", 10);
  };

  const incrementChatCount = () => {
    const chatCount = getChatCount() + 1;
    localStorage.setItem("chatCount", chatCount);
    return chatCount;
  };

  const getMessageCount = () => {
    return parseInt(localStorage.getItem("messageCount") || "0", 10);
  };

  const incrementMessageCount = () => {
    const messageCount = getMessageCount() + 1;
    localStorage.setItem("messageCount", messageCount);
    return messageCount;
  };

  const [chatTitle, setChatTitle] = useState(
    `Untitled-${getChatCount() || incrementChatCount()}`
  );

  useEffect(() => {
    fetchChatHistory();
  }, []);

  useEffect(() => {
    // Reset the message count to zero for a new chat
    localStorage.setItem("messageCount", "0");
  }, []);

  const fetchChatHistory = async () => {
    const email = localStorage.getItem("email");
    const projectName = localStorage.getItem("projectTitle");

    if (email && projectName) {
      try {
        const response = await getAllChats({
          email: email,
          projectName: projectName,
        });

        console.log("Response", response);
        // Set the chatHistory to the 'chats' array
        setChatHistory(response.chats || []); // Default to empty array if no chats found
      } catch (error) {
        console.error("Failed to fetch chat history:", error);
      }
    }
  };

  const handleStartNewChat = () => {
    setIsCreatingNewChat(true);
    setSelectedChat(null);
    setQuery("");
    // setSelectedModel(
    //   user.subscription.planName === "pro" ? "Gemini 1.5 pro" : ""
    // );
    if (
      user.subscription.planName === "pro" ||
      user.subscription.planName === "standard"
    ) {
      setSelectedModel("Gemini 1.5 pro");
    } else {
      setSelectedModel(""); // For other plans, keep default as empty
    }
    setModelError(false);
  };

  const handleBackToHome = () => {
    setIsCreatingNewChat(false);
    setSelectedChat(null);
    setQuery("");
    setSubmittedQuery("");
    setAnswer("");
    fetchChatHistory();
    setChatTitle(`Untitled-${getChatCount() || incrementChatCount()}`);
    setModelError(false);
  };

  const handleSend = async () => {
    if (!selectedModel) {
      setModelError(true);
      return;
    }
    setModelError(false);

    if (query.trim()) {
      setSubmittedQuery(query);
      setQuery("");
      setLoading(true);

      const timeoutId = setTimeout(() => {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Ask ResearchPal Chat, ${selectedModel}`,
          error: "20 seconds",
          query: query,
          delayInResponse: true,
        });
      }, 20000);

      try {
        const email = localStorage.getItem("email");
        const token = localStorage.getItem("token");

        // Creating the payload for sendQuery
        let queryPayload = {
          query: query,
          email: email,
          token: token,
          model: selectedModel,
          askResearchPal: true,
          id: isCreatingNewChat ? "" : selectedChat.id,
        };
        // if (!isCreatingNewChat && selectedChat?.id) {
        //   queryPayload.id = selectedChat.id;
        // }

        const response = await sendQuery(queryPayload);
        console.log("Query Response:", response);
        clearTimeout(timeoutId);
        setLoading(false);

        const messageNumber = incrementMessageCount();

        // Create new message object
        const newMessage = {
          id: `message_${messageNumber}`,
          question: query,
          answer: response.data?.response || "No answer received",
          model: selectedModel,
          date: new Date().getTime().toString(),
        };

        if (response.data?.error) {
          // Show error message in a Toast
          Toast("error", response.data.error);

          // Set the answer to show the error in the chat
          newMessage.answer = response.data.error;

          if (
            response.data.error ===
            "Ask ResearchPal limit reached. Please upgrade your subscription for more."
          ) {
            newMessage.limitReachedError = true;
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Ask ResearchPal Chat, ${selectedModel}`,
              error: response.data.error,
              query: query,
              limitReachedError: true,
            });
          } else {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Ask ResearchPal Chat, ${selectedModel}`,
              error: response.data.error,
              query: query,
            });
          }
        }

        if (isCreatingNewChat) {
          const chatPayload = {
            // chatTitle: `Untitled-${getChatCount() || incrementChatCount()}`,
            chatTitle: chatTitle,
            email: email,
            projectName:
              localStorage.getItem("projectTitle") || "DefaultProject",
            messages: [newMessage],
          };

          // Save the new chat and get its ID
          const savedChatResponse = await saveChat(chatPayload);
          console.log("SaveChat", savedChatResponse);
          const savedChatId = savedChatResponse.response.data._id; // Extract the _id from the response

          // Update the selectedChat with the new chat ID
          setSelectedChat({ id: savedChatId, messages: [newMessage] });
          setIsCreatingNewChat(false);
          await fetchChatHistory(); // Refresh chat history if needed
        } else {
          // Call updateChat with chatId and newMessage directly
          await updateChat(selectedChat.id, newMessage);
          setSelectedChat((prev) => ({
            ...prev,
            messages: [...prev.messages, newMessage],
          }));
        }

        // Clear the input field after sending
        setSubmittedQuery("");
        setQuery("");
      } catch (error) {
        clearTimeout(timeoutId);
        console.error("Error in handleSend:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChatClick = async (chat) => {
    setIsCreatingNewChat(false);
    setQuery("");

    try {
      const chatHistoryData = await getChatHistory(chat.id); // Using the chat ID from the selected chat

      // Check if chatHistoryData is valid and has messages
      if (
        chatHistoryData &&
        chatHistoryData.response &&
        chatHistoryData.response.data
      ) {
        const messages = chatHistoryData.response.data.chatHistory.messages;

        // Check if messages is an array
        if (Array.isArray(messages)) {
          const lastMessage = messages[messages.length - 1];
          setSelectedChat({ ...chat, messages }); // Combine chat and its messages
          setSelectedModel(lastMessage.model || "");
          console.log("Chat History Data:", chatHistoryData);
        } else {
          console.error("Chat messages are invalid or empty");
        }
      } else {
        console.error("Chat history data is invalid or empty");
      }
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
    }
  };

  const checkChatTitleExists = (title) => {
    return chatHistory.some((chat) => chat.chatTitle === title);
  };

  const handleOpenDeleteDialog = (chat) => {
    // console.log("Opening delete dialog for chat:", chat);
    // setChatToDelete(chat); // Store the chat to be deleted
    setOpenDeleteDialog(true);
    handleCloseMenu();
  };

  const handleDeleteChat = async () => {
    if (chatToDelete) {
      try {
        await deleteChat(chatToDelete.id);
        Toast("success", "Chat Title Deleted");
        fetchChatHistory(); // Refresh the chat history after deletion
      } catch (error) {
        console.error("Error deleting chat:", error);
      } finally {
        setOpenDeleteDialog(false);
        setChatToDelete(null); // Clear the chatToDelete state
      }
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    // Reset states when closing the chat
    if (isChatOpen) {
      setIsCreatingNewChat(false);
      setSelectedChat(null);
      setQuery("");
    }
  };

  const handleInput = (event) => {
    setQuery(event.target.value); // Update query state
  };

  const capitalizeFirstWord = (text) => {
    const words = text.split(" ");
    if (words.length > 0) {
      words[0] =
        words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    }
    return words.join(" ");
  };

  return (
    <>
      <Surface
        className={`flex items-center justify-center gap-1 fixed z-40 ${
          isChatOpen
            ? "bottom-0 right-1 w-[84%] sm:w-[550px] md:w-[600px] h-[88vh] lg:h-[92vh] xl:h-[92.5vh] 2xl:h-[95vh] overflow-y-auto p-1"
            : "bottom-8 right-12 border-none p-1 shadow-none"
        } `}
        style={{
          backgroundColor: isChatOpen ? "white" : "rgba(255, 255, 255, 0.1)", // Slightly transparent background when chat is closed
          backdropFilter: isChatOpen ? "none" : "blur(6px)", // Apply blur effect when chat is closed
        }}
      >
        {isChatOpen ? (
          <Stack
            spacing={2}
            sx={{ px: 3, py: 2, height: "100%", width: "100%" }}
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction={"row"}
              // mt={5}
            >
              {(isCreatingNewChat || selectedChat) && (
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBackToHome}
                  sx={{
                    color: "#7A7979",
                    // fontWeight: "600",
                    background: "white",
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    "&:hover": {
                      background: "#F6FAFD",
                      color: "#14224B",
                      boxShadow: "none",
                    },
                    px: 1,
                    textTransform: "capitalize",
                  }}
                >
                  Back
                </Button>
              )}

              <Text
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                  fontWeight: 700,
                  ml:
                    !isCreatingNewChat && !selectedChat ? { xs: 4, sm: 8 } : 0,
                  color: "#14224B",
                }}
              >
                Ask ResearchPal
              </Text>
              <Button onClick={toggleChat} sx={{ marginLeft: "auto" }}>
                <CloseIcon sx={{ fontSize: "19px", color: "#353535" }} />
              </Button>
            </Stack>

            {/* Interface 1: Display Start New Chat and Chat History */}
            {!selectedChat && !isCreatingNewChat && (
              <Box sx={{ width: "100%", height: "100%" }}>
                <Stack
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleStartNewChat}
                    sx={{
                      borderRadius: "6px",
                      // px: 6.5,
                      width: "100%",
                      // py: 1,
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      boxShadow: "none",
                      background: "#1E4F6B",
                      "&:hover": {
                        background: "#163C54",
                      },
                    }}
                  >
                    New Chat
                  </Button>
                </Stack>

                <Stack
                  spacing={2}
                  my={4}
                  sx={{
                    height:
                      chatHistory.length === 0
                        ? "90%"
                        : { xs: "58vh", md: "60vh", lg: "66vh" },
                    overflow: "auto",
                  }}
                >
                  {chatHistory.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Text sx={{ color: "#7A7979", fontSize: "14px" }}>
                        No Chats History
                      </Text>
                    </Box>
                  ) : (
                    chatHistory.map((chat, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          // gap={0.2}
                          sx={{
                            width: "100%",
                            borderRadius: "6px",
                            background: "#f6f6f6",
                            color: "#535D7B",
                            border: "1px solid #EFEFEF",
                            "&:hover": {
                              border: "1px solid #EFEFEF",
                              background: "#EFEFEF",
                              color: "#14224B",
                            },
                          }}
                          alignItems={"center"}
                        >
                          <Button
                            key={chat.id}
                            onClick={() => handleChatClick(chat)}
                            variant="outlined"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: { xs: 1, sm: 0 },
                              flexWrap: "wrap",
                              alignItems: "center",
                              fontWeight: 600,
                              width: "100%",
                              border: "none",
                              color: "#535D7B",
                              background: "inherit",
                              "&:hover": {
                                border: "none",
                                background: "inherit",
                                color: "#14224B",
                              },
                              px: 2,
                              py: 1,
                              // fontSize: "16px",
                            }}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              gap={1}
                            >
                              <CommentIcon sx={{ fontSize: "18px" }} />
                              <Text
                                sx={{ fontSize: "14px", textTransform: "none" }}
                              >
                                {chat.chatTitle.length > (isSmall ? 17 : 30)
                                  ? ` ${chat.chatTitle.substring(
                                      0,
                                      isSmall ? 17 : 30
                                    )}...`
                                  : ` ${chat.chatTitle}`}
                              </Text>
                            </Stack>

                            <Stack direction={"row"} gap={1}>
                              <Text
                                sx={{
                                  fontSize: "11px",
                                  fontFamily: "Robotto",
                                  textTransform: "capitalize",
                                }}
                              >
                                {new Date(
                                  chat.createdAt * 1000
                                ).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                              </Text>
                            </Stack>
                          </Button>

                          {/* <DeleteIcon
                          sx={{
                            fontSize: "18px",
                            cursor: "pointer",
                            color: "#1E4F6B",
                          }}
                          onClick={() => handleOpenDeleteDialog(chat)}
                        /> */}
                          <MoreHorizIcon
                            onClick={(event) => handleMenuClick(event, chat)}
                            sx={{ cursor: "pointer", mr: 1.5 }}
                          />

                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            PaperProps={{
                              sx: {
                                boxShadow: "none", // Remove box shadow
                                border: "1px solid #E6E6E6",
                                ml: -2,
                                px: 1,
                              },
                            }}
                          >
                            <MenuItem
                              onClick={handleOpenDialog}
                              sx={{
                                fontFamily: "Raleway",
                                fontSize: "14px",
                                color: "#1E4F6B",
                                my: 1,
                                p: 1,
                                borderRadius: "6px",
                              }}
                            >
                              <EditIcon
                                sx={{
                                  mr: 1,
                                  fontSize: "18px",
                                  color: "#1E4F6B",
                                }}
                              />
                              Rename
                            </MenuItem>

                            <MenuItem
                              onClick={() => handleOpenDeleteDialog(chat)}
                              sx={{
                                fontFamily: "Raleway",
                                fontSize: "14px",
                                color: "#F94643",
                                mb: 1,
                                p: 1,
                                borderRadius: "6px",
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  mr: 1,
                                  color: "#F94643",
                                  fontSize: "18px",
                                }}
                              />
                              Delete
                            </MenuItem>
                          </Menu>
                        </Stack>
                      </Box>
                    ))
                  )}
                </Stack>
              </Box>
            )}

            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                  padding: "8px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "16px 24px",
                  borderBottom: "1px solid #f6f6f6",
                  fontFamily: "Raleway",
                  color: "#163C54",
                }}
              >
                Rename Chat Title
              </DialogTitle>
              <DialogContent
                sx={{
                  textAlign: "center",
                  padding: "18px 24px",
                  mt: 2,
                }}
              >
                <TextField
                  value={newChatTitle}
                  onChange={(e) => setNewChatTitle(e.target.value)}
                  size="small"
                  fullWidth
                  sx={{
                    width: { xs: "100%", sm: "400px" },
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        fontFamily: "Raleway",
                      },
                    },
                  }}
                />
              </DialogContent>
              <DialogActions
                sx={{ justifyContent: "center", padding: "16px 24px" }}
              >
                <Button
                  onClick={() => setOpenDialog(false)}
                  variant="contained"
                  sx={{
                    bgcolor: "#F6F6F6",
                    color: "#535D7B",
                    fontFamily: "Raleway",
                    boxShadow: "none",
                    "&:hover": {
                      bgcolor: "#f6f6f6",
                      color: "#535D7B",
                    },
                    width: "110px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleEditChat}
                  variant="contained"
                  sx={{
                    background: "#1E4F6B",
                    fontFamily: "Raleway",
                    "&:hover": {
                      background: "#163C54",
                    },
                    color: "white",
                    width: "110px",
                    boxShadow: "none",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              maxWidth="xs"
              fullWidth
              sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}
            >
              <DialogTitle
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "16px 24px",
                  borderBottom: "1px solid #f6f6f6",
                  fontFamily: "Raleway",
                  color: "#163C54",
                }}
              >
                Delete Chat?
              </DialogTitle>
              <DialogContent
                sx={{
                  textAlign: "center",
                  padding: "18px 24px",
                  mt: 2,
                }}
              >
                <Text variant="body1" color="textSecondary">
                  This will permanently delete your conversation.
                </Text>
              </DialogContent>
              <DialogActions
                sx={{ justifyContent: "center", padding: "16px 24px" }}
              >
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  variant="contained"
                  sx={{
                    bgcolor: "#F6F6F6",
                    fontFamily: "Raleway",
                    color: "#535D7B",
                    boxShadow: "none",
                    "&:hover": {
                      bgcolor: "#f6f6f6",
                      color: "#535D7B",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteChat}
                  variant="contained"
                  color="error"
                  sx={{ fontFamily: "Raleway", boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {(isCreatingNewChat || selectedChat) && (
              <ChatAbstract
                isCreatingNewChat={isCreatingNewChat}
                selectedChat={selectedChat}
                query={query}
                handleSend={handleSend}
                handleInput={handleInput}
                user={user}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                answer={answer}
                submittedQuery={submittedQuery}
                getChatCount={getChatCount()}
                incrementChatCount={incrementChatCount()}
                incrementMessageCount={incrementMessageCount()}
                loading={loading}
                chatTitle={chatTitle}
                setChatTitle={setChatTitle}
                checkChatTitleExists={checkChatTitleExists}
                modelError={modelError}
              />
            )}
          </Stack>
        ) : (
          <Tooltip
            title="Ask ResearchPal"
            tippyOptions={{
              delay: 0,
            }}
          >
            <Button onClick={toggleChat} style={{ padding: 2, border: "none" }}>
              {/* <ChatIcon sx={{ height: "100%" }} /> */}
              <Box sx={{ width: "48px" }}>
                <img src={ChatBot} alt="" />
              </Box>
            </Button>
          </Tooltip>
        )}
      </Surface>
    </>
  );
}
