import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function Loader() {
  const { progress } = useSelector((state) => state.loader);
  return (
    <div>
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        progress
      )}%`}</Typography>
      <Box
        sx={{
          height: "6px",
          background: "rgba(242, 242, 242, 0.2)",
        }}
      >
        <LinearProgress
          sx={{
            height: "6px",
            "& .MuiLinearProgress-bar": {
              background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
            },
            background: "#f6f6f6",
          }}
          variant="determinate"
          value={progress}
        />
      </Box>
    </div>
  );
}

export default Loader;
