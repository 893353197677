import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  styled,
} from "@mui/material";
import { Typography, Grid } from "@mui/material";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { ExpandMore } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const RootStyle = styled(Box)(({ theme }) => ({
  background: "#fff",
  minHeight: "500px",
  padding: "64px 101px",
  [theme.breakpoints.down("md")]: {
    fontSize: "38px",
    padding: "64px 10px",
  },
}));
const FAQBox = styled(Box)(({ theme }) => ({
  minHeight: "225px",
  boxShadow: "0px 0px 24.35796356201172px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(12.17898178100586px)",
  borderRadius: "12px",
  padding: "17px 24px",
}));
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#14224B",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const CardText = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 550,
  fontSize: "20px",
  color: "#14224B",
}));

function FAQs() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const faqSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "FAQPage",
  //   mainEntity: [
  //     {
  //       "@type": "Question",
  //       name: "Is ResearchPal free?",
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: "Yes, we have a free plan for users who want to test out the product and its features. Standard plan is required after the free Quota ends.",
  //       },
  //     },
  //     {
  //       "@type": "Question",
  //       name: "Will my ResearchPal document be flagged by AI Detectors?",
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: "Being flagged by AI detectors isn’t necessarily a bad thing. Many authors use AI tools to improve writing and productivity. AI detectors promote accountability to ensure content is reliable. Understand the AI scoring and then use Paperpal’s output as a base to refine the text and express ideas in your style. Secondly, note that AI detectors are in the early stages of development. For example, one AI detector falsely claimed the US Constitution was written by AI. Therefore, these detectors can be used as a reference but should not be relied on for an accurate assessment.",
  //       },
  //     },
  //     {
  //       "@type": "Question",
  //       name: "Where are my files stored?",
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: "We store your files in encrypted cloud storage to ensure their security. Our strict security protocols guarantee the utmost safety for your information and protect it from potential threats. You have full ownership and control of your data.",
  //       },
  //     },
  //     {
  //       "@type": "Question",
  //       name: "Do I have the option to delete my files?",
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: "Yes, you can delete your files at any time and they will be removed from the platform and the cloud permanently, with no recovery.",
  //       },
  //     },
  //     {
  //       "@type": "Question",
  //       name: "Which Large Language Model is powering ResearchPal?",
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: "We are using OpenAI Legacy Models and giving access to more models in our Standard and Pro subscriptions.",
  //       },
  //     },
  //   ],
  // };

  return (
    <RootStyle>
      {/* <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet> */}
      <Title variant="h1" sx={{ mb: 3 }}>
        Frequently Asked Questions
      </Title>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ color: "#14224B !important" }} fontWeight={550}>
              Is ResearchPal free ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, we have a free plan for users who want to test out the
              product and its features. Standard plan is required after the free
              Quota ends.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ color: "#14224B !important" }} fontWeight={550}>
              Will my ResearchPal document be flagged by AI detectors ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Being flagged by AI detectors isn’t necessarily a bad thing. Many
              authors use AI tools to improve writing and productivity. AI
              detectors promote accountability to ensure content is reliable.
              Understand the AI scoring, and then use Paperpal’s output as a
              base to refine the text and express ideas in your style. Secondly,
              note that AI detectors are in the early stages of development. For
              example, one AI detector
              <a
                style={{ color: "blue", margin: "0px 4px" }}
                href="https://arstechnica.com/information-technology/2023/07/why-ai-detectors-think-the-us-constitution-was-written-by-ai/"
                target="_blank"
              >
                falsely claimed
              </a>
              the US Constitution was written by AI. Therefore, these detectors
              can be used as a reference, but should not be relied on for an
              accurate assessment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ color: "#14224B !important" }} fontWeight={550}>
              Where are my files stored ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We store your files in encrypted cloud storage to ensure their
              security. Our strict security protocols guarantee the utmost
              safety for your information and protect it from potential threats.
              you have full ownership and control of your data.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ color: "#14224B !important" }} fontWeight={550}>
              Do I have the option to delete my files ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, you can delete your files at any time and they will be
              removed from the platform and the cloud permanently, with no
              recovery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ color: "#14224B !important" }} fontWeight={550}>
              Which Large Language Model is powering ResearchPal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We are using OpenAI Legacy Models and giving access to more models
              in our Standard and Pro subscriptions.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </RootStyle>
  );
}

export default FAQs;
