import React from "react";
import AlertIcon from "../assets/images/delete alert.svg";
import { Button } from "@mui/material";
import { Stack, Typography, styled } from "@mui/material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "24px",
  fontWeight: 700,
  color: "#353535",
});

export default function DeleteDialog({ onClose, onSuccess, open }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={true} onClose={onClose}>
      <DialogContent>
        <Stack
          spacing={2.4}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <img height="64px" width="64px" src={AlertIcon} alt="" />
          <Title>Are you sure ?</Title>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          sx={{
            textTransform: "none",
            color: "#353535",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: "none",
            // background: "#353535 !important",
            background: "#1E4F6B !important",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
          variant="contained"
          onClick={onSuccess}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
