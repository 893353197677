import { useCallback } from 'react';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import { ImageUploader } from './ImageUploader';

export const ImageUpload = ({ getPos, editor }) => {
  const onUpload = useCallback(
    (url) => {
      if (url) {
        editor.chain().setImageBlock({ src: url }).deleteRange({ from: getPos(), to: getPos() }).focus().run();
      }
    },
    [getPos, editor],
  );

  return (
    <NodeViewWrapper>
      <div className="p-0 m-0" data-drag-handle>
        <ImageUploader onUpload={onUpload} />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
