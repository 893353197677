import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BtnSetting } from "../styles";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser } from "../../pages/Admin/userSlice";
import { clearAllLitReview } from "../../pages/literature review/reviewSlice";
import { clearAllInsights } from "../../pages/paper insights/insightsSlice";
import { clearProject } from "../../pages/projects/projectSlice";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "13px",
  color: "#353535",
  padding: "10px",
});
export default function Setting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  return (
    <Box>
      <BtnSetting
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ width: { xs: "100%", md: "none" } }}
      >
        <AssignmentIndIcon sx={{ fontSize: "16px", mr: 1 }} />
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontWeight: 600,
            fontSize: "12px",
          }}
        >
          Profile
        </Typography>
      </BtnSetting>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ mt: 0.5 }}
      >
        <Text>{`${email && email}`}</Text>
        <Link to="/subscription/profile">
          <MenuItem
            sx={{
              width: { xs: "100%", md: "none" },
              px: "10px",
              "&:hover": { background: "transparent" },
            }}
          >
            <AssignmentIndIcon sx={{ fontSize: "16px", mr: 0.5 }} />
            <Typography
              sx={{ fontFamily: "Raleway", fontWeight: 600, fontSize: "12px" }}
            >
              Your Profile
            </Typography>
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleLogout}
          sx={{
            width: { xs: "100%", md: "none" },
            px: "10px",
            "&:hover": { background: "transparent" },
          }}
        >
          <MeetingRoomIcon sx={{ fontSize: "16px", mr: 1 }} />

          <Typography
            sx={{ fontFamily: "Raleway", fontWeight: 600, fontSize: "12px" }}
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
