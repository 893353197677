import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Contact from "../subscription/Contact";
import ProCard from "./cards/ProCard";
import EnterpriseCard from "./cards/EnterpriseCard";
import FreeCard from "./cards/FreeCard";
import StandardCard from "./cards/StandardCard";
import { BtnSub, Text } from "./cards/styles";
import { StackedBarChart } from "@mui/icons-material";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "64px 202px 0 202px",
  marginInline: "10px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const CardGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#353535",
  textAlign: "center",
  marginBottom: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

function SubscribeSection({ handleLogin }) {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const handleCheck = (arg) => {
    if (arg === "enterprise") {
      toggle();
    } else if (arg === "standard") {
      handleLogin();
    } else if (arg === "pro") {
      handleLogin();
    }
  };

  return (
    <RootStyle>
      <Title variant="h1">Pricing</Title>
      <Stack
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        mb={5}
        spacing={1}
      >
        <Text
          sx={{
            fontSize: "16px !important",
          }}
        >
          Need a custom plan?
        </Text>
        <BtnSub
          variant="contained"
          onClick={() => handleCheck("enterprise")}
          sx={{ marginBottom: "10px", height: "30px" }}
        >
          <Text sx={{ fontSize: "18px" }}>Contact Us</Text>
        </BtnSub>
      </Stack>
      <Grid container spacing={2}>
        <CardGrid item md={4} xs={12}>
          <FreeCard />
        </CardGrid>
        {/* <CardGrid item md={6} xs={12}>
          <BeginnerCard />
        </CardGrid> */}
        <CardGrid item md={4} xs={12}>
          <StandardCard onSelect={handleCheck} />
        </CardGrid>
        <CardGrid item md={4} xs={12}>
          <ProCard onSelect={handleCheck} />
        </CardGrid>
        {/* <CardGrid item md={4} xs={12}>
          <EnterpriseCard onSelect={handleCheck} />
        </CardGrid> */}
      </Grid>
      {open && <Contact open={open} onClose={toggle} />}
    </RootStyle>
  );
}

export default SubscribeSection;
