import { Box, styled } from "@mui/material";

export const CenteredBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
export const CBBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
