import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import SearchIcon from "../assets/images/search-icon.svg";
import { InfoOutlined } from "@mui/icons-material";

const BtnSearch = styled(Button)({
  background: "linear-gradient(90deg, #5671F1 0%, rgba(23, 206, 173, 1) 100%)",
  borderRadius: "0px 4px 4px 0px",
  minWidth: "50px !important",
  height: "32px",
  marginTop: "-2px",
  boxShadow: "none",
  "&:hover": {
    background: "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
    boxShadow: "none",
  },
});

function SearchField({
  place,
  val,
  onChange,
  onClick,
  load,
  tooltip,
  wordCount,
  wordCounter,
}) {
  const remainingChars = 250 - val.length;

  return (
    <TextField
      fullWidth
      type="text"
      size="small"
      placeholder={place}
      value={val}
      onChange={onChange}
      sx={{
        background: "#F6F6F6",
        borderRadius: "6px",
        border: "none",
        height: "32px",
        "& fieldset": {
          padding: "0",
          margin: "0",
          border: "none",
        },
      }}
      onKeyDown={(e) => {
        if (e.key == "Enter" && !load) {
          onClick();
        }
      }}
      InputProps={{
        style: {
          padding: "0",
          fontFamily: "Raleway",
          fontWeight: 400,
          fontSize: "12px",
        },
        endAdornment: (
          <InputAdornment position="end">
            {wordCounter && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mr: tooltip ? 0 : 1,
                }}
              >
                {/* <Typography
                  fontSize={"13px"}
                  fontFamily={"Raleway"}
                  fontWeight={600}
                  color={"#a9a7a7"}
                >
                  <span style={{ color: wordCount >= 60 ? "red" : "#a9a7a7" }}>
                    {wordCount > 60 ? "60" : wordCount}
                  </span>
                  /60 w
                </Typography> */}
                <Typography
                  fontSize={"13px"}
                  fontFamily={"Raleway"}
                  fontWeight={600}
                  color={remainingChars < 0 ? "red" : "#a9a7a7"}
                >
                  {remainingChars < 0 ? "0" : remainingChars} char
                </Typography>

                {tooltip && (
                  <Tooltip
                    sx={{
                      // fontSize: "18px",
                      width: "100%",
                    }}
                    title={
                      <Typography fontSize={"12px"} fontFamily={"Raleway"}>
                        We have optimised our Literature Review for different
                        domains based on their most popular journals! As a lot
                        of research is interdisciplinary, changing the domain of
                        your search may give better results for some queries.
                      </Typography>
                    }
                    placement="top"
                  >
                    <InfoOutlined
                      sx={{
                        color: "#353535",
                        fontSize: "20px",
                        marginRight: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            <Box>
              <BtnSearch
                variant="contained"
                disabled={load ? true : false}
                onClick={onClick}
              >
                <img src={SearchIcon} />
              </BtnSearch>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchField;
