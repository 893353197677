import { Box, Button, Typography, styled } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export const Btn = styled(Button)({
  background: "#F6F6F6 !important",
  fontFamily: "Raleway",
  fontSize: "10px",
  fontWeight: 600,
  height: "24px !important",
  color: "#353535",
  textTransform: "none",
  padding: "6px 12px !important",
});

export const ImgBox = styled(Box)({
  background: "#FFF0F0",
  borderRadius: "8px",
  width: "45px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 400,
  color: "#14224B",
});
export const customStyles = {
  rows: {
    style: {
      background: "white",
      //   padding: "2px 10px",
    },
  },
  header: {
    style: {
      color: "#F9F9F9",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#1E4F6B",
      color: "#fff",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: "12px",
      // position:"sticky",
      // zIndex:5362,
      top:0
    },
  },
  cells: {
    style: {
      color: "#323232",
      fontFamily: "Raleway",
      fontWeight: 500,
      fontSize: "12px",
      padding:"8px 16px"
    },
  },
  table: {
    style: {
      background: "#ffffff",
      border: "1px solid #E0E7ED",
      height:"50vh",
      overflowY:"auto"
    },
  },
};
