import React, { useState } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import CardImg from "../../assets/images/card not found.svg";
import MasterCard from "../../assets/images/mastercard icon.png";
import { useSelector } from "react-redux";
import { Verified } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useEffect } from "react";
import Repo from "../../repo/Repo";

const RootStyle = styled(Box)({
  minHeight: "65vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const DetailsBox = styled(Box)(({ theme }) => ({
  background: "#F3F3F3",
  borderRadius: "8px",
  width: "45%",
  padding: "41px 52px",
  [theme.breakpoints.down("md")]: {
    width: "95%",
    padding: "20px 10px",
  },
}));

const Section = styled(Box)({
  padding: "15px 20px",
  background: "#3535350F",
  borderRadius: "6px",
});

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 600,
  color: "#353535",
});

function CardInfo({}) {
  // const { user } = useSelector((state) => state.user);
  const [user, setUser] = useState("");

  // useEffect(() => {
  //   getUser();
  // }, []);

  // const getUser = async () => {
  //   let { data } = await Repo.user({
  //     request: {
  //       method: "getUser",
  //       data: {
  //         _id: localStorage.getItem("email"),
  //         token: localStorage.getItem("token") || "",
  //       },
  //     },
  //   });
  //   if (data.response.status.statusCode == 200) {
  //     setUser(data.response.data.userObj);
  //   } else {
  //   }
  //   console.log("data:", data);
  // };

  let month = user?.cardDetails?.expMonth;
  if (user?.cardDetails?.expMonth < 10) {
    month = 0 + "" + user?.cardDetails?.expMonth;
  }

  return (
    <RootStyle>
      {user?.cardDetails?.expMonth && (
        <DetailsBox>
          <Stack spacing={4}>
            <Section sx={{ padding: "12px 16px", width: "fit-content" }}>
              <Title>Your card details</Title>
            </Section>

            <Section>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" spacing={1}>
                  <Verified sx={{ color: "#11D6AA" }} />
                  <img width="30px" src={MasterCard} alt="" />
                  <Title>Credit Card</Title>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <LockOutlinedIcon
                      sx={{ fontSize: "18px", color: "#353535" }}
                    />

                    <Title sx={{ fontSize: "14px", fontWeight: 400 }}>
                      Secure Payment
                    </Title>
                  </Stack>
                </Stack>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  minHeight: "38px",
                  background: "#FFFFFF1A",
                  p: "10px 15px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <CreditCardIcon sx={{ color: "#35353580" }} />
                  <Stack direction="row">
                    <Typography> ********</Typography>
                    <Title sx={{ fontSize: "14px" }}>
                      {user?.cardDetails?.last4}
                    </Title>
                  </Stack>
                </Stack>
                <Title sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Expires: {month}/
                  {user.cardDetails?.expYear?.toString().slice(2, 4)}
                </Title>
              </Box>
            </Section>
            {/* <Section sx={{ minHeight: "49px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
              >
                <Verified sx={{ color: "#11D6AA" }} />

                <Title sx={{ fontWeight: 400 }}>
                  ${user?.subscription?.price}
                  /month
                </Title>
              </Stack>
            </Section> */}
          </Stack>
        </DetailsBox>
      )}
      {!user?.cardDetails?.expMonth && (
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <img style={{ width: "365px" }} src={CardImg} alt="" />
          <Title sx={{ fontSize: "20px" }}>
            Your card info will appear once you are subscribed !
          </Title>
        </Stack>
      )}
    </RootStyle>
  );
}

export default CardInfo;
