import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, userAdd, userLogged } from "./userSlice";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { TextField, Stack, Button, styled } from "@mui/material";
import gIcon from "../../assets/images/G.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";

import TailSpinLoader from "../../components/TailSpinLoader";

const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "14px",
  color: "#353535",
});

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
    error: false,
    showPass: false,
  });

  const onSubmit = async () => {
    try {
      if (!state.email) {
        Toast("info", "Enter email");
        return;
      }
      if (!state.password) {
        Toast("info", "Enter password");
        return;
      }
      setState((prev) => ({ ...prev, loading: true }));
      let { data } = await Repo.user({
        request: {
          method: "loginAdmin",
          data: {
            email: state.email,
            password: state.password,
          },
        },
      });
      setState((prev) => ({ ...prev, loading: false }));
      if (data.response?.status?.statusCode == 200) {
        if (data.response.data.userObj) {
          if (data.response.data.userObj?.approved === true) {
            localStorage.setItem("email", data.response.data.userObj._id);
            localStorage.setItem("loggedIn", true);
            dispatch(userAdd(data.response.data.userObj));
            dispatch(userLogged("admin"));
            navigate("/admin/verify-users");
          } else {
            Toast("error", "User not approved");
          }
        }
      } else {
        Toast("error", "Invalid Credientials");
      }
    } catch (err) {
      setState((prev) => ({ ...prev, loading: false }));
      console.log("err:", err);
      Toast("error", "Error");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      // console.log("Enter:");
      // if()
      onSubmit();
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={true} onClose={""} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2.4}>
          <Text sx={{ fontWeight: 600, fontSize: "25px", textAlign: "center" }}>
            Admin Login
          </Text>
          <Stack spacing={0.8}>
            <Text sx={{ fontWeight: 600 }}>Email</Text>
            <TextField
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              name="email"
              placeholder="example@email.com"
              onKeyDown={handleKeyDown}
              value={state.email}
              onChange={handleInput}
            />
          </Stack>
          <Stack spacing={0.8}>
            <Text sx={{ fontWeight: 600 }}>Password</Text>

            <TextField
              fullWidth
              size="small"
              type="password"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              name="password"
              placeholder="********"
              onKeyDown={handleKeyDown}
              value={state.password}
              onChange={handleInput}
            />
          </Stack>

          <Box sx={{ height: "40px" }}>
            {state.loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "none", background: "#353535 !important" }}
                onClick={onSubmit}
              >
                Login
              </Button>
            )}
          </Box>

          {/* <Button
            variant="contained"
            fullWidth
            sx={{
              background: "#F0F0F0 !important",
              p: "1px 10px 1px 5px",
              textTransform: "none",
              fontFamily: "Raleway",
              fontWeight: 600,
            }}
            // onClick={googleLogin}
          >
            <img style={{ marginRight: "10px" }} src={gIcon} alt="" />
            <Text sx={{ fontWeight: 600 }}> Sign in with Google</Text>
          </Button> */}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Login;
