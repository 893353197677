// // class API {
// //   static async uploadImage(e) {
// //     await new Promise(r => setTimeout(r, 500));
// //     return e ? URL.createObjectURL(e) : 'https://via.placeholder.com/150';
// //   }
// // }

import { baseDomain } from "../../../repo/Repository";

// // export { API };
// // export default API;
const AWS = require("aws-sdk");

// Configure AWS SDK with your credentials and the region of your S3 bucket
AWS.config.update({
  // accessKeyId: "AKIAWC2A6LYAWKHRIGXW",
  // secretAccessKey: "kNKew1crEOdHOBoLIOI3kOj8TROCEuaa5TxSI+de",
  // accessKeyId: "AKIAWC2A6LYAVDRGNS6U",
  // secretAccessKey: "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",
  accessKeyId: "AKIAWC2A6LYA52SVE27C",
  secretAccessKey: "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",
  region: "us-west-2",
});

class API {
  static async uploadImage(e) {
    // Simulate image upload delay
    await new Promise((r) => setTimeout(r, 500));

    // Create an instance of the S3 service
    const s3 = new AWS.S3();

    // Define parameters for uploading the image to S3
    const params = {
      Bucket: "veracious-documents",
      Key: e.name, // The file name to upload
      Body: e,
    };

    try {
      // Upload the image to S3
      const data = await s3.upload(params).promise();

      // Return the URL of the uploaded image
      return data.Location;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      // Handle error
      return null; // Return null in case of error
    }
  }
}

export { API };
export default API;



// class API {
//   static async uploadImage(file) {
//     const email = localStorage.getItem("email");
//     const projectId = localStorage.getItem("projectId");

//     // Prepare form data with email, project_id, and the file
//     const formData = new FormData();
//     formData.append("email", email);
//     formData.append("project_id", projectId);
//     formData.append("file", file);

//     try {
//       const response = await fetch(`${baseDomain}/upload`, {
//         method: "POST",
//         body: formData,
//       });

//       const result = await response.json();

//       // Return the file URL or paths if the upload was successful
//       return result.fileUploaded || [];
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       return [];
//     }
//   }
// }

// export { API };
// export default API;
