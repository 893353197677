import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Typography, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Setting from "./Setting";
// import ResearchPal from "../../assets/images/NewImages/ResearchPal.svg";
import ResearchPal from "../../assets/images/NewImages/landing-RP.svg";
import { BtnSetting, MenuBox } from "../styles";
import home from "../../assets/svg/home.svg";
import home_hover from "../../assets/svg/home_hover.svg";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import card_membership from "../../assets/svg/card_membership.svg";
import card_membership_hover from "../../assets/svg/card_membership_hover.svg";
import redo from "../../assets/svg/redo.svg";
import redo_hover from "../../assets/svg/redo_hover.svg";

import BetaLogoMini from "../../assets/svg/BetaLogoMini.svg";
import { useDispatch } from "react-redux";
import { clearUser } from "../../pages/Admin/userSlice";
import { AssignmentInd, MeetingRoom } from "@mui/icons-material";
import { clearAllLitReview } from "../../pages/literature review/reviewSlice";
import { clearAllInsights } from "../../pages/paper insights/insightsSlice";
import { clearProject } from "../../pages/projects/projectSlice";
import ProductFeatures from "../../pages/sidebar/ProductFeatures";
import VideocamIcon from "@mui/icons-material/Videocam";
export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "13px",
  color: "#353535",
});
export const RootStyle = styled(Box)({
  background: "#fff",
  borderRadius: "6px",
  height: "50px",
});

function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const dispatch = useDispatch();
  const [cardHovered, setCardHovered] = useState(false);
  const [homeHovered, setHomeHovered] = useState(false);
  const [redoHovered, setRedoHovered] = useState(false);

  const [openDialogue, setOpenDialogue] = useState(false);

  const handleTutorialClick = () => {
    setOpenDialogue(true); // Open the dialog
  };

  const handleDialogueClose = (data) => {
    setOpenDialogue(false); // Close the dialog
    // Handle any additional logic if necessary
  };

  const toggle = () => {
    setNavbar((prev) => !prev);
  };
  const handleBack = () => {
    navigate("/projects");
    clearInterval(localStorage.getItem("abcd"));
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    // dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  return (
    <RootStyle sx={{ zIndex: 1, position: "sticky" }}>
      <Box
        sx={{
          height: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          px: "24px",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          {/* <img src={ResearchPal} alt="" style={{ height: "14px" }} /> */}
          <img src={ResearchPal} alt="" style={{ height: "34px" }} />
          {/* <ResearchPalMini /> */}
        </Stack>

        <Box sx={{ color: "black", display: { xs: "block", md: "none" } }}>
          {navbar ? (
            <CloseIcon
              sx={{ color: "#000", cursor: "pointer" }}
              onClick={toggle}
            />
          ) : (
            <MenuIcon
              sx={{ color: "#000", cursor: "pointer" }}
              onClick={toggle}
            />
          )}
        </Box>

        <Box
          sx={{
            width: "43%",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: "14px",
            zIndex: 1,
          }}
        >
          {location.pathname !== "/subscription" && (
            <Link to="/subscription">
              <BtnSetting
                sx={{
                  p: "6px 20px",
                  color: "#535D7B",
                  "&:hover": { color: "#14224B" },
                }}
                onMouseEnter={() => setCardHovered(true)}
                onMouseLeave={() => setCardHovered(false)}
              >
                {/* <img
                  style={{ height: "16px", width: "16px", marginRight: "5px" }}
                  src={card_membership}
                  alt=""
                /> */}
                <img
                  style={{ height: "16px", width: "16px", marginRight: "5px" }}
                  src={cardHovered ? card_membership_hover : card_membership}
                  alt=""
                />

                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Subscription
                </Typography>
              </BtnSetting>
            </Link>
          )}

          <BtnSetting
            sx={{
              p: "6px 20px",
              color: "#535D7B",
              "&:hover": { color: "#14224B" },
            }}
            onClick={handleTutorialClick} // Add click handler for Tutorial
          >
            <VideocamIcon sx={{ fontSize: "16px", mr: 0.5 }} />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              Tutorial
            </Typography>
          </BtnSetting>

          {location.pathname !== "/projects" && (
            <Link to="/projects">
              <BtnSetting
                sx={{
                  p: "6px 20px",
                  color: "#535D7B",
                  "&:hover": { color: "#14224B" },
                }}
                onMouseEnter={() => setHomeHovered(true)}
                onMouseLeave={() => setHomeHovered(false)}
              >
                {/* <img
                  src={home}
                  alt=""
                  style={{ height: "16px", marginRight: "5px" }}
                /> */}
                <img
                  style={{ height: "16px", width: "16px", marginRight: "5px" }}
                  src={homeHovered ? home_hover : home}
                  alt=""
                />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Home
                </Typography>
              </BtnSetting>
            </Link>
          )}
          {location.pathname !== "/subscription/profile" && (
            <Link to="/subscription/profile">
              <BtnSetting
                sx={{
                  p: "6px 20px",
                  color: "#535D7B",
                  "&:hover": { color: "#14224B" },
                }}
              >
                <AssignmentInd sx={{ fontSize: "16px", mr: 0.5 }} />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Profile
                </Typography>
              </BtnSetting>
            </Link>
          )}

          <BtnSetting
            sx={{
              p: "6px 20px",
              color: "#535D7B",
              "&:hover": { color: "#14224B" },
            }}
            onClick={handleLogout}
          >
            <MeetingRoom sx={{ fontSize: "16px", mr: 0.5 }} />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              Logout
            </Typography>
          </BtnSetting>
          {location.pathname !== "/projects" && (
            <BtnSetting
              sx={{
                p: "6px 20px",
                color: "#535D7B",
                "&:hover": { color: "#14224B" },
              }}
              onClick={handleBack}
              onMouseEnter={() => setRedoHovered(true)}
              onMouseLeave={() => setRedoHovered(false)}
            >
              <img
                // src={redo}
                src={redoHovered ? redo_hover : redo}
                alt=""
                style={{ height: "14px", marginRight: "5px" }}
              />
            </BtnSetting>
          )}
        </Box>
      </Box>

      {navbar && (
        <MenuBox>
          <Link to="/subscription">
            <BtnSetting
              sx={{
                width: navbar ? "100%" : "auto",
                color: "#535D7B",
                "&:hover": { color: "#14224B" },
              }}
            >
              <CardMembershipIcon sx={{ fontSize: "16px", mr: 1 }} />{" "}
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                Subscription
              </Typography>
            </BtnSetting>
          </Link>
          <Link to="/projects">
            <BtnSetting
              sx={{
                width: navbar ? "100%" : "auto",
                color: "#535D7B",
                "&:hover": { color: "#14224B" },
              }}
            >
              <img
                src={home}
                alt=""
                style={{ height: "16px", marginRight: "5px" }}
              />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                Home
              </Typography>
            </BtnSetting>
          </Link>

          <BtnSetting
            sx={{
              p: "6px 20px",
              color: "#535D7B",
              "&:hover": { color: "#14224B" },
            }}
            onClick={handleTutorialClick} // Add click handler for Tutorial
          >
            <VideocamIcon sx={{ fontSize: "16px", mr: 0.5 }} />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              Tutorial
            </Typography>
          </BtnSetting>

          {location.pathname !== "/subscription/profile" && (
            <Link to="/subscription/profile">
              <BtnSetting
                sx={{
                  p: "6px 20px",
                  width: navbar ? "100%" : "auto",
                  color: "#535D7B",
                  "&:hover": { color: "#14224B" },
                }}
              >
                <AssignmentInd sx={{ fontSize: "16px", mr: 0.5 }} />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Profile
                </Typography>
              </BtnSetting>
            </Link>
          )}
          <BtnSetting
            sx={{
              p: "6px 20px",
              color: "#535D7B",
              "&:hover": { color: "#14224B" },
            }}
            onClick={handleLogout}
          >
            <MeetingRoom sx={{ fontSize: "16px", mr: 0.5 }} />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              Logout
            </Typography>
          </BtnSetting>
        </MenuBox>
      )}
      {openDialogue && (
        <ProductFeatures
          openDialogue={openDialogue}
          onClose={handleDialogueClose}
        />
      )}
    </RootStyle>
  );
}

export default AdminHeader;
