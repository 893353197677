import {
  Box,
  TableCell,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

export const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 600,
  color: "#353535",
});

export const ItemBox = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  margin: 0,
  padding: "0 10px",
  rowGap: "8px",
});

export const RootStyle = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  minHeight: "100vh",
  padding: "16px 32px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "#E4E4E4",
    height: "56px",
    width: "250px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #F3F3F3",
  },
}));

export const filterList = [
  "TLDR",
  "Conclusions",
  "Summarized Abstract",
  "Results",
  "Summarized Introduction",
  "Methods Used",
  "Literature Survey",
  "Limitations",
  "Contributions",
  "Datasets",
  "Practical Implications",
];
