export const Features = [
  {
    id: 13,
    title: "Access to OpenAI o1 Models (preview & mini) (beta)",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "-",
  },
  {
    id: 0,
    title: "Projects",
    freePlandescription: 1,
    standardPlandescription: "unlimited",
    proPlandescription: "unlimited",
  },
  {
    id: 1,
    title: "Upload Research Papers",
    freePlandescription: 3,
    standardPlandescription: 150,
    proPlandescription: 150,
  },
  {
    id: 2,
    title: "Paper Insights",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 3,
    title: "Literature Reviews",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 4,
    title: "Manual References",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 5,
    title: "Ask ResearchPal",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 6,
    title: "Tones & Translate",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 7,
    title: "Generate Citation",
    freePlandescription: 2,
    standardPlandescription: 50,
    proPlandescription: 50,
  },
  {
    id: 8,
    title: "Text Tuner",
    freePlandescription: 2,
    standardPlandescription: 100,
    proPlandescription: 100,
  },
  {
    id: 9,
    title: "Library Chat",
    freePlandescription: 2,
    standardPlandescription: 100,
    proPlandescription: 100,
  },
  // {
  //   id: 10,
  //   title: "Access to OpenAI 4o-mini",
  //   freePlandescription: "Yes",
  //   proPlandescription: "Yes",
  //   standardPlandescription: "Yes",
  // },

  {
    id: 10,
    title: "Access to Gemini 1.5 Pro",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "Yes",
  },

  {
    id: 11,
    title: "Access to OpenAI 4-Turbo",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "-",
  },
  {
    id: 12,
    title: "Access to OpenAI 4o",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "-",
  },

  // {
  //   id: 14,
  //   title: "Access to OpenAI o1-mini (beta)",
  //   freePlandescription: "-",
  //   proPlandescription: "Yes",
  //   standardPlandescription: "-",
  // },


  

  {
    id: 14,
    title: "Access to Claude 3.5 Sonnet",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "-",
  },

  {
    id: 15,
    title: "Long literature review",
    freePlandescription: "-",
    proPlandescription: "Yes",
    standardPlandescription: "-",
  },
  // {
  //   id: 14,
  //   title: "Long paper insights (upcoming)",
  //   freePlandescription: "-",
  //   proPlandescription: "Upcoming",
  //   standardPlandescription: "-",
  // },
  // {
  //   id: 15,
  //   title: " Paper recommendations (upcoming)",
  //   freePlandescription: "-",
  //   proPlandescription: "Upcoming",  
  //   standardPlandescription: "-",
  // },
  {
    id: 16,
    title: "Video explaining a paper with slides (upcoming)",
    freePlandescription: "-",
    proPlandescription: "Upcoming",
    standardPlandescription: "-",
  },
];
