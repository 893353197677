import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";

const initialState = {
  loading: false,
  error: "",
  user: "",
  selectedText: "",
  isLoggedIn: "",
  // zoteroModal: false,
};

export const getUser = createAsyncThunk("user/fetchUser", (arg) => {
  let data = {
    _id: localStorage.getItem("email"),
  };
  if (arg) {
    data.googleLogin = true;
    localStorage.removeItem("token");
  } else {
    data.token = localStorage.getItem("token") || "";
  }
  return Repo.user({
    request: {
      method: "getUser",
      data: data,
    },
  })
    .then(({ data }) => data.response.data.userObj)
    .catch((err) => console.log(err));
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userAdd: (state, action) => {
      state.user = action.payload;
    },
    // handleZoteroModal: (state, action) => {
    //   state.zoteroModal = action.payload;
    // },
    addZoteroKey: (state, action) => {
      if (action.payload) {
        state.user.zoteroKey = action.payload;
      }
    },
    userLogged: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    clearUser: (state, action) => {
      state.isLoggedIn = "";
      state.user = "";
    },
    reducePapersInsightsLimit: (state, action) => {
      state.user.subscription["paper-insights"] -= action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      localStorage.setItem("userId", action.payload?.zoteroUserId);
      localStorage.setItem("zoteroKey", action.payload?.zoteroKey);
      if (action.payload?.token) {
        localStorage.setItem("token", action.payload?.zoteroKey);
      }
      if (action?.payload?.email) {
        state.user = action.payload;
        if (action?.payload?.admin) {
          state.isLoggedIn = "admin";
        } else {
          state.isLoggedIn = "user";
        }
      } else if (
        action.payload?.error &&
        action.payload?.error == "Token is empty!"
      ) {
        Toast("info", "Session has expired login again");
        state.isSessionExpired = true;
      } else if (
        action?.payload?.data?.error == "Invalid Token" ||
        action?.payload?.data?.error == "Invalid token"
      ) {
        Toast("info", "Session has expired. Please login again");
        state.isSessionExpired = true;
      } else {
        state.user = "";
        state.isLoggedIn = "";
        Toast("info", "Login Failed");
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("email");
      }

      state.loading = false;
      state.error = "";
      state.error = "";
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.user = "";
      state.error = action.error.message;
    });
  },
});

export const {
  userAdd,
  userLogged,
  clearUser,
  reducePapersInsightsLimit,
  addZoteroKey,
  // handleZoteroModal,
} = userSlice.actions;

export default userSlice.reducer;
