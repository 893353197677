import { useSelector } from "react-redux";
import Toast from "../../../../../components/Toast";
export const handleDownloadDoc = (arg = "", refFormats, user) => {
  const format = localStorage.getItem("selectedFormat") || "apa";
  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }
    let filtered = arr.filter((i) => i.toString().toLowerCase());

    return filtered;
  };
  //code for filtered refs to download the Doc and PDF
  // const filteredFormat = formats()?.map((item, i) => item);
  const filteredFormats = () => {
    const allFormats = formats();
    return allFormats.map((item) => item);
  };

  const references = filteredFormats() ? filteredFormats() : [];
  if (arg) {
    const referencesSection = references
      ?.map((ref, index) => {
        const matchDOI = ref.match(/(http:\/\/doi\.org\/[^\s]+)/);
        if (matchDOI) {
          const doi = matchDOI[0]; // Extracting DOI from the reference
          const refWithLink = ref.replace(
            doi,
            `<a href="${doi}" target="_blank">${doi}</a>`
          ); // Replacing DOI with clickable link
          return `${index + 1}- ${refWithLink}`;
        } else {
          return `${index + 1}- ${ref}`;
        }
      })
      .join("\n<br/>");

    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";

    // Include references on a separate page if there are references
    const referencesHtml =
      references.length > 0
        ? `<p>References:</p><p>${referencesSection}</p>`
        : "";

    const postHtml = "</body></html>";
    const combinedContent = preHtml + arg + referencesHtml + postHtml;

    const blob = new Blob(["\ufeff", combinedContent], {
      type: "application/doc",
    });

    const url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(combinedContent);
    const filename = `${localStorage.getItem("projectTitle")}.doc`;

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);

      // Click download link on button click
      // Note: Ensure you are calling this function in response to a user action, like a button click
      // Otherwise, browsers may block automatic downloads
      downloadLink.click();

      document.body.removeChild(downloadLink);
    }
  } else {
    Toast("info", "No data to download");
  }
};
