import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  askRearchPal,
  citation,
  sendErrorMail,
  tonesApi,
  translateApi,
} from "../../../../../../apiservice";
import Toast from "../../../../../../components/Toast";
import { languages, textTunner, tones } from "../../../../lib/constants";
import { DropdownButton } from "../../../ui/Dropdown/Dropdown";
import Icon from "../../../ui/Icon";
import { Surface } from "../../../ui/Surface";
import { Toolbar } from "../../../ui/Toolbar";
import AskResearchPalDialogue from "./Dialogues/AskResearchPalDialogue";
import CitationDialogue from "./Dialogues/CitationDialogue";
import TextTunnerDialogue from "./Dialogues/TextTunnerDialogue";
import TonesDialogue from "./Dialogues/TonesDialogue";
import TranslateDialogue from "./Dialogues/TranslateDialogue";

export const AIDropdown = ({ editor }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showCitationDialog, setShowCitationDialog] = useState(false);
  const [showToneDialog, setShowToneDialog] = useState(false);
  const [showTranslateDialog, setShowTranslateDialog] = useState(false);
  const [showTunnerDialog, setShowTunnerDialog] = useState(false);
  const [heading, setHeading] = useState("");
  const [askPalData, setAskPalData] = useState("");
  const [citationData, setCitationData] = useState([]);
  const [toneData, setToneData] = useState("");
  const [translateData, setTranslateData] = useState("");
  const [tunnerData, setTunnerData] = useState("");
  const [askErrorMessage, setAskErrorMessage] = useState("");
  const [citeErrorMessage, setCiteErrorMessage] = useState("");
  const [tonesErrorMessage, setTonesErrorMessage] = useState("");
  const [translateErrorMessage, setTranslateErrorMessage] = useState("");
  const [tunnerErrorMessage, setTunnerErrorMessage] = useState("");
  const [buttonStyle, setButtonStyle] = useState({
    background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
  });
  const { user } = useSelector((state) => state.user);
  const askPalDialogRef = useRef(null);
  const citationDialogueRef = useRef(null);
  const toneRef = useRef(null);
  const translateRef = useRef(null);
  const tunnerRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const getRateData = async (text) => {
    setAskPalData("");
    if (text.length > 280) {
      Toast("error", "Query must be 280 characters or fewer.");
      setAskErrorMessage("Query must be 280 characters or fewer.");
      return;
    }
    setAskErrorMessage("");

    setLoading(true);

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Ask ResearchPal Editor, ${user.model}`,
        error: "20 seconds",
        query: text,
        delayInResponse: true,
      });
    }, 20000);

    try {
      const info = {
        query: text,
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token"),
        model: user.model ? user.model : "OpenAI 4o-mini",
      };
      const response = await askRearchPal(info);
      clearTimeout(timeoutId);
      if (response.data.data.error) {
        Toast("error", response.data.data.error);
        setAskErrorMessage(response.data.data.error);
        if (
          response.data.data.error ===
          "Ask ResearchPal limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Ask ResearchPal Editor, ${user.model}`,
            error: response.data.data.error,
            query: text,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Ask ResearchPal Editor, ${user.model}`,
            error: response.data.data.error,
            query: text,
          });
        }

        return;
      }

      if (response.data.data.response === "Something went wrong!") {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Ask ResearchPal Editor, ${user.model}`,
          error: response.data.data.response,
          query: text,
        });

        return;
      }

      if (response.data.data.response) {
        setAskPalData(response.data.data.response);
      }
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Ask ResearchPal Editor, ${user.model}`,
        error: error,
        query: text,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = () => {
    setButtonStyle({
      background: "#ffff",
    });
  };

  const handleMouseLeave = () => {
    setButtonStyle({
      background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
    });
  };

  const handleAskResearchPal = useCallback(async () => {
    setShowDialog(true);
    const {
      state: {
        doc,
        selection,
        selection: { empty, from, to },
      },
    } = editor;
    const text = doc.textBetween(from, to).trim();
    await getRateData(text);
  }, [editor]);

  const handleAccept = () => {
    if (askPalData) {
      editor?.commands?.insertContent(askPalData);
      setShowDialog(false);
    }
  };

  const handleReject = () => {
    setShowDialog(false);
  };

  const getCitationData = async (text) => {
    setCiteErrorMessage("");
    if (text.length > 280) {
      Toast("error", "Query must be 280 characters or fewer.");
      setCitationData([]);
      return; // Exit the function if the query is too long
    }

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Cite Editor`,
        error: "20 seconds",
        query: text,
        delayInResponse: true,
      });
    }, 20000);

    setLoading(true);
    const info = {
      getPapers: "true",
      query: text,
      emailAccess: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      // model: user.model ? user.model : "OpenAI 4o-mini",
    };
    try {
      const response = await citation(info);
      clearTimeout(timeoutId);
      if (response.data.data.error) {
        Toast("error", response.data.data.error);
        setCitationData([]);
        setCiteErrorMessage(response.data.data.error);
        if (
          response.data.data.error ===
          "Cite limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Cite Editor`,
            error: response.data.data.error,
            query: text,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Cite Editor`,
            error: response.data.data.error,
            query: text,
          });
        }

        return;
      }
      if (response.data.data.response === "Something went wrong!") {
        Toast("error", response.data.data.response);
        setCiteErrorMessage(response.data.data.response);
        setCitationData([]);
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Cite Editor`,
          error: response.data.data.response,
          query: text,
        });
        return;
      }
      if (response.data.data.responseArr) {
        setCitationData(response.data.data.responseArr);
      }
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Cite Editor`,
        error: error,
        query: text,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCitation = async () => {
    setShowCitationDialog(true);
    const {
      state: {
        doc,
        selection,
        selection: { empty, from, to },
      },
    } = editor;
    const text = doc.textBetween(from, to).trim();
    //calculate the word count
    // const words = text.split(" ").length;
    // console.log("words:", words);
    // const characters = text.length;
    // console.log("characters:", characters);

    await getCitationData(text);
  };
  const handleCitationAccept = (citation) => {
    if (citationData) {
      editor?.commands?.insertContent(citationData);
      setShowCitationDialog(false);
    }
  };
  const handleCitationReject = () => {
    setShowCitationDialog(false);
  };
  const getToneData = async (selectedText, tone) => {
    setToneData("");
    if (selectedText.length > 3860) {
      Toast("error", "Query must be 3860 characters or fewer.");
      setTonesErrorMessage("Query must be 3860 characters or fewer.");
      return;
    }
    setTonesErrorMessage("");

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Tone Editor(${tone}), ${user.model}`,
        error: "20 seconds",
        query: selectedText,
        delayInResponse: true,
      });
    }, 20000);

    setLoading(true);
    const obj = {
      tones: true,
      text: selectedText,
      toneName: tone,
      emailAccess: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      model: user.model ? user.model : "OpenAI 4o-mini",
    };
    try {
      const response = await tonesApi(obj);
      clearTimeout(timeoutId);
      if (response.data.data.error) {
        Toast("error", response.data.data.error);
        setTonesErrorMessage(response.data.data.error);

        if (
          response.data.data.error ===
          "Tones limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Tone Editor(${tone}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Tone Editor(${tone}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
          });
        }
        return;
      }

      if (response.data.data.response === "Something went wrong!") {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Tone Editor(${tone}), ${user.model}`,
          error: response.data.data.response,
          query: selectedText,
        });

        return;
      }

      if (response.data.data.response) {
        //remove " " from the response
        let responseText = response.data.data.response.replace(/"/g, "");

        setToneData(responseText);
      }
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Tone Editor(${tone}), ${user.model}`,
        error: error,
        query: selectedText,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTone = (tone) => {
    return async () => {
      setShowToneDialog(true);
      const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor;
      const selectedText = doc.textBetween(from, to).trim();

      await getToneData(selectedText, tone);
    };
  };

  const handleToneAccept = () => {
    if (toneData) {
      editor?.commands?.insertContent(toneData);
      setShowToneDialog(false);
    }
  };
  const handleToneReject = () => {
    setShowToneDialog(false);
  };
  const getTranslateData = async (selectedText, translate) => {
    setTranslateData("");
    if (selectedText.length > 3860) {
      Toast("error", "Query must be 3860 characters or fewer.");
      setTranslateErrorMessage("Query must be 3860 characters or fewer.");
      return;
    }
    setTranslateErrorMessage("");

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Translation Editor(${translate}), ${user.model}`,
        error: "20 seconds",
        query: selectedText,
        delayInResponse: true,
      });
    }, 20000);

    setLoading(true);
    const obj = {
      translate: true,
      text: selectedText,
      language: translate,
      emailAccess: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      model: user.model ? user.model : "OpenAI 4o-mini",
    };
    try {
      const response = await translateApi(obj);
      clearTimeout(timeoutId);
      if (response.data.data.error) {
        Toast("error", response.data.data.error);
        setTranslateErrorMessage(response.data.data.error);

        if (
          response.data.data.error ===
          "Translate limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Translation Editor(${translate}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Translation Editor(${translate}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
          });
        }

        return;
      }

      if (response.data.data.response === "Something went wrong!") {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Translation Editor(${translate}), ${user.model}`,
          error: response.data.data.response,
          query: selectedText,
        });

        return;
      }

      if (response.data.data.response) {
        setTranslateData(response.data.data.response);
      }
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Translation Editor(${translate}), ${user.model}`,
        error: error,
        query: selectedText,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTranslate = (translate) => {
    return async () => {
      setShowTranslateDialog(true);
      const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor;
      const selectedText = doc.textBetween(from, to).trim();

      await getTranslateData(selectedText, translate);
    };
  };
  const handleTranslateAccept = () => {
    if (translateData) {
      editor?.commands?.insertContent(translateData);
      setShowTranslateDialog(false);
    }
  };
  const handleTranslateReject = () => {
    setShowTranslateDialog(false);
  };
  const getTunnerData = async (selectedText, tunner) => {
    setTunnerData("");
    if (selectedText.length > 3860) {
      Toast("error", "Query must be 3860 characters or fewer.");
      setTunnerErrorMessage("Query must be 3860 characters or fewer.");
      return;
    }
    setTunnerErrorMessage("");

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Text Tuner Editor(${tunner}), ${user.model}`,
        error: "20 seconds",
        query: selectedText,
        delayInResponse: true,
      });
    }, 20000);
    setLoading(true);
    const obj = {
      text: selectedText,
      tuner: tunner,
      executeTextTuner: true,
      emailAccess: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      model: user.model ? user.model : "OpenAI 4o-mini",
    };
    try {
      const response = await tonesApi(obj);
      clearTimeout(timeoutId);
      if (response.data.data.error) {
        Toast("error", response.data.data.error);
        setTunnerErrorMessage(response.data.data.error);

        if (
          response.data.data.error ===
          "Text tuner limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Text Tuner Editor(${tunner}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Text Tuner Editor(${tunner}), ${user.model}`,
            error: response.data.data.error,
            query: selectedText,
          });
        }
        return;
      }

      if (response.data.data.response === "Something went wrong!") {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Text Tuner Editor(${tunner}), ${user.model}`,
          error: response.data.data.response,
          query: selectedText,
        });

        return;
      }

      if (response.data.data.response) {
        // opposing_argument
        // continue_writing
        if (tunner === "headings") {
          const responseText = response.data.data.response.replace(/"/g, "");
          const headings = responseText
            .split(/\d+\./)
            .filter((item) => item.trim() !== "");

          const formattedData = headings
            .map((heading) => {
              return `${heading.trim()}`;
            })
            .join("\n");

          setTunnerData(formattedData);

          setTunnerData(`• ${formattedData}`);

          setTunnerData(formattedData);
        } else {
          let responseText = response.data.data.response.replace(/"/g, "");
          setTunnerData(responseText);
        }
      }
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Text Tuner Editor(${tunner}), ${user.model}`,
        error: error,
        query: selectedText,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleTunner = (tunner) => {
    return async () => {
      setHeading(tunner);
      setShowTunnerDialog(true);
      const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor;
      const selectedText = doc.textBetween(from, to).trim();

      await getTunnerData(selectedText, tunner);
    };
  };
  const handleTunnerAccept = () => {
    if (tunnerData && heading === "headings") {
      const numberedItems = tunnerData
        .split("\n")
        .map((item) => `<li>${item}</li>`)
        .join("");
      const htmlContent = `<ol>${numberedItems}</ol>`;
      editor?.chain().focus().insertContent(htmlContent).run();
      setShowTunnerDialog(false);
    } else if (tunnerData && heading === "opposing_argument") {
      const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor;
      editor?.commands.insertContentAt(to, tunnerData);
      setShowTunnerDialog(false);
    } else if (tunnerData && heading === "continue_writing") {
      const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor;
      editor?.commands?.insertContentAt(to, tunnerData);
      setShowTunnerDialog(false);
    } else {
      if (tunnerData) {
        editor?.commands?.insertContent(tunnerData);
        setShowTunnerDialog(false);
      }
    }
  };
  const handleTunnerReject = () => {
    setShowTunnerDialog(false);
  };
  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          {/* <Toolbar.Button
            className="text-white hover:text-gray-600 bg-gray-800 active:text-gray-950 dark:text-white dark:hover:text-gray-600 dark:active:text-gray-950"
            activeClassname="text-gray-600 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-600"
          >
            <Icon name="Sparkles" className="mr-1" />
            AI Tools
            <Icon name="ChevronDown" className="w-2 h-2 ml-1" />
          </Toolbar.Button> */}
          <Toolbar.Button
            className="text-white hover:text-gray-600 bg-gray-800 active:text-gray-950 dark:text-white dark:hover:text-gray-600 dark:active:text-gray-950"
            activeClassName="text-gray-600 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-600"
            style={buttonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon name="Sparkles" className="mr-1" />
            AI Tools
            <Icon name="ChevronDown" className="w-2 h-2 ml-1" />
          </Toolbar.Button>
        </Dropdown.Trigger>
        <Dropdown.Content asChild>
          <Surface className="p-2 min-w-[10rem]">
            <Dropdown.Item onClick={handleAskResearchPal}>
              <DropdownButton>
                <Icon name="CircleSlash" />
                Ask ResearchPal
              </DropdownButton>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleCitation}>
              <DropdownButton>
                <Icon name="Eraser" />
                Cite
              </DropdownButton>
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={onMakeLonger}>
            <DropdownButton>
              <Icon name="ArrowRightToLine" />
              Translate
            </DropdownButton>
          </Dropdown.Item> */}
            <Dropdown.Sub>
              <Dropdown.SubTrigger>
                <DropdownButton>
                  <Icon name="Mic" />
                  Tones
                  <Icon name="ChevronRight" className="w-4 h-4 ml-auto" />
                </DropdownButton>
              </Dropdown.SubTrigger>
              <Dropdown.SubContent>
                <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                  {tones.map((tone) => (
                    <Dropdown.Item
                      onClick={handleTone(tone.value)}
                      key={tone.value}
                    >
                      <DropdownButton>{tone.label}</DropdownButton>
                    </Dropdown.Item>
                  ))}
                </Surface>
              </Dropdown.SubContent>
            </Dropdown.Sub>

            <Dropdown.Sub>
              <Dropdown.SubTrigger>
                <DropdownButton>
                  <Icon name="Languages" />
                  Translate
                  <Icon name="ChevronRight" className="w-4 h-4 ml-auto" />
                </DropdownButton>
              </Dropdown.SubTrigger>
              <Dropdown.SubContent>
                <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                  {languages.map((lang) => (
                    <Dropdown.Item
                      onClick={handleTranslate(lang.value)}
                      key={lang.value}
                    >
                      <DropdownButton>{lang.label}</DropdownButton>
                    </Dropdown.Item>
                  ))}
                </Surface>
              </Dropdown.SubContent>
            </Dropdown.Sub>

            <Dropdown.Sub>
              <Dropdown.SubTrigger>
                <DropdownButton>
                  <Icon name="PenLine" />
                  Text Tunner
                  <Icon name="ChevronRight" className="w-4 h-4 ml-auto" />
                </DropdownButton>
              </Dropdown.SubTrigger>
              <Dropdown.SubContent>
                <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                  {textTunner.map((tunner) => (
                    <Dropdown.Item
                      onClick={handleTunner(tunner.value)}
                      key={tunner.value}
                    >
                      <DropdownButton>{tunner.label}</DropdownButton>
                    </Dropdown.Item>
                  ))}
                </Surface>
              </Dropdown.SubContent>
            </Dropdown.Sub>
          </Surface>
        </Dropdown.Content>
      </Dropdown.Root>
      {showDialog && (
        <AskResearchPalDialogue
          loading={loading}
          askPalData={askPalData}
          handleAccept={handleAccept}
          handleReject={handleReject}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          dialogRef={askPalDialogRef}
          setAskPalData={setAskPalData}
          errorMessage={askErrorMessage}
        />
      )}
      {showCitationDialog && (
        <CitationDialogue
          loading={loading}
          citationData={citationData}
          handleAccept={handleCitationAccept}
          handleReject={handleCitationReject}
          showCitationDialog={showCitationDialog}
          setShowCitationDialog={setShowCitationDialog}
          citationDialogueRef={citationDialogueRef}
          setCitationData={setCitationData}
          editor={editor}
          citeErrorMessage={citeErrorMessage}
        />
      )}
      {showToneDialog && (
        <TonesDialogue
          loading={loading}
          toneData={toneData}
          handleAccept={handleToneAccept}
          handleReject={handleToneReject}
          showToneDialog={showToneDialog}
          setShowToneDialog={setShowToneDialog}
          toneRef={toneRef}
          setToneData={setToneData}
          tonesErrorMessage={tonesErrorMessage}
        />
      )}
      {showTranslateDialog && (
        <TranslateDialogue
          loading={loading}
          translateData={translateData}
          handleAccept={handleTranslateAccept}
          handleReject={handleTranslateReject}
          showTranslateDialog={showTranslateDialog}
          setShowTranslateDialog={setShowTranslateDialog}
          translateRef={translateRef}
          setTranslateData={setTranslateData}
          translateErrorMessage={translateErrorMessage}
        />
      )}
      {showTunnerDialog && (
        <TextTunnerDialogue
          tunnerRef={tunnerRef}
          handleAccept={handleTunnerAccept}
          handleReject={handleTunnerReject}
          tunnerData={tunnerData}
          loading={loading}
          showTunnerDialog={showTunnerDialog}
          setShowTunnerDialog={setShowTunnerDialog}
          setTunnerData={setTunnerData}
          tunnerErrorMessage={tunnerErrorMessage}
        />
      )}
    </>
  );
};
