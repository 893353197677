import { useEffect, useRef } from "react";
import ForwardRefWrapper from "../../../../../components/ForwardRefWrapper";
import { DropdownButton } from "../../ui/Dropdown/Dropdown";
import Icon from "../../ui/Icon";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { clearRefs, getRefs } from "../../../../references/refsSlice";

const NewPdfPrint = ({ editor, references }) => {
  console.log("editor:", editor);
  const { refFormats } = useSelector((state) => state.refs);
  const dispatch = useDispatch();
  const projectId = localStorage.getItem("projectId");
  // useEffect(() => {
  //   if (!refFormats) {
  //     dispatch(getRefs(projectId));
  //   }
  // }, []);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: localStorage.getItem("projectTitle") || "Project",
    copyStyles: true,
    pageStyle: {
      "@page": {
        size: "A4",
        margin: "1cm",
      },

      ".break-before-page": {
        pageBreakBefore: "always",
      },
      ".anchor-link": {
        color: "blue",
        textDecoration: "underline",
      },
    },
    content: () => componentRef.current,
  });

  const format = localStorage.getItem("selectedFormat") || "apa";
  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }
    let filtered = arr.filter((i) => i.toString().toLowerCase());

    return filtered;
  };

  const filteredFormats = () => {
    const allFormats = formats();

    return allFormats.map((item) => item);
  };

  const refes = filteredFormats() ? filteredFormats() : [];

  const handleCustomPrint = async () => {
    handlePrint();
    dispatch(getRefs(projectId));
    dispatch(clearRefs());
  };
  return (
    <>
      <DropdownButton onClick={handleCustomPrint}>
        <Icon name="FileText" />
        PDF
      </DropdownButton>
      <div style={{ display: "none" }}>
        <ForwardRefWrapper ref={componentRef}>
          {editor && <div dangerouslySetInnerHTML={{ __html: editor }}></div>}
          <div className="break-before-page"></div>
          <h3 className="text-lg font-bold mt-4">References</h3>
          {refes &&
            refes?.map((ref, index) => {
              const matchDOI = ref.match(/(http:\/\/doi\.org\/[^\s]+)/);
              if (matchDOI) {
                const doi = matchDOI[0];
                const refWithLink = ref.replace(
                  doi,
                  `<a href="${doi}" target="_blank"  class="anchor-link" >${doi}</a>`
                );
                return (
                  <p key={index}>
                    <img src={doi} alt="" />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}- ${refWithLink}`,
                      }}
                    />
                  </p>
                );
              } else {
                return (
                  <p key={index}>
                    {index + 1}- {ref}
                  </p>
                );
              }
            })}
        </ForwardRefWrapper>
      </div>
    </>
  );
};
export default NewPdfPrint;
