import axios from "axios";
const liveDomain = true;

export const standardMonthlyLookupKey = liveDomain
  ? "standard_monthly"
  : "t-monthly";
export const standardYearlyLookupKey = liveDomain
  ? "standard-yearly"
  : "t-yearly";
export const proMonthlyLookupKey = liveDomain ? "pro-month" : "t-pro-monthly";
export const proYearlyLookupKey = liveDomain ? "pro-year" : "t-pro-yearly";

export const captchaKey = liveDomain
  ? "6LfLqC4oAAAAALoomkBhQzmDNTZhkRbFjIJUeUAG" //live
  : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; //test

export const baseDomain =
  liveDomain === true ? "https://api.urhaan.com" : "https://api.moweshi.com";
const baseURL = `${baseDomain}//`;
const api = axios.create({
  baseURL,
  headers: { "Cache-Control": "no-cache", "Content-Type": "application/json" },
});

// api.interceptors.response.use(
//   (response) => {
//     // Redirect to maintenance page for successful responses
//     window.location.href = "/maintenance";
//     return response;
//   },
//   (error) => {
//     // Redirect to maintenance page for error responses
//     if (error.response.status === 503) {
//       window.location.href = "/mantainance";
//     } else {
//       window.location.href = "/mantainance";
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
