import ReactStars from "react-rating-stars-component";
import React from "react";

export const Ratings = ({ getRatings }) => {
  const ratingChanged = (newRating) => {
    getRatings(newRating);
  };

  return (
    <ReactStars
      // count={5}
      onChange={ratingChanged}
      size={24}
      activeColor="#ffd700"
      color="#B0B0B0"
    />
  );
};
