import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";

export const ExcelDownload = async (reviewPapers, colsName) => {
  console.log("colsName:", colsName);
  console.log("reviewPapers:", reviewPapers);
  let obj = {};
  try {
    obj["Papers"] = reviewPapers.map((item) => item.title);
    obj["Insights"] = reviewPapers.map((item) => item.insights);
    if (colsName["Conclusions"]) {
      obj["Conclusions"] = reviewPapers.map((item) => item.conclusions);
    }
    if (colsName["TLDR"]) {
      obj["TLDR"] = reviewPapers.map((item) => item.tldr);
    }
    if (colsName["Summarized Abstract"]) {
      obj["Summarized Abstract"] = reviewPapers.map(
        (item) => item.summarized_abstracts
      );
    }
    if (colsName["Results"]) {
      obj["Results"] = reviewPapers.map((item) => item.results);
    }
    if (colsName["Summarized Introduction"]) {
      obj["Summarized Introduction"] = reviewPapers.map(
        (item) => item.summarized_introductions
      );
    }
    if (colsName["Methods Used"]) {
      obj["Methods Used"] = reviewPapers.map((item) => item.methods_used);
    }
    if (colsName["Literature Survey"]) {
      obj["Literature Survey"] = reviewPapers.map(
        (item) => item.literature_survey
      );
    }
    if (colsName["Limitations"]) {
      obj["Limitations"] = reviewPapers.map((item) => item.limitations);
    }
    if (colsName["Contributions"]) {
      obj["Contributions"] = reviewPapers.map((item) => item.contributions);
    }
    if (colsName["Datasets"]) {
      obj["Datasets"] = reviewPapers.map((item) => item.datasets);
    }
    if (colsName["Practical Implications"]) {
      obj["Practical Implications"] = reviewPapers.map(
        (item) => item.practical_implications
      );
    }

    let { data } = await Repo.excel({
      request: {
        method: "createInsightsToExcel",
        data: obj,
      },
    });
    if (data?.response?.status?.statusCode === 200) {
      window.open(data?.response?.data?.success, "_blank");
      Toast("success", "Excel file downloaded");
    } else {
      Toast("error", "Error");
    }
  } catch (err) {
    console.log(err);
  }
};
