import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import searchInsights from "../../../assets/images/NewImages/searchInsights.png";
import chat from "../../../assets/images/NewImages/chat.png";
import sendFile from "../../../assets/images/NewImages/sendFile.png";
import searchFile from "../../../assets/images/NewImages/searchFile.png";
import libraryCheck from "../../../assets/images/NewImages/libraryCheck.png";
import aiBots from "../../../assets/images/NewImages/aiBots.png";
import generates from "../../../assets/images/NewImages/generates.png";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",

  fontWeight: 600,
  fontSize: "32px",
  color: "#14224B",
  textAlign: "center",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const Text = styled(Typography)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 600,
  padding: "15px",
  color: "#14224B",
}));
const data = [
  {
    title:
      "Extract insights (methodology, limitations etc) from research papers within seconds.",
    img: searchInsights,
  },
  {
    title:
      "Accelerate paper reading with a trained chat bot and quick summaries.",
    img: chat,
  },
  {
    title: "Manage references in various formats (APA, MLA, Chicago, Harvard).",
    img: sendFile,
  },
  {
    title:
      "Search for research sources from credible journals and instantly save the relevant ones.",
    img: searchFile,
  },
  {
    title: "Maintain your library of research sources.",
    img: libraryCheck,
  },
  {
    title:
      "Instantly train a bot with your library and get answers to specific questions.",
    img: aiBots,
  },
  {
    title: "Automatically generate references for your uploaded PDFs.",
    img: generates,
  },
];
const AcedmicsSection = () => {
  return (
    <Box
      sx={{
        marginTop: "100px",
        backgroundColor: "#F5F8FF",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Title variant="h1">Built By Academics, For Academics</Title>
      {data.map((item) => (
        <Box>
          <Grid
            container
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={2} md={0.5} m={1}>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              > */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  src={item.img}
                  alt="img"
                  sx={{
                    width: "50px",
                    height: { xs: "62px", sm: "74px" },
                    marginTop: { sm: "-10px" },
                    borderRadius: "10px",
                  }}
                />
              </Box>
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} sm={8} md={10.5} m={1}>
              <Text>{item.title}</Text>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default AcedmicsSection;
