import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { DialogContent, IconButton, InputAdornment, Box } from "@mui/material";
import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import GmailIcon from "../../assets/images/gmail icon.png";
import PasswordIcon from "../../assets/images/password icon.png";
import {
  CheckCircle,
  CheckCircleOutline,
  DoDisturb,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import AwardStar from "../../assets/images/NewImages/AwardStar.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { Link } from "react-router-dom";
import { clearUser, getUser } from "../Admin/userSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import TailSpinLoader from "../../components/TailSpinLoader";
import UsageDialogue from "./UsageDialogue";
import { sendCancelSubscriptionMail, updateUser } from "../../apiservice";
import domain from "../../assets/images/domain.png";
import userType from "../../assets/images/userType.png";
import moment from "moment/moment";

const IconBox = styled(Box)({
  background: "#35353514",
  height: "35px",
  width: "35px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function ProfileSection({ user }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openUsageDialogue, setOpenUsageDialogue] = useState(false);
  const [openFeedbackDialogue, setOpenFeedbackDialogue] = useState(false);
  const [feedbackReason, setFeedbackReason] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const toggle = () => setShowPassword((show) => !show);
  const toggleOpen = () => setOpen((prev) => !prev);
  const toggleCancel = () => setModal((prev) => !prev);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleConfirmInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));

    if (value === state.password) {
      setValidated(!validated);
    } else setValidated(false);
  };
  const handleUsage = () => {
    setOpenUsageDialogue(true);
  };

  const handleCancel = async () => {
    if (!feedbackReason) {
      setError(true);
      Toast("error", "Please select a reason for cancellation!");
      return; // Stop the cancellation process if no reason is selected
    }

    if (
      (feedbackReason === "Found a better alternate" ||
        feedbackReason === "Other") &&
      comment.trim() === ""
    ) {
      setError(true);
      Toast(
        "error",
        "Additional comment is required for the current option selected!"
      );
      return; // Stop the cancellation process if validation fails
    } else {
      setError(false); // Clear the error if validation passes
    }
    try {
      setLoading(true);

      let { data } = await Repo.subscribe({
        request: {
          method: "cancelSubscription",
          data: {
            email: localStorage.getItem("email"),
          },
        },
      });
      if (data.response.status.statusCode == 200) {
        Toast("success", "Subscription Cancelled");
        dispatch(getUser());

        await handleSendCancellationEmail(feedbackReason, comment);

        setLoading(false);
        toggleCancel();
      } else {
        Toast("error", "Error");
        setLoading(false);
        toggleCancel();
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      toggleCancel();
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.user({
        request: {
          method: "deleteUser",
          data: {
            _id: localStorage.getItem("email"),
          },
        },
      });
      if (data.response.status.statusCode == 200) {
        Toast("success", "Accound Deleted");
        dispatch(clearUser());
        setLoading(false);
        toggleOpen();
      } else {
        Toast("error", "Error");
        setLoading(false);
        toggleOpen();
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      toggleOpen();
    }
  };

  const UpdatePassword = async (arg) => {
    if (!arg.password) {
      Toast("error", "Password can't be empty!");
      return;
    }

    if (!arg.confirmPassword) {
      Toast("error", "Please confirm your password!");
      return;
    }

    if (arg.password !== arg.confirmPassword) {
      Toast("error", "Passwords mismatch!");
      return;
    }
    try {
      setLoading(true);
      const data = await updateUser({
        email: localStorage.getItem("email"),
        password: arg.password,
        confirmPassword: arg.confirmPassword,
      });
      setLoading(false);
      if (data.response.status.statusCode === 200) {
        Toast("success", "Password updated successfully");
        setEdit(!edit);
      } else {
        Toast("error", "Password not updated");
      }
    } catch (err) {
      Toast("error", "Error");
    }
  };

  const handleSendFeedback = () => {
    setOpenFeedbackDialogue(true);
  };

  const handleSendCancellationEmail = async (reason, comment) => {
    // Check if additional comment is required for the selected reason
    if (
      (reason === "Found a better alternate" || reason === "Other") &&
      comment.trim() === ""
    ) {
      setError(true);
      Toast(
        "error",
        "Additional comment is required for the current option selected!"
      );

      return; // Stop the function if validation fails
    } else {
      setError(false);
    }

    try {
      // Proceed with sending the cancellation email if validation passes
      let data = await sendCancelSubscriptionMail(
        localStorage.getItem("email"),
        reason,
        comment
      );

      if (data.response.status.statusCode == 200) {
        Toast("success", "Cancellation email sent");
      } else {
        Toast("error", "Failed to send cancellation email");
      }
    } catch (err) {
      console.log("Error sending email:", err);
      Toast("error", "Error sending cancellation email");
    }
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
        width: { xs: "95%", md: "60%", lg: "60%" },
        height: "100vh",
      }}
    >
      <Stack spacing={1.6} px={{ xs: 0, sm: 3, md: 0 }}>
        <Grid container spacing={2} mt={2}>
          <Grid item md={6} sx={{ width: { xs: "100%", sm: "auto" } }}>
            <Stack
              spacing={1}
              sx={{
                display: { xs: "flex", sm: "unset" },
                alignItems: { xs: "center", sm: "unset" },
              }}
            >
              <InputLabel
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#14224B",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                Domain
              </InputLabel>

              <Box
                sx={{
                  display: "flex",
                  background: "#fff",
                  borderRadius: "4px",
                  height: "40px",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    width: "60px",
                    background: "#35353514",
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    <img src={domain} alt="" />
                  </Stack>
                </Box>
                <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>
                  {user.domain}
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ width: { xs: "100%", sm: "auto" } }}>
            {" "}
            <Stack
              spacing={1}
              sx={{
                display: { xs: "flex", sm: "unset" },
                alignItems: { xs: "center", sm: "unset" },
              }}
            >
              <InputLabel
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#14224B",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                User Type
              </InputLabel>

              <Box
                sx={{
                  display: "flex",
                  background: "#fff",
                  borderRadius: "4px",
                  height: "40px",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    width: "60px",
                    background: "#35353514",
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    <img src={userType} alt="" />
                  </Stack>
                </Box>
                <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>
                  {user.userType}
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ width: { xs: "100%", sm: "auto" } }}>
            <Stack
              spacing={1}
              sx={{
                display: { xs: "flex", sm: "unset" },
                alignItems: { xs: "center", sm: "unset" },
              }}
            >
              <InputLabel
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#14224B",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                Email
              </InputLabel>

              <Box
                sx={{
                  display: "flex",
                  background: "#fff",
                  borderRadius: "4px",
                  height: "40px",
                  width: { xs: "80%", sm: "auto" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    width: "60px",
                    background: "#35353514",
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    <img src={GmailIcon} alt="" />
                  </Stack>
                </Box>
                <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>
                  {user.email}
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ width: { xs: "100%", sm: "auto" } }}>
            <Stack
              spacing={0.5}
              sx={{
                display: { xs: "flex", sm: "unset" },
                alignItems: { xs: "center", sm: "unset" },
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                width={{ xs: "80%", sm: "auto" }}
              >
                <InputLabel
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#14224B",
                  }}
                >
                  Password
                </InputLabel>
                <InputLabel
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#14224B",
                  }}
                >
                  (can be updated)
                </InputLabel>
              </Stack>
              <TextField
                variant="outlined"
                size="small"
                placeholder="*********"
                name="password"
                disabled={!edit}
                type={showPassword ? "text" : "password"}
                sx={{
                  marginTop: "8px !important",
                  borderRadius: "4px",
                  background: "#fff !important",
                  border: "none",
                  "& fieldset": { padding: "0px", border: "none" },
                  width: { xs: "80%", sm: "100%" },
                }}
                autoComplete="off"
                InputProps={{
                  style: {
                    padding: "0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          borderRadius: "4px",
                          width: "60px",
                          background: "#35353514",
                        }}
                      >
                        <Stack alignItems="center" justifyContent="center">
                          <img src={PasswordIcon} alt="" />
                        </Stack>
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment sx={{ mr: 2 }} position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggle}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={state.password}
                onChange={handleInput}
              />
              {edit && (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    width={{ xs: "80%", sm: "auto" }}
                  >
                    <InputLabel
                      sx={{
                        fontFamily: "Raleway",
                        fontWeight: 600,
                        fontSize: "16px",
                        // color: validated ? "green" : "#353535",
                        color: "#353535",
                      }}
                    >
                      Confirm Password
                    </InputLabel>
                    {/* {validated && (
                      <InputLabel
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#353535",
                        }}
                      >
                        <CheckCircle sx={{ color: "#62B14E" }} />
                      </InputLabel>
                    )} */}
                  </Stack>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="*********"
                    name="confirmPassword"
                    disabled={!edit}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      marginTop: "0px !important",
                      borderRadius: "4px",
                      background: "#fff !important",
                      border: "none",
                      "& fieldset": { padding: "0px", border: "none" },
                      width: { xs: "80%", sm: "100%" },
                    }}
                    autoComplete="off"
                    InputProps={{
                      style: {
                        padding: "0",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              borderRadius: "4px",
                              width: "60px",
                              background: "#35353514",
                            }}
                          >
                            <Stack alignItems="center" justifyContent="center">
                              <img src={PasswordIcon} alt="" />
                            </Stack>
                          </Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment sx={{ mr: 2 }} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggle}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={state.confirmPassword}
                    onChange={handleConfirmInput}
                  />
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {edit && validated && (
                  <Box
                    sx={{
                      flex: 1,
                      justifyContent: "flex-start",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="3px"
                      marginTop="12px"
                    >
                      <InputLabel
                        sx={{
                          fontWeight: 400,
                          fontSize: "10px",
                          color: "#353535",
                        }}
                      >
                        <CheckCircle
                          sx={{ color: "#62B14E", fontSize: "12px" }}
                        />
                      </InputLabel>
                      <InputLabel
                        sx={{
                          fontFamily: "Raleway",
                          fontWeight: 600,
                          fontSize: "12px",
                          color: "green",
                        }}
                      >
                        Password Confirmed
                      </InputLabel>
                    </Stack>
                  </Box>
                )}

                {!edit && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      color: "#14224B",
                      textTransform: "none",
                      background: "#fff !important",
                      marginTop: "12px",
                    }}
                    onClick={() => setEdit(!edit)}
                  >
                    Edit
                  </Button>
                )}
                {edit && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      color: "#FF0000",
                      textTransform: "none",
                      background: "#fff !important",
                      marginRight: "12px",
                      marginTop: "12px",
                    }}
                    onClick={() => setEdit(!edit)}
                  >
                    Cancel
                  </Button>
                )}
                {edit && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      color: "#14224B",
                      textTransform: "none",
                      background: "#fff !important",
                      marginTop: "12px",
                    }}
                    onClick={() => UpdatePassword(state)}
                  >
                    Save
                  </Button>
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Box
          sx={{
            borderRadius: "8px",
            background: "#fff",
            minHeight: "136px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: "16px",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent={{ xs: "center", sm: "space-evenly" }}
            alignItems="center"
            spacing={2}
            width={{ xs: "100%", sm: "auto" }}
          >
            <IconBox sx={{ width: "53px", height: "53px" }}>
              <img width="32px" height="36px" src={AwardStar} alt="" />
            </IconBox>
            <Stack sx={{ py: { xs: 2, sm: 0 } }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#C8C8C8",
                  textAlign: "center",
                }}
              >
                PLAN TYPE
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  fontSize: { xs: "16px", md: "24px" },
                  color: "#14224B",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                {user?.subscription?.planName || "Free"}
              </Typography>
            </Stack>
            {/* <Stack
              sx={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
              }}
            > */}
            {/* </Stack> */}
          </Stack>
          {user?.subscription &&
            user?.cancelSubscription &&
            moment.unix(user?.subscriptionEndDate).isValid() && (
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  fontSize: "12px",
                  width: { xs: "100%", md: "40%" },
                  color: "#353535",
                  textAlign: "center",
                  // py: { xs: 3, sm: 0 },
                  my: { md: 4, lg: 0 },
                }}
              >
                {/* Your subscription is now cancelled. Enjoy your remaining usage
                capacity until{" "} */}
                Your subscription is scheduled for cancellation on{" "}
                <Typography
                  variant="span"
                  sx={{ fontWeight: 600, color: "#E35050" }}
                >
                  {moment.unix(user?.subscriptionEndDate).format("DD-MM-YYYY")}
                </Typography>
                . After this date, your remaining usage capacity will end. To
                continue using our services, please resubscribe.
              </Typography>
            )}
          <Stack
            alignItems={{ xs: "center", sm: "flex-end" }}
            justifyContent={{ xs: "center", sm: "flex-end" }}
            spacing={2}
            width={{ xs: "100%", sm: "auto" }}
            sx={{ pb: { xs: 2, sm: 0 } }}
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={3}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  color: "#353535",
                  background: "#f6f6f6 !important",
                  textTransform: "none",
                  borderRadius: "4px",
                }}
                onClick={handleUsage}
              >
                Check Usage
              </Button>
              <Link to="/subscription">
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    color: "#353535",
                    background: "#f6f6f6 !important",
                    textTransform: "none",
                    borderRadius: "4px",
                  }}
                >
                  Change Plan
                </Button>
              </Link>
            </Stack>
            <Stack direction="row" spacing={1}>
              <VerifiedIcon sx={{ color: "#17CEAD" }} />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  color: "#17CEAD",
                  fontSize: "18px",
                }}
              >
                {user?.subscription?.planName === "free-plan"
                  ? "Active"
                  : "Subscription Active"}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          flexWrap={"wrap"}
        >
          {user?.subscription?.planName !== "free-plan" &&
            !user?.cancelSubscription && (
              <Box>
                {/* <Typography
                variant="caption"
                sx={{ color: "#353535", mt: "12px" }}
              >
                Please contact us at info@researchpal.co to cancel your
                subscription
              </Typography> */}
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    color: "#14224B",
                    textTransform: "none",
                    background: "#fff !important",
                    my: "12px",
                  }}
                  onClick={toggleCancel}
                >
                  <DoDisturb sx={{ color: "#353535", mr: 1 }} /> Cancel
                  Subscription
                </Button>
              </Box>
            )}
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                color: "#E25050",
                textTransform: "none",
                background: "#fff !important",
                my: "12px",
              }}
              onClick={toggleOpen}
            >
              <Delete sx={{ color: "#E25050", mr: 1 }} /> Delete Account
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={toggleOpen}>
        <DialogContent
          sx={{
            background: "#f6f6f6",
          }}
        >
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <HighlightOffIcon sx={{ color: "#E25050", fontSize: "60px" }} />
            <Typography variant="h6" align="center" fontFamily={"Raleway"}>
              Are you sure you want to delete your account? All your data will
              be permanently removed.
            </Typography>
            {loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: "none", background: "#E25050 !important" }}
                onClick={handleDelete}
              >
                Delete Account
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog open={modal} onClose={toggleCancel}>
        <DialogContent
          sx={{
            background: "#f6f6f6",
          }}
        >
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <HighlightOffIcon sx={{ color: "#E25050", fontSize: "60px" }} />
            <Typography variant="h6" align="center" fontFamily={"Raleway"}>
              Are you sure you want to cancel your subscription? You will have
              access to your subscription till the end of your billing cycle.
            </Typography>

            {loading ? (
              <TailSpinLoader />
            ) : (
              // <Button
              //   variant="contained"
              //   sx={{ textTransform: "none", background: "#E25050 !important" }}
              //   onClick={handleCancel}
              // >
              //   Cancel Subscription
              // </Button>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Button
                  variant="contained"
                  onClick={toggleCancel}
                  sx={{
                    background: "#f6f6f6 !important",
                    // py: "8px",
                    // px: "30px",
                    textTransform: "capitalize",
                    color: "#353535",
                  }}
                >
                  Not Now
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSendFeedback}
                  sx={{
                    background: "#1E4F6B",
                    "&:hover": {
                      background: "#163C54",
                    },
                  }}
                >
                  Proceed
                </Button>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog
        open={openFeedbackDialogue}
        onClose={() => setOpenFeedbackDialogue(false)}
      >
        <DialogContent>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            We would like to hear your feedback
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 3 }}>
            We are sad to see you go! Please tell us the reason for your
            cancellation:
          </Typography>

          <Box sx={{ mt: 1 }}>
            <RadioGroup
              value={feedbackReason}
              onChange={(e) => {
                setFeedbackReason(e.target.value);
                setError(false); // Clear error when a radio button is selected
              }}
            >
              <FormControlLabel
                value="Completed my project, don't need it anymore"
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label="Completed my project, don't need it anymore"
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              />
              <FormControlLabel
                value="Don't find it useful"
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label="Don't find it useful"
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              />
              <FormControlLabel
                value="Too expensive"
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label="Too expensive"
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              />
              <FormControlLabel
                value="Found a better alternate"
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label="Found a better alternate"
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              />
              <FormControlLabel
                value="Other"
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label="Other"
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              />
            </RadioGroup>
            {!feedbackReason && (
              <Typography color="error" variant="caption">
                Please select a reason for cancellation!
              </Typography>
            )}
          </Box>

          <TextField
            label="Add a comment"
            multiline
            fullWidth
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={error}
            helperText={
              error
                ? "Additional comment is required for the current option selected!"
                : ""
            }
            sx={{ marginTop: 2 }}
          />

          <Stack
            direction="row"
            spacing={{ xs: 0, sm: 2 }}
            justifyContent="space-between"
            flexWrap={"wrap"}
            gap={2}
            sx={{ marginTop: 3 }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenFeedbackDialogue(false)}
              sx={{
                background: "#f6f6f6 !important",
                // py: "8px",
                // px: "30px",
                textTransform: "capitalize",
                color: "#353535",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              Not Now
            </Button>
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{
                background: "#1E4F6B",
                "&:hover": {
                  background: "#163C54",
                },
                width: { xs: "100%", sm: "auto" },
              }}
            >
              Confirm Cancellation
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {openUsageDialogue && (
        <UsageDialogue
          open={openUsageDialogue}
          setOpen={setOpenUsageDialogue}
          user={user}
        />
      )}
    </Box>
  );
}

export default ProfileSection;
