import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  // fontSize: "13px",
  color: "#353535",
  padding: "10px",
});

const GuidelineDialog = ({ open, onClose, value }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          //   flexWrap: "wrap-reverse",
        }}
      >
        <Text sx={{ fontWeight: 600 }}>
          Guidelines for Using the "
          {value === "library"
            ? "Library Literature Review"
            : "Literature Review"}
          " Feature
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: "absolute",
            right: 8,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {value === "library" ? (
          <>
            <Text>
              <strong>Must Read:</strong> To ensure optimal results from the
              "Library Literature Review," please submit clear and concise
              queries related to the content of your uploaded files. This
              feature is designed to work with focused academic inquiries drawn
              from your own library of PDFs. Avoid using long-form prompts or
              conversational-style input, as these can result in errors or
              irrelevant responses.
            </Text>
            <Text>
              To get the best results from the "Library Literature Review"
              feature, please follow these guidelines:
            </Text>
            <Text>
              <strong>1. Keep Your Query Precise and Focused:</strong> Enter a
              single, clear research topic or question based on the content of
              your uploaded files. Avoid using multiple topics or overly complex
              prompts to ensure better accuracy.
            </Text>
            <Text>
              <strong>2. Use Specific Keywords:</strong> Include essential
              keywords related to the topic or question you are exploring from
              your library documents. This helps guide the system to relevant
              sections in your files.
            </Text>
            <Text>
              <strong>3. Avoid Long or Conversational Queries:</strong> Queries
              should be academic in nature and not resemble general
              conversational AI prompts. Overly long or complex questions may
              lead to processing errors, reducing the quality of the literature
              review.
            </Text>
            <Text>
              <strong>4. Single Question or Topic per Query:</strong> Address
              one specific research question or topic at a time to get clear and
              focused literature reviews from your uploaded documents. For
              multiple queries, submit them separately for the best results.
            </Text>
            <Text>
              <strong>5. Check for Clarity:</strong> Before submitting, ensure
              your query is well-defined and free from unnecessary details. A
              concise and direct query helps retrieve the most relevant sections
              from your PDFs for a more accurate literature review.
            </Text>
            <Text>
              By following these guidelines, you'll be able to obtain a more
              targeted and useful literature review from your personal library
              of academic materials.
            </Text>
          </>
        ) : (
          <>
            <Text>
              <strong>Must Read:</strong> To ensure optimal results from the
              "Literature Review", please enter clear and concise research
              questions, topics, or paper titles. This tool is not designed for
              conversational AI interactions, so avoid using long-form prompts
              or questions. It is specifically built to generate comprehensive
              literature reviews from focused academic queries, as
              conversational input e.g GPT-like prompts may lead to errors.
            </Text>
            <Text>
              To get the best results from the "Literature Review" feature,
              please follow these guidelines:
            </Text>
            <Text>
              <strong>1. Keep Your Query Precise and Focused:</strong> Enter a
              single, clear research topic or question. Avoid using multiple
              topics or complex, lengthy prompts for better results.
            </Text>
            <Text>
              <strong>2. Use Specific Keywords:</strong> Include essential
              keywords related to your topic or question to guide the search
              effectively.
            </Text>
            <Text>
              <strong>3. Avoid General or Vague Queries:</strong> General
              prompts may lead to less relevant results. Be as specific as
              possible about the aspect of the topic or question you are
              interested in.
            </Text>
            <Text>
              <strong>4. Single Question or Topic per Query:</strong> Each
              prompt should address only one question or topic. If you have
              multiple queries, enter them separately to get clear and focused
              literature reviews for each.
            </Text>
            <Text>
              <strong>5. Check Your Query for Clarity:</strong> Before
              submitting, ensure your topic or question is well-defined and free
              from unnecessary details. Clear and direct questions help the
              ResearchPal retrieve the most relevant academic sources.
            </Text>
            <Text>
              Following these guidelines will help you obtain a more accurate
              and useful literature review.
            </Text>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GuidelineDialog;
