import { Close, Restore } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const HistoryDialogue = ({ open, onClose, restore }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-abstract-title"
      open={open}
      maxWidth="lg"
      sx={{
        zIndex: 999999,
        padding: "0px !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        fontSize={14}
        fontWeight={600}
        fontFamily={"Raleway"}
        sx={{
          fontWeight: 700,
          // color: "#1E4F6B",
          textAlign: "center",
        }}
      >
        Restore Version
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ padding: "5px", width: "fit-content" }}>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Restore
            sx={{
              fontSize: "40px",
              color: "#1E4F6B",
            }}
          />

          <Typography
            sx={{
              padding: "6px",
              fontFamily: "Raleway",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Are you sure you want to restore this version?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={2}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{
              background: "#e4e4e4 !important",
              textTransform: "none",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontWeight: 700,
              color: "#868686",
              boxShadow: "none !important",
            }}
            onClick={onClose}
            startIcon={<Close />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{
              // background: "#353535 !important",
              background: "#1E4F6B",
              textTransform: "none",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontWeight: 700,
              color: "#fff",
              boxShadow: "none !important",
            }}
            onClick={restore}
            startIcon={<Restore />}
          >
            Restore
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryDialogue;
