import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
} from "@mui/material";
import { TableContainer, TableHead, tableCellClasses } from "@mui/material";
import { FormControlLabel, Menu, MenuItem, styled, Box } from "@mui/material";
import { Stack, Typography, TableRow, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Toast from "../../components/Toast";
import TailSpinLoader from "../../components/TailSpinLoader";
import Repo from "../../repo/Repo";
import {
  addForInsights,
  addInsightsProject,
  addReviewPapers,
  addSearchedKeys,
  clearInsights,
  clearSelectedPapers,
  clearSelectedToInsights,
  handleIsInsight,
  handlePaperSelection,
  updateReviewPapers,
  setSelectedFiles,
  setColumnLoadingState,
  clearColumnLoading,
  setInsightLoading,
  clearInsightLoading,
  setInsightFormat,
  clearInsightFormat,
  setFormatLoadingState,
  clearFormatLoading,
  clearAllFormatLoading,
} from "./insightsSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import PaperInsights from "../../assets/images/paper-insights.svg";
import BtnClear from "../../components/BtnClear";
import DeleteDialog from "../../components/DeleteDialog";
import { getUser, reducePapersInsightsLimit } from "../Admin/userSlice";
import ReactGA from "../../Analytics";
import { useLocation, useNavigate } from "react-router-dom";
import { ItemBox, RootStyle, Title, filterList } from "./styles";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import SearchIcon from "../../assets/images/search-icon.svg";
import { Article, PictureAsPdf } from "@mui/icons-material";
import { ExcelDownload } from "./ExcelDownload";
import { CsvDownload } from "./CsvDownload";
import { BibDownload } from "./BibDownload";
import { RisDownload } from "./RisDownload";
import { XmlDownload } from "./XmlDownload";
import { Btn } from "../style";
import FormatSelector from "./FormatSelector";
import { sendErrorMail } from "../../apiservice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "#1E4F6B",
    height: "56px",
    width: "250px",
    // border: "1px solid rgba(224, 224, 224, 1)",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #F3F3F3",
    verticalAlign: "top",
  },
}));
const BtnSearch = styled(Button)({
  background: "linear-gradient(90deg, #5671F1 0%, rgba(23, 206, 173, 1) 100%)",
  "&:hover": {
    background: "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
    boxShadow: "none",
  },
  borderRadius: "0px 4px 4px 0px",
  minWidth: "50px !important",
  height: "40px",
  // marginTop: "-2px",
  boxShadow: "none",
});
function PapersInsights() {
  const title = localStorage.getItem("projectTitle");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  let test = JSON.parse(localStorage.getItem("columns"));
  const { insightsProjects, isInsight, insightLoading } = useSelector(
    (state) => state.insights
  );
  console.log("isInsight:", isInsight);

  const ins = useSelector((state) => state.insights);
  let currentProject = insightsProjects.find((i) => i.title == title);
  console.log("Current Project is", currentProject);
  let projectTitle = currentProject?.title || "";
  let searchedKeys = currentProject?.searchedKeys || [];
  console.log("Searched Keys:", searchedKeys);
  let reviewPapers = currentProject?.reviewPapers || [];
  console.log("Review Paper:", reviewPapers);
  let selectedToInsights = currentProject?.selectedToInsights || [];
  let selectedPapers = currentProject?.selectedPapers || {};

  // const [loading, setLoading] = useState(false);
  const [toastShown, setToastShown] = useState(false);
  const [colLoad, setColLoad] = useState(false);
  // const [columnLoading, setColumnLoading] = useState({});
  const columnLoading = useSelector((state) => state.insights.columnLoading);
  const formatLoading = useSelector((state) => state.insights.columnLoading);
  const [columns, setColumns] = useState(test || []);
  const [colsName, setColsName] = useState({});
  console.log("colsName:", colsName);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [insightFilesData, setInsightFilesData] = useState([]);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const selectedFiles = useSelector((state) => state.insights.selectedFiles);
  console.log("Selected Files", selectedFiles);
  const isReviewPaperSelected = selectedFiles.some((title) =>
    reviewPapers.some((paper) => paper.title === title)
  );
  console.log("Is reviewPaper", isReviewPaperSelected);
  const [exportDoc, setExportDoc] = useState(null);
  const [excelLoading, setExcelLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const insightFormat = useSelector((state) => state.insights.insightFormat);
  const open = Boolean(anchorEl);
  const openExpo = Boolean(exportDoc);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      },
    },
  };

  useEffect(() => {
    if (location.state?.selected) {
      // setSelectedFiles(location.state.selected.map((file) => file.title));
      dispatch(
        setSelectedFiles(location.state.selected.map((file) => file.title))
      );
    }
    // }, [location.state]);
  }, [location.state, dispatch]);

  // useEffect(() => {
  //   const storedSelected = JSON.parse(localStorage.getItem("selectedPapers"));
  //   if (storedSelected) {
  //     dispatch(setSelectedFiles(storedSelected.map((file) => file.title))); // Dispatch action to set selected files
  //   }
  // }, [dispatch]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Paper Insights",
    });
  }, []);

  useEffect(() => {
    if (window.fbq) {
      console.log("Facebook Pixel is loaded and initialized.");
      window.fbq("track", "Paper Insights View", {
        page: location.pathname,
        title: "Paper Insights",
      });
    }
  }, [location]);

  // TikTok Pixel Integration
  useEffect(() => {
    if (window.ttq) {
      console.log("Tiktok Pixel is loaded and initialized.");
      window.ttq.track("ViewContent", {
        page: location.pathname,
        title: "Paper Insights",
      });
    }
  }, [location]);

  useEffect(() => {
    console.log("Inside useEffect - isInsight:", isInsight);
    console.log("Inside useEffect - selectedToInsights:", selectedToInsights);
    if (isInsight) {
      if (selectedToInsights.length > 0) {
        ["Insights", ...columns].forEach((col, i) => {
          fetchPapersData(col, i);
        });
        // After fetching data, reset isInsight to false
        dispatch(handleIsInsight(false));
      }
    }
    // }, [isInsight]);
  }, [isInsight, selectedToInsights, dispatch]);

  useEffect(() => {
    let obj = {};
    columns.length > 0 &&
      columns.forEach((item) => {
        obj[item] = item;
      });
    setColsName(obj);
  }, [columns]);
  useEffect(() => {
    getFiles();
  }, []);
  const handleExportClick = (event) => {
    setExportDoc(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportDoc(null);
  };
  useEffect(() => {
    if (!currentProject) {
      console.log("helllo");
      dispatch(addInsightsProject(localStorage.getItem("projectTitle")));
    }
  }, [insightsProjects]);

  const getName = (arg) => {
    let name = "";
    if (arg == "Summarized Abstract") {
      name = "summarized_abstracts";
    } else if (arg == "Summarized Introduction") {
      name = "summarized_introductions";
    } else if (arg == "Methods Used") {
      name = "methods_used";
    } else if (arg == "Literature Survey") {
      name = "literature_survey";
    } else if (arg == "Practical Implications") {
      name = "practical_implications";
    } else {
      name = arg.toLowerCase();
    }
    return name;
  };

  const toggle = () => {
    setModal((prev) => !prev);
  };

  let errorShown = false;
  const fetchPapersData = async (col) => {
    console.log("colNewData:", col);

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: "Paper Insights",
        error: "35 seconds",
        query: getName(col),
        delayInResponse: true,
      });
    }, 35000);

    try {
      // setLoading(true);
      dispatch(setInsightLoading());
      const arr = selectedToInsights.map((i) => i.pdfFile);
      console.log("selectedToInsights is", arr);
      let str = arr.join("|");
      let { data } = await Repo.script({
        // multipleScriptsOperation: getName(col),
        // s3Keys: str,
        // paperKeys: "",
        // email: localStorage.getItem("email"),
        // title: projectTitle,
        // columns: col == "Insights" ? true : false,
        // token: localStorage.getItem("token") || "",
        paperInsights: true,
        type: getName(col),
        title: projectTitle,
        s3KeysArr: arr,
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token") || "",
        length: insightFormat,
        limit: isReviewPaperSelected ? false : true,
      });
      console.log("API response data:", data);

      clearTimeout(timeoutId);

      if (data.data.responseArr) {
        data.data.responseArr.forEach((item, i) => {
          if (i == 0) {
            dispatch(addSearchedKeys({ selected: arr, title: title }));
            dispatch(reducePapersInsightsLimit(selectedToInsights.length));
          }
          if (Object.keys(item).length !== 0) {
            // dispatch(addReviewPapers({ item, title: title }));
            // Add format to each item
            dispatch(
              addReviewPapers({
                item: { ...item, format: insightFormat },
                title,
              })
            );
            if (i + 1 == data.data.responseArr.length) {
              dispatch(clearSelectedToInsights({ title: title }));
            }
          }
        });
        if (col === "Insights") {
          Toast("success", "Papers added for insights");
        }
      } else if (data.data.error !== "") {
        let message =
          "Paper Insights limit reached. Please upgrade your subscription for more.";
        if (data.data.error == message) {
          dispatch(handleIsInsight(false));
        }
        // Toast("error", data.data.error);
        if (!errorShown) {
          Toast("error", data.data.error);
          // setToastShown(true);
          errorShown = true;
        }
        await sendErrorMail({
          user: localStorage.getItem("email"),
          feature: "Paper Insights",
          error: data.data.error,
          query: getName(col),
          ...(data.data.error === message && { limitReachedError: true }),
        });
      } else {
        // Toast("error", "Error");
        // dispatch(handleIsInsight(false));
        if (!errorShown) {
          Toast("error", "Something went wrong! Please contact support");
          errorShown = true;

          await sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Paper Insights",
            error: data.data.response,
            query: getName(col),
          });
        }
        dispatch(handleIsInsight(false));
      }
      // setLoading(false);
      dispatch(clearInsightLoading());
    } catch (err) {
      clearTimeout(timeoutId);
      if (!errorShown) {
        Toast("error", "Error");
        errorShown = true;
      }
      // setLoading(false);
      dispatch(clearInsightLoading());
    }
  };

  const handleFormatChange = async (fileName, format, col) => {
    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `Paper Insights, ${format}`,
        error: "20 seconds",
        query: getName(col),
        delayInResponse: true,
      });
    }, 20000);

    try {
      // dispatch(setColumnLoadingState({ columnName: col, isLoading: true }));
      dispatch(
        setFormatLoadingState({ fileName, columnName: col, isLoading: true })
      );

      // Construct the payload for the API call
      let { data } = await Repo.script({
        paperInsights: true,
        type: getName(col),
        title: projectTitle,
        s3KeysArr: [fileName],
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token") || "",
        length: format,
        limit: false,
      });
      console.log("Format response data:", data);
      clearTimeout(timeoutId);
      if (data.data.responseArr) {
        // Process the response data and update the state if needed
        dispatch(
          updateReviewPapers({
            idx: 0, // Adjust index if needed
            val: { ...data.data.responseArr[0], format },
            title: projectTitle, // Adjust title if needed
          })
        );
        Toast("success", "Format updated successfully");
      } else if (data.data.error) {
        Toast("error", data.data.error);
        await sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Paper Insights, ${format}`,
          error: data.data.error,
          query: getName(col),
        });
      } else {
        Toast("error", "Something went wrong! Please contact support");

        await sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Paper Insights, ${format}`,
          error: data.data.response,
          query: getName(col),
        });
      }
    } catch (err) {
      Toast("error", "Error");
      console.log(err);
    } finally {
      // dispatch(setColumnLoadingState({ columnName: col, isLoading: false }));
      clearTimeout(timeoutId);
      dispatch(
        setFormatLoadingState({ fileName, columnName: col, isLoading: false })
      );
    }
  };

  // const setColumnLoadingState = (columnName, isLoading) => {
  //   setColumnLoading((prevState) => ({
  //     ...prevState,
  //     [columnName]: isLoading,
  //   }));
  // };

  const fetchColumnData = async (col) => {
    console.log("colData:", col);
    // const timeoutId = setTimeout(() => {
    //   sendErrorMail({
    //     user: localStorage.getItem("email"),
    //     feature: `Paper Insights`,
    //     error: "20 seconds",
    //     query: getName(col),
    //     delayInResponse: true,
    //   });
    // }, 20000);

    try {
      // setColLoad(true);
      // setColumnLoadingState(col, true);
      dispatch(setColumnLoadingState({ columnName: col, isLoading: true }));
      const str = searchedKeys.join("|");
      let { data } = await Repo.script({
        // multipleScriptsOperation: getName(col),
        // s3Keys: str,
        // paperKeys: "",
        // email: localStorage.getItem("email"),
        // title: projectTitle,
        // columns: false,
        // token: localStorage.getItem("token") || "",
        paperInsights: true,
        type: getName(col),
        title: projectTitle,
        s3KeysArr: searchedKeys,
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token") || "",
        length: insightFormat,
        // limit: isReviewPaperSelected ? false : true,
        limit: false,
      });
      // clearTimeout(timeoutId);
      if (data.data.responseArr) {
        data.data.responseArr.forEach((item, i) => {
          // dispatch(updateReviewPapers({ idx: parseInt(i), val: item, title }));
          dispatch(
            updateReviewPapers({
              idx: parseInt(i),
              val: { ...item, format: insightFormat },
              title,
            })
          );
        });
      } else if (data.data.error !== "") {
        let message =
          "Paper Insights limit reached. Please upgrade your subscription for more.";
        if (data.data.error == message) {
          dispatch(handleIsInsight(false));
        }
        Toast("error", data.data.error);

        await sendErrorMail({
          user: localStorage.getItem("email"),
          feature: "Paper Insights",
          error: data.data.error,
          query: getName(col),
        });
      } else {
        Toast("error", "Error");

        await sendErrorMail({
          user: localStorage.getItem("email"),
          feature: "Paper Insights",
          error: data.data.response,
          query: getName(col),
        });
      }
      // setColLoad(false);
      // setColumnLoadingState(col, false);
      dispatch(setColumnLoadingState({ columnName: col, isLoading: false }));
    } catch (err) {
      // clearTimeout(timeoutId);
      // setColLoad(false);
      // setColumnLoadingState(col, false);
      dispatch(setColumnLoadingState({ columnName: col, isLoading: false }));
      console.log(err);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenu = () => {
    setAnchorEl(null);
  };

  // const handleInput = (e) => {
  //   const { name, checked } = e.target;
  //   console.log("name:", name);
  //   if (name == "Insights") {
  //     return;
  //   }
  //   let copy = JSON.parse(JSON.stringify(columns));
  //   if (checked == true) {
  //     if (reviewPapers.length > 0) {
  //       let found = reviewPapers[0].hasOwnProperty(getName(name));
  //       if (!found) {
  //         fetchColumnData(name);
  //       }
  //     }
  //     //q:when i check and uncheck the heading title swaped
  //     //a:

  //     copy.push(name);
  //     setColumns(copy);
  //     localStorage.setItem("columns", JSON.stringify(copy));
  //   } else {
  //     let filtered = copy.filter((item) => item != name);
  //     setColumns(filtered);
  //     localStorage.setItem("columns", JSON.stringify(filtered));
  //   }
  // };

  // const handleFormatChange = async (fileName, newFormat) => {
  //   // Update format in Redux
  //   dispatch(
  //     updateReviewPapers({
  //       idx: reviewPapers.findIndex((paper) => paper.fileName === fileName),
  //       val: {
  //         ...reviewPapers.find((paper) => paper.fileName === fileName),
  //         format: newFormat,
  //       },
  //       title: projectTitle,
  //     })
  //   );

  //   // Fetch data with the new format
  //   // await fetchColumnData("Insights");
  //   ["Insights", ...columns].forEach((col, i) => {
  //     fetchColumnData(col, i);
  //   });
  // };

  const handleInput = (e) => {
    const { name, checked } = e.target;

    if (name == "Insights") {
      return;
    }
    let copy = JSON.parse(JSON.stringify(columns));
    if (checked) {
      if (reviewPapers.length > 0) {
        let found = reviewPapers[0].hasOwnProperty(getName(name));
        if (!found) {
          fetchColumnData(name);
        }
      }

      // Ensure the column is added in a specific order
      if (!copy.includes(name)) {
        copy.push(name);
      }
    } else {
      // Remove the column in a specific order
      copy = copy.filter((item) => item != name);
    }

    // Sort the copy array based on a predefined order of columns
    const columnOrder = [
      // "Length",
      "Insights",
      "Conclusions",
      "TLDR",
      "Summarized Abstract",
      "Results",
      "Summarized Introduction",
      "Methods Used",
      "Literature Survey",
      "Limitations",
      "Contributions",
      "Datasets",
      "Practical Implications",
    ];
    copy.sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));

    setColumns(copy);
    localStorage.setItem("columns", JSON.stringify(copy));
  };
  const handleClear = () => {
    dispatch(clearInsights({ title }));
  };
  const handleRemoveSelected = () => {
    setModal(false);
    dispatch(clearSelectedPapers({ title }));
  };

  const handleSelection = (e, index) => {
    const { checked } = e.target;
    dispatch(handlePaperSelection({ value: checked, index, title }));
  };

  function hasTrueValue() {
    for (const key in selectedPapers) {
      if (selectedPapers[key] == true) {
        return true;
      }
    }
    return false;
  }
  const isPDF = (filename) => {
    return filename.toLowerCase().endsWith(".pdf");
  };
  const handleInsights = async () => {
    try {
      if (!filesData) {
        Toast("info", "Kindly upload papers");
        return;
      }

      if (!insightFormat) {
        dispatch(setInsightFormat("short"));
        return;
      }

      if (selectedFiles.length == 0) {
        Toast("info", "Kindly select at least one paper");
        return;
      }
      let pdfFiles = filesData.filter((item) => isPDF(item.pdfFile));
      let selectedPdfFiles = pdfFiles.filter((item) =>
        selectedFiles.includes(item.title)
      );

      if (selectedPdfFiles.length < selectedFiles.length) {
        Toast("info", "Kindly select only pdf files");
        return;
      }
      let uploadLimit = user.subscription?.[`paper-insights`] || 0;
      if (uploadLimit > 0 && selectedFiles.length > uploadLimit) {
        Toast("info", `Your paper insights limit is ${uploadLimit}`);
        return;
      }
      //  else if (uploadLimit < 1) {
      //   Toast(
      //     "info",
      //     "Paper Insights limit reached. Please upgrade your subscription for more."
      //   );
      //   return;
      // }

      let found = filesData.find((i) => i.pdfFile == selectedFiles[0].pdfFile); //q: what this line will do?
      if (found) {
        Toast("info", "Some papers are already insighted");
        return;
      } else {
        console.log("run:", filesData);
        dispatch(
          addForInsights({
            title: projectTitle,
            selected: filesData.reduce((acc, curr) => {
              if (selectedFiles.includes(curr.title)) {
                acc.push(curr);
              }
              return acc;
            }, []),
          })
        );
        dispatch(handleIsInsight(true));
      }
    } catch (error) {
      console.error("Error in handleInsights:", error);
      Toast(
        "error",
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleFormat = (e) => {
    const { value } = e.target;
    // setState((prev) => ({ ...prev, selectedFormat: value }));
    dispatch(setInsightFormat(value));
    localStorage.setItem("selectedFormat", value);
  };

  const getFiles = async () => {
    try {
      // setLoading(true);
      let { data } = await Repo.research({
        request: {
          method: "getPdfData",
          data: {
            researchProjectId: localStorage.getItem("projectId"),
          },
        },
      });
      // setLoading(false);
      if (data?.response?.status?.statusCode === 200) {
        let res = data?.response?.data?.projObj;
        // setInsightFilesData(filesData);
        // console.log("filesData:", filesData);

        // // convert to array with keys and other data
        let arr = [];
        for (let key in res) {
          arr.push({ key, ...res[key] });
        }

        // console.log(arr);
        setFilesData(arr);
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      // setLoading(false);
      console.log(err);
      Toast("error", err);
    }
  };
  // const handleChange = (event) => {
  //   // save the selected files
  //   const { value } = event.target;
  //   console.log("value:", value);
  //   if (value.length > 5) {
  //     Toast("error", "You can select maximum 5 files");
  //     return;
  //   }
  //   //if selected files is less than 1
  //   // if (value.length < 1) {
  //   //   Toast("error", "Please select atleast 1 file");
  //   //   return;
  //   // }

  //   const filteredValues = value.filter(
  //     (title) => !reviewPapers.some((paper) => paper.title === title)
  //   );
  //   // setSelectedFiles((prev) => value);
  //   // dispatch(setSelectedFiles(value));
  //   dispatch(setSelectedFiles(filteredValues));
  // };
  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   console.log("value:", value);

  //   let planName = user?.subscription?.planName;
  //   let uploadLimit = 0;

  //   if (planName === "free-plan") {
  //     uploadLimit = 2;
  //   } else if (planName === "standard") {
  //     uploadLimit = 3;
  //   } else if (planName === "pro") {
  //     uploadLimit = 3;
  //   }

  //   if (value.length > uploadLimit) {
  //     Toast("error", `You can select a maximum of ${uploadLimit} files.`);
  //     return;
  //   }

  //   const filteredValues = value.filter(
  //     (title) => !reviewPapers.some((paper) => paper.title === title)
  //   );

  //   dispatch(setSelectedFiles(filteredValues));
  // };

  const handleChange = (event) => {
    const { value } = event.target;
    console.log("value:", value);

    let planName = user?.subscription?.planName;
    let uploadLimit = 0;

    if (planName === "free-plan") {
      uploadLimit = 2;
    } else if (planName === "standard") {
      uploadLimit = 3;
    } else if (planName === "pro") {
      uploadLimit = 3;
    }

    // Only consider new selections and ignore existing reviewPapers
    const filteredValues = value.filter(
      (title) => !reviewPapers.some((paper) => paper.title === title)
    );

    // Check the length of the filtered values against the upload limit
    if (filteredValues.length > uploadLimit) {
      Toast("error", `You can select a maximum of ${uploadLimit} new files.`);
      return;
    }

    // Dispatch only the new selections
    dispatch(setSelectedFiles(filteredValues));
  };

  return (
    <RootStyle sx={{ padding: { xs: "16px 8px", md: "16px 32px" } }}>
      <Box>
        <FormControl
          sx={{ width: "100%", height: "40px", marginTop: "2px" }}
          size="small"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <InputLabel
              sx={{ p: "0px 0px 0px 0px !important", fontSize: "14px" }}
            >
              Select files upto{" "}
              {user?.subscription?.planName == "free-plan" ? 2 : 3}
            </InputLabel>

            <Select
              // label="Select files upto 3"
              label={`Select files up to ${
                user?.subscription?.planName === "free-plan" ? 2 : 3
              }`}
              multiple
              fullWidth
              size="small"
              sx={{
                width: "100%",
                p: "0px 0px 0px 0px",
                fontSize: "12px",
                fontFamily: "Raleway",
                overflow: "hidden",
                // height: "40px",
              }}
              value={selectedFiles.length > 0 ? selectedFiles : []}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <Box>
              //       <BtnSearch
              //         variant="contained"
              //         // disabled={load ? true : false}
              //         onClick={handleInsights}
              //         sx={{
              //           height: "34px",
              //           fontSize: "12px",
              //           fontFamily: "Raleway",
              //         }}
              //       >
              //         Proceed
              //       </BtnSearch>
              //     </Box>
              //   </InputAdornment>
              // }
            >
              {filesData.length > 0 ? (
                filesData?.map((name, i) => (
                  <MenuItem
                    key={i}
                    value={name.title}
                    disabled={reviewPapers.some(
                      (paper) => paper.title === name.title
                    )}
                    sx={{
                      borderBottom: "1px solid #F6F6F6",
                      fontFamily: "Raleway",
                      fontSize: "12px",
                    }}
                  >
                    {/* {name.title} */}
                    <Checkbox
                      size="small"
                      sx={{
                        transform: "scale(0.9)",
                        padding: 0,
                        marginRight: 1,
                      }}
                      checked={selectedFiles.indexOf(name.title) > -1}
                    />
                    <ListItemText
                      primary={name.title}
                      primaryTypographyProps={{
                        sx: {
                          borderBottom: "1px solid #F6F6F6",
                          fontFamily: "Raleway",
                          fontSize: "12px",
                          py: "2px",
                        },
                      }}
                    />
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value="No files"
                  sx={{
                    borderBottom: "1px solid #F6F6F6",
                    fontFamily: "Raleway",
                    fontSize: "12px",
                  }}
                  disabled
                >
                  No file(s) uploded in library.
                </MenuItem>
              )}
            </Select>
            {/* <FormControl
              sx={{
                width: { xs: "100%", sm: "60%", md: "40%" },
                marginTop: { xs: 1, sm: 0 },
              }}
            >
              <InputLabel
                sx={{ p: "0px 0px 0px 0px !important", fontSize: "14px" }}
              >
                Format
              </InputLabel>

              <Select
                size="small"
                value={insightFormat}
                label="Format"
                onChange={handleFormat}
                sx={{
                  fontSize: "12px",
                  fontFamily: "Raleway",
                  width: "100%",
                }}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="short"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Raleway",
                  }}
                >
                  Short
                </MenuItem>
                <MenuItem
                  value="long"
                  disabled={user.subscription.planName !== "pro"}
                  disabled={user.subscription.planName !== "pro"}
                  sx={{
                    fontSize: "12px",
                    
                    fontFamily: "Raleway",
                  }}
                >
                  Long
                  {user.subscription.planName !== "pro" && " ( Pro plan )"}
                  {user.subscription.planName !== "pro" && " ( Pro plan )"}
                </MenuItem>
              </Select>
            </FormControl> */}

            <Box>
              <BtnSearch
                variant="contained"
                onClick={handleInsights}
                disabled={insightLoading || isReviewPaperSelected}
                sx={{
                  height: "34px",
                  fontSize: "12px",
                  fontFamily: "Raleway",
                  borderRadius: "3px",
                  marginTop: { xs: 1, sm: 0 },
                  color: "#FFFFFF", // Ensure text color remains #FFFFFF
                  "&.Mui-disabled": {
                    color: "#FFFFFF", // Ensure text color remains #FFFFFF when disabled
                    // background: "#353535 !important", // Optionally maintain background color when disabled
                    background:
                      "linear-gradient(90deg, #5671F1 0%, rgba(23, 206, 173, 1) 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                      boxShadow: "none",
                    },
                  },
                }}
              >
                Proceed
              </BtnSearch>
            </Box>
          </Box>
        </FormControl>
      </Box>
      {reviewPapers.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: { xs: 6, sm: 0 } }}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <Button
              size="small"
              variant="contained"
              sx={{
                background: "#FFFFFF !important",
                textTransform: "none",
                boxShadow: "none !important",
                border: "1px solid #868686",
              }}
              onClick={handleClick}
            >
              <AddCircleOutlineIcon
                sx={{ fontSize: "15px", color: "#353535", mr: 1 }}
              />
              <Title sx={{ fontWeight: 500, fontSize: "10px" }}>
                Add Columns
              </Title>
            </Button>
            {hasTrueValue() && (
              <DeleteIcon
                sx={{
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "#CB4C4C99",
                }}
                onClick={toggle}
              />
            )}
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenu}
              onChange={handleInput}
            >
              <MenuItem sx={{ height: "30px", mb: 0.5 }} disableRipple>
                <FormControlLabel
                  value="Insights"
                  name="Insights"
                  control={
                    <Checkbox
                      size="small"
                      disabled
                      disableRipple
                      checked={true}
                    />
                  }
                  label="Insights"
                  labelPlacement="end"
                />
              </MenuItem>
              {filterList.map((item, i) => {
                return (
                  <MenuItem
                    key={i}
                    sx={{ height: "30px", mb: 0.5 }}
                    disableRipple
                  >
                    <FormControlLabel
                      value={item}
                      name={item}
                      control={
                        <Checkbox
                          size="small"
                          disableRipple
                          checked={colsName[item] ? true : false}
                        />
                      }
                      label={item}
                      labelPlacement="end"
                    />
                  </MenuItem>
                );
              })}
            </Menu>
          </Stack>
          <Stack
            spacing={1}
            direction="row"
            flexWrap={"wrap"}
            // sx={{ marginTop: { xs: 1, sm: 0 } }}
          >
            <div>
              <Btn
                id="fade-button"
                aria-controls={openExpo ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openExpo ? "true" : undefined}
                onClick={handleExportClick}
                sx={{
                  textTransform: "capitalize",
                  fontSize: "12px",
                  color: "#868686",
                  height: "28px",
                  fontFamily: "Raleway",
                  background: "#F3F3F3",
                  "&:hover": {
                    background: "#E4E4E4",
                  },
                }}
              >
                Export{" "}
              </Btn>
              <Menu
                id="fade-menu"
                anchorEl={exportDoc}
                open={openExpo}
                onClose={handleExportClose}
                TransitionComponent={Fade}
                MenuListProps={{}}
                sx={{
                  mt: 1,
                  width: "100%",
                  fontSize: "12px",
                  color: "#868686",
                  fontFamily: "Raleway",
                  "& .MuiPaper-root": {
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    "& .MuiMenu-list": {
                      padding: "4px 0",
                    },
                  },
                }}
              >
                <MenuItem onClick={() => ExcelDownload(reviewPapers, colsName)}>
                  <ListItemIcon>
                    <Article fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Excel</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => CsvDownload(reviewPapers, colsName)}>
                  <ListItemIcon>
                    <Article fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Csv</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => BibDownload(filesData)}>
                  <ListItemIcon>
                    <Article fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Bib</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => RisDownload(filesData)}>
                  <ListItemIcon>
                    <Article fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Ris</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => XmlDownload(filesData)}>
                  <ListItemIcon>
                    <Article fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Xml</ListItemText>
                </MenuItem>
              </Menu>
            </div>
            <BtnClear onClick={handleClear}></BtnClear>
          </Stack>
        </Stack>
      )}

      {reviewPapers.length > 0 && (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          {reviewPapers && (
            <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
              <Table stickyHeader aria-label="sticky table" sx={{ mb: 1 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        minWidth: { sm: "250px" },
                        position: { sm: "sticky" },
                        left: { sm: 0 },
                        zIndex: { sm: 29 },
                        backgroundColor: { sm: "white" },
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Title sx={{ color: "#fff !important" }}>Papers </Title>
                        <Title sx={{ color: "#fff !important" }}> [ </Title>
                        <Title
                          sx={{ fontWeight: 400, color: "#fff !important" }}
                        >
                          {reviewPapers?.length}
                        </Title>
                        <Title sx={{ color: "#fff !important" }}> ]</Title>
                      </Stack>
                    </StyledTableCell>

                    {/* <StyledTableCell sx={{ minWidth: "350px" }}>
                      Length
                    </StyledTableCell> */}

                    <StyledTableCell sx={{ minWidth: "350px" }}>
                      Insights
                    </StyledTableCell>

                    {colsName["Conclusions"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Conclusions
                      </StyledTableCell>
                    )}
                    {colsName["TLDR"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        TLDR
                      </StyledTableCell>
                    )}
                    {colsName["Summarized Abstract"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Summarized Abstract
                      </StyledTableCell>
                    )}
                    {colsName["Results"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Results
                      </StyledTableCell>
                    )}
                    {colsName["Summarized Introduction"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Summarized Introduction
                      </StyledTableCell>
                    )}
                    {colsName["Methods Used"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Methods Used
                      </StyledTableCell>
                    )}
                    {colsName["Literature Survey"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Literature Survey
                      </StyledTableCell>
                    )}
                    {colsName["Limitations"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Limitations
                      </StyledTableCell>
                    )}
                    {colsName["Contributions"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Contributions
                      </StyledTableCell>
                    )}
                    {colsName["Datasets"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Datasets
                      </StyledTableCell>
                    )}
                    {colsName["Practical Implications"] && (
                      <StyledTableCell sx={{ minWidth: "350px" }}>
                        Practical Implications
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewPapers.map((row, i) => (
                    <TableRow key={i}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          position: { sm: "sticky" },
                          left: { sm: 0 },
                          zIndex: { sm: 1 },
                          backgroundColor: { sm: "white" },
                        }}
                      >
                        <ItemBox sx={{ py: 5.2 }}>
                          <Checkbox
                            size="small"
                            disableRipple
                            checked={selectedPapers[i] || false}
                            onChange={(e) => handleSelection(e, i)}
                            sx={{
                              // display: "flex",
                              // justifyContent: "start",
                              // alignItems: "left",
                              marginBottom: "auto",
                              textAlign: "center",
                              padding: 0,
                              paddingTop: "2px",
                              mr: "8px",
                            }}
                          />

                          <Title
                            sx={{
                              fontSize: "14px",
                              // height: "200px",
                              // overflow: "auto",
                            }}
                          >
                            {row?.title || row?.fileName}
                          </Title>
                        </ItemBox>
                      </StyledTableCell>

                      {/* <StyledTableCell component="th" scope="row">
                        <ItemBox>
                          <Title
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            {row?.format || "short"}
                          </Title>
                          <FormControl variant="outlined" size="small">
                            <InputLabel>Format</InputLabel>
                            <Select
                              value={row.format || "short"}
                              onChange={(e) =>
                                handleFormatChange(row.fileName, e.target.value)
                              }
                              label="Format"
                            >
                              <MenuItem value="short">Short</MenuItem>
                              <MenuItem value="long"
                              disabled={user.subscription.planName !== "pro"}>Long
                              {user.subscription.planName !== "pro" && " ( Pro plan )"}</MenuItem>
                            </Select>
                          </FormControl>
                        </ItemBox>
                        
                      </StyledTableCell> */}

                      <StyledTableCell>
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", px: 2, py: 0, mb: 1 }}
                        >
                          {/* <InputLabel>Format</InputLabel> */}
                          <Select
                            value={row?.format?.insights || "short"}
                            disabled={formatLoading[row.fileName]?.["Insights"]}
                            onChange={(e) =>
                              handleFormatChange(
                                row.fileName,
                                e.target.value,
                                "Insights"
                              )
                            }
                            sx={{
                              alignSelf: "end",
                              fontSize: "12px",
                              fontFamily: "Raleway",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#C4C4C4",
                                  borderWidth: "1px",
                                },
                            }}
                          >
                            <MenuItem
                              value="short"
                              sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                            >
                              Short
                            </MenuItem>
                            <MenuItem
                              value="long"
                              disabled={user.subscription.planName !== "pro"}
                              sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                            >
                              Long
                              {user.subscription.planName !== "pro" &&
                                " ( Pro plan )"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {formatLoading[row.fileName]?.["Insights"] ? (
                          <Stack alignItems="center" justifyContent="center">
                            <TailSpinLoader />
                          </Stack>
                        ) : row?.insights ? (
                          <ItemBox>
                            <Title
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                height: "200px",
                                overflow: "auto",
                              }}
                            >
                              {row?.insights.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  <div>{line}</div>
                                  <br />
                                </React.Fragment>
                              ))}
                            </Title>
                          </ItemBox>
                        ) : (
                          <Stack alignItems="center" justifyContent="center">
                            {(colLoad || insightLoading) && <TailSpinLoader />}
                          </Stack>
                        )}
                      </StyledTableCell>

                      {columns.map((col, colIndex) => (
                        <StyledTableCell key={colIndex}>
                          {colsName[col] && (
                            <>
                              {/* Display loader if specific column is loading */}
                              {/* {formatChangeColLoading[col] ? (
                                <Stack
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <TailSpinLoader />
                                </Stack>
                              ) : (
                                <> */}
                              {col === "Conclusions" && (
                                <FormatSelector
                                  value={row?.format?.conclusions || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.conclusions}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}
                              {col === "TLDR" && (
                                <FormatSelector
                                  value={row?.format?.tldr || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.tldr}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}
                              {col === "Summarized Abstract" && (
                                <FormatSelector
                                  value={
                                    row?.format?.summarized_abstracts || "short"
                                  }
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.summarized_abstracts}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Results" && (
                                <FormatSelector
                                  value={row?.format?.results || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.results}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Summarized Introduction" && (
                                <FormatSelector
                                  value={
                                    row?.format?.summarized_introductions ||
                                    "short"
                                  }
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.summarized_introductions}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Methods Used" && (
                                <FormatSelector
                                  value={row?.format?.methods_used || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row.methods_used}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Literature Survey" && (
                                <FormatSelector
                                  value={
                                    row?.format?.literature_survey || "short"
                                  }
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row?.literature_survey}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Limitations" && (
                                <FormatSelector
                                  value={row?.format?.limitations || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row?.limitations}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Contributions" && (
                                <FormatSelector
                                  value={row?.format?.contributions || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row?.contributions}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Datasets" && (
                                <FormatSelector
                                  value={row?.format?.datasets || "short"}
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row?.datasets}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}

                              {col === "Practical Implications" && (
                                <FormatSelector
                                  value={
                                    row?.format?.practical_implications ||
                                    "short"
                                  }
                                  onChange={(e) =>
                                    handleFormatChange(
                                      row.fileName,
                                      e.target.value,
                                      col
                                    )
                                  }
                                  loading={formatLoading[row.fileName]?.[col]}
                                  columnLoading={columnLoading[col]}
                                  rowContent={row?.practical_implications}
                                  disable={formatLoading[row.fileName]?.[col]}
                                />
                              )}
                            </>
                            //   )}
                            // </>
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </TableContainer>
      )}
      {/* {!loading && reviewPapers.length == 0 && ( */}
      {!insightLoading && reviewPapers.length == 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "75vh",
          }}
        >
          <img src={PaperInsights} alt="" />
          <Title sx={{ fontSize: "14px", color: "#4E4E4E", my: 1 }}>
            No papers added yet !
          </Title>
          <Title sx={{ fontSize: "14px", color: "#4E4E4E", fontWeight: 500 }}>
            Kindly add papers from your library to get insights.
          </Title>

          <Box sx={{ mt: "16px" }}>
            <Title sx={{ fontSize: "13px", color: "#515256", fontWeight: 500 }}>
              Step 1: Go to Library section, select files(s) and click "Add to
              Paper Insights" button.
            </Title>
            <Title sx={{ fontSize: "13px", color: "#515256", fontWeight: 500 }}>
              Step 2: Or uploads PDF file(s) in Library & use dropdown menu
              above to access them.
            </Title>
          </Box>
        </Box>
      )}
      {modal && (
        <DeleteDialog onClose={toggle} onSuccess={handleRemoveSelected} />
      )}

      {/* {loading && <TailSpinLoader />} */}
      {insightLoading && <TailSpinLoader />}
    </RootStyle>
  );
}

export default PapersInsights;
