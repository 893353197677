import React, { useEffect, useRef, useState } from "react";
import ModelSelection from "./ModelSelection";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  styled,
  Typography,
  Tooltip,
  Link,
  IconButton,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import SendIcon from "@mui/icons-material/Send";
import {
  sendQuery,
  saveChat,
  updateChat,
  updateChatTitle,
} from "../../../../apiservice";
import { ThreeDots } from "react-loader-spinner";
import Toast from "../../../../components/Toast";
import ResearchPal from "../../../../assets/images/NewImages/landing-RP.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Text = styled(Typography)({
  fontFamily: "Raleway",
});

const ChatAbstract = ({
  user,
  selectedModel,
  setSelectedModel,
  query,
  answer,
  submittedQuery,
  getChatCount,
  incrementChatCount,
  getMessageCount,
  incrementMessageCount,
  handleInput,
  handleSend,
  isCreatingNewChat,
  selectedChat,
  loading,
  chatTitle,
  setChatTitle,
  checkChatTitleExists,
  modelError,
}) => {
  const [newChatTitle, setNewChatTitle] = useState("");
  const chatContainerRef = useRef(null);
  const [isTitleSettingVisible, setIsTitleSettingVisible] = useState(true);
  console.log("isTitleSettingVisible", isTitleSettingVisible);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const handleCopy = (answerText, messageId) => {
    navigator.clipboard.writeText(answerText);
    setCopiedMessageId(messageId);

    // Hide the tooltip after 1.5 seconds
    setTimeout(() => {
      setCopiedMessageId(null);
    }, 1500);
  };

  const handleRenameChat = async () => {
    if (!checkChatTitleExists(newChatTitle)) {
      const chatId = selectedChat.id; // Get the selected chat ID
      const chatTitle = newChatTitle; // The new chat title

      try {
        await updateChatTitle(chatId, chatTitle);

        // Update the chat title in the local state
        setChatTitle(newChatTitle);
        setIsTitleSettingVisible(false);

        Toast("success", "Chat Title Saved");
      } catch (error) {
        console.error("Failed to update chat title:", error);
        Toast("error", "Failed to update chat title");
      }
    } else {
      alert("Chat Title already exists");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    if (selectedChat) {
      setNewChatTitle(selectedChat.chatTitle || chatTitle);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [selectedChat, submittedQuery, answer, loading]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModelSelection
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        user={user}
      />

      {selectedModel !== "Gemini 1.5 pro" && selectedModel && (
        <Typography
          variant="caption"
          color="error"
          sx={{ mt: { xs: 0, sm: 1 }, fontSize: "10px" }}
        >
          The selected model does not support context memory, i.e., contextual
          conversation.
        </Typography>
      )}

      {modelError && !selectedModel && (
        <Text sx={{ color: "red", fontSize: "10px", mt: 1 }}>
          Please select a model
        </Text>
      )}

      {!isCreatingNewChat &&
        selectedChat &&
        isTitleSettingVisible &&
        !selectedChat.chatTitle && (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <TextField
              value={newChatTitle}
              onChange={(e) => setNewChatTitle(e.target.value)}
              placeholder={
                selectedChat ? selectedChat.chatTitle : "Enter Chat Title"
              }
              variant="outlined"
              sx={{
                my: 1,
                width: "100%",
                fontFamily: "Raleway",
                fontSize: "12px",
              }}
              size="small"
              InputProps={{
                style: { fontSize: "12px", fontFamily: "Raleway" },
              }}
              InputLabelProps={{
                style: { fontFamily: "Raleway", fontSize: "12px" },
              }}
            />

            <Button
              onClick={handleRenameChat}
              variant="contained"
              sx={{
                width: "50%",
                py: 0.8,
                fontFamily: "Raleway",
                fontSize: "12px",
                background: "#1E4F6B",
                boxShadow: "none",
                "&:hover": {
                  background: "#163C54",
                },
                textTransform: "capitalize",
              }}
            >
              Rename
            </Button>
          </Stack>
        )}

      {!isCreatingNewChat && selectedChat && (
        <Box mt={4}>
          <Stack
            spacing={1.5}
            sx={{
              flexGrow: 1,
              maxHeight: {
                xs:
                  selectedModel !== "Gemini 1.5 pro"
                    ? "37vh"
                    : !selectedChat.chatTitle && isTitleSettingVisible
                    ? "40vh"
                    : "48vh",
                lg:
                  selectedModel !== "Gemini 1.5 pro"
                    ? "48vh"
                    : !selectedChat.chatTitle && isTitleSettingVisible
                    ? "44vh"
                    : "54vh",
              },
              overflowY: "auto",
              mb: 2,
            }}
            ref={chatContainerRef}
          >
            {/* Display messages from selectedChat */}
            {selectedChat.messages.map((message) => (
              <Stack key={message.id} spacing={1} sx={{ mb: 1 }}>
                {/* Render the question if it exists */}
                {message.question && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box
                      sx={{
                        background: "rgb(225 239 255)",
                        padding: "8px",
                        borderRadius: "6px",
                        maxWidth: "70%",
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: "14px",
                          px: 1,
                          wordBreak: "break-word",
                        }}
                      >
                        {message.question}
                      </Text>
                    </Box>
                    <Person2Icon
                      fontSize="18px"
                      sx={{ color: "#1E4F6B", mt: 0.5, ml: 1 }}
                    />
                  </Stack>
                )}

                {/* Render the answer if it exists */}
                {message.answer && (
                  <Stack
                    direction="row"
                    // alignItems="center"
                    justifyContent="flex-start"
                    sx={{ py: 2 }}
                  >
                    <Box sx={{ width: "16px", height: "14px", mt: 0.5, mr: 1 }}>
                      <img src={ResearchPal} alt="" />
                    </Box>

                    <Box
                      sx={{
                        background: "#f6f6f6",
                        padding: "8px",
                        borderRadius: "6px",
                        maxWidth: "70%",
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: "14px",
                          px: 1,
                          wordBreak: "break-word",
                        }}
                      >
                        {/* {message.answer} */}
                        {/* {message.answer.split("\r\n").map((line, index) => (
                            <React.Fragment key={index}>
                              {line.trim()}
                              <br />
                            </React.Fragment>
                          ))} */}
                        {message.answer.split("\r\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line
                              .split(/\*\*(.*?)\*\*/g)
                              .map((segment, segIndex) => (
                                <React.Fragment key={segIndex}>
                                  {segment.match(/(https?:\/\/[^\s]+)/g) ? (
                                    <Link
                                      href={segment}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      sx={{
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {segment}
                                    </Link>
                                  ) : segment.trim() ? (
                                    <span
                                      style={{
                                        fontWeight: segment.trim()
                                          ? "normal"
                                          : "bold",
                                      }}
                                    >
                                      {segment}
                                    </span>
                                  ) : null}
                                </React.Fragment>
                              ))}
                            <br />
                          </React.Fragment>
                        ))}
                      </Text>
                    </Box>
                  </Stack>
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  // justifyContent={"center"}
                  spacing={1}
                  sx={{ ml: 2 }}
                >
                  <Tooltip
                    title="Copied!"
                    open={copiedMessageId === message.id} // Only show the tooltip for the copied message
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                  >
                    <Stack
                      direction="column"
                      alignItems="center"
                      spacing={1}
                      sx={{ ml: 2 }}
                    >
                      <IconButton
                        aria-label="copy"
                        onClick={() => handleCopy(message.answer, message.id)}
                        size="small"
                      >
                        <ContentCopyIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    </Stack>
                  </Tooltip>

                  <Tooltip
                    title={`Model: ${message.model || "Unknown Model"}`}
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <IconButton aria-label="model-info" size="small">
                      <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}

            {submittedQuery && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box
                  sx={{
                    background: "rgb(225 239 255)",
                    padding: "8px",
                    borderRadius: "6px",
                    maxWidth: "70%",
                  }}
                >
                  <Text
                    sx={{ fontSize: "14px", px: 1, wordBreak: "break-word" }}
                  >
                    {submittedQuery}
                  </Text>
                </Box>
                <Person2Icon
                  fontSize="18px"
                  sx={{ color: "#1E4F6B", mt: 0.5, ml: 1 }}
                />
              </Stack>
            )}

            {loading ? ( // Show loading spinner while loading
              <Stack justifyContent="center" alignItems="center">
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#5AB7BF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </Stack>
            ) : (
              answer && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box sx={{ width: "16px", height: "14px", mt: 0.5, mr: 1 }}>
                    <img src={ResearchPal} alt="" />
                  </Box>
                  <Box
                    sx={{
                      background: "#f6f6f6",
                      padding: "8px",
                      borderRadius: "6px",
                      maxWidth: "70%",
                    }}
                  >
                    <Text
                      sx={{ fontSize: "14px", px: 1, wordBreak: "break-word" }}
                    >
                      {answer}
                    </Text>
                  </Box>
                </Stack>
              )
            )}
          </Stack>
        </Box>
      )}

      {isCreatingNewChat && (
        <Box mt={4}>
          <Stack
            spacing={1.5}
            sx={{
              flexGrow: 1,
              maxHeight: "52vh",
              overflowY: "auto",
              mb: 2,
            }}
          >
            {submittedQuery && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box
                  sx={{
                    background: "rgb(225 239 255)",
                    padding: "8px",
                    borderRadius: "6px",
                    maxWidth: "70%",
                  }}
                >
                  <Text
                    sx={{ fontSize: "14px", px: 1, wordBreak: "break-word" }}
                  >
                    {submittedQuery}
                  </Text>
                </Box>
                <Person2Icon
                  fontSize="18px"
                  sx={{ color: "#1E4F6B", mt: 0.5, ml: 1 }}
                />
              </Stack>
            )}

            {loading ? (
              <Stack justifyContent="center" alignItems="center">
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#5AB7BF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </Stack>
            ) : (
              answer && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box sx={{ width: "16px", height: "14px", mt: 0.5, mr: 1 }}>
                    <img src={ResearchPal} alt="" />
                  </Box>
                  <Box
                    sx={{
                      background: "#f6f6f6",
                      padding: "8px",
                      borderRadius: "6px",
                      maxWidth: "70%",
                    }}
                  >
                    <Text
                      sx={{ fontSize: "14px", px: 1, wordBreak: "break-word" }}
                    >
                      {answer}
                    </Text>
                  </Box>
                </Stack>
              )
            )}
          </Stack>
        </Box>
      )}

      {/* Input Box */}
      <FormControl fullWidth sx={{ mt: "auto" }}>
        <TextField
          sx={{
            background: "#F8F8F8",
            "& .MuiInputBase-input": {
              fontFamily: "Raleway",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1AB394", // Change border color when focused
              },
            },
          }}
          value={query}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          multiline
          rows={2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handleSend}
                  disabled={!query.trim()}
                  sx={{ minWidth: 0, padding: "4px" }}
                >
                  <SendIcon sx={{ color: "#1AB394" }} />
                </Button>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: {
              fontFamily: "Raleway",
              fontSize: "12px",
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export default ChatAbstract;
