import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRefs } from "../../../references/refsSlice"; // import your action
import TailSpinLoader from "../../../../components/TailSpinLoader";

const ReferenceEditor = ({ editor }) => {
  const dispatch = useDispatch();
  const { refFormats, loading } = useSelector((state) => state.refs);
  console.log("RefFormats", refFormats);
  const [referenceList, setReferenceList] = useState([]);
  const [format, setFormat] = useState(
    localStorage.getItem("selectedFormat") || "apa"
  );
  const [query, setQuery] = useState("");
  const [copyTooltip, setCopyTooltip] = useState(
    "Inserts citation at last cursor position."
  );

  // Dispatch the action to load refFormats
  // useEffect(() => {
  //   dispatch(getRefs(localStorage.getItem("projectId"))); // Dispatch the action to fetch reference formats
  // }, [dispatch]);

  useEffect(() => {
    if (!refFormats) {
      dispatch(getRefs(localStorage.getItem("projectId")));
    }
  }, []);

  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }

    let filtered = arr.filter((i) =>
      i.toString().toLowerCase().includes(query)
    );

    return filtered;
  };
  console.log("Formats", formats());

  // const handleCopy = (key) => {
  //   if (key.endsWith(".pdf")) {
  //     // Find the citation format corresponding to the PDF key
  //     const citationFormat = refFormats[key];
  //     if (citationFormat && citationFormat.inTextCitations) {
  //       // Copy only the inTextCitation
  //       navigator.clipboard
  //         .writeText(citationFormat.inTextCitations)
  //         .then(() => {
  //           setCopyTooltip("Cite copied!");
  //           setTimeout(() => setCopyTooltip(""), 2000);
  //         });
  //     } else {
  //       console.error("inTextCitations not found for key:", key);
  //     }
  //   } else {
  //     // For non-.pdf keys, copy the key directly
  //     navigator.clipboard.writeText(key).then(() => {
  //       setCopyTooltip("Cite copied!");
  //       setTimeout(() => setCopyTooltip(""), 2000);
  //     });
  //   }
  //   if (editor) {
  //     editor.chain().focus().insertContent(textToInsert).run();
  //   } else {
  //     console.error("Editor instance not available");
  //   }
  // };

  // const handleCopy = (key) => {
  //   let citationText = key; // Default to key if no citation format is found

  //   if (key.endsWith(".pdf")) {
  //     const citationFormat = refFormats[key];
  //     if (citationFormat && citationFormat.inTextCitations) {
  //       citationText = citationFormat.inTextCitations;
  //     } else {
  //       console.error("inTextCitations not found for key:", key);
  //     }
  //   }

  //   // Copy the citation text to the clipboard
  //   navigator.clipboard.writeText(citationText).then(() => {
  //     setCopyTooltip("Cite copied!");
  //     setTimeout(() => setCopyTooltip(""), 2000);

  //     // Insert citation text into the editor
  //     if (editor) {
  //       editor.chain().focus().insertContent(citationText).run();
  //     } else {
  //       console.error("Editor instance not available");
  //     }
  //   });
  // };

  const handleCopy = (key) => {
    let citationText = key; // Default to the key
    let url = null;

    // Check if the key ends with ".pdf"
    const citationFormat = refFormats[key];

    if (key.endsWith(".pdf")) {
      citationText = citationFormat?.inTextCitations || citationText;
      url = citationFormat?.url;

      if (!citationFormat?.inTextCitations) {
        console.error("inTextCitations not found for key:", key);
      }
    } else {
      // For non-PDF keys, retrieve the Harvard format and look for the short format
      citationText = key;
      // url =
      //   citationFormat?.url ||
      //   JSON.parse(localStorage.getItem("mappedAbstracts") || "{}")[
      //     citationFormat?.harvard
      //   ]?.url;
      url =
        citationFormat?.link ||
        JSON.parse(localStorage.getItem("mappedAbstracts") || "{}")[
          citationFormat?.harvard
        ]?.url;
    }

    // Prepare the content for the editor, with or without a link
    const content = url
      ? `<a href="${url}" target="_blank" rel="noreferrer">${citationText}</a>`
      : citationText;

    // Insert the citation text or linked citation into the editor
    if (editor) {
      editor.chain().focus().insertContent(content).run();
    } else {
      console.error("Editor instance not available");
    }

    // Copy citation text to clipboard
    navigator.clipboard.writeText(citationText).then(() => {});
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "2px 25px",
          sm: "2px 50px",
          md: "2px 100px",
          lg: "2px 250px",
        },
      }}
    >
      {loading ? (
        <Typography>
          <TailSpinLoader />{" "}
          {/* Display loading indicator while fetching data */}
        </Typography>
      ) : refFormats && formats().length > 0 ? (
        <Box mt={4} sx={{ borderTop: "1px solid #222222" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: 700, mb: 2, mt: 2 }}
            >
              References
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#7A7979" }}>
              To edit reference(s) go to the “References” section.
            </Typography>
          </Stack>
          {/* <ul style={{ listStyleType: "none", padding: 0 }}>
            {formats().map((item, i) => (
              <li
                key={i}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    overflowX: "auto",
                    color: "#353535",
                    fontFamily: "Roboto",
                  }}
                >
                  <span style={{ fontWeight: "700", fontSize: "16px" }}>
                    {i + 1}.{" "}
                  </span>
                  {item}
                </Typography>
                <Tooltip title={copyTooltip || "Cite"}>
                  <Button onClick={() => handleCopy(item)} size="small">
                    Cite
                  </Button>
                </Tooltip>
              </li>
            ))}
          </ul> */}
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {formats().map((item, i) => {
              // Retrieve the key from refFormats based on the current item
              const key = Object.keys(refFormats).find(
                (k) => refFormats[k][format] === item
              );
              return (
                <li
                  key={i}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      overflowX: "auto",
                      color: "#353535",
                      fontFamily: "Roboto",
                    }}
                  >
                    <span style={{ fontWeight: "700", fontSize: "16px" }}>
                      {i + 1}.{" "}
                    </span>
                    {item}
                  </Typography>
                  <Tooltip title={copyTooltip}>
                    <Button
                      variant="contained"
                      onClick={() => handleCopy(key)}
                      size="small"
                      sx={{
                        background: "#1E4F6B",
                        boxShadow: "none",
                        "&:hover": {
                          background: "#163C54",
                        },
                        textTransform: "capitalize",
                        ml: 2,
                      }}
                      disabled={key === ""}
                    >
                      Cite
                    </Button>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </Box>
      ) : null}
    </Box>
  );
};

export default ReferenceEditor;
