import React from "react";
import UpdatePassword from "./UpdatePassword";
import { Box } from "@mui/material";

function ResetPassword() {
  const constant = () => {};
  return (
    <Box>
      <UpdatePassword open={true} onClose={constant} />
    </Box>
  );
}

export default ResetPassword;
