import { EditorInfo } from "./EditorInfo";
import { Toolbar } from "../ui/Toolbar";
import Icon from "../ui/Icon";

export const EditorHeader = ({
  characters,
  currentTime,
  words,
  isSidebarOpen,
  toggleSidebar,
  editor,
  setCurrentTime,
}) => {
  return (
    <div className="flex flex-row items-center justify-between max-h-[100%] flex-none py-2 pl-6 pr-3 text-black bg-white border-b border-neutral-200 dark:bg-white dark:text-black dark:border-neutral-200 ">
      <div className="flex flex-row gap-x-1.5 items-center">
        <div className="flex items-center gap-x-1.5">
          <Toolbar.Button
            tooltip={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
            onClick={toggleSidebar}
            active={isSidebarOpen}
            className={isSidebarOpen ? "bg-transparent" : ""}
          >
            <Icon name={isSidebarOpen ? "PanelLeftClose" : "PanelLeft"} />
          </Toolbar.Button>
        </div>
      </div>
      <EditorInfo
        characters={characters}
        words={words}
        currentTime={currentTime}
        editor={editor}
        setCurrentTime={setCurrentTime}
      />
    </div>
  );
};
