import { BubbleMenu as BaseBubbleMenu } from "@tiptap/react";
import React, { useCallback, useEffect, useState } from "react";
import { LinkEditorPanel } from "../../panels/LinkEditorPanel/LinkEditorPanel";
import { LinkPreviewPanel } from "../../panels/LinkPreviewPanel/LinkPreviewPanel";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import SimpleAccordion from "../../../../abstract/AbstractFiles";

export const LinkMenu = ({
  editor,
  appendTo,
  title,
  tip,
  projectTitle,
  anchor,
  data,
  file,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive("link");

    return isActive;
  }, [editor]);

  const { href: link, target } = editor.getAttributes("link");

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url, openInNewTab) => {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: openInNewTab ? "_blank" : "" })
        .run();
      setShowEdit(false);
    },
    [editor]
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    setShowEdit(false);

    return null;
  }, [editor]);

  const onShowEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onHideEdit = useCallback(() => {
    setShowEdit(false);
  }, []);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        popperOptions: {
          modifiers: [
            { name: "flip", enabled: true },
            {
              name: "preventOverflow",
              options: {
                altBoundary: true, // true by default
              },
            },
          ],
        },
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowEdit(false);
        },
      }}
      render
    >
      {showEdit ? (
        <LinkEditorPanel
          initialUrl={link}
          initialOpenInNewTab={target === "_blank"}
          onSetLink={onSetLink}
        />
      ) : (
        <LinkPreviewPanel
          url={link}
          onClear={onUnsetLink}
          onEdit={handleEdit}
          title={title}
          tip={tip}
          projectTitle={projectTitle}
          anchor={anchor}
          data={data}
          file={file}
          editor={editor}
        />
      )}
    </BaseBubbleMenu>
  );
};

export default LinkMenu;
