// import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight';
import TaskList from "@tiptap/extension-task-list";
import Columns from "./MultiColumn/Columns";
import TaskItem from "@tiptap/extension-task-item";
import Column from "./MultiColumn/Column";
import Heading from "./Heading/Heading";
import HorizontalRule from "./HorizontalRule/HorizontalRule";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import FontSize from "./FontSize/FontSize";
import FontFamily from "@tiptap/extension-font-family";
import Color from "@tiptap/extension-color";
import { TrailingNode } from "./TrailingNode/trailing-node";
import Link from "./Link/Link";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import CharacterCount from "@tiptap/extension-character-count";
import TableOfContents from "@tiptap-pro/extension-table-of-contents";
import ImageBlock from "./ImageBlock/ImageBlock";
import FileHandler from "@tiptap-pro/extension-file-handler";
import Emoji from "@tiptap-pro/extension-emoji";
import emojiSuggestion from "./EmojiSuggestion/suggestion";
import TextAlign from "@tiptap/extension-text-align";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Table from "./Table/Table";
import { TableCell } from "./Table/Cell";
import TableHeader from "./Table/Header";
import TableRow from "./Table/Row";
import Typography from "@tiptap/extension-typography";
import Placeholder from "@tiptap/extension-placeholder";
import SlashCommand from "./SlashCommand/SlashCommand";
import { FocusClasses as Focus } from "@tiptap/extension-focus";
import Figcaption from "./FigCaption/FigCaption";
import BlockquoteFigure from "./BlockquoteFigure/BlackQuote";
import Dropcursor from "@tiptap/extension-dropcursor";
import API from "../lib/api";
import ImageUpload from "./ImageUpload/ImageUpload";
import { TableOfContentsNode } from "./TableOfContentsNode/TableOfContentsNode";
import Document from "@tiptap/extension-document";
import { History } from "@tiptap/extension-history";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { createLowlight } from "lowlight";
import { LineHeight } from "./LineHeight/LineHeight";
const ExtensionKit = ({ provider, userId, userName = "Maxi" }) => [
  Document,
  Columns,
  TaskList,
  History.configure({
    limit: 100,
    depth: 100,
  }),
  TaskItem.configure({
    nested: true,
  }),
  //   AiWriter.configure({
  //     authorId: userId,
  //     authorName: userName,
  //   }),
  //   AiImage.configure({
  //     authorId: userId,
  //     authorName: userName,
  //   }),
  Column,
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    history: false,
    codeBlock: false,
  }),
  CodeBlockLowlight.configure({
    lowlight: createLowlight(),
    defaultLanguage: null,
  }),
  TextStyle,
  FontSize,
  LineHeight,
  FontFamily,
  Color,
  TrailingNode,
  Link.configure({
    openOnClick: false,
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  CharacterCount.configure({ limit: 350000 }),
  TableOfContents,
  TableOfContentsNode,
  ImageUpload.configure({
    clientId: provider?.document?.clientID,
  }),
  ImageBlock,

  FileHandler.configure({
    allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],
    onDrop: (currentEditor, files, pos) => {
      files.forEach(async () => {
        const url = await API.uploadImage();

        currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run();
      });
    },
    onPaste: (currentEditor, files) => {
      files.forEach(async () => {
        const url = await API.uploadImage();

        return currentEditor
          .chain()
          .setImageBlockAt({
            pos: currentEditor.state.selection.anchor,
            src: url,
          })
          .focus()
          .run();
      });
    },
  }),
  Emoji.configure({
    enableEmoticons: true,
    suggestion: emojiSuggestion,
  }),
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ["heading", "paragraph"],
  }),
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => "",
  }),
  SlashCommand,
  Focus,
  Figcaption,
  BlockquoteFigure,
  Dropcursor.configure({
    width: 2,
    class: "ProseMirror-dropcursor border-black",
  }),
];

export default ExtensionKit;
