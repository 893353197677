import { cn } from "../../../lib/utils";

export const DropdownCategoryTitle = ({ children }) => {
  return (
    <div className="text-[.65rem] font-semibold mb-1 uppercase text-neutral-800 dark:text-neutral-800 px-1.5">
      {children}
    </div>
  );
};

export const DropdownButton = ({
  children,
  isActive,
  onClick,
  disabled,
  className,
}) => {
  const buttonClass = cn(
    "flex items-center gap-2 p-1.5 text-sm font-medium text-neutral-500 dark:text-neutral-500 text-left bg-transparent w-full rounded",
    !isActive &&
      !disabled &&
      "hover:bg-neutral-100 hover:text-neutral-800 dark:hover:bg-neutral-100 dark:hover:text-neutral-800",
    "hover:bg-neutral-100 hover:text-neutral-800 ",
    isActive &&
      !disabled &&
      "bg-neutral-100 text-neutral-800 dark:bg-neutral-100 dark:text-neutral-800",
    disabled && "text-neutral-400 cursor-not-allowed dark:text-neutral-800",
    className
  );

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};
