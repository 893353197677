import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getFeedbackEmails } from "../../apiservice";
import { Box, Button } from "@mui/material";
import { CircularProgress, FormControl, FormControlLabel } from "@mui/material";
import { Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";

import { baseDomain } from "../../repo/Repository";
import { useDispatch } from "react-redux";
import { clearUser } from "./userSlice";
import FeedBacks from "./FeedBacks";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { TailSpin } from "react-loader-spinner";
import { CBBox } from "../styles";
import Limitations from "./Limitations";

const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      background: "transparent",
    },
  },
  header: {
    style: {
      // borderTopRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#01A35F",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "40px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#01A35F",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

const Protal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("user");
  const [open, setOpen] = useState(false);
  const [openRating, setRatingOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [activityLoad, setActivityLoad] = useState(false);
  const [feedLoad, setFeedLoad] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [latestActivity, setLatestActivity] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [litRevFeedbackData, setLitRevFeedbackData] = useState([]);
  const [ratingInfo, setRatingInfo] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [openLimitation, setOpenLimitation] = useState(false);
  const [limitationData, setLimitationData] = useState([]);
  const [totalUser, setTotalUser] = useState("");

  useEffect(() => {
    if (selectedTab == "user") {
      handleAllUser();
    } else if (selectedTab == "feedback") {
      fetchFeedBack();
    } else if (selectedTab == "latestActivity") {
      getActivityData();
    } else if (selectedTab == "literatureReviewFeedback") {
      getLitRevFeedback();
    }
  }, [selectedTab]);

  const fetchFeedBack = async () => {
    setFeedLoad(true);
    const resp = await getFeedbackEmails();
    setFeedbackData(resp?.emailsArr);
    setFeedLoad(false);
  };

  const feedCols = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Date of Feedback",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleDateString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Feedback",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => toggle(row.email)}
        >
          Check Feedback
        </Button>
      ),
    },
  ];
  const columns = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Total Word Count",
      selector: (row) => row.wordCount || 0,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
  ];

  const actCols = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Modified Data",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleDateString();
      },
    },
    {
      name: "Time",
      cell: (row) => {
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleTimeString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Limitations",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => toggleLimitation(row)}
        >
          Check Limitations
        </Button>
      ),
    },
  ];

  const litReviewFedColumns = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Date",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toDateString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Feedback",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => handleRating(row.email)}
        >
          Check Feedback
        </Button>
      ),
    },
  ];
  // const handleAllUser = async () => {
  //   try {
  //     setLoading(true);
  //     let data = await axios
  //       .post(`${baseDomain}/users`, {
  //         request: {
  //           method: "getTopUsersActivity",
  //         },
  //       })
  //       .then((res) => {
  //         axios.post(`${baseDomain}/research-projects`, {
  //           request: {
  //             method: "getWordCountByEmail",
  //             data: {
  //               emails: res.data?.response?.data?.usersArr.map(
  //                 (item) => item.email
  //               ),
  //             },
  //           },
  //         });
  //       });

  //     console.log("newData", data);
  //     // if (data.response.status.statusCode == 200) {
  //     //   let arr = data.response.data?.usersArr;

  //     //   const { newData } = await axios.post(
  //     //     `${baseDomain}/research-projects`,
  //     //     {
  //     //       request: {
  //     //         method: "getWordCountByEmail",
  //     //         data: {
  //     //           emails: arr.map((item) => item.email),
  //     //         },
  //     //       },
  //     //     }
  //     //   );
  //     //   console.log("newData", newData);
  //     //   if (newData.response.status.statusCode == 200) {
  //     //     setAllUsers(newData.response.data.researchProjects);
  //     //   }
  //     // } else {
  //     //   setAllUsers([]);
  //     // }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };
  const handleAllUser = async () => {
    try {
      setLoading(true);
      let data = await axios
        .post(`${baseDomain}/users`, {
          request: {
            method: "getTopUsersActivity",
          },
        })
        .then((res) => {
          // Return the inner axios.post call
          return axios.post(`${baseDomain}/research-projects`, {
            request: {
              method: "getWordCountByEmail",
              data: {
                emails: res.data?.response?.data?.usersArr.map(
                  (item) => item.email
                ),
              },
            },
          });
        });

      setAllUsers(data.data.response.data.researchProjects);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getActivityData = async () => {
    try {
      setActivityLoad(true);
      let { data } = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTopUsersActivity",
        },
      });

      console.log("output", data.response.data?.usersArr);
      setLatestActivity(data.response.data?.usersArr);
      setTotalUser(data.response.data?.totalUsers);
      setActivityLoad(false);
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };
  const getLitRevFeedback = async () => {
    try {
      setLoading(true);
      let { data } = await axios.post(`${baseDomain}/literature_ratings`, {
        request: {
          method: "getLiteratureRatingsEmails",
        },
      });
      if (data.response.status.statusCode == 200) {
        //sort by date
        const sorted = data.response.data.emailsArr.sort(
          (a, b) => b.modified_at - a.modified_at
        );

        setLitRevFeedbackData(sorted);
      } else {
        setLitRevFeedbackData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleTabChange = (e) => {
    const { value } = e.target;
    setSelectedTab(value);
    if (value == "user" && allUsers.length == 0) {
      handleAllUser();
    } else if (value == "feedback" && feedbackData.length == 0) {
      fetchFeedBack();
    } else if (value == "latestActivity" && latestActivity.length == 0) {
      getActivityData();
    } else if (
      value == "literatureReviewFeedback" &&
      litRevFeedbackData.length == 0
    ) {
      getLitRevFeedback();
    }
  };

  const toggle = (row = null) => {
    setOpen((prev) => !prev);
    setUserInfo(row);
  };

  const toggleLimitation = (limitation) => {
    setLimitationData(limitation);
    setOpenLimitation(true);
  };
  const handleRating = (row = null) => {
    setRatingOpen((prev) => !prev);
    setRatingInfo(row);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = () => {
    if (selectedTab == "user") {
      if (searchTerm) {
        let filtered = allUsers?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return allUsers;
      }
    } else if (selectedTab == "feedback") {
      if (searchTerm) {
        let filtered = feedbackData?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return feedbackData;
      }
    } else if (selectedTab == "literatureReviewFeedback") {
      if (searchTerm) {
        let filtered = litRevFeedbackData?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return litRevFeedbackData;
      }
    } else {
      if (searchTerm) {
        let filtered = latestActivity?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return latestActivity;
      }
    }
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      let { data } = await Repo.excel({
        request: {
          method: "createAllEmailsExcel",
        },
      });
      setDownloading(false);
      if (data.response.data.success) {
        window.open(data.response.data.success);
      } else {
        Toast("info", data.response.status.statusMessage);
      }
    } catch (err) {
      console.log("err:", err);
      setDownloading(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    navigate("/");
  };
  const handleClose = () => {
    setOpenLimitation(false);
  };
  return (
    <Box sx={{ p: "1%" }}>
      <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
        <Box></Box>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Registered Users
        </Typography>
        <Button
          variant="contained"
          size="small"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>

      <CBBox sx={{ my: 1 }}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={handleSearch}
          size="small"
        />
        <Stack direction="row" spacing="8px">
          {totalUser && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginInline: "20px",
              }}
            >
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {totalUser && `Total Users: ${totalUser}`}
              </Typography>
            </Box>
          )}
          {!loading && (
            <Box sx={{ minWidth: "80px" }}>
              {downloading && (
                <TailSpin
                  height="36"
                  width="36"
                  color="#353535"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              )}
              {!downloading && (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={handleDownload}
                >
                  Download Emails
                </Button>
              )}
            </Box>
          )}

          <FormControl>
            <RadioGroup row value={selectedTab} onChange={handleTabChange}>
              <FormControlLabel
                value="user"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Users"
              />
              <FormControlLabel
                value="feedback"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Feedbacks"
              />
              <FormControlLabel
                value="latestActivity"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Latest Activity"
              />
              <FormControlLabel
                value="literatureReviewFeedback"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Literature Review Feedbacks"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </CBBox>
      {selectedTab == "user" && (
        <Box>
          {loading && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!loading && (
            <DataTable
              columns={columns}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "feedback" && (
        <Box>
          {feedLoad && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!feedLoad && (
            <DataTable
              columns={feedCols}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "latestActivity" && (
        <Box>
          {activityLoad && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!activityLoad && (
            <DataTable
              columns={actCols}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "literatureReviewFeedback" && (
        <Box>
          {loading && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!loading && (
            <DataTable
              columns={litReviewFedColumns}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}

      {open && <FeedBacks info={userInfo} onClose={toggle} />}
      {openRating && !open && (
        <FeedBacks ratingInfo={ratingInfo} onClose={handleRating} rating />
      )}
      {latestActivity && openLimitation && (
        <Limitations
          limitation={limitationData?.limitations}
          open={openLimitation}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};

export default Protal;
