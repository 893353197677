import React from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebarToggle } from "./toggleSlice";

function ToggleSide() {
  const dispatch = useDispatch();
  const { sidebarOpen } = useSelector((state) => state.sidebar);

  const toggle = () => {
    dispatch(handleSidebarToggle());
  };
  return (
    <Box
      sx={{
        background: "#f6f6f6",
        height: "32px",
        width: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "4px",
      }}
    >
      {sidebarOpen ? (
        <MenuOpenIcon
          onClick={toggle}
          sx={{ height: "20px", cursor: "pointer" }}
        />
      ) : (
        <MenuIcon onClick={toggle} sx={{ height: "20px", cursor: "pointer" }} />
      )}
    </Box>
  );
}

export default ToggleSide;
