export const Domains = [
  "Computer Science",
  "Medical & Health Sciences",
  "Humanities and Social Sciences",
  "Business Studies",
  "Chemistry",
  // "Physics",
  // "Economics & Finance",
  // "Psychology",
  "Other",
];
