import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabValue: 0,
  loading: false,
  error: "",
  papers: [],
  googlePapers: [],
  searchWindow: false,
  paperQuery: "",
  googlePaperQuery: "",
  onSearch: false,
  progress: 0,
  saveStatus: {},
  page:1,
  domain: "",
};

export const paperSlice = createSlice({
  name: "paper",
  initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    clearTabValue: (state) => {
      state.tabValue = initialState.tabValue
    },
    addGooglePapers: (state, action) => {
      state.googlePapers = [...state.googlePapers, ...action.payload];
    },
    addPapers: (state, action) => {
      state.papers = [...state.papers, ...action.payload];
    },
    addStatus: (state, action) => {
      state.saveStatus = action.payload;
    },
    setProgress: (state, action) => {
      state.progress =
        action.payload !== 0
          ? state.progress === 99
            ? 70
            : state.progress + action.payload
          : 0;
    },
    handlePaperQuery: (state, action) => {
      state.paperQuery = action.payload;
      // state.onSearch = false;
    },
    handleGooglePaperQuery: (state, action) => {
      state.googlePaperQuery = action.payload;
      // state.onSearch = false;
    },
    clearPapers: (state) => {
      state.papers = [];
      state.progress = 0;
    },

    clearGooglePapers: (state) => {
      state.googlePapers = [];
      state.progress = 0;
    },
    clearPaperQuery: (state) => {
      state.paperQuery = "";
      state.onSearch = false;
    },
    clearGooglePaperQuery: (state) => {
      state.googlePaperQuery = "";
      state.onSearch = false;
    },
    handleOnSearch: (state) => {
      state.onSearch = true;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPageNo:(state,action)=>{
      state.page = action.payload;
    },
    resetPageNo: (state)=>{
      state.page = initialState.page;
    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    clearDomain: (state) =>{
      state.domain = initialState.domain
    },
  },
});

export const {
  handlePaperQuery,
  clearPapers,
  addPapers,
  clearPaperQuery,
  handleOnSearch,
  setProgress,
  setLoading,
  addGooglePapers,
  clearGooglePapers,
  clearGooglePaperQuery,
  handleGooglePaperQuery,
  addStatus,
  setPageNo,
  resetPageNo, setDomain, clearDomain, setTabValue, clearTabValue
} = paperSlice.actions;

export default paperSlice.reducer;
