import { Grid, Stack, Typography, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import footerLogo from "../../assets/images/NewImages/footerLogo.svg";
import footerLogo from "../../assets/images/NewImages/Footer Logo.png";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";

const Heading = styled(Typography)({
  color: "#fff",
  fontSize: "18px",
  fontWeight: 700,
  fontFamily: "Raleway",
});
const Text = styled(Typography)({
  color: "#fff",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 400,
});
const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmall = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <footer
      style={{
        background:
          "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%)",
        padding: "50px",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          sm={12}
          md={2.3}
          sx={{
            borderRight: isSmall
              ? "none"
              : "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={footerLogo}
              alt="logo"
              width={isMobile ? 50 : 40}
              height={isMobile ? 50 : 40}
            />
            {!isMobile && (
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "18px",
                  fontFamily: "Raleway",
                  fontWeight: 600,
                }}
                onClick={() => handleNavigation("/")}
                style={{ cursor: "pointer" }} // Add cursor to indicate clickable
              >
                ResearchPal
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid
          item
          sm={12}
          md={2.3}
          sx={{
            color: "#FFFFFF",
            fontFamily: "Raleway",
            fontSize: "14px",
            borderRight: isSmall
              ? "none"
              : "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Heading>Products</Heading>
          <br />
          <Stack direction={{ xs: "row", sm: "column" }} gap={{ xs: 4, sm: 0 }}>
            <Text
              onClick={() => handleNavigation("/api")}
              style={{ cursor: "pointer" }}
            >
              API
            </Text>
            {!isMobile && <br />}
            <Text
              onClick={() => handleNavigation("/pricing")}
              style={{ cursor: "pointer" }}
            >
              Pricing
            </Text>
            {!isMobile && <br />}
            <Text
              onClick={() => handleNavigation("/faqs")}
              style={{ cursor: "pointer" }}
            >
              FAQs
            </Text>
          </Stack>
          {!isMobile && <br />}
        </Grid>
        <Grid
          item
          sm={12}
          md={2.3}
          fontSize={"16px"}
          sx={{
            color: "#FFFFFF",
            fontFamily: "Raleway",
            fontSize: "14px",
            borderRight: isSmall
              ? "none"
              : "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Heading>Company</Heading>
          <br />
          <Text
            onClick={() => handleNavigation("/features")}
            style={{ cursor: "pointer" }}
          >
            Features
          </Text>
          <br />
          <Text
            onClick={() => handleNavigation("/universities")}
            style={{ cursor: "pointer" }}
          >
            Universities
          </Text>
          <br />
          <Text
            onClick={() => handleNavigation("/termsandconditions")}
            style={{ cursor: "pointer" }}
          >
            Terms
          </Text>
          <br />
        </Grid>
        <Grid
          item
          sm={12}
          md={2.3}
          sx={{
            color: "#FFFFFF",
            fontFamily: "Raleway",
            fontSize: "14px",
            borderRight: isSmall
              ? "none"
              : "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Heading>Social</Heading>
          <br />
          <a
            href="https://www.facebook.com/profile.php?id=100093418913123"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Text>
              <Facebook sx={{ marginRight: "5px" }} /> Facebook
            </Text>
          </a>
          <br />
          <a
            href="https://twitter.com/ResearchPal_AI"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Text>
              <Twitter sx={{ marginRight: "5px" }} />
              Twitter
            </Text>
          </a>
          <br />
          <a
            href="https://www.linkedin.com/company/102305132/admin/feed/posts/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Text>
              <LinkedIn sx={{ marginRight: "5px" }} />
              LinkedIn
            </Text>
          </a>
        </Grid>
        <Grid item sm={12} md={2.3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Raleway",
              }}
            >
              Contact
            </Typography>
            <Typography
              sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: 400 }}
            >
              :
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: "Raleway",
              }}
            >
              Info@ResearchPal.Co
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ marginTop: "20px" }}
          >
            <Typography
              sx={{ color: "#FFFFFF", fontSize: "16px", fontFamily: "Raleway" }}
            >
              Level 1, Devonshire House, One Mayfair Place, London, United
              Kingdom
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ marginTop: "20px" }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Raleway",
              }}
            >
              Copyright
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Raleway",
              }}
            >
              {new Date().getFullYear()}
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: "Raleway",
              }}
            >
              |
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: "Raleway",
              }}
            >
              ResearchPal
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
