import React, { useState } from "react";
import AlertIcon from "../../assets/images/delete alert.svg";
// import { Box, Button } from "@mui/material";
// import { FormControlLabel, Stack, Typography, styled } from "@mui/material";
// import { Dialog, Checkbox, DialogActions, DialogContent } from "@mui/material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "24px",
  fontWeight: 700,
  color: "#353535",
});
const SubTitle = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  color: "#353535CC",
});
const ItemBox = styled(Box)({
  background: "#F9F9F9",
  padding: "8px",
  borderRadius: "4px",
  marginLeft: "8px",
  width: "-webkit-fill-available",
});

export default function ConfirmationDialog({ text, onClose, onSuccess, refs }) {
  const [selected, setSelected] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const [includeReferences, setIncludeReferences] = useState(false);

  // const handleSelectAll = (e) => {
  //   const { checked } = e.target;
  //   if (checked) {
  //     if (text.includes("library")) {
  //       let obj = {};
  //       let arr = [];
  //       refs.forEach((item) => {
  //         if (item.citations != "-") {
  //           obj[item.citations] = item.citations;
  //           arr.push(item.citations);
  //         }
  //       });
  //       setSelected(arr);
  //       setSelectedKeys(obj);
  //     } else {
  //       let obj = {};
  //       for (let i = 0; i < refs.length; i++) {
  //         obj[refs[i].title] = refs[i].title;
  //       }
  //       setSelected(refs);
  //       setSelectedKeys(obj);
  //     }
  //   } else {
  //     setSelected([]);
  //     setSelectedKeys({});
  //   }
  // };
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      let obj = {};
      let arr = [];
      refs.forEach((item) => {
        if (item.citations != "-") {
          obj[item.citations] = item.citations;
          arr.push(item.citations);
        }
      });
      setSelected(arr);
      setSelectedKeys(obj);
    } else {
      setSelected([]);
      setSelectedKeys({});
    }
  };
  // const handleSelect = (val) => {
  //   if (selectedKeys[val]) {
  //     let filtered = selected.filter((item) => item != val);
  //     let copy = JSON.parse(JSON.stringify(selectedKeys));
  //     delete copy[val];
  //     setSelected(filtered);
  //     setSelectedKeys(copy);
  //   } else {
  //     let copy = JSON.parse(JSON.stringify(selectedKeys));
  //     copy[val] = val;
  //     setSelected((prev) => [...prev, val]);
  //     setSelectedKeys(copy);
  //   }
  // };
  const handleSelect = (val) => {
    if (selectedKeys[val]) {
      let filtered = selected.filter((item) => item != val);
      let copy = { ...selectedKeys };
      delete copy[val];
      setSelected(filtered);
      setSelectedKeys(copy);
    } else {
      let copy = { ...selectedKeys };
      copy[val] = val;
      setSelected((prev) => [...prev, val]);
      setSelectedKeys(copy);
    }
  };

  return (
    // <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
    //   <DialogContent>
    //     <Stack
    //       spacing={2.4}
    //       width="100%"
    //       alignItems="center"
    //       justifyContent="center"
    //     >
    //       <img height="64px" width="64px" src={AlertIcon} alt="" />
    //       <Title>Are you sure ?</Title>
    //       <SubTitle>{text}</SubTitle>
    //     </Stack>
    //     {/* {text.includes("library") && refs?.length > 0 && (
    //       <Box sx={{ my: 2.4 }}>
    //         <SubTitle sx={{ textAlign: "center", fontSize: "12px" }}>
    //           References found. Select to delete or keep them in your project.
    //         </SubTitle>

    //         {refs?.length > 1 && (
    //           <FormControlLabel
    //             control={
    //               <Checkbox
    //                 size="small"
    //                 disableRipple
    //                 sx={{ p: "0", m: 0, mx: 1.5 }}
    //                 checked={selected.length == refs.length ? true : false}
    //                 onChange={handleSelectAll}
    //               />
    //             }
    //             label="Select All"
    //           />
    //         )}

    //         {refs.map((item, i) => {
    //           if (item?.citations != "-") {
    //             return (
    //               <Box
    //                 key={i}
    //                 sx={{ mt: 1, display: "flex", alignItems: "flex-start" }}
    //               >
    //                 <Checkbox
    //                   size="small"
    //                   disableRipple
    //                   sx={{ p: "0", m: 0, mt: 0.5 }}
    //                   checked={selectedKeys[item?.pdfFile] ? true : false}
    //                   onChange={() => handleSelect(item?.pdfFile)}
    //                 />
    //                 <ItemBox>{item?.citations}</ItemBox>
    //               </Box>
    //             );
    //           }
    //         })}
    //       </Box>
    //     )}
    //     {text.includes("abstracts") && refs?.length > 0 && (
    //       <Box sx={{ my: 2.4 }}>
    //         <SubTitle sx={{ textAlign: "center", fontSize: "12px" }}>
    //           In-text citations found. Select to delete or keep them in your
    //           project.
    //         </SubTitle>

    //         {refs?.length > 1 && (
    //           <FormControlLabel
    //             control={
    //               <Checkbox
    //                 size="small"
    //                 disableRipple
    //                 sx={{ p: "0", m: 0, mx: 1.5 }}
    //                 checked={selected.length == refs.length ? true : false}
    //                 onChange={handleSelectAll}
    //               />
    //             }
    //             label="Select All"
    //           />
    //         )}

    //         {refs.map((item, i) => {
    //           return (
    //             <Box
    //               key={i}
    //               sx={{ mt: 1, display: "flex", alignItems: "flex-start" }}
    //             >
    //               <Checkbox
    //                 size="small"
    //                 disableRipple
    //                 sx={{ p: "0", m: 0, mt: 0.5 }}
    //                 checked={selectedKeys[item.title] ? true : false}
    //                 onChange={() => handleSelect(item.title)}
    //               />
    //               <ItemBox>{item.title}</ItemBox>
    //             </Box>
    //           );
    //         })}
    //       </Box>
    //     )} */}
    //   </DialogContent>

    //   <DialogActions sx={{ justifyContent: "space-around" }}>
    //     <Button
    //       sx={{
    //         textTransform: "none",
    //         color: "#353535",
    //         fontFamily: "Raleway",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //       }}
    //       onClick={onClose}
    //     >
    //       Cancel
    //     </Button>
    //     <Button
    //       sx={{
    //         textTransform: "none",
    //         background: "#353535 !important",
    //         fontFamily: "Raleway",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //       }}
    //       variant="contained"
    //       onClick={() => onSuccess(selected)}
    //     >
    //       Continue
    //     </Button>
    //   </DialogActions>
    // </Dialog>

    <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
      <DialogContent>
        <Stack
          spacing={2.4}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <img height="64px" width="64px" src={AlertIcon} alt="" />
          <Title>Are you sure ?</Title>
          <SubTitle>{text}</SubTitle>
          {refs.length > 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeReferences}
                  onChange={() => setIncludeReferences(!includeReferences)}
                />
              }
              label="Delete Reference(s) of the file(s)?"
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          sx={{
            textTransform: "none",
            color: "#353535",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: "none",
            // background: "#353535 !important",
            background: "#1E4F6B !important",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => onSuccess(selected, includeReferences)}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
