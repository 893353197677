import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";

export const RisDownload = async (filesData) => {
  try {
    const risString = filesData.map((item) => {
      return `TY -JOUR
        AU -${item.author || " "},
        PY -${item.year || " "},
        DA -${item.uploadDate || " "},
        TI -${item.title || " "},
        T2 -${item.journal || " "},
        SP -${item.pages || " "},
        EP -${item.pages || " "},
        VL -${item.volume || " "},
        ER  -`;
    });

    let { data } = await Repo.otherFormats({
      request: {
        method: "createInsightsToRIS",
        data: risString,
      },
    });
    if (data?.response?.status?.statusCode === 200) {
      window.open(data?.response?.data?.success, "_blank");
      Toast("success", "RIS file downloaded");
    } else {
      Toast("error", "Error");
    }
  } catch (err) {
    console.log(err);
  }
};
