import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  blog:[],
  features:[]
  
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {

    addBlog:(state,action)=>{
      state.blog=action.payload;
    },
    clearBlog:(state,action)=>{
      state.blog=[];
    },

    addFeature:(state,action)=>{
      state.features=action.payload;
    },
    clearFeature:(state,action)=>{
      state.features=[];
    },
   
  },
});

export const {

  addBlog,
  clearBlog,
  addFeature,
  clearFeature
} = blogSlice.actions;

export default blogSlice.reducer;
