import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ResearchpalPapers from "./ResearchpalPapers";
import SemanticPapers from "./SemanticPapers";
import { setTabValue } from "./paperSlice";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#14224B",
  },
  justifyContent: "center",
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#14224B",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&.Mui-selected": {
      color: "#14224B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SearchPapers() {
  // const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const value = useSelector((state) => state.papers.tabValue);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const handleChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
  };

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Box sx={{ bgcolor: "#fff" }}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          centered
          variant="fullWidth"
        >
          <AntTab label="Semantic Search" {...a11yProps(0)} />
          <AntTab label="ResearchPal Search" {...a11yProps(1)} />
        </AntTabs>
        <CustomTabPanel value={value} index={0}>
          <SemanticPapers />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ResearchpalPapers />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default SearchPapers;
