import React from "react";
import { useState } from "react";
import { updateUser } from "../../apiservice";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../../components/Toast";
import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import { InputAdornment, OutlinedInput, Stack, styled } from "@mui/material";
import { Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TailSpinLoader from "../../components/TailSpinLoader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const schema = yup
  .object({
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "14px",
  color: "#353535",
});

function UpdatePassword({ open, onClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const location = useLocation();
  let email = "";
  let found = localStorage.getItem("email");
  if (found) {
    email = found;
  } else {
    const searchParams = new URLSearchParams(location.search);
    email = searchParams.get("email");
  }

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShow = () => setShowPassword((show) => !show);

  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await updateUser({ email, password: data.password });
      setLoading(false);
      Toast("success", "Password updated successfully");
      onClose();
      if (location.pathname == "/reset-password") {
        navigate("/", { state: undefined });
      }
    } catch (err) {
      Toast("error", "Error");
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2}>
          <Text sx={{ textAlign: "center", fontWeight: 600, fontSize: "18px" }}>
            Update Password
          </Text>
          <Stack>
            <Text sx={{ fontWeight: 600 }}>Password</Text>
            <OutlinedInput
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShow}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="********"
              {...register("password")}
            />
            <Typography sx={{ color: "red" }}>
              {errors.password?.message}
            </Typography>
          </Stack>
          <Stack>
            <Text sx={{ fontWeight: 600 }}>Confirm Password</Text>
            <OutlinedInput
              fullWidth
              size="small"
              sx={{
                background: "#F0F0F0",
                border: "none",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShow}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="********"
              {...register("confirmPassword")}
            />
            <Typography sx={{ color: "red" }}>
              {errors.confirmPassword?.message}
            </Typography>
          </Stack>
          {loading ? (
            <Box sx={{ height: "50px" }}>
              <TailSpinLoader />
            </Box>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              height="50px"
            >
              <Button
                variant="contained"
                sx={{ textTransform: "none", background: "#353535 !important" }}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePassword;
