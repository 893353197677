// import Repo from "../../../repo/Repo";

// export const singUp = async (email, password, domain, userType) => {
//   let { data } = await Repo.user({
//     request: {
//       method: "signupUser",
//       data: {
//         email: email,
//         password: password,
//         domain: domain,
//         userType: userType,
//       },
//     },
//   });
//   return data;
// };

import Repo from "../../../repo/Repo";

export const singUp = async (email, password, domain, userType) => {
  // Check if `isTolt` is true in localStorage
  const affiliateReferral =
    localStorage.getItem("affiliateReferral") === "true";

  // Construct the payload dynamically
  const payload = {
    email: email,
    password: password,
    domain: domain,
    userType: userType,
    ...(affiliateReferral && { affiliateReferral: true }), // Add `affiliateReferral` only if `isTolt` is true
  };

  let { data } = await Repo.user({
    request: {
      method: "signupUser",
      data: payload,
    },
  });
  return data;
};
