import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import LandingHeader from "../../../layout/header";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";

import HeroSection from "./HeroSection";
import TrustedBySection from "./TrustedBySection";
import PlatformSection from "./PlatformSection";
import ModalsSection from "./ModalsSection";
import EditorSection from "./EditorSection";
import AcedmicsSection from "./AcedmicsSection";
import Footer from "../../../layout/footer";
import ContactUsSection from "./ContactUsSection";
import hero_bg from "../../../assets/images/NewImages/hero_bg.png";
import { ArrowUpward } from "@mui/icons-material";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "10px 202px 100px 202px",
  // marginBottom: "10vh",
  backgroundColor: "#fff",
  // marginInline: "10px",
  marginTop: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 10px 84px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "24px 10px 89px 10px",
  },
}));

const CardGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#353535",
  textAlign: "center",
  marginBottom: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const Universities = () => {
  const location = useLocation();
  const [showScroll, setShowScroll] = useState(false);

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  const handleScroll = (name) => {
    console.log("name:", name);
    scroller.scrollTo([name], {
      duration: 2000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
        position: "relative",
        padding: "0px !important",
        margin: "0px !important",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "ResearchPal",
            "url": "https://researchpal.co/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://researchpal.co/universities{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
      </Helmet>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>

      <Box sx={{ px: { xs: 3, sm: 0 } }}>
        <HeroSection />
      </Box>

      <TrustedBySection />
      <RootStyle>
        <Box sx={{ px: { xs: 3, sm: 0 } }}>
          <PlatformSection />
        </Box>

        <Box sx={{ px: { xs: 3, sm: 0 } }}>
          <ModalsSection />
        </Box>

        <Box sx={{ px: { xs: 2.5, sm: 0 } }}>
          <EditorSection />
        </Box>

        <Box sx={{ px: { xs: 2.5, sm: 0 } }}>
          <AcedmicsSection />
        </Box>

        {showScroll && (
          <Button
            onClick={scrollTop}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",
              bottom: "40px",
              right: "40px",
              zIndex: 1000,
              // backgroundColor: "#353535",
              background:
                "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%)",
              color: "#fff",
              // "&:hover": {
              //   backgroundColor: "#5f5f5f",
              // },
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(48, 70, 174, 1) 0%, rgba(20, 181, 153, 1) 100%) !important",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              },
            }}
          >
            <ArrowUpward />
          </Button>
        )}
      </RootStyle>
      <Footer />

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </Box>
  );
};

export default Universities;
