import React from "react";
import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import BetaLogo from "../../assets/images/BetaLogo.svg";
import MaintenanceImg from "../../assets/images/maintenance.svg";
import { CenteredBox } from "../styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../Admin/userSlice";
import { clearProject } from "../projects/projectSlice";
import { clearAllInsights } from "../paper insights/insightsSlice";
import { clearAllLitReview } from "../literature review/reviewSlice";

const Text = styled(Typography)({
  color: "#323232",
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "24",
});

function MaintenancePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  return (
    <Box sx={{ height: "100vh", p: "5%" }}>
      <Grid
        container
        spacing="48px"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid item md={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "40px",
            }}
          >
            {/* <img style={{ width: "70%" }} src={BetaLogo} alt="" /> */}
            <img style={{ maxHeight: "500px" }} src={MaintenanceImg} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Stack spacing="24px">
            <Text
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Maintenance Mode
            </Text>
            <Grid container>
              <Grid item xs={0.2}>
                <Box
                  sx={{ width: "1px", background: "#323232", height: "100%" }}
                ></Box>
              </Grid>
              <Grid item xs={11.8}>
                <Stack spacing="24px">
                  <Text>
                    We are currently in scheduled maintenance and are improving
                    our features. Site will be operational really soon.
                  </Text>
                  <Text>Thank you for your patience !</Text>
                  <Text sx={{ fontSize: "18" }}>
                    Estimated restore time : 03:00 - 06:00 Hours
                  </Text>
                </Stack>
              </Grid>
            </Grid>

            <CenteredBox>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#323232",
                  color: "#fff",
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </CenteredBox>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MaintenancePage;
