import React from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useCallback } from "react";
import { Toolbar } from "../../../ui/Toolbar";
import Icon from "../../../ui/Icon";
import { Surface } from "../../../ui/Surface";
import { DropdownButton } from "../../../ui/Dropdown/Dropdown";

const lineHeight = [
  { label: "Small", value: "1.0" },
  { label: "Medium", value: "2" },
  { label: "Large", value: "2.5" },
  { label: "Extra Large", value: "3" },
];
const LineHeightPicker = ({ onChange, value }) => {
  const currentValue = lineHeight.find((size) => size.value === value);
  const currentSizeLabel = currentValue?.label.split(" ")[0] || "Medium";

  const selectSize = useCallback((size) => () => onChange(size), [onChange]);
  //q;why its giving error? on change is not a function
  //a: because the function is not defined
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button active={!!currentValue?.value}>
          {currentSizeLabel}
          <Icon name="ChevronDown" className="w-2 h-2" />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="flex flex-col gap-1 px-2 py-4">
          {lineHeight.map((size) => (
            <DropdownButton
              isActive={value === size.value}
              onClick={selectSize(size.value)}
              key={`${size.label}_${size.value}`}
            >
              <span style={{ fontSize: size.value }}>{size.label}</span>
            </DropdownButton>
          ))}
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default LineHeightPicker;
