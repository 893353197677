import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { getUser } from "../Admin/userSlice";
import { useNavigate } from "react-router-dom";

export default function Confirmation({ onClose, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    handleSubmitSession();
  }, []);

  const handleSubmitSession = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.subscribe({
        request: {
          method: "createPortalSession",
          data: {
            sessionId: id,
            email: localStorage.getItem("email"),
          },
        },
      });

      if (data.response.data.sessionUrl) {
        setLoading(false);
        dispatch(getUser());
        navigate("/subscription");
        Toast("success", "Plan Subscribed Successfully");
      } else {
        setLoading(false);
        Toast("error", data.response.status.statusMessage);
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };
  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };
  return (
    <Dialog open={true} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          {loading && <Typography>Please Wait</Typography>}

          <input type="hidden" id="session-id" name="session_id" value={id} />
          {loading && (
            <TailSpin height="37" width="37" color="#5AB7BF" visible={true} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
