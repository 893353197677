import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { getFeedback } from "../../apiservice";
import Modal from "../../utils/Modal";

const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      background: "transparent",
    },
  },
  header: {
    style: {
      // borderTopRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#01A35F",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "20px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#01A35F",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      display: "flex",
      justifyContent: "center",
      minHeight: "40px",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

const Feedback = () => {
  const columns = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Logs",
      selector: (row) => (
        <button
          className={`rounded text-white font-medium p-2 ${"bg-green-400 hover:bg-green-500 "}`}
          onClick={() => open(row)}>
          View logs
        </button>
      ),
    },
  ];
  const modalColumns = [
    {
      name: "Date",
      selector: (row, idx) => row.date,
    },
    {
      name: "Rating",
      selector: (row, idx) => row.rating,
    },
    {
      name: "Feedback",
      selector: (row, idx) => row.message,
      width: 900,
    },
  ];
  const [userData, setUserData] = useState([]);
  const [state, setState] = useState({
    modalOpen: false,
    modalData: [],
    modalName: "",
  });
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = async () => {
    let data = {
      email: localStorage.getItem("userEmail"),
    };
    let userData = await getFeedback(data);
    setUserData(userData.feedbacksArr);
  };
  console.log(userData);
  const open = (data) => {
    setState((prev) => ({
      ...prev,
      modalOpen: !prev.modalOpen,
      modalData: data.feedbacks.reverse(),
      modalName: data.modal,
    }));
  };
  const close = () => {
    setState((prev) => ({
      ...prev,
      modalOpen: !prev.modalOpen,
    }));
  };
  return (
    <div className="h-screen bg-gray-100 p-3">
      <div className="mb-5">
        <div className=" grid place-items-center text-center">
          <h1 className="mt-3 font-medium text-lg">Feedback</h1>{" "}
        </div>
        <Link to={"/admin/verify-users"}>
          <button
            className="absolute top-5  right-10 m-auto h-fit px-4 py-1 hover:bg-blue-600 text-white 
        btn-bg transition duration-150 ease-in-out rounded-md text-rose hover:text-white">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </Link>
      </div>
      <DataTable
        columns={columns}
        data={userData}
        customStyles={customStyles}
      />
      <Modal
        isOpen={state.modalOpen}
        onClose={() => {
          close();
        }}
        xl
        title={state.modalName}
        titleCenter
        content={
          <div className="w-fit-content">
            <DataTable
              columns={modalColumns}
              data={state.modalData}
              customStyles={customStyles}
            />
          </div>
        }
      />
    </div>
  );
};

export default Feedback;
