import React, { useState } from "react";
import AlertIcon from "../../assets/images/delete alert.svg";
import { Button } from "@mui/material";
import { FormControlLabel, Stack, Typography, styled } from "@mui/material";
import {
  Dialog,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "24px",
  fontWeight: 700,
  color: "#353535",
});
const SubTitle = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  color: "#353535CC",
});

export default function ConfirmationDialogAbstracts({
  text,
  onClose,
  onSuccess,
  refs,
}) {
  const [selected, setSelected] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const handleConfirm = () => {
    // Pass checkbox state as part of refRemove
    onSuccess(isChecked ? refs : []);
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      if (text.includes("library")) {
        let obj = {};
        let arr = [];
        refs.forEach((item) => {
          if (item.citations != "-") {
            obj[item.citations] = item.citations;
            arr.push(item.citations);
          }
        });
        setSelected(arr);
        setSelectedKeys(obj);
      } else {
        let obj = {};
        for (let i = 0; i < refs.length; i++) {
          obj[refs[i].title] = refs[i].title;
        }
        setSelected(refs);
        setSelectedKeys(obj);
      }
    } else {
      setSelected([]);
      setSelectedKeys({});
    }
  };
  const handleSelect = (val) => {
    if (selectedKeys[val]) {
      let filtered = selected.filter((item) => item != val);
      let copy = JSON.parse(JSON.stringify(selectedKeys));
      delete copy[val];
      setSelected(filtered);
      setSelectedKeys(copy);
    } else {
      let copy = JSON.parse(JSON.stringify(selectedKeys));
      copy[val] = val;
      setSelected((prev) => [...prev, val]);
      setSelectedKeys(copy);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
      <DialogContent>
        <Stack
          spacing={2.4}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <img height="64px" width="64px" src={AlertIcon} alt="" />
          <Title>Are you sure ?</Title>
          <SubTitle>{text}</SubTitle>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
            }
            label="Delete associated Reference(s)"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          onClick={onClose}
          sx={{
            textTransform: "none",
            color: "#353535",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            textTransform: "none",
            // background: "#353535 !important",
            background: "#1E4F6B !important",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 600,
          }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
