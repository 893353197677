import {
  Box,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import BadgeIcon from "../../../assets/images/NewImages/badgeIcon.svg";
import westUni from "../../../assets/images/westUni.png";
import uclUni from "../../../assets/images/uclUni.png";
import yaleUni from "../../../assets/images/yaleUni.png";
import LSEUni from "../../../assets/images/LSEUni.png";
import harvardUni from "../../../assets/images/harvardUni.png";

const TrustedBySection = () => {
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const CustomGrid = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    border: isSmallScreen && "1px solid #f8f8f8",
    margin: isSmallScreen && "2px",
  });
  return (
    <Box
      sx={{
        // marginTop: "100px",
        // mt: 6,
        backgroundColor: "transparent",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Stack
        sx={{
          mb: 7.6,
          // paddingInline: "10px",
          marginTop: isSmallScreen ? "10px" : "0px",
        }}
        direction={isSmallScreen ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          <Box>
            <img
              src={BadgeIcon}
              alt=""
              style={{
                opacity: 0.8,
              }}
            />
          </Box>
          <Typography
            // textAlign={"center"}
            textAlign={isSmallScreen && "center"}
            sx={{
              fontSize: "20px",
              fontFamily: "Raleway",
              fontWeight: 700,
              color: "#15A68F",
              mx: "8px",
            }}
          >
            Trusted By 10,000 + {!isSmallScreen && "|"}
          </Typography>
        </Stack>
        <Typography
          sx={{
            // fontSize: "15px",
            fontSize: { xs: "16px", md: "20px" },
            fontFamily: "Raleway",
            fontWeight: 500,
            color: "#14224B",
            mt: { xs: 2, sm: 0 },
            // width: "60%",
          }}
          textAlign={isSmallScreen && "center"}
        >
          Researchers and students at Universities like Harvard, Yale, UCL,
          Warwick and LSE
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <CustomGrid
          item
          xs={12}
          md={2.4}
          sx={{
            width: "60px !important",
            height: "60px !important",
          }}
        >
          <img
            src={westUni}
            alt="Logo of University of Warwick"
            style={{
              opacity: 0.5,
            }}
          />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={2.4}
          sx={{
            width: "60px !important",
            height: "60px !important",
          }}
        >
          <img
            src={LSEUni}
            alt="Logo of London School of Economics and  Political Science"
            style={{
              opacity: 0.5,
            }}
          />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={2.4}
          sx={{
            width: "60px !important",
            height: "60px !important",
          }}
        >
          <img
            src={yaleUni}
            alt="Logo of Yale"
            style={{
              opacity: 0.5,
            }}
          />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={2.4}
          sx={{
            width: "60px !important",
            height: "60px !important",
          }}
        >
          <img
            src={harvardUni}
            alt="Logo of Harvard University"
            style={{
              opacity: 0.5,
            }}
          />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={2.4}
          sx={{
            width: "60px !important",
            height: "60px !important",
          }}
        >
          <img
            src={uclUni}
            alt="Logo of University College London"
            style={{
              opacity: 0.5,
            }}
          />
        </CustomGrid>
      </Grid>
    </Box>
  );
};

export default TrustedBySection;
