import React from "react";
import { CameraFront, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Grid, IconButton, Stack } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import AskFiles from "../literature review/AskFiles";

const ImageDialogue = ({ handleClose, open, imageUrl }) => {
  console.log("imageUrl:", imageUrl);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        padding: "0px !important",
      }}
    >
      <DialogTitle
        fontSize={12}
        fontWeight={600}
        fontFamily={"Raleway"}
        id="customized-dialog-title"
      >
        Image File
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        {
          //show image

          imageUrl ? (
            <img src={imageUrl} alt="img" width="100%" height="100%" />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "40vh",
                width: "auto",
              }}
            >
              <CameraFront sx={{ fontSize: "40px", color: "#4E4E4E" }} />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#4E4E4E",
                  my: 1,
                }}
              >
                No Image Found
              </Typography>
            </Box>
          )
        }
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialogue;
