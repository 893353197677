import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Repo from "../../repo/Repo";

const initialState = {
  loading: false,
  error: "",
  references: "",
  refFormats: "",
};

export const getRefs = createAsyncThunk("refs/fetchRefs", (arg) => {
  return Repo.research({
    request: {
      method: "getResearchReferences",
      data: {
        researchProjectId: arg,
      },
    },
  })
    .then(({ data }) => data.response.data)
    .catch((err) => console.log(err));
});

export const refsSlice = createSlice({
  name: "Refs",
  initialState,
  reducers: {
    clearRefs: (state) => {
      state.references = "";
      state.refFormats = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRefs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRefs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
      }
      if (action.payload.references) {
        state.references = Object.values(action.payload.references);
      }
      if (action.payload.referenceFormats) {
        if (isEmptyObject(action.payload.referenceFormats)) {
          state.refFormats = "";
        } else {
          state.refFormats = action.payload.referenceFormats;
        }
      }
    });
    builder.addCase(getRefs.rejected, (state, action) => {
      state.loading = false;
      state.references = "";
      state.refFormats = "";
      state.error = action.error.message;
    });
  },
});

export const { clearRefs } = refsSlice.actions;

export default refsSlice.reducer;
