import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/material";

function BtnClear({ onClick }) {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        background: "#F2F2F2 !important",
        textTransform: "none",
        fontFamily: "Raleway",
        fontSize: "12px",
        fontWeight: 500,
        color: "#868686",
        boxShadow: "none !important",
      }}
      onClick={onClick}
    >
      <HighlightOffIcon fontSize="small" sx={{ mr: 1 }} />
      Clear All
    </Button>
  );
}

export default BtnClear;
