import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  progress: 0,
  interval: 500,
  zoteroLoading: false,
  mendelyLoading: false,
  paperLoading: false,
};

export const loaderSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    addProgress: (state, action) => {
      if (state.progress < 100) {
        state.progress += 1;
      } else {
        state.progress = 1;
      }
    },
    clrProgress: (state) => {
      state.progress = 0;
    },
    setZoteroLoading: (state, action) => {
      state.zoteroLoading = action.payload;
    },
    handlePaperLoading: (state, action) => {
      state.paperLoading = action.payload;
    },
    setMendelyLoading: (state, action) => {
      state.mendelyLoading = action.payload;
    },
  },
});

export const {
  addProgress,
  clrProgress,
  setZoteroLoading,
  setMendelyLoading,
  handlePaperLoading,
} = loaderSlice.actions;

export default loaderSlice.reducer;
