import React from "react";
import {
  Box,
  Stack,
  Divider,
  Typography,
  styled,
  Tooltip,
} from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AddIcon from "@mui/icons-material/Add";
import Check from "@mui/icons-material/Check";
import LanguageIcon from "@mui/icons-material/Language";
import { BtnSave, Title } from "./styles";
import { TailSpin } from "react-loader-spinner";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "12px",
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});

const SemanticItemBox = ({
  item,
  handleItemClick,
  truncateText,
  savePaper,
  saveLoading,
  selectedDoi,
  semanticSaveStatus,
}) => {
  const key = item.doi && item.doi !== "None" ? item.doi : item.title;
  const isDoiNone = item.doi === "None";
  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        mr: 1,
        background: "rgba(246, 246, 246, 0.7)",
        border: "1.5px solid #D4D4D4",
        my: 2,
        cursor: "pointer",
        borderRadius: "10px",
      }}
    >
      <Box onClick={() => handleItemClick(item)}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1} direction="row" alignItems="center">
            <DescriptionOutlinedIcon
              sx={{ height: "20px", color: "#353535" }}
            />
            <Title sx={{ cursor: "pointer", fontSize: "14px" }}>
              {item.title}
            </Title>
          </Stack>
        </Stack>
        <Box sx={{ my: 2, background: "#EDEDED", borderRadius: "10px" }}>
          {" "}
          <Box sx={{ p: 2 }}>
            <Title
              sx={{
                cursor: "pointer",
                fontSize: { xs: "10px", md: "12px" },
              }}
            >
              {item?.tldr && item.tldr !== "None"
                ? item.tldr
                : truncateText(item.abstracts, 250) || item.abstracts}
            </Title>
          </Box>{" "}
        </Box>

        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          mt={{ xs: 0.75, md: 2 }}
          sx={{ px: 1, pb: 2 }}
        >
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent={{ xs: "center", sm: "normal" }}
            >
              <Box display={{ xs: "none", md: "flex" }} alignItems="center">
                <Text
                  sx={{
                    mb: { xs: 1, md: 0 },
                    maxWidth: { md: 200, lg: "lg" },
                  }}
                >
                  {item.journal ? truncateText(item.journal, 20) : "N/A"}
                </Text>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    mx: { xs: 1, sm: 1.5 },
                    mt: { xs: 1.5, md: 1 },
                    bgcolor: "grey.200",
                    height: { xs: 24, md: 18 },
                  }}
                />
              </Box>
              <Text sx={{ mr: { xs: 1, sm: 1.5 } }}>
                {item.author ? truncateText(item.author, 20) : "N/A"}
              </Text>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mr: { xs: 1, sm: 1.5 },
                  mt: { xs: 1.5, md: 1 },
                  bgcolor: "grey.200",
                  height: { xs: 24, md: 18 },
                }}
              />
              <Text
                sx={{
                  mr: { xs: 1, sm: 1.5 },
                  textTransform: "lowercase",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="span" style={{ color: "black" }}>
                  {item.citationCount}
                </Typography>{" "}
                Citations
              </Text>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mr: { xs: 1, sm: 1.5 },
                  mt: { xs: 1.5, md: 1 },
                  bgcolor: "grey.200",
                  height: { xs: 24, md: 18 },
                }}
              />
              <Text
                sx={{
                  mr: { xs: 1, sm: 1.5 },
                  textTransform: "lowercase",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="span" style={{ color: "black" }}>
                  {item.referenceCount}
                </Typography>{" "}
                References
              </Text>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mr: { xs: 1, sm: 1.5 },
                  mt: { xs: 1.5, md: 1 },
                  bgcolor: "grey.200",
                  height: { xs: 24, md: 18 },
                }}
              />
              <Text>{item.year}</Text>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              gap={{ xs: 0.75, sm: 1.5 }}
              flexWrap="wrap"
            ></Box>
          </Box>
          <Box width={24} />
        </Box>
      </Box>
      <Divider />

      <Box sx={{ pt: 2, px: 1 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          spacing={{ xs: "12px", md: "0px" }}
          alignItems="center"
        >
          <Stack spacing={{ xs: 0.3, sm: 0.8 }} direction="row">
            <Title>DOI : </Title>
            <Title sx={{ color: "#35353599" }}>{item.doi}</Title>
          </Stack>

          <Stack direction="row" spacing="16px">
            <Tooltip
              title={
                isDoiNone
                  ? "It seems like a review paper. Hence can't be saved"
                  : ""
              }
              arrow
              disableHoverListener={!isDoiNone}
            >
              <Box>
                <BtnSave
                  sx={{
                    width: "80px",
                    backgroundColor: semanticSaveStatus[key]
                      ? "#e2e2e2 !important"
                      : "initial", // Change color if item is saved
                    "&:hover": {
                      backgroundColor: semanticSaveStatus[key]
                        ? "#e2e2e2 !important"
                        : "initial", // Optional: change hover color for saved items
                    },
                    color: semanticSaveStatus[key]
                      ? "green !important"
                      : "#353535CC",
                  }}
                  disabled={isDoiNone || semanticSaveStatus[key]}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    savePaper(item);
                  }}
                >
                  {saveLoading[key] && selectedDoi[key] && (
                    <Stack direction="row" width="100%" justifyContent="center">
                      <TailSpin
                        height="15"
                        width="15"
                        color="#353535"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </Stack>
                  )}
                  {/* {(saveLoading[item.doi || item?.title] ||
                !saveLoading[item.doi || item?.title]) &&
                !selectedDoi[item.doi || item?.title] &&
                (semanticSaveStatus[item.doi || item?.title] ? ( */}
                  {!saveLoading[key] &&
                    !selectedDoi[key] &&
                    (semanticSaveStatus[key] ? (
                      <Check
                        size="small"
                        sx={{
                          fontSize: "14px",
                          mr: 1,
                          color: semanticSaveStatus[key]
                            ? "green"
                            : "#353535CC",
                        }}
                      />
                    ) : (
                      <AddIcon
                        fontSize="small"
                        sx={{ fontSize: "12px", mr: 1 }}
                      />
                    ))}
                  {!selectedDoi[key] && !semanticSaveStatus[key]
                    ? "Save"
                    : "Saved"}
                </BtnSave>
              </Box>
            </Tooltip>

            <a href={item.url} target="_blank" rel="noreferrer">
              <BtnSave variant="contained" size="small" sx={{ mt: -0.2 }}>
                <LanguageIcon sx={{ fontSize: "12px", mr: 1 }} /> Source
              </BtnSave>
            </a>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SemanticItemBox;
