import {
  Close,
  ExpandMore,
  Folder,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Toast from "../../components/Toast";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import {
  getDocumentData,
  saveEmbed,
  saveMultipleFiles,
  sendErrorMail,
} from "../../apiservice";
import { setZoteroLoading } from "../loader/LoaderSlice";
import { baseDomain } from "../../repo/Repository";
import { clearRefs } from "../references/refsSlice";

const SubFolderAccordian = ({
  data,
  zoteroDialogClose,
  open,
  getProject,
  onClose,
}) => {
  console.log("hexumburg:", data);
  const [state, setState] = React.useState({
    searchData: [],
    name: "",
    openAccordion: null,
  });
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch();
  const [s3Files, setS3Files] = useState([]);

  const handleAccordionChange = (panel) => {
    setState((prev) => ({
      ...prev,
      openAccordion: panel === state.openAccordion ? null : panel,
    }));
  };
  const getProjects = async () => {
    let newInfo = {
      email: localStorage?.getItem("email"),
      title: localStorage?.getItem("projectTitle"),
    };
    let res = await getDocumentData(newInfo);

    let found = {};
    found = res.data.researchProjects;
    // if (res) {
    //   if (found) {
    //     setS3Files(found.s3Files);
    //   }
    // }

    return found?.s3Files;
  };
  const searchFiles = async (key, file) => {
    let id = localStorage.getItem("userId");
    let zotKey = localStorage.getItem("zoteroKey");
    setState((prev) => ({ ...prev, searchData: [], name: file }));
    let s3 = await getProjects();
    if (key) {
      try {
        axios
          .get(`https://api.zotero.org/users/${id}/collections/${key}/items`, {
            headers: {
              "Zotero-API-Key": zotKey,
            },
          })
          .then((response) => {
            console.log("response:", response.data);
            if (response.status === 200) {
              let filtered = response.data.filter((item) => {
                const email = localStorage.getItem("email");
                const title = item.data.title;
                const pattern = new RegExp(`${email}/[0-9]${title}`);
                return s3.some((file) => pattern.test(file));
              });

              filtered.forEach((object) => {
                object.alreadyAdded = true;
              });
              filtered.map((obj) => {
                response.data.map((item) => {
                  if (obj.key === item.key) {
                    item = { ...obj, ...item };
                  }
                  return item;
                });
                return obj;
              });
              setState((prev) => ({
                ...prev,
                searchData: response.data,
                loading: false,
              }));
              // setState((prev) => ({
              //   ...prev,
              //   searchData: response.data,
              // }));
            } else {
              console.error("Error:", response.status, response.statusText);
            }
          })
          .catch((error) => {
            console.error("API Request Error:", error);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      Toast("error", "NO DATA FOUND");
    }
  };

  const handleToggle = (value) => () => {
    setChecked((prevChecked) => {
      const currentIndex = prevChecked.indexOf(value);
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });
  };
  const selectAll = (e, data) => {
    const { checked } = e.target;
    if (checked) {
      setChecked(data);
    } else {
      setChecked([]);
    }
  };

  const saveMultipleFile = async () => {
    setState((prev) => ({ ...prev, btn: true }));

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: "Zotero File Upload",
        error: "20 seconds",
        query: "",
        delayInResponse: true,
      });
    }, 20000);

    let obj = {};
    checked.forEach((item) => {
      if (item?.links?.enclosure) {
        let title = item.data.title;
        if (title) {
          obj[title] = item.links?.enclosure?.href;
        }
      } else if (item?.links?.attachment) {
        let title =
          item?.links?.attachment.attachmentType === "application/pdf"
            ? `${item.data.title}.pdf`
            : item.data.title;
        if (title) {
          obj[title] = `${item.links?.attachment?.href}/file/view`;
        }
      } else if (item?.links?.self) {
        let title = `${item.data.title}`;
        if (title) {
          obj[title] = `${item.links?.self?.href}`;
        }
      }
    });
    let datas = {
      email: localStorage.getItem("email"),
      app: "zotero",
      projectId: localStorage.getItem("projectId"),
      urls: obj,
    };

    if (Object.keys(obj).length !== 0) {
      let res = await saveMultipleFiles(datas);
      clearTimeout(timeoutId);
      if (res.error) {
        Toast("error", res.error);
        setState((prev) => ({ ...prev, btn: false }));
        if (
          res.error ===
          "Upload papers limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Zotero File Upload",
            error: res.error,
            query: "",
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Zotero File Upload",
            error: res.error,
            query: "",
          });
        }
        return;
      }
      if (res.fileError || res.filesSaved) {
        if (res.fileError) {
          Toast("warning", res.fileError);
          if (res.fileError.includes("Upload papers limit reached")) {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Zotero File Upload",
              error: res.fileError,
              query: "",
              limitReachedError: true,
            });
          } else {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Zotero File Upload",
              error: res.fileError,
              query: "",
            });
          }
        }
        if (res.filesSaved.length > 0) {
          const saveEmbedInfo = {
            saveEmbeddings: true,
            email: localStorage.getItem("email"),
            title: localStorage.getItem("projectTitle"),
            s3KeysArr: res.filesSaved,
          };
          saveEmbed(saveEmbedInfo);
          saveRef(res.filesSaved);
          setState((prev) => ({ ...prev, btn: false }));
          Toast(
            "info",
            "Please wait while we add relevant reference for this file in references section"
          );
          getProject();
          zoteroDialogClose();
        } else {
          Toast("error", "No saved files found");
        }
      }
    } else {
      Toast("info", "No urls found");
    }
  };

  const saveRef = async (arr) => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      dispatch(setZoteroLoading(true));
      let obj = {
        getCitationsAgainstMultiplePapers: true,
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        s3KeysArr: arr,
      };
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(obj),
        redirect: "follow",
      };
      fetch(`${baseDomain}/script`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          let parsed = JSON.parse(result);
          dispatch(setZoteroLoading(false));
          if (parsed?.data?.sourceObj?.error) {
            Toast("error", parsed.data.sourceObj.error);
          }
          const reference = parsed?.data?.sourceObj.responseArr.map((item) => {
            return item;
          });
          //convert reference to object from array
          const refObj = {};
          reference.forEach((item) => {
            refObj[`${item.title}`] = item;
          });
          dispatch(clearRefs());

          if (parsed?.data?.sourceObj.responseArr) {
            dispatch(clearRefs());
            getProject();
            setState((prev) => ({ ...prev, loading: false }));
            // dispatch(setZoteroLoading(false));
          }
          getProject();

          setState((prev) => ({ ...prev, loading: false }));
        });
    } catch (err) {
      console.log("err:", err);
      Toast("error", err);
    }
  };
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle
        sx={{
          fontFamily: "Raleway",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Sub Folders
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <div>
          {data &&
            data.map((item, index) => (
              <Accordion
                sx={{ m: 2 }}
                expanded={state.openAccordion === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary
                  onClick={() => searchFiles(item.key, item.name)}
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {index + 1} -{" "}
                    <Folder sx={{ color: "#D32f2f", mr: 2, ml: 1 }} />
                    {item.data.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    {state.searchData && state.searchData.length !== 0 && (
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              disableRipple
                              onChange={(e) => selectAll(e, state.searchData)}
                            />
                          </ListItemIcon>

                          <ListItemText primary={`Select All`} />
                        </ListItemButton>
                      </ListItem>
                    )}
                    {state.searchData && state.searchData.length !== 0 ? (
                      state.searchData.map((value, id) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;

                        return (
                          state.name === item.name && (
                            <ListItem key={value} disablePadding>
                              {`${id + 1}.`}
                              <ListItemButton disabled={value.alreadyAdded}>
                                <ListItemIcon onClick={handleToggle(value)}>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        color: "#353535",
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemAvatar>
                                  <InsertDriveFile />
                                </ListItemAvatar>
                                <ListItemText
                                  disabled={value.alreadyAdded}
                                  id={labelId}
                                  //split the title name ... if it is too long
                                  primary={
                                    value.data.title?.length > 30
                                      ? `${value.data.title.substring(
                                          0,
                                          30
                                        )}...`
                                      : value.data.title
                                  }
                                />
                                {value.alreadyAdded && (
                                  <Chip
                                    label="Uploaded"
                                    color="success"
                                    sx={{
                                      height: "20px",
                                      borderRadius: "5px !important",
                                    }}
                                  />
                                )}
                              </ListItemButton>
                            </ListItem>
                          )
                        );
                      })
                    ) : (
                      <CircularProgress
                        size={20}
                        style={{ marginLeft: "50%" }}
                      />
                    )}
                  </>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        {checked &&
          checked.length !== 0 &&
          (state.btn ? (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#FFFFFF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"14px"} sx={{ color: "#FFFFFF" }}>
                Uploading ...
              </Typography>
            </Stack>
          ) : (
            <Button
              variant="contained"
              disabled={state.btn}
              sx={{
                textTransform: "capitalize",
                color: state.btn ? "#FFFFFF" : "#FFFFFF",
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
              }}
              onClick={saveMultipleFile}
            >
              Import
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default SubFolderAccordian;
