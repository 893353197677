import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

const RootStyle = styled(Box)({
  background: "#FFFFFF",
  minHeight: "67px",
  padding: "0 5% ",
  display: "flex",
  alignItems: "center",
});

function AdminFooter() {
  return (
    <RootStyle>
      <Stack
        width="100%"
        alignItems={{ xs: "center", sm: "none" }}
        direction={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontFamily: "Raleway",
              color: "#353535",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {new Date().getFullYear()}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{ color: "#353535", fontSize: "14px", fontWeight: 600 }}
          >
            Contact
          </Typography>
          <Typography
            sx={{ color: "#353535", fontSize: "14px", fontWeight: 500 }}
          >
            :
          </Typography>
          <Typography
            sx={{ color: "#353535", fontSize: "14px", fontWeight: 500 }}
          >
            Info@ResearchPal.Co
          </Typography>
        </Stack>
      </Stack>
    </RootStyle>
  );
}

export default AdminFooter;
