import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { CustomBox } from "./style";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { clearUser } from "../Admin/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TailSpinLoader from "../../components/TailSpinLoader";

const UsageDialogue = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const standardBillingPeriod =
    user.subscription?.price === 99 ? "yearly" : "monthly";
  const proBillingPeriod =
    user.subscription?.price === 359.88 ? "yearly" : "monthly";
  const cancelSubscription = user.cancelSubscription === true ? true : false;

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await Repo.user({
        request: {
          method: "getUser",
          data: {
            _id: localStorage.getItem("email"),
            token: localStorage.getItem("token") || "",
          },
        },
      });
      if (data.response.status.statusCode === 200) {
        setUser(data.response.data.userObj);
        setLoading(false);
      } else {
        handleErrorResponse(data);
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  const handleErrorResponse = (data) => {
    const error = data.response?.data?.error;
    if (error === "Invalid Token" || error === "Token is empty!") {
      Toast("info", "Session has expired. Please login again");
      localStorage.clear();
      dispatch(clearUser());
      navigate("/");
    } else {
      Toast("error", data.response.status.statusMessage);
    }
  };

  const renderLoading = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "30vh",
      }}
    >
      <TailSpinLoader />
    </Box>
  );

  const renderDetails = () => (
    <Grid container spacing={2}>
      {renderDetail("Projects", "max-projects")}
      {renderDetail("Document Upload", "upload-papers")}
      {renderDetail("Paper Insights", "paper-insights")}
      {renderDetail("Manual Reference", "manuel-references")}
      {renderDetail("Text Editor (Cite)", "cite")}
      {renderDetail("Text Editor (Ask ResearchPal)", "query-web")}
      {renderDetail("Tones", "tones")}
      {renderDetail("Translate", "translate")}
      {renderDetail("Text Tuner", "ai-tools")}
      {renderDetail("Library Chat", "chat")}
      {renderDetail("Literature Review (Web + Library)", "lit-review-web")}
    </Grid>
  );

  const renderDetail = (title, field) => {
    const value = user.subscription?.[field];
    const limits = getLimits(field);
    const planName = user.subscription?.planName;

    if (
      title === "Projects" &&
      (planName === "standard" || planName === "pro")
    ) {
      return (
        <Grid item md={4} key={title}>
          <Typography fontSize={"14px"} color="#14224B" fontWeight={500}>
            {title}
          </Typography>
          <CustomBox>Remaining: Unlimited</CustomBox>{" "}
          {/* Optionally show N/A or leave empty */}
        </Grid>
      );
    }

    let remainingLimit;
    if (
      planName === "free-plan" ||
      planName === "standard" ||
      planName === "pro"
    ) {
      remainingLimit =
        planName === "free-plan" &&
        (user.subscription?.price === 99 || user.subscription?.price === 9) &&
        cancelSubscription
          ? limits["standard"][field]
          : planName === "free-plan" &&
            (user.subscription?.price === 359.88 ||
              user.subscription?.price === 39.99) &&
            cancelSubscription
          ? limits["pro"][field]
          : limits[planName][field];
    } else {
      remainingLimit = "Unlimited";
    }

    return (
      <Grid item md={4} key={title}>
        <Typography fontSize={"14px"} color="#14224B" fontWeight={500}>
          {title}
        </Typography>
        {value === 999 && (
          <CustomBox>Remaining : {value === 999 && "Unlimited"}</CustomBox>
        )}
        {value !== 999 && (
          <CustomBox>
            Remaining : {value} / {remainingLimit}
          </CustomBox>
        )}
      </Grid>
    );
  };

  const getLimits = (field) => {
    return {
      [`free-plan`]: {
        "max-projects": 1,
        "upload-papers": 3,
        "paper-insights": 2,
        "manuel-references": 2,
        cite: 2,
        "query-web": 2,
        tones: 2,
        translate: 2,
        "ai-tools": 2,
        chat: 2,
        "lit-review-web": 2,
      },
      standard: {
        "max-projects": "Unlimited",
        "upload-papers": standardBillingPeriod === "yearly" ? 1800 : 150,
        "paper-insights": standardBillingPeriod === "yearly" ? 600 : 50,
        "manuel-references": standardBillingPeriod === "yearly" ? 600 : 50,
        cite: standardBillingPeriod === "yearly" ? 600 : 50,
        "query-web": standardBillingPeriod === "yearly" ? 600 : 50,
        tones: standardBillingPeriod === "yearly" ? 1200 : 100,
        translate: standardBillingPeriod === "yearly" ? 1200 : 100,
        "ai-tools": standardBillingPeriod === "yearly" ? 1200 : 100,
        chat: standardBillingPeriod === "yearly" ? 1200 : 100,
        "lit-review-web": standardBillingPeriod === "yearly" ? 600 : 50,
      },
      pro: {
        "max-projects": "Unlimited",
        "upload-papers": proBillingPeriod === "yearly" ? 1800 * 3 : 450,
        "paper-insights": proBillingPeriod === "yearly" ? 600 * 3 : 150,
        "manuel-references": proBillingPeriod === "yearly" ? 600 * 3 : 150,
        cite: proBillingPeriod === "yearly" ? 600 * 3 : 150,
        "query-web": proBillingPeriod === "yearly" ? 600 * 3 : 150,
        tones: proBillingPeriod === "yearly" ? 1200 * 3 : 150,
        translate: proBillingPeriod === "yearly" ? 1200 * 3 : 150,
        "ai-tools": proBillingPeriod === "yearly" ? 1200 * 3 : 300,
        chat: proBillingPeriod === "yearly" ? 1200 * 3 : 300,
        "lit-review-web": proBillingPeriod === "yearly" ? 600 * 3 : 150,
      },
    };
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="usage-dialogue-title"
      open={open}
      maxWidth="md"
      fullWidth
      sx={{
        padding: "0px !important",
        width: "100% !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        id="usage-dialogue-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "18px",
              color: "#14224B",
              textAlign: "center",
            }}
          >
            Usage Details
          </Typography>
          {!loading && (
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "12px",
                color: "#353535",
                textAlign: "center",
              }}
            >
              Subscription : {user.subscription?.planName || "free-plan"}
              {user.subscription?.planName === "standard" ? " plan" : ""} active
            </Typography>
          )}
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ padding: "16px" }}>
        {loading ? renderLoading() : renderDetails()}
      </DialogContent>
    </Dialog>
  );
};

export default UsageDialogue;
