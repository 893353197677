import { Box, Button, Typography, styled } from "@mui/material";

export const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 600,
  color: "#353535",
});
export const BtnSave = styled(Button)({
  width: "fit-content",
  border: "1px solid #353535CC",
  background: "transparent !important",
  textTransform: "none",
  color: "#353535CC",
  height: "25px",
});
export const RecomendationBtn = styled(Button)({
  width: "fit-content",
  // border: "1px solid #353535CC",
  textTransform: "none",
  color: "#353535CC",
  height: "25px",
});

export const RootStyle = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  minHeight: "100vh",
  padding: "16px 32px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  // boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
}));
export const ItemBox = styled(Box)({
  background: "#F9F9F9",
  padding: "20px 10px",
  width: "99%",
  minHeight: "150px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: "8px",
  "&:hover": {
    background: "#EFEFEF",
  },
});
