import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";

export const Bar = styled(Grid)({
  background: "#FFFFFF",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
  // borderRadius: "6px",
  height: "88.5vh",
  padding: "10px",
});
export const RightBar = styled(Stack)({
  background: "#FFFFFF",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
  // borderRadius: "6px",
  height: "85.5vh",
  padding: "10px",
});
export const Btn = styled(Button)({
  textTransform: "capitalize",
  fontSize: "14px",
  fontFamily: "Raleway",
  color: "rgba(53, 53, 53, 0.4)",
  ":hover": {
    background: "#FFF",
    color: "rgba(53, 53, 53, 0.6)",
  },

  ":active": {
    background: "#FFF",
    color: "rgba(53, 53, 53, 1)",
  },
});
export const Project = styled(Stack)({
  fontSize: "14px",
  // justifyContent: "center",
  // alignItems: "center",
  display: "flex",
  // marginTop: 60,
  color: "rgba(53, 53, 53, 0.4)",
});
export const Save = styled(Button)({
  color: "rgba(53, 53, 53, 0.4)",
  borderColor: "rgba(53, 53, 53, 0.4)",
  // width: "64px",
  textTransform: "capitalize",
  "&:hover": {
    borderColor: "rgba(53, 53, 53, 0.6)",
    background: "rgba(53, 53, 53, 0.6)",
    color: "#FFFFFF",
  },
});
export const Delete = styled(Button)({
  color: "#D85C5C",
  borderColor: "#D85C5C",
  // width: "64px",
  textTransform: "capitalize",
  "&:hover": {
    borderColor: "#D85C5C",
    background: "#D85C5C",
    color: "#FFFFFF",
  },
});
export const Cancel = styled(Button)({
  textTransform: "capitalize",
  fontSize: "14px",
  fontFamily: "Raleway",
  alignItems: "center",
  display: "flex",
  borderColor: "rgba(53, 53, 53, 0.4)",
  // marginTop: 5,
  color: "rgba(53, 53, 53, 0.4)",
  ":hover": {
    background: "rgba(53, 53, 53, 1)",
    color: "#FFF",
  },

  ":active": {
    background: "rgba(53, 53, 53, 1)",
    color: "#FFF",
  },
});
export const HeaderInfoBox = styled(Box)({
  background: "#F3F3F3",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  height: "40px",
  padding: "16px",
  columnGap: "48px",
  marginBottom: "8px",
});
export const RestoreBtn = styled(Button)({
  height: "26px",
  padding: "4px 12px 4px 12px",
  borderRadius: "4px",
  background: "#E3E3E3",
  textTransform: "capitalize",
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "10px",
  color: "#353535CC",
  ":hover": {
    background: "lightgrey",
    // color: "#353535CC",
  },
});
export const TitleDialogue = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "12px",
});
