import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";

export default function Alerts(open) {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/", {
      state: "login",
    });
  };
  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="p-5">
            To use VeraciousAI services you need to login first. Thanks
          </DialogContentText>
          <DialogActions sx={{ justifyContent: "flex-end" }}>
            <Button variant="contained" size="small" onClick={handleLogin}>
              Login
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
