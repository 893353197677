import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  insightLoading: false,
  error: "",
  isInsight: false,
  insightsProjects: [],
  selectedFiles: [],
  columnLoading: {},
  insightFormat: "short",

};

export const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    addInsightsProject: (state, action) => {
      let project = {
        title: action.payload,
        reviewPapers: [],
        selKeysNames: {},
        searchedKeys: [],
        selectedPapers: {},
      };
      let found = state.insightsProjects.find(
        (item) => item.title == action.payload
      );
      if (!found) {
        state.insightsProjects.push(project);
      }
    },
    setInsightLoading: (state) => {
      state.insightLoading = true;
    },
    clearInsightLoading: (state) => {
      state.insightLoading = false;
    },
    handleIsInsight: (state, action) => {
      state.isInsight = action.payload;
    },

    clearInsights: (state, action) => {
      let { title } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        found.selectedPapers = {};
        found.reviewPapers = [];
        found.searchedKeys = [];
      }
    },
    removeSelKeys: (state, action) => {
      const { title } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        if (found.reviewPapers.length > 0) {
          const updated = found.reviewPapers.filter((item, i) => {
            const regex = new RegExp(
              `.*${item.fileName.replace(".", "\\.")}.*`,
              "i"
            );
            let foundKey = found.selKeys.find((selKey) => regex.test(selKey));
            return !foundKey;
          });
          found.reviewPapers = updated;

          let filteredKeys = found.searchedKeys.filter(
            (key) => !found.selKeys.includes(key)
          );
          found.searchedKeys = filteredKeys;
        }
        found.selKeysNames = {};
      }
    },
    clearSelectedPapers: (state, action) => {
      const { title } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        const updated = found.reviewPapers.filter((item, i) => {
          let foundPaper = found.selectedPapers[i];
          if (foundPaper) {
            const regex = new RegExp(
              `.*${item.fileName.replace(".", "\\.")}.*`,
              "i"
            );
            let filtered = found.searchedKeys.filter((i) => !regex.test(i));
            found.searchedKeys = filtered;
          }
          return !foundPaper;
        });
        found.reviewPapers = updated;
        found.selectedPapers = {};
      }
    },
    // addReviewPapers: (state, action) => {
    //   const { item, title } = action.payload;
    //   // const { fileName, ...rest } = item;
    //   const { fileName, format, ...rest } = item; 
    //   let found = state.insightsProjects.find((i) => {
    //     return i.title == title;
    //   });

    //   if (found) {
    //     let type = Object.keys(rest)[0];
    //     const foundIndex = found.reviewPapers.findIndex(
    //       (i) => i.fileName === fileName
    //     );

    //     if (foundIndex !== -1) {
    //       if (found.reviewPapers[foundIndex][type]) {
    //         found.reviewPapers[foundIndex][type] += rest[type];
    //       } else {
    //         found.reviewPapers[foundIndex][type] = rest[type];
    //       }
    //       // Update format
    //   found.reviewPapers[foundIndex].format = format;
    //     } else {
    //       let paperTitle = found.selectedToInsights.find((i) => {
    //         if (i.pdfFile == fileName) {
    //           return i;
    //         }
    //       });
    //       if (paperTitle) {
    //         found.reviewPapers.push({ ...item, title: paperTitle.title });
    //       } else {
    //         found.reviewPapers.push(item);
    //       }
    //     }
    //   }
    // },
    // updateReviewPapers: (state, action) => {
    //   let { val, idx, title } = action.payload;
    //   // const { fileName, ...rest } = val;
    //   const { fileName, format, ...rest } = val;
    //   let found = state.insightsProjects.find((i) => i.title == title);
    //   if (found) {
    //     let type = Object.keys(rest)[0];
    //     let val = found.reviewPapers[idx][type] || "";
    //     if (val) {
    //       found.reviewPapers[idx][type] += rest[type];
    //     } else {
    //       found.reviewPapers[idx][type] = rest[type];
    //     }
    //        // Update format
    // found.reviewPapers[idx].format = format;
    //   }
    // },


//New updateReviewPaper

    // updateReviewPapers: (state, action) => {
    //   const { val, idx, title } = action.payload;
    //   const { fileName, format, ...rest } = val;
    
    //   let found = state.insightsProjects.find((i) => i.title === title);
    //   if (found) {
    //     let type = Object.keys(rest)[0];
    //     // Clear old insights and replace with new
    //     found.reviewPapers = found.reviewPapers.map((paper) =>
    //       paper.fileName === fileName
    //         ? { ...paper, ...rest, format }
    //         : paper
    //     );
    //     // If paper doesn't exist, add new paper
    //     if (!found.reviewPapers.some((paper) => paper.fileName === fileName)) {
    //       let paperTitle = found.selectedToInsights.find((i) => i.pdfFile === fileName);
    //       if (paperTitle) {
    //         found.reviewPapers.push({ ...val, title: paperTitle.title });
    //       } else {
    //         found.reviewPapers.push(val);
    //       }
    //     }
    //   }
    // },

    addReviewPapers: (state, action) => {
      const { item, title } = action.payload;
      const { fileName, format, ...rest } = item; 
      let found = state.insightsProjects.find((i) => i.title === title);

      if (found) {
        let type = Object.keys(rest)[0];
        const foundIndex = found.reviewPapers.findIndex((i) => i.fileName === fileName);

        if (foundIndex !== -1) {
          if (found.reviewPapers[foundIndex][type]) {
            found.reviewPapers[foundIndex][type] += rest[type];
          } else {
            found.reviewPapers[foundIndex][type] = rest[type];
          }
          // Update format
          if (!found.reviewPapers[foundIndex].format) {
            found.reviewPapers[foundIndex].format = {};
          }
          found.reviewPapers[foundIndex].format[type] = format;
        } else {
          let paperTitle = found.selectedToInsights.find((i) => i.pdfFile === fileName);
          let newItem = {
            ...item,
            format: {
              [type]: format
            },
          };
          if (paperTitle) {
            found.reviewPapers.push({ ...newItem, title: paperTitle.title });
          } else {
            found.reviewPapers.push(newItem);
          }
        }
      }
    },

    updateReviewPapers: (state, action) => {
      const { val, idx, title } = action.payload;
      const { fileName, format, ...rest } = val;

      let found = state.insightsProjects.find((i) => i.title === title);
      if (found) {
        let type = Object.keys(rest)[0];
        found.reviewPapers = found.reviewPapers.map((paper) =>
          paper.fileName === fileName
            ? { ...paper, ...rest, format: { ...paper.format, [type]: format } }
            : paper
        );
        if (!found.reviewPapers.some((paper) => paper.fileName === fileName)) {
          let paperTitle = found.selectedToInsights.find((i) => i.pdfFile === fileName);
          let newItem = {
            ...val,
            format: {
              [type]: format
            },
          };
          if (paperTitle) {
            found.reviewPapers.push({ ...newItem, title: paperTitle.title });
          } else {
            found.reviewPapers.push(newItem);
          }
        }
      }
    },
    // other reducers


    addForInsights: (state, action) => {
      let { title, selected } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        found.selectedToInsights = selected;
      }
      state.isInsight = true;
    },

    addSearchedKeys: (state, action) => {
      const { title, selected } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        const mergedArray = [...found.searchedKeys, ...selected];
        const uniqueArray = [...new Set(mergedArray)];
        found.searchedKeys = uniqueArray;
        state.isInsight = false;
      }
    },
    clearSelectedToInsights: (state, action) => {
      const { title } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        found.selectedToInsights = [];
      }
    },
    handlePaperSelection: (state, action) => {
      const { value, index, title } = action.payload;
      let found = state.insightsProjects.find((i) => i.title == title);
      if (found) {
        found.selectedPapers[index] = value;
      }
    },
    clearAllInsights: (state) => {
      return initialState;
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload;
    },
    clearSelectedFiles: (state)=>{
      state.selectedFiles = initialState.selectedFiles
    },
    setColumnLoadingState: (state, action) => {
      const { columnName, isLoading } = action.payload;
      state.columnLoading[columnName] = isLoading;
    },
    // clearColumnLoading: (state, action) => {
    //   const { columnName } = action.payload;
    //   if (columnName) {
    //     delete state.columnLoading[columnName];
    //   } else {
    //     state.columnLoading = {};
    //   }
    // },
    clearColumnLoading: (state) => {
      state.columnLoading = {};
    },


    setFormatLoadingState: (state, action) => {
      const { fileName, columnName, isLoading } = action.payload;
      if (!state.columnLoading[fileName]) {
        state.columnLoading[fileName] = {};
      }
      state.columnLoading[fileName][columnName] = isLoading;
    },
    clearFormatLoading: (state, action) => {
      const { fileName, columnName } = action.payload;
      if (state.columnLoading[fileName]) {
        delete state.columnLoading[fileName][columnName];
      }
    },
    clearAllFormatLoading: (state) => {
      state.columnLoading = {};
    },
    setInsightFormat: (state, action) => {
      state.insightFormat = action.payload;
    },
    clearInsightFormat: (state) => {
      state.insightFormat = initialState.insightFormat;
    },
  },
});

export const {
  clearInsights,
  addReviewPapers,
  updateReviewPapers,
  addSearchedKeys,
  handlePaperSelection,
  clearSelectedPapers,
  removeSelKeys,
  addInsightsProject,
  addForInsights,
  handleIsInsight,
  clearSelectedToInsights,
  clearAllInsights,
  setSelectedFiles,
  clearSelectedFiles,
  setColumnLoadingState,
  clearColumnLoading,
  setInsightLoading,
  clearInsightLoading,
  setInsightFormat,
  clearInsightFormat,
  setFormatLoadingState,
  clearAllFormatLoading,
  clearFormatLoading
} = insightsSlice.actions;

export default insightsSlice.reducer;
