import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog } from "@mui/material";
import { DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { TextField, Typography } from "@mui/material";
import { saveResearchReferences, sendErrorMail } from "../../../apiservice";
import { useDispatch } from "react-redux";
import Toast from "../../../components/Toast";
import { clearRefs, getRefs } from "../refsSlice";
import { clearUser } from "../../Admin/userSlice";
import { useNavigate } from "react-router-dom";
import { baseDomain } from "../../../repo/Repository";
import { clearAllInsights } from "../../paper insights/insightsSlice";
import { clearAllLitReview } from "../../literature review/reviewSlice";
import { clearProject } from "../../projects/projectSlice";

const ManualRefrenceDialogue = ({ handleClose, open, data }) => {
  const navigate = useNavigate();
  const [titleOfArticle, setTitleOfArticle] = useState("");
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [ref, setRef] = useState("");
  console.log("ref", ref);
  const [refLoading, setRefLoading] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  console.log("isLimit", isLimitReached);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    let myToken = localStorage.getItem("token");
    let validToken = "";
    if (myToken && myToken != "undefined") {
      validToken = myToken;
    }
    e.preventDefault();
    setLoading(true);

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: "Manual Reference",
        error: "20 seconds",
        query: titleOfArticle,
        delayInResponse: true,
      });
    }, 20000);

    const email = localStorage.getItem("email");
    const obj = {
      manualReference: true,
      email: email,
      title: titleOfArticle,
      token: validToken,
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "http://localhost:3000");
    myHeaders.append("Access-Control-Allow-Origin", "https://researchpal.co/");
    myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE");

    const request = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
      redirect: "follow",
    };

    fetch(`${baseDomain}/script`, request) // Replace with your actual API endpoint
      .then((response) => response.text())
      .then((result) => {
        clearTimeout(timeoutId);
        setLoading(false);
        const parse = JSON.parse(result);
        console.log("Parsed response:", parse); // Log the response
        if (
          parse.data.error ===
            "Invalid Session Id! You might be logged in from two different devices" ||
          parse.data.error == "Token is empty!"
        ) {
          Toast("info", "Session has expired login again");
          handleLogout();
        }
        // else if (parse.data.error && parse.data.response == "") {
        //   // Toast("error", parse.data.error);
        //   if (
        //     parse.data.error ===
        //     "Manuel references limit reached. Please upgrade your subscription for more."
        //   ) {
        //     setIsLimitReached(true); // Set limit reached flag
        //   } else {
        //     Toast("error", parse.data.error);
        //   }
        // }
        else if (parse.data.sourceObj.error && parse.data.response == "") {
          if (
            parse.data.sourceObj.error ===
            "Manual references limit reached. Please upgrade your subscription for more."
          ) {
            setIsLimitReached(true); // Set limit reached flag
            setRef(parse.data.sourceObj);
            setModal(true);
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Manual Reference",
              error: parse.data.sourceObj.error,
              query: titleOfArticle,
              limitReachedError: true,
            });

            return;
          } else {
            Toast("error", parse.data.sourceObj.error);
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Manual Reference",
              error: parse.data.sourceObj.error,
              query: titleOfArticle,
            });
          }
        } else if (parse.data.response === "Something went wrong!") {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Manual Reference",
            error: parse.data.response,
            query: titleOfArticle,
          });
          return;
        } else {
          setRef(parse.data.sourceObj);
          setModal(true);
          // handleClose();
        }
      });
  };
  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(ref)) {
      arr.push(value);
    }

    return arr;
  };

  console.log("formats()", formats());
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  const saveRefs = async () => {
    try {
      const projectID = localStorage.getItem("projectId");
      let req = {
        researchProjectId: projectID,
      };
      let references = req.references || {};
      console.log("References", references);
      //get first index of ref
      // const newRef = Object.values(ref)[0];
      const newRefKey = Object.keys(ref)[0];
      // Get the citation value associated with the key
      const newRefValue = ref[newRefKey];

      // Update the references object with the key-value pair
      references[newRefKey] = newRefValue;
      req.references = references;
      req.link = ref.link;

      // references[newRef] = newRef;
      // req.references = references;
      const data = await saveResearchReferences(req);
      console.log("data:", data);

      dispatch(clearRefs());
      dispatch(getRefs(projectID));
      setRefLoading(false);
      handleClose();
    } catch (err) {
      Toast("error", "Error");
    }
  };
  const handleUpgradeSubscription = () => {
    navigate("/subscription"); // Redirect to subscription page
  };
  const toggle = () => {
    handleClose();
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-abstract-title"
        open={open}
        maxWidth="sm"
        sx={{
          padding: "0px !important",
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          fontSize={12}
          fontWeight={600}
          fontFamily={"Raleway"}
          id="customized-abstract-title"
          sx={{
            scrollBehavior: "smooth",
            scrollMarginTop: "100px",
          }}
        >
          ADD MANUAL REFERENCE
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers sx={{ padding: "10px", width: "100%" }}>
          {!ref ? (
            <form onSubmit={handleSubmit}>
              <Box sx={{ marginY: 1 }}>
                <Stack>
                  <TextField
                    id="title-of-article"
                    label="Title of Article"
                    variant="outlined"
                    size="small"
                    value={titleOfArticle}
                    onChange={(event) => setTitleOfArticle(event.target.value)}
                  />
                </Stack>
              </Box>

              <Box sx={{ marginY: 1 }}>
                <Stack>
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    sx={{
                      // background: "#353535 !important",
                      background:
                        "linear-gradient(90deg, #5671F1 0%, rgba(23, 206, 173, 1) 100%) !important",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                        boxShadow: "none",
                      },
                      fontFamily: "Raleway",
                      fontSize: "10px",
                      fontWeight: 500,
                      height: "40px !important",
                      color: "#353535",
                      textTransform: "none",
                      padding: "6px 12px !important",
                    }}
                  >
                    {Loading ? <CircularProgress size={20} /> : "Submit"}
                  </Button>
                </Stack>
              </Box>
            </form>
          ) : (
            <Dialog open={modal} fullWidth maxWidth="sm">
              <DialogContent>
                <Stack spacing={5}>
                  <Typography variant="h6">Paper Reference:</Typography>
                  <Box>
                    {ref === "" && (
                      <Typography sx={{ textAlign: "center" }}>
                        No Reference found
                      </Typography>
                    )}
                    {ref &&
                      formats()
                        .filter((ref) => !ref.includes("http"))
                        .map((ref) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {ref}
                          </Typography>
                        ))}
                  </Box>
                  {refLoading && (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress size={30} color="success" />
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        background: "#ffffff !important",
                        color: "#353535",
                      }}
                      onClick={toggle}
                    >
                      Cancel
                    </Button>

                    {isLimitReached ? (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          background: "#1E4F6B !important",
                        }}
                        onClick={handleUpgradeSubscription}
                      >
                        Upgrade Subscription
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          // background: "#353535 !important",
                          background: "#1E4F6B !important",
                        }}
                        onClick={saveRefs}
                      >
                        Add to References
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </DialogContent>
            </Dialog>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManualRefrenceDialogue;
