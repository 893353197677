import React from "react";
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import {
  Tune as TuneIcon,
  Close as CloseIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

const FilterDrawer = ({
  drawerOpen,
  toggleDrawer,
  handleCloseDrawer,
  handleToggleDropdown,
  handleStartYearChange,
  handleEndYearChange,
  handleAccessChange,
  handleDomainSelection,
  handlePublicationSelection,
  handleFilterClear,
  handleApplyFilters,
  filters,
  isOpen,
  PapersDomain,
  Publications,
  currentYear,
}) => (
  <Drawer
    anchor="right"
    open={drawerOpen}
    onClose={toggleDrawer}
    sx={{ fontFamily: "Raleway" }}
  >
    <Box sx={{ width: 320, fontFamily: "Raleway" }}>
      <Box sx={{ py: 2, px: 2, width: "100%", fontFamily: "Raleway" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <Typography
            variant="body1"
            component="h2"
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#14224B",
              fontFamily: "Raleway",
            }}
          >
            Filters
          </Typography>
          <Button
            variant="Outline"
            sx={{
              fontSize: "18px",
              color: "#889CAA",
              display: "flex",
              px: 0,
              justifyContent: "end",
            }}
            onClick={handleCloseDrawer}
          >
            <CloseIcon />
          </Button>
        </Stack>
        <Box sx={{ overflowY: "auto", height: "70vh" }}>
          {/* Papers Filter */}
          <List
            disablePadding
            sx={{
              py: 2,
              borderBottom: "1px solid #DEE0E3",
              fontFamily: "Raleway",
            }}
          >
            <ListItem
              disablePadding
              sx={{
                color: "#373535",
                py: "4px",
                "&:hover": { background: "#f6f6f6" },
                cursor: "pointer",
                fontFamily: "Raleway",
              }}
              onClick={() => handleToggleDropdown("papers")}
            >
              <ListItemText
                primary={"Papers"}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "600",
                    color: "#14224B",
                    fontFamily: "Raleway",
                  },
                }}
              />
              {isOpen["papers"] ? (
                <KeyboardArrowUpIcon sx={{ color: "#14224B" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#14224B" }} />
              )}
            </ListItem>
            {isOpen["papers"] && (
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    py: 1,
                    fontFamily: "Raleway",
                    color: "#14224B",
                  }}
                >
                  Select Year Range
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <InputLabel
                      variant="body1"
                      sx={{ fontSize: "12px", fontFamily: "Raleway", px: 4 }}
                    >
                      Start Date:
                    </InputLabel>
                    <Select
                      value={filters.startYear}
                      onChange={handleStartYearChange}
                      size="small"
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Raleway",
                                color: "#aaa",
                                pt: 0.25,
                              }}
                            >
                              Select start date
                            </Typography>
                          );
                        }
                        return selected;
                      }}
                      sx={{ fontFamily: "Raleway", textAlign: "center" }}
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                        >
                          Select start date
                        </Typography>
                      </MenuItem>
                      {Array.from(
                        { length: currentYear - 2000 + 1 },
                        (_, index) => 2000 + index
                      ).map((year) => (
                        <MenuItem key={year} value={year}>
                          <Stack
                            direction="row"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                            >
                              {year}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      fontFamily: "Raleway",
                    }}
                  >
                    <InputLabel
                      variant="body1"
                      sx={{ fontSize: "12px", fontFamily: "Raleway", px: 4 }}
                    >
                      End Date:
                    </InputLabel>
                    <Select
                      value={filters.endYear || ""}
                      onChange={handleEndYearChange}
                      size="small"
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Raleway",
                                color: "#aaa",
                                pt: 0.25,
                              }}
                            >
                              Select end date
                            </Typography>
                          );
                        }
                        return selected;
                      }}
                      sx={{ fontFamily: "Raleway", textAlign: "center" }}
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                        >
                          Select end date
                        </Typography>
                      </MenuItem>
                      {Array.from(
                        { length: currentYear - 2000 + 1 },
                        (_, index) => 2000 + index
                      ).map((year) => (
                        <MenuItem key={year} value={year}>
                          <Stack
                            direction="row"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontFamily: "Raleway" }}
                            >
                              {year}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    py: 2,
                  }}
                >
                  <InputLabel
                    variant="body1"
                    sx={{ fontFamily: "Raleway", color: "#14224B" }}
                  >
                    Open Access
                  </InputLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filters.openAccessPdfs}
                          onChange={handleAccessChange}
                        />
                      }
                    />
                  </FormGroup>
                </Typography>
              </Box>
            )}
          </List>
          {/* Domains Filter */}
          <List
            disablePadding
            sx={{ py: 2, borderBottom: "1px solid #DEE0E3" }}
          >
            <ListItem
              disablePadding
              sx={{
                color: "#373535",
                py: "4px",
                "&:hover": { background: "#f6f6f6" },
                cursor: "pointer",
                fontFamily: "Raleway",
              }}
              onClick={() => handleToggleDropdown("domains")}
            >
              <ListItemText
                primary={"Domains"}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "600",
                    color: "#14224B",
                    fontFamily: "Raleway",
                  },
                }}
              />
              {isOpen["domains"] ? (
                <KeyboardArrowUpIcon sx={{ color: "#14224B" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#14224B" }} />
              )}
            </ListItem>
            {isOpen["domains"] && (
              <Box sx={{ fontFamily: "Raleway" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    py: 1,
                    fontFamily: "Raleway",
                    color: "#14224B",
                  }}
                >
                  Select Domains
                </Typography>
                <Box sx={{ py: 1, fontFamily: "Raleway" }}>
                  <FormGroup sx={{ fontFamily: "Raleway" }}>
                    {PapersDomain.map((domain) => (
                      <FormControlLabel
                        key={domain}
                        sx={{ fontFamily: "Raleway" }}
                        control={
                          <Checkbox
                            checked={Boolean(
                              filters.fieldsOfStudy?.includes(domain)
                            )}
                            sx={{ fontFamily: "Raleway" }}
                            onChange={() => handleDomainSelection(domain)}
                          />
                        }
                        label={domain}
                      />
                    ))}
                  </FormGroup>
                </Box>
              </Box>
            )}
          </List>
          {/* Publications Filter */}
          <List
            disablePadding
            sx={{ py: 2, borderBottom: "1px solid #DEE0E3" }}
          >
            <ListItem
              disablePadding
              sx={{
                color: "#373535",
                py: "4px",
                "&:hover": { background: "#f6f6f6" },
                cursor: "pointer",
              }}
              onClick={() => handleToggleDropdown("publications")}
            >
              <ListItemText
                primary={"Publications"}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "600",
                    color: "#14224B",
                    fontFamily: "Raleway",
                  },
                }}
              />
              {isOpen["publications"] ? (
                <KeyboardArrowUpIcon sx={{ color: "#14224B" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#14224B" }} />
              )}
            </ListItem>
            {isOpen["publications"] && (
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    py: 1,
                    fontFamily: "Raleway",
                    color: "#14224B",
                  }}
                >
                  Select Publications
                </Typography>
                <Box sx={{ py: 1, fontFamily: "Raleway" }}>
                  <FormGroup sx={{ fontFamily: "Raleway" }}>
                    {Publications.map((publication) => (
                      <FormControlLabel
                        key={publication}
                        sx={{ fontFamily: "Raleway" }}
                        control={
                          <Checkbox
                            checked={Boolean(
                              filters.publicationTypes?.includes(publication)
                            )}
                            onChange={() =>
                              handlePublicationSelection(publication)
                            }
                          />
                        }
                        label={publication}
                      />
                    ))}
                  </FormGroup>
                </Box>
              </Box>
            )}
          </List>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ py: 2 }}
          spacing={1}
        >
          <Button
            fullWidth
            size="small"
            variant="contained"
            sx={{
              background: "#f6f6f6 !important",
              // py: "8px",
              // px: "30px",
              textTransform: "capitalize",
              color: "#353535",
            }}
            onClick={handleFilterClear}
          >
            Clear
          </Button>
          <Button
            fullWidth
            variant="contained"
            size="small"
            sx={{
              // background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
              // "&:hover": {
              //   background: "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
              // },
              background: "#1E4F6B",
              "&:hover": {
                background: "#163C54",
              },
              // py: "8px",
              // px: "30px",
              textTransform: "capitalize",
            }}
            onClick={() => handleApplyFilters("domains")}
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  </Drawer>
);

export default FilterDrawer;
