export const BeforeTOC = [
  {
    content: (
      <p>
        We are Veracious Limited. ("Company,""we," "us," "our" ), a company
        registered in West Yorkshire, United Kingdom at 7 Dickinson Court, WF1
        3PU <br></br>
        <br></br>
        We operate the website https://researchpal.co/ (the "Site" ), as well as
        any other related products and services that refer or link to these
        legal terms (the"Legal Terms") (collectively, the "Services").<br></br>
        <br></br>You can contact us by email at info@researchpal.co or by mail
        to 7 Dickinson Court, Wakefield, WF1 3PU, West Yorkshire, United
        Kingdom.<br></br>
        <br></br>These Legal Terms constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity
        (&quot;you&quot;), and Veracious Limited, concerning your access to and
        use of the Services. You agree that by accessing the Services, you have
        read, understood, and agreed to be bound by all of these Legal Terms. IF
        YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
        PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
        IMMEDIATELY.<br></br>
        <br></br>Supplemental terms and conditions or documents that may be
        posted on the Services from time to time are hereby expressly
        incorporated herein by reference. We reserve the right, in our sole
        discretion, to make changes or modifications to these Legal Terms from
        time to time. We will alert you about any changes by updating the
        &quot;Last updated&quot; date of these Legal Terms, and you waive any
        right to receive specific notice of each such change. It is your
        responsibility to periodically review these Legal Terms to stay informed
        of updates. You will be subject to, and will be deemed to have been made
        aware of and to have accepted, the changes in any revised Legal Terms by
        your continued use of the Services after the date such revised Legal
        Terms are posted.<br></br>
        <br></br>
        The Services are intended for users who are at least 13 years of age.
        All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Services. If
        you are a minor, you must have your parent or guardian read and agree to
        these Legal Terms prior to using the Services.
        <br></br>
        <br></br>
        We recommend that you print a copy of these Legal Terms for your
        records.
        <br></br>
        <br></br>
      </p>
    ),
  },
];

export const tableOfContents = [
  {
    content: (
      <p>
        &nbsp;<strong>1. OUR SERVICES</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>3. USER REPRESENTATIONS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>4. USER REGISTRATION</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>5. PURCHASES AND PAYMENT</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>6. SUBSCRIPTIONS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>7. PROHIBITED ACTIVITIES</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>8. USER GENERATED CONTRIBUTIONS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>9. CONTRIBUTION LICENSE</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>10. THIRD-PARTY WEBSITES AND CONTENT</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>11. SERVICES MANAGEMENT</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>12. PRIVACY POLICY</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>13. TERM AND TERMINATION</strong>
      </p>
    ),
  },

  {
    content: (
      <p>
        &nbsp;<strong>14. MODIFICATIONS AND INTERRUPTIONS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>15. GOVERNING LAW</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>16. DISPUTE RESOLUTION</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>17. CORRECTIONS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>18. DISCLAIMER</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>19. LIMITATIONS OF LIABILITY</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>20. INDEMNIFICATION</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>21. USER DATA</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;
        <strong>
          22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>23. CALIFORNIA USERS AND RESIDENTS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>24. MISCELLANEOUS</strong>
      </p>
    ),
  },
  {
    content: (
      <p>
        &nbsp;<strong>25. CONTACT US</strong>
      </p>
    ),
  },
];

export const tableofcontentPoints = [
  {
    heading: "1. OUR SERVICES",
    content: (
      <p>
        The information provided when using the Services is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Services from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.<br></br>
        <br></br>
        The Services are not tailored to comply with industry-specific
        regulations (Health Insurance Portability and Accountability Act
        (HIPAA), Federal Information Security Management Act (FISMA), etc.), so
        if your interactions would be subjected to such laws, you may not use
        the Services. You may not use the Services in a way that would violate
        the Gramm-Leach-Bliley Act (GLBA). ResearchPal has the right to add or
        remove features at any time to improve the user experience. Users have
        the right to unsubscribe or stop using the service.
      </p>
    ),
  },
  {
    heading: "2. INTELLECTUAL PROPERTY RIGHTS",
    content: (
      <p>
        Our intellectual property<br></br>
        <br></br>
        We are the owner or the licensee of all intellectual property rights in
        our Services, including all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        in the Services (collectively, the &quot;Content&quot;), as well as the
        trademarks, service marks, and logos contained therein (the
        &quot;Marks&quot;).<br></br>
        <br></br>
        Our Content and Marks are protected by copyright and trademark laws (and
        various other intellectual property rights and unfair competition laws)
        and treaties in the United States and around the world.<br></br>
        <br></br>
        The Content and Marks are provided in or through the Services &quot;AS
        IS&quot; for your personal, non-commercial use or internal business
        purpose only.
        <br></br>
        <br></br>
        <strong>Your use of our Services</strong>
        <br></br>
        <br></br>
        Subject to your compliance with these Legal Terms, including the
        &quot;PROHIBITED ACTIVITIES&quot; section below, we grant you a
        non-exclusive, non-transferable, revocable license to:
        <br></br>
        <br></br>
        <ul>
          <li>access the Services; and</li>
          <li>
            download or print a copy of any portion of the Content to which you
            have properly gained access.
          </li>
        </ul>
        solely for your personal, non-commercial use or internal business
        purpose.
        <br></br> <br></br>
        Except as set out in this section or elsewhere in our Legal Terms, no
        part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
        <br></br> <br></br>
        If you wish to make any use of the Services, Content, or Marks other
        than as set out in this section or elsewhere in our Legal Terms, please
        address your request to: info@researchpal.co. If we ever grant you the
        permission to post, reproduce, or publicly display any part of our
        Services or Content, you must identify us as the owners or licensors of
        the Services, Content, or Marks and ensure that any copyright or
        proprietary notice appears or is visible on posting, reproducing, or
        displaying our Content.
        <br></br> <br></br>
        We reserve all rights not expressly granted to you in and to the
        Services, Content, and Marks.
        <br></br> <br></br>
        Any breach of these Intellectual Property Rights will constitute a
        material breach of our Legal Terms and your right to use our Services
        will terminate immediately.
        <br></br> <br></br>
        <strong>Your submissions</strong>
        <br></br> <br></br>
        Please review this section and the &quot;PROHIBITED ACTIVITIES&quot;
        section carefully prior to using our Services to understand the (a)
        rights you give us and (b) obligations you have when you post or upload
        any content through the Services.
        <br></br> <br></br>
        Submissions: By directly sending us any question, comment, suggestion,
        idea, feedback, or other information about the Services
        (&quot;Submissions&quot;), you agree to assign to us all intellectual
        property rights in such Submission. You agree that we shall own this
        Submission and be entitled to its unrestricted use and dissemination for
        any lawful purpose, commercial or otherwise, without acknowledgment or
        compensation to you.
        <br></br> <br></br>
        You are responsible for what you post or upload: By sending us
        Submissions through any part of the Services you:
        <br></br> <br></br>
        <ul>
          <li>
            confirm that you have read and agree with our &quot;PROHIBITED
            ACTIVITIES&quot; and will not post, send, publish, upload, or
            transmit through the Services any Submission that is illegal,
            harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
            discriminatory, threatening to any person or group, sexually
            explicit, false, inaccurate, deceitful, or misleading;
          </li>
          <br></br>
          <li>
            to the extent permissible by applicable law, waive any and all moral
            rights to any such Submission; warrant that any such Submission are
            original to you or that you have the necessary rights and licenses
            to submit such Submissions and that you have full authority to grant
            us the above-mentioned rights in relation to your Submissions; and
          </li>
          <br></br>
          <li>
            warrant and represent that your Submissions do not constitute
            confidential information.
          </li>
        </ul>
        <br></br> <br></br>
        You are solely responsible for your Submissions and you expressly agree
        to reimburse us for any and all losses that we may suffer because of
        your breach of (a) this section, (b) any third party&#39;s intellectual
        property rights, or (c) applicable law.
      </p>
    ),
  },
  {
    heading: "3. USER REPRESENTATIONS",
    content: (
      <p>
        By using the Services, you represent and warrant that:
        <br></br>
        <ol>
          <li>
            (1) All registration information you submit will be true, accurate,
            current, and complete.
          </li>
          <li>
            (2) You will maintain the accuracy of such information and promptly
            update such registration information as necessary.
          </li>
          <li>
            (3) You have the legal capacity and you agree to comply with these
            Legal Terms.
          </li>
          <li>(4) You are not under the age of 13.</li>
          <li>
            (5) You are not a minor in the jurisdiction in which you reside, or
            if a minor, you have received parental permission to use the
            Services.
          </li>
          <li>
            (6) You will not access the Services through automated or non-human
            means, whether through a bot, script or otherwise.
          </li>
          <li>
            (7) You will not use the Services for any illegal or unauthorized
            purpose.
          </li>
          <li>
            (8) your use of the Services will not violate any applicable law or
            regulation.
          </li>
        </ol>
      </p>
    ),
  },
  {
    heading: "4. USER REGISTRATION",
    content: (
      <p>
        You may be required to register to use the Services. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
    ),
  },
  {
    heading: "5. PURCHASES AND PAYMENT",
    content: (
      <p>
        We accept the following forms of payment:
        <br></br> <br></br>
        <strong>Visa</strong>
        <br></br>
        <strong>Mastercard</strong>
        <br></br>
        <strong>American Express</strong>
        <br></br>
        <strong>PayPal</strong>
        <br></br> <br></br>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the Services. You further
        agree to promptly update account and payment information, including
        email address, payment method, and payment card expiration date, so that
        we can complete your transactions and contact you as needed. Sales tax
        will be added to the price of purchases as deemed required by us. We may
        change prices at any time. All payments shall be in US dollars or the
        equivalent exchange rate.
        <br></br> <br></br>
        You agree to pay all charges at the prices then in effect for your
        purchases and any applicable shipping fees, and you authorize us to
        charge your chosen payment provider for any such amounts upon placing
        your order. We reserve the right to correct any errors or mistakes in
        pricing, even if we have already requested or received payment.
        <br></br> <br></br>
        We reserve the right to refuse any order placed through the Services. We
        may, in our sole discretion, limit or cancel quantities purchased per
        person, per household, or per order. These restrictions may include
        orders placed by or under the same customer account, the same payment
        method, and/or orders that use the same billing or shipping address. We
        reserve the right to limit or prohibit orders that, in our sole
        judgment, appear to be placed by dealers, resellers, or distributors.
      </p>
    ),
  },
  {
    heading: "6. SUBSCRIPTIONS",
    content: (
      <p>
        <strong>Billing and Renewal</strong>
        <br></br> <br></br>
        Your subscription will continue and automatically renew unless canceled.
        You consent to our charging your payment method on a recurring basis
        without requiring your prior approval for each recurring charge, until
        such time as you cancel the applicable order. The length of your billing
        cycle will depend on the type of subscription plan you choose when you
        subscribed to the Services.
        <br></br> <br></br>
        <strong>Cancellation</strong>
        <br></br> <br></br>
        All purchases are non-refundable unless required by law. For example,
        users living in the European Union have the right to cancel their Paid
        Account subscriptions and obtain a refund within 14 days of signing up
        for, upgrading to, or renewing a Paid Account by contacting our support
        channels. You can cancel your subscription at any time by logging into
        your account. Your cancellation will take effect at the end of the
        current paid term. If you have any questions or are unsatisfied with our
        Services, please email us at https://researchpal.co/.
        <br></br> <br></br>
        <strong>Fee Changes</strong>
        <br></br> <br></br>
        We may, from time to time, make changes to the subscription fee and will
        communicate any price changes to you in accordance with applicable law.
      </p>
    ),
  },
  {
    heading: "7. PROHIBITED ACTIVITIES",
    content: (
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
        <br></br> <br></br>
        As a user of the Services, you agree not to:
        <br></br> <br></br>
        <ul>
          <li>
            Systematically retrieve data or other content from the Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the Services and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Services.
          </li>
          <li>
            Use any information obtained from the Services in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use the Services in a manner inconsistent with any applicable laws
            or regulations.
          </li>
          <li>Engage in unauthorized framing of or linking to the Services.</li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party&#39;s uninterrupted use and enjoyment of
            the Services or modifies, impairs, disrupts, alters, or interferes
            with the use, features, functions, operation, or maintenance of the
            Services.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (&quot;gifs&quot;), 1×1 pixels, web bugs,
            cookies, or other similar devices (sometimes referred to as
            &quot;spyware&quot; or &quot;passive collection mechanisms&quot; or
            &quot;pcms&quot;).
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Services
            or the networks or services connected to the Services.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.
          </li>
          <li>
            Attempt to bypass any measures of the Services designed to prevent
            or restrict access to the Services, or any portion of the Services.
          </li>
          <li>
            Copy or adapt the Services&#39; software, including but not limited
            to Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Services.
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Services, or
            use or launch any unauthorized script or other software. Use a
            buying agent or purchasing agent to make purchases on the Services.
          </li>
          <li>
            Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use the Services as part of any effort to compete with us or
            otherwise use the Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
          <li>
            Use the Services to advertise or offer to sell goods and services.
          </li>
        </ul>
      </p>
    ),
  },
  {
    heading: "8. USER GENERATED CONTRIBUTIONS",
    content: (
      <p>The Services does not offer users to submit or post content.</p>
    ),
  },
  {
    heading: "9. CONTRIBUTION LICENSE",
    content: (
      <p>
        You and Services agree that we may access, store, process, and use any
        information and personal data that you provide following the terms of
        the Privacy Policy and your choices (including settings).
        <br></br> <br></br>
        By submitting suggestions or other feedback regarding the Services, you
        agree that we can use and share such feedback for any purpose without
        compensation to you.
      </p>
    ),
  },
  {
    heading: "10. THIRD-PARTY WEBSITES AND CONTENT",
    content: (
      <p>
        The Services may contain (or you may be sent via the Site) links to
        other websites (&quot;Third-Party Websites&quot;) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (&quot;Third-Party
        Content&quot;). Such Third-Party Websites and Third-Party Content are
        not investigated, monitored, or checked for accuracy, appropriateness,
        or completeness by us, and we are not responsible for any Third-Party
        Websites accessed through the Services or any Third-Party Content posted
        on, available through, or installed from the Services, including the
        content, accuracy, offensiveness, opinions, reliability, privacy
        practices, or other policies of or contained in the Third-Party Websites
        or the Third-Party Content. Inclusion of, linking to, or permitting the
        use or installation of any Third-Party Websites or any Third-Party
        Content does not imply approval or endorsement thereof by us. If you
        decide to leave the Services and access the Third-Party Websites or to
        use or install any Third-Party Content, you do so at your own risk, and
        you should be aware these Legal Terms no longer govern. You should
        review the applicable terms and policies, including privacy and data
        gathering practices, of any website to which you navigate from the
        Services or relating to any applications you use or install from the
        Services. Any purchases you make through Third-Party Websites will be
        through other websites and from other companies, and we take no
        responsibility whatsoever in relation to such purchases which are
        exclusively between you and the applicable third party. You agree and
        acknowledge that we do not endorse the products or services offered on
        Third-Party Websites and you shall hold us blameless from any harm
        caused by your purchase of such products or services. Additionally, you
        shall hold us blameless from any losses sustained by you or harm caused
        to you relating to or resulting in any way from any Third-Party Content
        or any contact with Third-Party Websites.
      </p>
    ),
  },
  {
    heading: "11. SERVICES MANAGEMENT",
    content: (
      <p>
        We reserve the right, but not the obligation, to:
        <br></br> <br></br>
        <ul>
          <li>(1) Monitor the Services for violations of these Legal Terms.</li>
          <li>
            (2) Take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these Legal Terms, including without
            limitation, reporting such user to law enforcement authorities.
          </li>
          <li>
            (3) In our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your Contributions or any portion
            thereof.
          </li>
          <li>
            (4) In our sole discretion and without limitation, notice, or
            liability, to remove from the Services or otherwise disable all
            files and content that are excessive in size or are in any way
            burdensome to our systems.
          </li>
          <li>
            (5) Otherwise manage the Services in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Services.
          </li>
        </ul>
      </p>
    ),
  },
  {
    heading: "12. PRIVACY POLICY",
    content: (
      <p>
        We care about data privacy and security. By using the Services, you
        agree to be bound by our Privacy Policy, which is incorporated into
        these Legal Terms. Please be advised the Services are hosted in the
        United States. If you access the Services from any other region of the
        world with laws or other requirements governing personal data
        collection, use, or disclosure that differ from applicable laws in the
        United States, then through your continued use of the Services, you are
        transferring your data to the United States, and you expressly consent
        to have your data transferred to and processed in the United States.
        Further, we do not knowingly accept, request, or solicit information
        from children or knowingly market to children. Therefore, in accordance
        with the U.S. Children&#39;s Online Privacy Protection Act, if we
        receive actual knowledge that anyone under the age of 13 has provided
        personal information to us without the requisite and verifiable parental
        consent, we will delete that information from the Services as quickly as
        is reasonably practical.
      </p>
    ),
  },
  {
    heading: "13. TERM AND TERMINATION",
    content: (
      <p>
        These Legal Terms shall remain in full force and effect while you use
        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
        OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        <br></br>
        <br></br>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress
      </p>
    ),
  },

  {
    heading: "14. MODIFICATIONS AND INTERRUPTIONS",
    content: (
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Services at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Services. We will not be liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the
        Services.
        <br></br>
        <br></br>
        We cannot guarantee the Services will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Services, resulting in interruptions, delays,
        or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Services at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the Services during any downtime or
        discontinuance of the Services. Nothing in these Legal Terms will be
        construed to obligate us to maintain and support the Services or to
        supply any corrections, updates, or releases in connection therewith.
      </p>
    ),
  },
  {
    heading: "15. GOVERNING LAW",
    content: (
      <p>
        These Legal Terms and your use of the Services are governed by and
        construed in accordance with the laws of the United Kingdom applicable
        to agreements made and to be entirely performed within the United
        Kingdom, without regard to its conflict of law principles.
      </p>
    ),
  },
  {
    heading: "16. DISPUTE RESOLUTION",
    content: (
      <p>
        Informal Negotiations<br></br>
        <br></br>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Legal Terms (each a &quot;Dispute&quot; and
        collectively, the &quot;Disputes&quot;) brought by either you or us
        (individually, a &quot;Party&quot; and collectively, the
        &quot;Parties&quot;), the Parties agree to first attempt to negotiate
        any Dispute (except those Disputes expressly provided below) informally
        for at least thirty (30) days before initiating arbitration. Such
        informal negotiations commence upon written notice from one Party to the
        other Party.
        <br></br>
        <br></br>
        Binding Arbitration<br></br>
        <br></br>
        If the Parties are unable to resolve a Dispute through informal
        negotiations, the Dispute (except those Disputes expressly excluded
        below) will be finally and exclusively resolved by binding arbitration.
        YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
        SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced
        and conducted under the Commercial Arbitration Rules of the American
        Arbitration Association (&quot;AAA&quot;) and, where appropriate, the
        AAA&#39;s Supplementary Procedures for Consumer Related Disputes
        (&quot;AAA Consumer Rules&quot;), both of which are available at the
        American Arbitration Association (AAA) website. Your arbitration fees
        and your share of arbitrator compensation shall be governed by the AAA
        Consumer Rules and, where appropriate, limited by the AAA Consumer
        Rules. The arbitration may be conducted in person, through the
        submission of documents, by phone, or online. The arbitrator will make a
        decision in writing, but need not provide a statement of reasons unless
        requested by either Party. The arbitrator must follow applicable law,
        and any award may be challenged if the arbitrator fails to do so. Except
        where otherwise required by the applicable AAA rules or applicable law,
        the arbitration will take place in the United Kingdom. Except as
        otherwise provided herein, the Parties may litigate in court to compel
        arbitration, stay proceedings pending arbitration, or to confirm,
        modify, vacate, or enter judgment on the award entered by the
        arbitrator.
        <br></br>
        <br></br>
        If for any reason, a Dispute proceeds in court rather than arbitration,
        the Dispute shall be commenced or prosecuted in the state and federal
        courts located in the United Kingdom, and the Parties hereby consent to,
        and waive all defenses of lack of personal jurisdiction, and forum with
        respect to venue and jurisdiction in such state and federal courts.
        Application of the United Nations Convention on Contracts for the
        International Sale of Goods and the Uniform Computer Information
        Transaction Act (UCITA) are excluded from these Legal Terms.
        <br></br>
        <br></br>
        If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court.
        <br></br>
        <br></br>
        Restrictions
        <br></br>
        <br></br>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class action procedures; and (c) there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons.
        <br></br>
        <br></br>
        Exceptions to Informal Negotiations and Arbitration
        <br></br>
        <br></br>
        The Parties agree that the following Disputes are not subject to the
        above provisions concerning informal negotiations binding arbitration:
        (a) any Disputes seeking to enforce or protect, or concerning the
        validity of, any of the intellectual property rights of a Party; (b) any
        Dispute related to, or arising from, allegations of theft, piracy,
        invasion of privacy, or unauthorized use; and (c) any claim for
        injunctive relief. If this provision is found to be illegal or
        unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent
        jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.
      </p>
    ),
  },
  {
    heading: "17. CORRECTIONS",
    content: (
      <p>
        There may be information on the Services that contains typographical
        errors, inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Services at any time, without prior notice.
      </p>
    ),
  },
  {
    heading: "18. DISCLAIMER",
    content: (
      <p>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&#39;
        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY:
        <br></br>
        <br></br>
        <ol>
          <li>
            (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
          </li>
          <li>
            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES,
          </li>
          <li>
            (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
            ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
            THEREIN,
          </li>
          <li>
            (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
            SERVICES,
          </li>
          <li>
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
          </li>
          <li>
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD- PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
          </li>
        </ol>
      </p>
    ),
  },
  {
    heading: "19. LIMITATIONS OF LIABILITY",
    content: (
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
        OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
        OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT
        PAID, IF ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO
        ANY CAUSE OF ACTION ARISING OR $144.00 USD. CERTAIN US STATE LAWS AND
        INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
        SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
        YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>
    ),
  },
  {
    heading: "20. INDEMNIFICATION",
    content: (
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys&#39; fees and expenses,
        made by any third party due to or arising out of: (1) use of the
        Services; (2) breach of these Legal Terms; (3) any breach of your
        representations and warranties set forth in these Legal Terms; (4) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights; or (5) any overt harmful act toward any
        other user of the Services with whom you connected via the Services.
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.
      </p>
    ),
  },
  {
    heading: "21. USER DATA",
    content: (
      <p>
        We will maintain certain data that you transmit to the Services for the
        purpose of managing the performance of the Services, as well as data
        relating to your use of the Services. Although we perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the Services. You agree that we shall have no liability to you for any
        loss or corruption of any such data, and you hereby waive any right of
        action against us arising from any such loss or corruption of such data.
      </p>
    ),
  },
  {
    heading: "22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
    content: (
      <p>
        Visiting the Services, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Services, satisfy any legal requirement that such communication
        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SERVICES. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non- electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
    ),
  },
  {
    heading: "23. CALIFORNIA USERS AND RESIDENTS",
    content: (
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>
    ),
  },
  {
    heading: "24. MISCELLANEOUS",
    content: (
      <p>
        These Legal Terms and any policies or operating rules posted by us on
        the Services or in respect to the Services constitute the entire
        agreement and understanding between you and us. Our failure to exercise
        or enforce any right or provision of these Legal Terms shall not operate
        as a waiver of such right or provision. These Legal Terms operate to the
        fullest extent permissible by law. We may assign any or all of our
        rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Legal Terms is determined to be unlawful,
        void, or unenforceable, that provision or part of the provision is
        deemed severable from these Legal Terms and does not affect the validity
        and enforceability of any remaining provisions. There is no joint
        venture, partnership, employment or agency relationship created between
        you and us as a result of these Legal Terms or use of the Services. You
        agree that these Legal Terms will not be construed against us by virtue
        of having drafted them. You hereby waive any and all defenses you may
        have based on the electronic form of these Legal Terms and the lack of
        signing by the parties hereto to execute these Legal Terms.
      </p>
    ),
  },
  {
    heading: "25. CONTACT US",
    content: (
      <p>
        In order to resolve a complaint regarding the Services or to receive
        further information regarding use of the Services, please contact us at:
        info@researchpal.co.
      </p>
    ),
  },
];
