import React, { useCallback, useState } from "react";
// import { HexColorPicker } from 'react-colorful';
import { ColorButton } from "./ColorButton";
import { Toolbar } from "../../ui/Toolbar";
import { themeColors } from "../../../lib/constants";
import Icon from "../../ui/Icon";

const ColorPicker = ({ color, onChange, onClear }) => {
  const [colorInputValue, setColorInputValue] = useState(color || "");

  const handleColorUpdate = useCallback((event) => {
    setColorInputValue(event.target.value);
  }, []);

  const handleColorChange = useCallback(() => {
    const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(colorInputValue);

    if (!isCorrectColor) {
      if (onChange) {
        onChange("");
      }

      return;
    }

    if (onChange) {
      onChange(colorInputValue);
    }
  }, [colorInputValue, onChange]);

  return (
    <div className="flex flex-col gap-2">
      {/* <HexColorPicker className="w-full" color={color || ''} onChange={onChange} /> */}
      <input
        type="text"
        className="w-full p-2 text-black bg-white border rounded dark:bg-white dark:text-black border-neutral-200 dark:border-neutral-200 focus:outline-1 focus:ring-0 focus:outline-neutral-300 dark:focus:outline-neutral-300"
        placeholder="#000000"
        value={colorInputValue}
        onChange={handleColorUpdate}
        onBlur={handleColorChange}
      />
      <div className="flex flex-wrap items-center justify-center gap-1 max-w-[15rem]">
        {themeColors.map((currentColor) => (
          <ColorButton
            active={currentColor === color}
            color={currentColor}
            key={currentColor}
            onColorChange={onChange}
          />
        ))}
        <Toolbar.Button tooltip="Reset color to default" onClick={onClear}>
          <Icon name="Undo" />
        </Toolbar.Button>
      </div>
    </div>
  );
};

export { ColorPicker };
