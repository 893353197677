import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarOpen: true,
  selectedCom: "editor",
  userBoarding: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    handleSidebarToggle: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    closeSidebar: (state) => {
      state.sidebarOpen = false;
    },
    handleSelectedCom: (state, action) => {
      state.selectedCom = action.payload;
    },
    setUserBoarding: (state, action) => {
      state.userBoarding = action.payload;
    },
  },
});

export const {
  closeSidebar,
  handleSidebarToggle,
  handleSelectedCom,
  setUserBoarding,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
