import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import * as cssVar from './cssVar';
import * as getRenderContainer from './getRenderContainer';
import * as isCustomNodeSelected from './isCustomNodeSelected';
import * as isTextSelected from './isTextSelected';


export function cn(...inputs) {
  return twMerge(clsx(...inputs));
}

export function randomElement(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export {
  cssVar,
  getRenderContainer,
  isCustomNodeSelected,
  isTextSelected,
};
