import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { ImageUpload as ImageUploadComponent } from './view/ImageUpload';

export const ImageUpload = Node.create({
  name: 'imageUpload',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    const node= this;
    return [
      {
        tag: `div[data-type="${node.name}"]`,
      },
    ];
  },

  renderHTML() {
    const node= this;
    return ['div', { 'data-type': node.name }];
  },

  addCommands() {
    const node= this;
    return {
      setImageUpload() {
        return ({ commands }) => commands.insertContent(`<div data-type="${node.name}"></div>`);
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageUploadComponent);
  },
});

export default ImageUpload;
