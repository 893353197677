import { Box, Button, Typography, styled } from "@mui/material";

export const CardBox = styled(Box)({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "200px",
  width: "100%",
  borderRadius: "12px",
  background: "#fff",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 1px 0px 0px #335C76",
    background: "#f6f6f6",
  },
});
export const CreateBtn = styled(Button)({
  borderRadius: "6px",
  padding: "8px 16px",
  textTransform: "capitalize",
  color: "#FFF",
  background: "#fff !important",
  "&:hover": {
    background: "#DFDFDF !important",
  },
});

export const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 700,
  color: "#14224B",
  fontSize: "20px",
});
