import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { Close } from "@mui/icons-material";
import { getFeedback, getLitFeedback } from "../../apiservice";
import { CustomBox } from "../profile/style";
import TailSpinLoader from "../../components/TailSpinLoader";

const Columns = [
  {
    name: "Index",
    selector: (row, idx) => idx + 1,
    width: "100px",
  },

  {
    name: "Date",
    selector: (row, idx) => row.date,
    width: "200px",
  },
  {
    name: "Rating",
    selector: (row, idx) => row.rating,
    width: "100px",
  },
  {
    name: "Feedback",
    selector: (row, idx) => row.message,
    width: 900,
    wrap: true,
  },
];

const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      background: "transparent",
    },
  },
  header: {
    style: {
      // borderTopRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#01A35F",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "40px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#01A35F",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

function Limitations({ limitation, handleClose, open }) {
  console.log("limitation:", limitation);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const standardPlan = limitation?.planName === "standard" ? true : false;
  const billingPeriod = limitation?.price === 99 ? "yearly" : "monthly";
  const cancelSubscription = limitation?.cancelSubscription;

  useEffect(() => {
    if (limitation) {
      const limiitations = Object.keys(limitation).map((key) => {
        return limitation[key];
      });
      setRowData(limiitations);
    }
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="usage-dialogue-title"
      open={open}
      maxWidth="md"
      fullWidth
      sx={{
        padding: "0px !important",
        width: "100% !important",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        id="usage-dialogue-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "18px",
              color: "#353535",
              textAlign: "center",
            }}
          >
            Usage Details
          </Typography>
          {!loading && (
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "12px",
                color: "#353535",
                textAlign: "center",
              }}
            >
              Subscription : {limitation?.planName || "Free"}
              {limitation?.planName === "standard" ? " plan" : ""} active
            </Typography>
          )}
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ padding: "16px" }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "30vh",
            }}
          >
            <TailSpinLoader />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Projects</Typography>
              <CustomBox>
                {standardPlan || cancelSubscription
                  ? limitation?.[`max-projects`] && "Unlimited"
                  : limitation?.[`max-projects`]}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Document Upload</Typography>
              <CustomBox>
                Remaining : {limitation?.[`upload-papers`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 1800"
                  : standardPlan || cancelSubscription
                  ? "150"
                  : " 3"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Paper Insights</Typography>
              <CustomBox>
                Remaining : {limitation?.[`paper-insights`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 600"
                  : standardPlan || cancelSubscription
                  ? "50"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Manual Reference</Typography>
              <CustomBox>
                Remaining : {limitation?.[`manuel-references`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 600"
                  : standardPlan || cancelSubscription
                  ? " 50"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Text Editor (Cite)</Typography>
              <CustomBox>
                Remaining : {limitation?.cite} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 600"
                  : standardPlan || cancelSubscription
                  ? " 50"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>
                Text Editor (Web Queries)
              </Typography>
              <CustomBox>
                Remaining : {limitation?.[`query-web`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 600"
                  : standardPlan || cancelSubscription
                  ? " 50"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Tones</Typography>
              <CustomBox>
                Remaining : {limitation?.tones} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 1200"
                  : standardPlan || cancelSubscription
                  ? " 100"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Translate</Typography>
              <CustomBox>
                Remaining : {limitation?.translate} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 1200"
                  : standardPlan || cancelSubscription
                  ? " 100"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Other AI Commands</Typography>
              <CustomBox>
                Remaining : {limitation?.[`ai-tools`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 1200"
                  : standardPlan || cancelSubscription
                  ? " 100"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Library Chat</Typography>
              <CustomBox>
                Remaining : {limitation?.chat} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 1200"
                  : standardPlan || cancelSubscription
                  ? "100"
                  : " 2"}
              </CustomBox>
            </Grid>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>
                Literature Review (Web + Library)
              </Typography>
              <CustomBox>
                Remaining : {limitation?.[`lit-review-web`]} /
                {(standardPlan && billingPeriod === "yearly") ||
                cancelSubscription
                  ? " 600"
                  : standardPlan || cancelSubscription
                  ? " 50"
                  : " 2"}
              </CustomBox>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default Limitations;
