import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Box,
  Typography,
  CardMedia,
  styled,
  ListItem,
  List,
  ListItemText,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import { useDispatch, useSelector } from "react-redux";
import { addBlog } from "./BlogSlice";
import { blogPosts } from "./blogPosts";

const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: "32px",
  marginBottom: "16px",
  fontFamily: "Raleway",
});

const DividerText = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Raleway",
  fontWeight: 600,
  textTransform: "uppercase",
  color: "#6E7A84",
});

const CardText = styled(Typography)({
  fontSize: "18px",
  lineHeight: "28px",
  marginBottom: "32px",
  fontFamily: "Raleway",
});

const SectionTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: "40px",
  marginBottom: "32px",
  marginTop: "32px",
  fontFamily: "Raleway",
});

const PointTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "24px",
  marginBottom: "24px",
  marginTop: "24px",
  color: "#4A90E2",
  fontFamily: "Raleway",
});

const SubHeading = styled(Typography)({
  fontWeight: 500,
  fontSize: "20px",
  marginBottom: "20px",
  marginTop: "24px",
  // color: "#4A90E2",
  fontFamily: "Raleway",
});

const PointDescription = styled(Typography)({
  fontSize: "18px",
  lineHeight: "24px",
  marginBottom: "12px",
  fontFamily: "Raleway",
});

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "32px 32px 32px 10px",
  marginInline: "10px",
  marginBottom: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 30px 0 30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const BlogDetails = () => {
  const { blog } = useSelector((state) => state.blogs);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(blog || null);
  const [isVisible, setIsVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // Retrieve the selected blog post based on the updated `id` param
    const selectedBlog = blogPosts.find((post) => post.id === id);

    if (selectedBlog) {
      setItem(selectedBlog);
      localStorage.setItem("selectedBlog", JSON.stringify(selectedBlog)); // Optionally store the selected blog
    } else {
      // If no blog is found, navigate back to the blog list or handle as needed
      navigate("/blog");
    }
  }, [id, navigate]);

  // useEffect(() => {
  //   if (!item) {
  //     console.log("hellllo");
  //     // If item is not in state, try to retrieve it from local storage
  //     const storedItem = localStorage.getItem("selectedBlog");
  //     if (storedItem) {
  //       const parsedItem = JSON.parse(storedItem);
  //       if (parsedItem.id === id) {
  //         setItem(parsedItem);
  //       }
  //     }
  //   }
  // }, [id, item]);
  useEffect(() => {
    const storedItem = localStorage.getItem("selectedBlog");
    if (storedItem) {
      setItem(JSON.parse(storedItem));
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const rootElement = document.getElementById("root-style");
      const rootBottom = rootElement.getBoundingClientRect().bottom;

      if (rootBottom <= window.innerHeight) {
        setIsFixed(false); // Make static when scrolled out
      } else {
        setIsFixed(true); // Keep fixed when inside the RootStyle
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });

  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetPosition =
        element.getBoundingClientRect().top + window.scrollY - 82;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  console.log("BlogPosts", blogPosts);

  if (!item) {
    // Redirect to /blog if no blog post matches
    return <Navigate to="/blog" />;
  }

  return (
    <>
      <Box>
        {/* Landing Header with onClick to toggle modals */}
        <Box
          sx={{
            zIndex: 1,
            p: 2,
            position: "sticky",
            right: 5,
            left: 5,
            top: 1,
            backgroundColor: "rgba(254, 254, 254, 0.32)",
            backdropFilter: "blur(12.17898178100586px)",
          }}
        >
          <LandingHeader onClick={(e) => toggle(e)} />
        </Box>

        {/* Table of Contents */}

        {/* Blog Post Details */}
        <RootStyle id="root-style">
          <Stack
            direction={{ xs: "column", lg: "row" }}
            sx={{ gap: { xs: 0, lg: 4 } }}
          >
            {!isSmallScreen && (
              <Box
                sx={{
                  position: isFixed ? "sticky" : "static",
                  top: isFixed ? "134px" : "auto", // Fixed when inside RootStyle
                  left: "32px",
                  width: "1200px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "16px",
                  zIndex: 10,
                  maxHeight: !isVisible ? "10vh" : "55vh",
                  overflowY: "hidden",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 800,
                      fontFamily: "Raleway",
                    }}
                  >
                    Table of Contents
                  </Typography>

                  {/* Toggle button */}

                  <Button
                    variant="text"
                    size="small"
                    onClick={toggleVisibility}
                    sx={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                  >
                    [{isVisible ? "Hide" : "Show"}]
                  </Button>
                </Stack>
                {isVisible && (
                  <List sx={{ overflowY: "auto", height: "100%" }}>
                    {item.sections.map((section, index) => (
                      <Box key={index}>
                        <ListItem
                          button
                          onClick={() =>
                            scrollToSection(
                              section.heading.replace(/\s+/g, "-")
                            )
                          }
                          sx={{
                            padding: "4px 0",
                          }} // Padding for section headings
                        >
                          <ListItemText
                            primary={`${index + 1}. ${section.heading}`}
                            primaryTypographyProps={{
                              fontWeight: "700",
                              fontSize: "12px",
                              fontFamily: "Raleway",
                              color: "#4992D9",
                            }}
                          />
                        </ListItem>
                      </Box>
                    ))}
                  </List>
                )}
              </Box>
            )}

            <Box>
              <Title
                sx={{
                  fontSize: { xs: "56px", sm: "72px" },
                  lineHeight: { xs: "72px", sm: "auto" },
                  fontWeight: 800,
                  color: "#1AB394",
                }}
              >
                {item.title}
              </Title>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  color: "#555",
                  borderTop: "1px solid #ddd",
                  paddingTop: "15px",
                  gap: "15px",
                  marginBottom: "24px",
                }}
              >
                <DividerText>
                  <li>{item.postOn}</li>
                </DividerText>

                <DividerText>
                  <li>{item.category}</li>
                </DividerText>

                <DividerText>
                  <li>{item.read}</li>
                </DividerText>
              </Box>

              <CardMedia
                component="img"
                height="300"
                image={item.image}
                alt={item.alt}
                sx={{
                  marginBottom: "32px",
                  width: "100%",
                  height: { xs: "auto", md: "500px" },
                }}
              />
              <CardText
                sx={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: item.text.replace(
                    /<a/g,
                    `<a style="color: #4A90E2;" target="_blank" `
                  ), // Apply color styling to all <a> tags
                }}
              />

              {/* Render sections */}
              {item.sections.map((section, index) => (
                <Box key={index} id={section.heading.replace(/\s+/g, "-")}>
                  <SectionTitle sx={{ fontSize: { xs: "32px", sm: "40px" } }}>
                    {section.heading}
                  </SectionTitle>
                  <CardText
                    sx={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      __html: section.content.replace(
                        /<a /g,
                        `<a style="color: #4A90E2;" target="_blank" `
                      ),
                    }}
                  />

                  {section.points &&
                    section.points.map((point, pointIndex) => (
                      <Box
                        key={pointIndex}
                        id={point.title.replace(/\s+/g, "-")}
                      >
                        <PointTitle sx={{ fontWeight: 600 }}>
                          {point.title}
                        </PointTitle>
                        {/* <PointDescription sx={{ whiteSpace: "pre-line" }}>
                          {point.description
                            .split("**")
                            .map((text, textIndex) =>
                              textIndex % 2 === 1 ? (
                                <PointDescription
                                  key={textIndex}
                                  component="span"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {text}
                                </PointDescription>
                              ) : (
                                text
                              )
                            )}
                        </PointDescription> */}
                        <PointDescription sx={{ whiteSpace: "pre-line" }}>
                          {point.description
                            .split("**")
                            .map((text, textIndex) =>
                              textIndex % 2 === 1 ? (
                                <PointDescription
                                  key={textIndex}
                                  component="span"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {text}
                                </PointDescription>
                              ) : (
                                // Render text normally, and allow HTML rendering
                                <span
                                  key={textIndex}
                                  dangerouslySetInnerHTML={{
                                    __html: text.replace(
                                      /<a /g,
                                      `<a style="color: #4A90E2;" `
                                    ), // Adds color style to the anchor tag
                                  }}
                                />
                              )
                            )}
                        </PointDescription>

                        {point.descPoint && (
                          <PointDescription
                            sx={{ whiteSpace: "pre-line", ml: 3, mt: 0.5 }}
                          >
                            {point.descPoint.split("**").map((text, index) =>
                              index % 2 === 1 ? (
                                <PointDescription
                                  key={index}
                                  component="span"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {text}
                                </PointDescription>
                              ) : (
                                text
                              )
                            )}
                          </PointDescription>
                        )}

                        {point.subPoints &&
                          point.subPoints.map((subPoint, subPointIndex) => (
                            <React.Fragment key={subPointIndex}>
                              <SubHeading
                                sx={{ fontWeight: 600, mt: 3, ml: 3 }}
                              >
                                {subPoint.subHeading}
                              </SubHeading>
                              <PointDescription
                                sx={{ whiteSpace: "pre-line", ml: 3 }}
                              >
                                {subPoint.subDescription
                                  .split("**")
                                  .map((text, textIndex) =>
                                    textIndex % 2 === 1 ? (
                                      <PointDescription
                                        key={textIndex}
                                        component="span"
                                        sx={{ fontWeight: 600 }}
                                      >
                                        {text}
                                      </PointDescription>
                                    ) : (
                                      <span
                                        key={textIndex}
                                        dangerouslySetInnerHTML={{
                                          __html: text.replace(
                                            /<a /g,
                                            `<a style="color: #4A90E2;" target="_blank" ` // Adds color and target="_blank" for opening in a new tab
                                          ),
                                        }}
                                      />
                                    )
                                  )}
                              </PointDescription>

                              {subPoint.subMultiPoint && (
                                <PointDescription
                                  sx={{
                                    whiteSpace: "pre-line",
                                    ml: 6,
                                    mt: 0.5,
                                  }}
                                >
                                  {subPoint.subMultiPoint}
                                </PointDescription>
                              )}
                            </React.Fragment>
                          ))}
                      </Box>
                    ))}

                  {/* {section.relatedReading && (
                    <Box sx={{ mt: 3 }}>
                      <PointTitle sx={{ color: "black", fontWeight: 600 }}>
                        {section.relatedReading.title}
                      </PointTitle>
                      <PointDescription
                        sx={{ fontWeight: 400, whiteSpace: "pre-line" }}
                      >
                        {section.relatedReading.description
                          .split("**")
                          .map((text, textIndex) =>
                            textIndex % 2 === 1 ? (
                              <PointDescription
                                key={textIndex}
                                component="span"
                                sx={{ fontWeight: 600 }}
                              >
                                {text}
                              </PointDescription>
                            ) : (
                              <span
                                key={textIndex}
                                dangerouslySetInnerHTML={{
                                  __html: text.replace(
                                    /<a /g,
                                    `<a style="color: #4A90E2;" `
                                  ),
                                }}
                              />
                            )
                          )}
                      </PointDescription>
                    </Box>
                  )} */}

                  {section.relatedReading && (
                    <Box sx={{ mt: 3 }}>
                      <PointTitle sx={{ color: "black", fontWeight: 600 }}>
                        {section.relatedReading.title}
                      </PointTitle>
                      <PointDescription
                        sx={{ fontWeight: 400, whiteSpace: "pre-line" }}
                      >
                        {section.relatedReading.description
                          .split("\n") // Split by line breaks
                          .map((line, lineIndex) => {
                            let modifiedLine = line;

                            blogPosts.forEach((blog) => {
                              // Case-insensitive match for the blog category in the line text
                              if (
                                line
                                  .toLowerCase()
                                  .includes(blog.category.toLowerCase())
                              ) {
                                modifiedLine = line.replace(
                                  new RegExp(blog.category, "i"), // Case-insensitive replacement
                                  `<a href="/blog/${blog.id}" style="color: #4A90E2;" target="_blank">${blog.category}</a>`
                                );
                              }
                            });

                            // Check if the line contains an <a> tag
                            if (line.includes("<a")) {
                              modifiedLine = modifiedLine.replace(
                                /<a(.*?)>/g,
                                '<a$1 style="color: #4A90E2;">'
                              );
                            }

                            // Wrap each line in a <div> to ensure it appears on a new line without extra bullet points
                            return (
                              <div
                                key={lineIndex}
                                dangerouslySetInnerHTML={{
                                  __html: modifiedLine,
                                }}
                              />
                            );
                          })}
                      </PointDescription>
                    </Box>
                  )}

                  {section.relatedReading2 && (
                    <Box sx={{ mt: 3 }}>
                      <PointTitle sx={{ color: "black", fontWeight: 600 }}>
                        {section.relatedReading2.title}
                      </PointTitle>
                      <PointDescription
                        sx={{ fontWeight: 400, whiteSpace: "pre-line" }}
                      >
                        {section.relatedReading2.description
                          .split("\n") // Split by line breaks
                          .map((line, lineIndex) => {
                            let modifiedLine = line;

                            // Check if the line contains an <a> tag
                            if (line.includes("<a")) {
                              modifiedLine = modifiedLine.replace(
                                /<a(.*?)>/g,
                                '<a$1 style="color: #4A90E2;">'
                              );
                            }

                            // Wrap each line in a <div> to ensure it appears on a new line without extra bullet points
                            return (
                              <div
                                key={lineIndex}
                                dangerouslySetInnerHTML={{
                                  __html: modifiedLine,
                                }}
                              />
                            );
                          })}
                      </PointDescription>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Stack>
        </RootStyle>

        {/* Footer */}
        <Footer />

        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </Box>
    </>
  );
};

export default BlogDetails;
