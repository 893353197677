import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";

const ModelSelection = ({ selectedModel, setSelectedModel, user }) => {
  return (
    <FormControl fullWidth size="small">
      <Select
        value={selectedModel}
        onChange={(e) => setSelectedModel(e.target.value)}
        // displayEmpty
        // displayEmpty={!user.subscription.planName === "pro"}
        displayEmpty={!["pro", "standard"].includes(user.subscription.planName)}
        inputProps={{ "aria-label": "Select Model" }}
        size="small"
        sx={{ fontSize: "12px", fontFamily: "Raleway" }}
      >
        {/* Default MenuItem */}
        <MenuItem
          value=""
          disabled
          sx={{ fontSize: "12px", fontFamily: "Raleway" }}
        >
          Select Model
        </MenuItem>

        {/* Models available to free and standard users */}
        {user.subscription.planName === "free-plan" && [
          <MenuItem
            value="OpenAI 4o-mini"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4o-mini
          </MenuItem>,

          <MenuItem
            value="Claude 3 Sonnet"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3 Sonnet (Standard)
          </MenuItem>,
          <MenuItem
            value="Claude 3 Haiku"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3 Haiku (Standard)
          </MenuItem>,

          <MenuItem
            value="Claude 3.5 Sonnet"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3.5 Sonnet (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="Gemini 1.5 pro"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Gemini 1.5 pro (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI 4-Turbo"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4-Turbo (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI 4o"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4o (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-preview"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-preview (beta) (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-mini"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-mini (beta) (Pro-Plan)
          </MenuItem>,
        ]}

        {user.subscription.planName === "standard" && [
          <MenuItem
            value="Gemini 1.5 pro"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Gemini 1.5 pro
          </MenuItem>,

          <MenuItem
            value="OpenAI 4o-mini"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4o-mini
          </MenuItem>,
          <MenuItem
            value="Claude 3 Sonnet"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3 Sonnet
          </MenuItem>,
          <MenuItem
            value="Claude 3 Haiku"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3 Haiku
          </MenuItem>,
          <MenuItem
            value="Claude 3.5 Sonnet"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3.5 Sonnet (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI 4-Turbo"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4-Turbo (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI 4o"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4o (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-preview"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-preview (beta) (Pro-Plan)
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-mini"
            disabled
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-mini (beta) (Pro-Plan)
          </MenuItem>,
        ]}

        {user.subscription.planName === "pro" && [
          <MenuItem
            value="Gemini 1.5 pro"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Gemini 1.5 pro
          </MenuItem>,

          <MenuItem
            value="Claude 3.5 Sonnet"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            Claude 3.5 Sonnet
          </MenuItem>,
          <MenuItem
            value="OpenAI 4-Turbo"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4-Turbo
          </MenuItem>,
          <MenuItem
            value="OpenAI 4o"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI 4o
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-preview"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-preview (beta)
          </MenuItem>,
          <MenuItem
            value="OpenAI o1-mini"
            sx={{ fontSize: "12px", fontFamily: "Raleway" }}
          >
            OpenAI o1-mini (beta)
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
};

export default ModelSelection;
