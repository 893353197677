import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, styled } from "@mui/material";
import { Grid, Typography, Link as MuiLink } from "@mui/material";
import ProjectLibrary from "../../../assets/images/NewImages/project-library-illustration.png";
import LiteratureReview from "../../../assets/images/NewImages/literature-review-illustration.png";
import TextEditor from "../../../assets/images/NewImages/text-editor-illustration.png";
import SearchImage from "../../../assets/images/NewImages/search-illustration.png";
import AskResearch from "../../../assets/images/NewImages/ask-researchpal-illustration.png";
import TextCitation from "../../../assets/images/NewImages/text-citations-illustration.png";
import { TypeAnimation } from "react-type-animation";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";
import OGMetaTags from "../OGMetaTags";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { featuresCard } from "./FeaturesCard";
import { addFeature } from "../blog/BlogSlice";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  background: "#fff",
  // marginTop: "10vh",
  padding: "0 102px 64px 102px",
  [theme.breakpoints.down("md")]: {
    padding: "0 10px 64px 10px",
  },
}));
const CustomCard = styled(Card)({
  margin: "0 auto",
  maxWidth: "334px",
  padding: "8px",
  borderRadius: "16px",
  minHeight: "435px",
  boxShadow: "0px 0px 24.35796356201172px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(12.17898178100586px)",
  display: "flex",
  flexDirection: "column", // Stack children vertically
  justifyContent: "space-between", // Push content to top and bottom
});
const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "22px",
  marginBottom: "8px",
});
const CardText = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "16px",
  //justify text to justified
  lineHeight: "24px",
});

export const ReadMoreLink = styled(MuiLink)({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "16px",
  textDecoration: "none",
  marginTop: "16px",
  display: "flex",
  color: "#1C2A51",
  justifyContent: "center",
  borderRadius: "8px",
  padding: "5px 0px",
  cursor: "pointer",
  background: "#f2f2f2",
  "&:hover": {
    background: "#edeef0",
  },
  width: "100%",
});

function Features() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("Features url", window.location.href);
  console.log("Features Card", featuresCard);
  const { user } = useSelector((state) => state.user);
  console.log("User", user);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  const handleCardClick = (item) => {
    setSelectedFeature(item);
    localStorage.setItem("selectedFeature", JSON.stringify(item));
    dispatch(addFeature(item));
    localStorage.setItem("Features", item);
    navigate(`/features/${item.id}`);
  };
  return (
    <Box>
      <OGMetaTags
        title="Features of ResearchPal"
        description="Discover the powerful features of ResearchPal, from literature review automation to insightful paper analysis."
        image={ProjectLibrary} // Use a representative image
        url={window.location.href}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "ResearchPal",
            "url": "https://researchpal.co/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://researchpal.co/features{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
      </Helmet>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <Typography
          variant="h1"
          sx={{
            color: "#14224B",
            fontWeight: 400,
            fontFamily: "Righteous",
            fontSize: "32px",
            textAlign: "center",
            pt: 6.4,
          }}
        >
          Augmenting Human Intelligence
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2.4 }}>
          <TypeAnimation
            sequence={[
              "For Students ...",
              1000,
              "For Researchers ...",
              1000,
              "For Industry Professionals ...",
              1500,
              "For Knowledge Workers ...",
              1500,
            ]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: "2em",
              display: "inline-block",
              color: "#15A68F",
              fontWeight: 500,
              fontFamily: "Raleway",
              fontSize: "24px",
            }}
            repeat={Infinity}
          />
        </Box>
        {/* <Grid container spacing={5.6}>
          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={LiteratureReview}
                title="preview"
                alt="Generating the literature review using new ideas."
              />
              <CardContent>
                <Title>Literature Review</Title>
                <CardText>
                  Generate a Literature Review for your research question in
                  seconds with authentic citations from credible research
                  sources.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={AskResearch}
                title="preview"
                alt="Robot reference generator"
              />
              <CardContent>
                <Title>Reference Generator</Title>
                <CardText>
                  Generate references in any format by adding the title of your
                  research paper or uploading a PDF.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>

          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={TextEditor}
                title="preview"
                alt="AI Tools rewriting the text"
              />
              <CardContent>
                <Title>Rewrite in Any Tone </Title>
                <CardText>
                  Rewrite your paper in any tone and ask ResearchPal to increase
                  or decrease the length of your text in different languages.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{ mt: { xs: 0, md: "1%" } }}>
          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={TextCitation}
                title="preview"
                alt="Hand holding the letter “T”"
              />
              <CardContent>
                <Title>In-Text Citations</Title>
                <CardText>
                  User ResearchPal to find authentic citations from credible
                  journals for your claims within seconds.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={SearchImage}
                title="preview"
                alt="Searching papers with a magnifying glass"
              />
              <CardContent>
                <Title>Search</Title>
                <CardText>
                  Optimise your literature search by instantly saving relevant
                  papers references and their abstracts. Find relevant papers
                  with our domain-specific Search.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>

          <Grid item md={4} xs={12} sx={{ mt: { xs: 0, md: "7%" } }}>
            <CustomCard>
              <CardMedia
                sx={{ height: 200, borderRadius: "16px" }}
                image={ProjectLibrary}
                title="preview"
                alt="Magnifying glass searching inside papers"
              />
              <CardContent>
                <Title>Paper Insights</Title>
                <CardText>
                  Analyse research papers at superhuman speed. Automate
                  summarizing papers, extracting methodology and contributions
                  or synthesizing your findings.
                </CardText>
              </CardContent>
            </CustomCard>
          </Grid>
        </Grid> */}

        <Grid container spacing={5.6}>
          {featuresCard.map((feature) => (
            <Grid
              key={feature.id}
              item
              md={4}
              xs={12}
              sx={{ mt: { xs: 0, md: "7%" } }}
            >
              <CustomCard>
                <CardMedia
                  sx={{ height: 200, borderRadius: "16px" }}
                  image={feature.img}
                  title="preview"
                  alt={feature.alt}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Title>{feature.title}</Title>
                  <CardText>
                    {feature.description.split("\n").map((part, index) => (
                      <React.Fragment key={index}>
                        {part}
                        {index < feature.description.split("\n").length - 1 && (
                          <br />
                        )}{" "}
                        {/* Only add <br /> if it's not the last part */}
                      </React.Fragment>
                    ))}
                  </CardText>
                </CardContent>
                <ReadMoreLink
                  // component={Link}
                  // to={`/features/${feature.id}`}

                  onClick={() => handleCardClick(feature)}
                >
                  Read More
                </ReadMoreLink>
              </CustomCard>
            </Grid>
          ))}
        </Grid>

        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </RootStyle>
      <Footer />
    </Box>
  );
}

export default Features;
