import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastSearchPapersRoute: null,
};

const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setLastSearchPapersRoute(state, action) {
      state.lastSearchPapersRoute = action.payload;
    },
    clearLastSearchPapersRoute(state,action){
        state.lastSearchPapersRoute=action.payload;
    }
  },
});

export const { setLastSearchPapersRoute, clearLastSearchPapersRoute } = routesSlice.actions;
export default routesSlice.reducer;
