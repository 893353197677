import React, { useEffect, useRef, useState } from "react";
import { Box, Button, List, ListItem, OutlinedInput } from "@mui/material";
import { FormControl, styled } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Slide, Stack, Typography } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import Toast from "../../components/Toast";
import CopyButton from "../../components/CopyButton";
import Person2Icon from "@mui/icons-material/Person2";
import Qstn from "../../assets/images/question.png";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Repo from "../../repo/Repo";
import { sendErrorMail } from "../../apiservice";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 500,
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 2,
  height: "100%",

  width: "100%",
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
}));
const MainStyle = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  height: "100%",
  padding: "16px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
}));

function AskFiles(props) {
  const scrollRef = useRef();
  const { data, projectData } = props;
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [tab, setTab] = React.useState(0);
  const [scroll, setScroll] = useState("");

  const Questions = [
    {
      question: "Q : Explain Abstract of this paper in 2 lines",
      // value: "summarized_abstracts",
      value: "Explain Abstract of this paper in 2 lines",
    },
    {
      question: "Q : Results of the paper",
      // value: "results",
      value: "Results of the paper",
    },
    {
      question: "Q : Methods used in this paper",
      // value: "methods_used",
      value: "Methods used in this paper",
    },
    {
      question: "Q : Summarise introduction of this paper",
      // value: "summarized_introductions",
      value: "Summarise introduction of this paper",
    },
    {
      question: "Q : What are the contributions of this paper",
      // value: "contributions",
      value: "What are the contributions of this paper",
    },
    {
      question: "Q : Explain the practical implications of this paper",
      // value: "practical_implications",
      value: "Explain the practical implications of this paper",
    },
    {
      question: "Q : Limitations of this paper",
      // value: "limitations",
      value: "Limitations of this paper",
    },
    {
      question: "Q : Literature survey of this paper",
      // value: "literature_survey",
      value: "Literature survey of this paper",
    },
    {
      question: "Q : What data has been used in this paper",
      // value: "datasets",
      value: "What data has been used in this paper",
    },
    // {
    //   question: "Q : Future work suggested in this paper",
    //   value: "future_work",
    // },
  ];
  //generation questions according to key value pair

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("buttons").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [scroll, tab]);

  const handleInput = (e) => {
    let { value } = e.target;
    setQuery(value);
  };

  const getData = async (item = "") => {
    setTab(1);
    if (!query && item.length === 0) {
      Toast("info", "Enter your query");
      return;
    }

    setScroll(item);

    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: `PDF Chat Library,${localStorage.getItem("FileKey")}`,
        error: "20 seconds",
        query: query || item,
        delayInResponse: true,
      });
    }, 20000);

    try {
      setLoading(true);
      let info = {};
      if (query) {
        info = {
          getAnswer: "true",
          query: query,
          fileKey: localStorage.getItem("FileKey"),
          email: localStorage.getItem("email"),
          token: localStorage.getItem("token") || "",
          title: localStorage.getItem("projectTitle"),
        };
      } else {
        info = {
          // generalQuestionsOperation: item,
          // s3Keys: localStorage.getItem("FileKey"),
          // paperKeys: "",
          // email: localStorage.getItem("email"),
          // title: localStorage.getItem("projectTitle"),
          // token: localStorage.getItem("token") || "",
          getAnswer: "true",
          query: item,
          fileKey: localStorage.getItem("FileKey"),
          email: localStorage.getItem("email"),
          token: localStorage.getItem("token") || "",
          title: localStorage.getItem("projectTitle"),
        };
      }
      let { data } = await Repo.script(info);
      const result = await data.data.response;

      clearTimeout(timeoutId);

      if (
        data?.data?.response &&
        data.data.response.includes(
          "The context provided does not contain information about"
        )
      ) {
        Toast(
          "error",
          "The context does not contain the requested information."
        );
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `PDF Chat Library,${localStorage.getItem("FileKey")}`,
          error: data.data.response,
          query: query || item,
        });
      }

      if (data?.data?.error !== "") {
        Toast("error", data?.data?.error);

        if (
          data.data.error ===
          "Chat limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `PDF Chat Library`,
            error: data.data.error,
            query: query,
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `PDF Chat Library`,
            error: data.data.error,
            query: query,
          });
        }
      }

      let key = query ? query : item;

      const question = Questions?.filter(
        (q) => q.value == key
      )[0]?.question.replace("Q :", "");

      const chatResponse = result;

      const raw = {
        query: item ? question : query,
        answer: chatResponse,
      };
      setScroll(chatResponse);
      setConversation([...conversation, raw]);
    } catch (error) {
      clearTimeout(timeoutId);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setQuery("");
    }
  };

  const handleGeneralQuestions = async (item) => {
    setTab(1);
    setQuery(item);
    await getData(item);
  };
  useEffect(() => {
    scrollToBottom();
    if (tab === 1) {
      scrollToBottom();
    }
    if (tab === 0) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [conversation, tab]);
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <RootStyle>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <MainStyle>
          {/* <KeyboardDoubleArrowRightIcon
            onClick={onBack}
            sx={{ color: "#35353599", cursor: "pointer" }}
          /> */}
          <Stack justifyContent="center" alignItems="center">
            <Typography>Ask Research Pal</Typography>
          </Stack>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={`Generals(${Questions.length + 1})`}
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="My Conversation"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
          <Scrollbar>
            <CustomTabPanel value={tab} index={0}>
              {Questions.map((item) => (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    background: "#FFF !important",
                    fontSize: "13px",
                    alignItems: "start",
                    justifyContent: "start",
                    textAlign: "start",
                  }}
                  fullWidth
                  onClick={() => handleGeneralQuestions(item.value)}
                >
                  {item.question}
                </Button>
              ))}
            </CustomTabPanel>
            <CustomTabPanel
              value={tab}
              index={1}
              sx={{
                scrollBehavior: "smooth",
                scrollMarginTop: "100px",
              }}
            >
              <div
                ref={scrollRef}
                style={{
                  height: "100%",
                }}
              >
                {conversation.length !== 0 ? (
                  <>
                    {conversation.map((item) => (
                      <Stack justifyContent="space-between" spacing={1.5}>
                        <Stack direction="row">
                          <Person2Icon
                            fontSize="15px"
                            sx={{ color: "#353535CC", mt: 0.5, mr: 1 }}
                          />
                          <Box
                            sx={{
                              background: "#F8F8F8",
                              padding: "8px",
                              borderRadius: "6px",
                            }}
                          >
                            <Text sx={{ fontSize: "14px" }}>{item.query}</Text>
                          </Box>
                        </Stack>
                        <Stack direction="row">
                          <img
                            src={Qstn}
                            alt="answer"
                            style={{
                              margin: "5px 7px 0px 0px",
                              height: "15px",
                            }}
                          />
                          <Box
                            sx={{
                              background: "#F8F8F8",
                              padding: "8px",
                              borderRadius: "6px",
                              marginBottom: 1,
                            }}
                          >
                            {Array.isArray(item.answer) ? (
                              item.answer.map((answer, index) => (
                                <List
                                  key={index}
                                  sx={{
                                    paddingTop: "0px !important",
                                    paddingBottom: "0px !important",
                                  }}
                                >
                                  <ListItem
                                    sx={{
                                      paddingTop: "0px !important",
                                      paddingBottom: "0px !important",
                                    }}
                                  >
                                    <Text sx={{ fontSize: "14px" }}>
                                      {answer}
                                    </Text>
                                  </ListItem>
                                </List>
                              ))
                            ) : (
                              <Text sx={{ fontSize: "14px" }}>
                                {item.answer}
                              </Text>
                            )}
                            {/* <Text sx={{ fontSize: "14px" }}></Text> */}

                            {item?.answer != "Something went wrong!" && (
                              <Stack flexDirection="row-reverse">
                                <CopyButton text={`${item.answer}`} />
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                      </Stack>
                    ))}
                    {loading && (
                      <Stack justifyContent="center" alignItems="center">
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#353535"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </Stack>
                    )}
                  </>
                ) : loading ? (
                  <Stack justifyContent="center" alignItems="center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#353535"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ marginBottom: "10px" }}
                  >
                    <img
                      src={Qstn}
                      alt="answer"
                      className="mr-2  "
                      style={{ height: "20px" }}
                    />
                    <Typography
                      sx={{
                        color: "rgba(53, 53, 53, 0.8)",
                        background: "rgba(248, 248, 248, 1)",
                        padding: "5px",
                        width: "170px",
                        borderRadius: "4px",
                      }}
                    >
                      How may I help you?
                    </Typography>
                  </Stack>
                )}
              </div>
            </CustomTabPanel>
          </Scrollbar>

          <FormControl
            fullWidth
            sx={{
              marginTop: "auto",
            }}
          >
            <OutlinedInput
              type="text"
              size="medium"
              // value={query}
              name="query"
              onChange={handleInput}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  getData();
                }
              }}
              sx={{ background: "#F8F8F8" }}
              placeholder="Message here ...."
              endAdornment={
                <InputAdornment position="start">
                  <button id="buttons" onClick={getData} disabled={loading}>
                    <SendIcon sx={{ color: "#353535" }} />
                  </button>
                </InputAdornment>
              }
            />
          </FormControl>
        </MainStyle>
      </Slide>
    </RootStyle>
  );
}

export default AskFiles;
