import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { TableCell, TableRow, tableCellClasses } from "@mui/material";
export const PriceTitle = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "30px",
  color: "#353535",
  textAlign: "center",
});
export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "13px",
  color: "#353535",
});

export const SubTitle = styled(Box)(({ theme }) => ({
  background: "#353535",
  color: "#ffffff",
  fontFamily: "Raleway",
  padding: "12px 37px",
  borderRadius: "6px",
  display: "flex",
  fontsize: "20px",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
  height: "36px",
  margin: "0 auto",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
}));
export const PlanCard = styled(Stack)(({ theme }) => ({
  height: "100%",
  minHeight: "660px",
  maxWidth: "368px",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-evenly",
  padding: "15px 19px",
  borderRadius: "12px",
  border: "1px solid black",
  [theme.breakpoints.down("sm")]: {
    minHeight: "660px", // Adjust this value as needed for smaller screens
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "110vh",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "660px",
  },
  // boxShadow: "0px 0px 24.35796356201172px 0px rgba(0, 0, 0, 0.08)",
  // backdropFilter: "blur(12.17898178100586px)",
}));
export const BtnSub = styled(Button)({
  color: "#353535",
  background: "#f3f3f3 !important",
  textTransform: "none",
});
export const BtnSubscribed = styled(Button)({
  color: "#f3f3f3",
  background: "#353535 !important",
  textTransform: "none",
  cursor: "default",
});

export const HeadingText = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "16px",
  color: "#353535",
});

export const CustomBox = styled(Box)({
  borderBottom: "3px solid #F3F3F3",
  display: "flex",
  width: "100%",
  padding: "8px",
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.grey,
    color: theme.palette.common.black,
    border: "none",
    fontFamily: "Raleway",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderTop: "none",
    border: "none",
    fontFamily: "Raleway",
    //content should be center
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
