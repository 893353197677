import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";

export const BibDownload = async (filesData) => {
  try {


    const bibStrings = filesData.map((item) => {
      return `@article{ ${
        //pick last name from author of the first author + year +ttle intial
        item.author.split(",")[0].split(" ")[1] +
        item.year +
        item.title.split(" ")[0]
      },
    title=${item.title || " "},
    author=${item.author || " "},
    journal=${item.journal | " "},
    volume=${item.volume || " "},
    number=${item.number || " "},
    pages=${item.pages || " "},
    year=${item.year || " "},
    publisher=${item.publisher || " "}
  }`;
    });

    const bibFileContent = bibStrings.join("\n\n");
    console.log("bibFileContent:", bibFileContent);
    let { data } = await Repo.otherFormats({
      request: {
        method: "createInsightsToBibTeX",
        data: [bibFileContent],
      },
    });
    console.log("data:", data);
    if (data?.response?.status?.statusCode === 200) {
      
      window.open(data?.response?.data?.success, "_blank");
      Toast("success", "Bib file downloaded");
    } else {
      Toast("error", "Error");
    }
  } catch (err) {
    console.log(err);
  }
};
