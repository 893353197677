import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
// import Contact from "../subscription/Contact";
// import ProCard from "./cards/ProCard";
// import EnterpriseCard from "./cards/EnterpriseCard";
// import FreeCard from "./cards/FreeCard";
// import StandardCard from "./cards/StandardCard";
import { BtnSub, Text } from "../cards/styles";
import { StackedBarChart } from "@mui/icons-material";
import FreeCard from "../cards/FreeCard";
import StandardCard from "../cards/StandardCard";
import ProCard from "../cards/ProCard";
import Contact from "../../subscription/Contact";
import LandingHeader from "../../../layout/header";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import Footer from "../../../layout/footer";
import NewCards from "../../subscription/new cards";
import { Helmet } from "react-helmet";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  // padding: "64px 202px 0 202px",
  marginInline: "10px",
  // marginTop: "10vh",
  marginBottom: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const CardGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  color: "#353535",
  textAlign: "center",
  marginBottom: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

function Pricing({ handleLogin }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    console.log("name:", name);
    if (name === "enterprise") {
      setState((prev) => ({
        ...prev,
        [name]: !prev[name],
        contactUsModal: true,
      }));
    } else if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else if (name === "standard" || name === "pro") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: true }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };
  // const toggle = () => {
  //   setOpen((prev) => !prev);
  // };

  const handleCheck = (arg) => {
    if (arg === "enterprise") {
      toggle("contactUsModal");
    } else if (arg === "standard") {
      handleLogin();
    } else if (arg === "pro") {
      handleLogin();
    }
  };

  return (
    <Box>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "ResearchPal",
              "url": "https://researchpal.co/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://researchpal.co/pricing{search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <NewCards onSelect={(e) => toggle(e)} />
        {state.contactUsModal && (
          <Contact
            open={state.contactUsModal}
            onClose={() => toggle("contactUsModal")}
          />
        )}
        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </RootStyle>
      <Footer />
    </Box>
  );
}

export default Pricing;
