import * as Popover from "@radix-ui/react-popover";
import React, { useEffect } from "react";
import { Surface } from "../../../../ui/Surface";
import { Spinner } from "../../../../ui/Spinner/Spinner";
import { Toolbar } from "../../../../ui/Toolbar";
import { BubbleMenu } from "@tiptap/react";
import { useTextmenuStates } from "../../hooks/useTextmenuStates";
import { Error } from "@mui/icons-material";

const AskResearchPalDialogue = ({
  dialogRef,
  handleAccept,
  handleReject,
  askPalData,
  loading,
  setShowDialog,
  setAskPalData,
  showDialog,
  editor,
  errorMessage,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setShowDialog(false);
        setAskPalData("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Popover.Root open={showDialog}>
      <Popover.Trigger asChild>
        <Toolbar.Button
          onClick={() => {
            setShowDialog(!showDialog);
          }}
          //   icon="research"
          //   tooltip="Ask Research Pal"
        />
      </Popover.Trigger>

      <Popover.Content
        asChild
        side="bottom"
        sideOffset={8}
        className="transform translate-y-0 translate-x-[213px] "
      >
        <Surface
          ref={dialogRef}
          className=" justify-center items-center p-2 min-w-[43rem] max-w-[43rem] "
        >
          {/* <div className="bg-gray-100 p-2 rounded-sm dark:bg-[#444444]"> */}
          <div className="bg-gray-100 p-2 rounded-md ">
            {loading ? (
              // <p className="flex space-x-2 dark:text-white">
              <p className="flex space-x-2 ">
                <Spinner className="mr-5" /> Please wait while your data is
                fetching{" "}
              </p>
            ) : !askPalData ? (
              // <p className="flex flex-col text-sm justify-center items-center dark:bg-[#444444] dark:text-white">
              <p className="flex flex-col text-sm justify-center items-center ">
                <Error fontSize="large" />
                {/* Something went wrong. Please try again!
                 */}
                {errorMessage
                  ? errorMessage
                  : "Something went wrong. Please try again!"}
              </p>
            ) : (
              <p className="text-sm text-black p-1 dark:text-black">
                {askPalData}
              </p>
            )}
          </div>

          <div className="mt-4 flex justify-end">
            <button
              // className="px-4 py-2 mr-2 bg-[#353535]  rounded text-white"
              // className="px-4 py-2 mr-2 bg-[#1E4F6B]  rounded text-white"
              className="px-4 py-2 mr-2 bg-[#F6F6F6] text-black rounded hover:bg-gray-500 hover:text-white"
              onClick={handleReject}
            >
              Reject
            </button>
            <button
              // className="px-4 py-2 bg-[#353535] text-white rounded "
              className="px-4 py-2 bg-[#1E4F6B] text-white rounded "
              onClick={handleAccept}
            >
              Accept
            </button>
          </div>
        </Surface>
      </Popover.Content>
    </Popover.Root>
  );
};

export default AskResearchPalDialogue;
