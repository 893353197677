import React from "react";
import { useSelector } from "react-redux"; // Ensure this is imported
import { FormControl, Select, MenuItem, Stack } from "@mui/material";
import TailSpinLoader from "../../components/TailSpinLoader";
import { ItemBox, RootStyle, Title, filterList } from "./styles";

const FormatSelector = ({
  value,
  onChange,
  loading,
  columnLoading,
  rowContent,
  disable,
}) => {
  const { user } = useSelector((state) => state.user); // Access user from Redux state

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          display: "flex",
          px: 2,
          py: 0,
          mb: 1,
        }}
      >
        <Select
          value={value}
          onChange={onChange}
          disabled={disable}
          sx={{
            alignSelf: "end",
            fontSize: "12px",
            fontFamily: "Raleway",
            border: "none",
            // "&.Mui-disabled": {
            //   border: "1px solid #C4C4C4",
            // },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#C4C4C4",
              borderWidth: "1px",
            },
          }}
        >
          <MenuItem
            value="short"
            sx={{
              fontSize: "12px",
              fontFamily: "Raleway",
            }}
          >
            Short
          </MenuItem>
          <MenuItem
            value="long"
            disabled={user?.subscription?.planName !== "pro"}
            sx={{
              fontSize: "12px",
              fontFamily: "Raleway",
            }}
          >
            Long
            {user?.subscription?.planName !== "pro" && " ( Pro plan )"}
          </MenuItem>
        </Select>
      </FormControl>
      {loading ? (
        <Stack alignItems="center" justifyContent="center">
          <TailSpinLoader />
        </Stack>
      ) : rowContent ? (
        <ItemBox>
          <Title
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              height: "200px",
              overflow: "auto",
            }}
          >
            {rowContent.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                <div>{line}</div>
                <br />
              </React.Fragment>
            ))}
          </Title>
        </ItemBox>
      ) : (
        <Stack alignItems="center" justifyContent="center">
          {columnLoading && <TailSpinLoader />}
        </Stack>
      )}
    </>
  );
};

export default FormatSelector;
