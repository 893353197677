import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  semanticLoading: false,
  error: "",
  semanticPapers: [],
  googlePapers: [],
  searchWindow: false,
  semanticPaperQuery: "",
  googlePaperQuery: "",
  onSearch: false,
  progress: 0,
  semanticSaveStatus: {},
  items:[],
  filters: {
    fieldsOfStudy: "",
    openAccessPdfs: false,
    publicationTypes: "",
    startYear:null,
    endYear:null,
    yearRange: null,
  },
  isFilterApplied: false,
  offsets: 0,
  isOpen: {
    papers: false,
    domains: false,
    publications: false,
  },
};

export const semanticPaperSlice = createSlice({
  name: "semanticPaper",
  initialState,
  reducers: {
    addGooglePapers: (state, action) => {
      state.googlePapers = [...state.googlePapers, ...action.payload];
    },
    addSemanticPapers: (state, action) => {
      state.semanticPapers = [...state.semanticPapers, ...action.payload];
    },
    addSemanticStatus: (state, action) => {
      state.semanticSaveStatus = action.payload;
    },
    clearSemanticStatus:(state)=>{
      state.semanticSaveStatus = initialState.semanticSaveStatus
    },
    addItems:(state,action)=>{
      state.items=action.payload;
    },
    clearItems:(state,action)=>{
      state.items=[];
    },
    setSemanticProgress: (state, action) => {
      state.progress =
        action.payload !== 0
          ? state.progress === 99
            ? 70
            : state.progress + action.payload
          : 0;
    },
    handleSemanticPaperQuery: (state, action) => {
      state.semanticPaperQuery = action.payload;
      // state.onSearch = false;
    },
    handleGooglePaperQuery: (state, action) => {
      state.googlePaperQuery = action.payload;
      // state.onSearch = false;
    },
    clearSemanticPapers: (state) => {
      state.semanticPapers = [];
      state.progress = 0;
    },

    clearGooglePapers: (state) => {
      state.googlePapers = [];
      state.progress = 0;
    },
    clearSemanticPaperQuery: (state) => {
      state.semanticPaperQuery = "";
      state.onSearch = false;
    },
    clearGooglePaperQuery: (state) => {
      state.googlePaperQuery = "";
      state.onSearch = false;
    },
    handleSemanticOnSearch: (state) => {
      state.onSearch = true;
    },

    setSemanticLoading: (state, action) => {
      state.semanticLoading = action.payload;
    },
    setSemFilters: (state, action) => {
      // state.filters = action.payload;
      state.filters = { ...state.filters, ...action.payload };
      state.isFilterApplied = true;
    },
    setOffsets: (state, action) => {
      state.offsets = action.payload;
    },
    clearSemFilters: (state) => {
      state.filters = initialState.filters;
      state.isFilterApplied = false;
    },
    clearOffsets: (state) =>{
      state.offsets = initialState.offsets;
    },
    toggleDropdown: (state, action) => {
      const dropdownName = action.payload;
      state.isOpen[dropdownName] = !state.isOpen[dropdownName];
    },
    clearToggleDropdown:(state, action) =>{
      state.isOpen = initialState.isOpen
    }
  },
});

export const {
  handleSemanticPaperQuery,
  clearSemanticPapers,
  addSemanticPapers,
  clearPaperQuery,
  handleSemanticOnSearch,
  setSemanticProgress,
  setSemanticLoading,
  addGooglePapers,
  clearGooglePapers,
  clearGooglePaperQuery,
  handleGooglePaperQuery,
  addSemanticStatus,
  clearSemanticPaperQuery,
  addItems,
  clearItems,
  clearSemFilters,
  setSemFilters, setOffsets, clearOffsets, clearSemanticStatus, toggleDropdown, clearToggleDropdown
} = semanticPaperSlice.actions;

export default semanticPaperSlice.reducer;
