import * as Popover from "@radix-ui/react-popover";
import DragHandle from "@tiptap-pro/extension-drag-handle-react";
import { useEffect, useState } from "react";
import { DropdownButton } from "../../ui/Dropdown/Dropdown";
import Icon from "../../ui/Icon";
import { Surface } from "../../ui/Surface";
import { Toolbar } from "../../ui/Toolbar";
import useContentItemActions from "./hooks/useContentItemActions";
import { useData } from "./hooks/useData";
import { Tooltip } from "../../ui/Tooltip/Tooltip";

export const ContentItemMenu = ({ editor }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const data = useData();
  const actions = useContentItemActions(
    editor,
    data.currentNode,
    data.currentNodePos
  );

  useEffect(() => {
    if (menuOpen) {
      editor.commands.setMeta("lockDragHandle", true);
    } else {
      editor.commands.setMeta("lockDragHandle", false);
    }
  }, [editor, menuOpen]);

  return (
    <DragHandle
      pluginKey="ContentItemMenu"
      editor={editor}
      onNodeChange={data.handleNodeChange}
      tippyOptions={{
        offset: [-2, 16],
        zIndex: 99999,
      }}
    >
      <div className="flex items-center gap-0.5">
        <Tooltip title="Add new line" delay={500}>
          <Toolbar.Button onClick={actions.handleAdd}>
            <Icon name="Plus" />
          </Toolbar.Button>
        </Tooltip>
        <Popover.Root open={menuOpen} onOpenChange={setMenuOpen}>
          <Tooltip title="Formating">
            <Popover.Trigger asChild>
              <Toolbar.Button>
                <Icon name="GripVertical" />
              </Toolbar.Button>
            </Popover.Trigger>
          </Tooltip>
          <Popover.Content side="bottom" align="start" sideOffset={8}>
            <Surface className="p-2 flex flex-col min-w-[16rem] ">
              <Popover.Close>
                <DropdownButton onClick={actions.resetTextFormatting}>
                  <Icon name="RemoveFormatting" />
                  Clear formatting
                </DropdownButton>
              </Popover.Close>
              <Popover.Close>
                <DropdownButton onClick={actions.copyNodeToClipboard}>
                  <Icon name="Clipboard" />
                  Copy to clipboard
                </DropdownButton>
              </Popover.Close>
              <Popover.Close>
                <DropdownButton onClick={actions.duplicateNode}>
                  <Icon name="Copy" />
                  Duplicate
                </DropdownButton>
              </Popover.Close>
              <Toolbar.Divider horizontal />
              <Popover.Close>
                <DropdownButton
                  onClick={actions.deleteNode}
                  className="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
                >
                  <Icon name="Trash2" />
                  Delete
                </DropdownButton>
              </Popover.Close>
            </Surface>
          </Popover.Content>
        </Popover.Root>
      </div>
    </DragHandle>
  );
};
